/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/core';
import IconPen from 'styles/image/icon_workboard_pen.svg';
import IconEraser from 'styles/image/icon_workboard_eraser.svg';
import IconDelete from 'styles/image/icon_workboard_delete.svg';
import IconSave from 'styles/image/icon_workboard_save.svg';
import { CanvasStat, Pen, Point, RoomData } from 'sagas/call/classes/RoomData';
import { DrawingDataSender } from 'sagas/call/classes/DrawingDataSender';
import { SfuRoom } from 'skyway-js';
import { CanvasTmpData } from 'reducers/call/workboard';
import PointerPen from 'styles/image/pointer_pen.png';
import PointerEraser from 'styles/image/pointer_eraser.png';
import { NOT_AVAILABLE_MSG } from 'components/home/Home';
import { COLORS, rounded, verticalScrollBar } from 'styles/style';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { CallCommonMessages } from '../CallCommonMessages';

const mainCss = css`
  background: repeating-linear-gradient(-45deg, #2b2d2d 0, #2b2d2d 10px, #383838 10px, #383838 11px);
  height: 100%;
  overflow: auto;
  padding: 20px 20px 20px 10px;
  width: 100%;

  &:hover {
    ${verticalScrollBar};

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${COLORS.gray};
    }
  }

  .canvas {
    height: 100%;

    .outer-content {
      display: table;
      height: 90%;
      width: 100%;

      .inner-content {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
`;

const canvasOuterCss = css`
  background: ${COLORS.white};
  border: solid 1px #000;
  height: 652px;
  margin: auto;
  width: 1302px;
`;

const canvasOptionCss = css`
  left: 4px;
  position: fixed;
  top: 4px;
  width: 100px;

  .option-item {
    align-items: center;
    display: flex;
    flex-flow: column;
    font-size: 12px;
  }

  .text {
    margin-bottom: 5px;
  }
`;

const iconWrapCss = css`
  ${rounded};
  align-items: center;
  background-color: ${COLORS.lightGray};
  border: 1px solid ${COLORS.gray};
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding: 20px 0 20px 0;

  .icon-workboard {
    background: none no-repeat center;
    background-size: 30px;
    box-sizing: border-box;
    height: 30px;
    width: 30px;

    a,
    button {
      background: none;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      display: block;
      height: 100%;
      margin-right: 5px;
      outline: 0;
      width: 100%;
    }

    &.pencil {
      background-image: url(${IconPen});
    }

    &.eraser {
      background-image: url(${IconEraser});
    }

    &.delete {
      background-image: url(${IconDelete});
    }

    &.save {
      background-image: url(${IconSave});
    }
  }
`;

const inputRange = css`
  ${rounded};
  -webkit-appearance: none;
  appearance: none;
  border: solid 1px ${COLORS.darkGray};
  height: 5px;
  margin: 5px 0 5px 0;
  width: 100%;

  /* -webkit-向けのつまみ */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: ${COLORS.black};
    border: 1px solid ${COLORS.lightGray};
    border-radius: 50%;
    height: 15px;
    width: 15px;
  }

  &.disabled::-webkit-slider-thumb {
    background: ${COLORS.darkGray};
  }
  /* -moz-向けのつまみ */
  &::-moz-range-thumb {
    background: ${COLORS.black};
    border: 1px solid ${COLORS.lightGray};
    border-radius: 50%;
    height: 15px;
    width: 15px;
  }

  &.disabled::-moz-range-thumb {
    background: ${COLORS.darkGray};
  }

  /* Firefoxで点線が周りに表示されてしまう問題の解消 */
  &::-moz-focus-outer {
    border: 0;
  }
`;

const inputColor = css`
  border: none;
  height: 36px;
  margin: -4px 0 -4px 0;
  width: 34px;
`;

function drawLine(ctx: CanvasRenderingContext2D, pen?: Pen, point?: Point) {
  if (pen && point) {
    ctx.lineWidth = pen.width; // 線の太さ
    ctx.beginPath();
    ctx.moveTo(point.bx, point.by);
    ctx.lineTo(point.x, point.y);
    ctx.strokeStyle = pen.color;
    ctx.stroke();
    ctx.closePath();
  }
}

function drawDot(ctx: CanvasRenderingContext2D, pen?: Pen, x?: number, y?: number) {
  if (pen && x && y) {
    ctx.beginPath();
    ctx.arc(x, y, pen.width / 2, 0, Math.PI * 2, false);
    ctx.fill();
  }
}

function clearCanvas(ctx: CanvasRenderingContext2D, width?: number, height?: number) {
  if (width && height) {
    ctx.fillStyle = '#ffffff';
    ctx.clearRect(0, 0, width, height);
    ctx.fillRect(0, 0, width, height);
  }
}
function drawOperation(drawData: CanvasStat, ctx: CanvasRenderingContext2D) {
  switch (drawData.operation) {
    case 'drawLine': {
      drawLine(ctx, drawData.pen, drawData.point);
      break;
    }
    case 'drawDot': {
      drawDot(ctx, drawData.pen, drawData.x, drawData.y);
      break;
    }
    case 'clear': {
      clearCanvas(ctx, drawData.x, drawData.y);
      break;
    }
    default: {
      break;
    }
  }
}
// ペンのデフォルト設定値
const DEFAULT_PEN_WIDTH = 1;
const DEFAULT_PEN_COLOR = '#000000';
const DEFAULT_PEN_MODE = 'pencil';

export interface CollaborativeCanvasProps {
  sender: DrawingDataSender | undefined;
  room: SfuRoom | undefined;
  tmpData: CanvasTmpData;
  currentUserId: string;
  sendCanvasTmpData: (data: string) => void;
}
/**
 * Collaborative Canvas
 */
export const Canvas: FC<CollaborativeCanvasProps> = ({ sender, room, tmpData, currentUserId, sendCanvasTmpData }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasOuterRef = useRef<HTMLDivElement>(null);
  const clearButtonRef = useRef<HTMLButtonElement>(null);
  const eraserButtonRef = useRef<HTMLButtonElement>(null);
  const penWidthSlideRef = useRef<HTMLInputElement>(null);
  const colorPickerRef = useRef<HTMLInputElement>(null);

  const [penWidth, setPenWidth] = useState(DEFAULT_PEN_WIDTH);
  const [penColor, setPenColor] = useState(DEFAULT_PEN_COLOR);
  const [penMode, setPenMode] = useState<'pencil' | 'eraser'>(DEFAULT_PEN_MODE);

  const pen: Pen = { mode: DEFAULT_PEN_MODE, width: DEFAULT_PEN_WIDTH, color: DEFAULT_PEN_COLOR };
  const point: Point = { x: 0, y: 0, bx: 0, by: 0 };
  const tmpDataUrl: { url: string } = { url: '' };

  useEffect(() => {
    if (room && sender) {
      const canvas = canvasRef.current;
      const clearButton = clearButtonRef.current;
      const eraserButton = eraserButtonRef.current;
      const penWidthSlide = penWidthSlideRef.current;
      const penColorPicker = colorPickerRef.current;

      if (canvas && clearButton && eraserButton && penWidthSlide && penColorPicker) {
        const ctx = canvas.getContext('2d');

        if (ctx) {
          // マウント時に他のメンバーが描画したデータがあれば描画する
          if (tmpData.tmpData && tmpData.isInitialized) {
            const img = new Image();
            img.src = tmpData.tmpData;
            img.onload = () => {
              ctx.drawImage(img, 0, 0);
            };
          } else if (!tmpData.isInitialized) {
            // 他のメンバーが描画した一時データも現在やりとりしているデータもなければ白塗り（初期化）
            ctx.fillStyle = '#FFFFFF';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }

          // データを受けっているところ
          room.on('data', async roomData => {
            const { data }: { data: RoomData } = roomData;
            if (data.type === 'canvas') {
              const histories = data.payload;
              histories.forEach(history => {
                drawOperation(history, ctx);
              });
            }
          });

          let drawing = false;

          canvas.addEventListener('mousedown', event => {
            if (!event) {
              return;
            }
            const target = event.target as HTMLCanvasElement;
            const rect = target.getBoundingClientRect();
            // クリック位置の取得
            const x = event.pageX - rect.left;
            const y = event.pageY - rect.top;

            switch (pen.mode) {
              case 'pencil':
              case 'eraser':
                drawing = true;
                // 位置の初期化
                point.bx = x;
                point.by = y;
                break;
              default: {
                break;
              }
            }
          });

          // 自分のキャンバスに描く＆全員に共有
          const draw = (drawData: CanvasStat) => {
            drawOperation(drawData, ctx);
            sender.enqueueAndSend(drawData);
          };

          canvas.addEventListener('mousemove', event => {
            const target = event.target as HTMLCanvasElement;
            const rect = target.getBoundingClientRect();
            if (drawing) {
              // マウス位置の取得(canvas内の相対座標)
              point.x = event.pageX - rect.left;
              point.y = event.pageY - rect.top;

              const drawData: CanvasStat = { operation: 'drawLine', pen, point };
              // 線の描画
              draw(drawData);

              // 位置履歴の更新
              point.bx = point.x;
              point.by = point.y;
            }
          });

          canvas.addEventListener('mouseup', event => {
            const target = event.target as HTMLCanvasElement;
            const rect = target.getBoundingClientRect();
            drawing = false;
            // 動かさずにマウスを離したとき
            if (point.bx !== point.x && point.by !== point.y) {
              // マウス位置の取得(canvas内の相対座標)
              const x = event.pageX - rect.left;
              const y = event.pageY - rect.top;

              const drawData: CanvasStat = { operation: 'drawDot', pen, x, y };
              // 点の描画
              draw(drawData);
            }
          });

          // クリアボタン
          clearButton.onclick = () => {
            const x = canvas.width;
            const y = canvas.height;

            const drawData: CanvasStat = { operation: 'clear', x, y };
            // クリア
            draw(drawData);
          };

          // 消しゴム・ペンの切り替え
          eraserButton.addEventListener('click', () => {
            if (pen.mode === 'pencil') {
              pen.mode = 'eraser';
              pen.color = '#ffffff';
              pen.width = 10;
            } else {
              pen.mode = 'pencil';
              pen.color = penColorPicker.value;
              pen.width = Number(penWidthSlide.value);
            }
          });
          // ペンの太さ
          penWidthSlide.addEventListener('change', event => {
            if (pen.mode === 'pencil') {
              const target = event.target as HTMLInputElement;
              pen.width = Number(target.value);
            }
          });

          // ペンの色
          penColorPicker.addEventListener('input', event => {
            if (pen.mode === 'pencil') {
              const target = event.target as HTMLInputElement;
              pen.color = target.value;
            }
          });
        }
      }

      return () => {
        room.removeAllListeners('data');
      };
    }

    return () => {};
  }, [tmpData.tmpData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return () => {};
    // ペンツール描画に参加しているメンバーのうちの一人が、3秒に1回一時データを更新する
    const setTmpData = setInterval(() => {
      const tmpCanvasData = canvas.toDataURL('image/jpeg', 0.7);
      if (currentUserId === tmpData.members[0] && tmpCanvasData !== tmpDataUrl.url) {
        sendCanvasTmpData(tmpCanvasData);
        tmpDataUrl.url = tmpCanvasData;
      }
    }, 3000);

    return () => clearInterval(setTmpData);
  }, [tmpData.members]); // eslint-disable-line react-hooks/exhaustive-deps

  const cursorIcon = penMode === 'pencil' ? PointerPen : PointerEraser;
  // 自動整形によるエラー回避
  // prettier-ignore
  const cursorCss = css`
    cursor: url(${cursorIcon}) 0 15, pointer;
`;

  return (
    <div css={mainCss}>
      <div className="canvas">
        <div className="outer-content">
          <div className="inner-content">
            <div css={canvasOuterCss} ref={canvasOuterRef}>
              {/* ユーザーごとに表示サイズが異なると描画できる範囲が変わってしまったり、リサイズすると描画内容が消えてしまうため、キャンバスサイズは固定でリサイズ不可とする */}
              <canvas ref={canvasRef} width={1300} height={650} css={cursorCss} />
              <CallCommonMessages disappear={false} />
              <div css={canvasOptionCss}>
                <div css={iconWrapCss}>
                  {penMode === 'pencil' ? (
                    <div className="option-item">
                      <span className="text">消しゴム</span>
                      <div className="icon-workboard eraser">
                        <button
                          ref={eraserButtonRef}
                          type="button"
                          data-testid="eraserButton"
                          onClick={() => setPenMode('eraser')}
                        >
                          &nbsp;
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="option-item">
                      <span className="text">ペン</span>
                      <div className="icon-workboard pencil">
                        <button
                          ref={eraserButtonRef}
                          type="button"
                          data-testid="eraserButton"
                          onClick={() => setPenMode('pencil')}
                        >
                          &nbsp;
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="option-item" css={penMode !== 'pencil' && { opacity: 0.2 }}>
                    <span className="text">色</span>
                    <input
                      css={inputColor}
                      type="color"
                      value={penColor}
                      onChange={e => setPenColor(e.target.value)}
                      ref={colorPickerRef}
                      disabled={penMode !== 'pencil'}
                    />
                  </div>
                  <div className="option-item" css={penMode !== 'pencil' && { opacity: 0.2 }}>
                    <span className="text">太さ</span>
                    <div css={{ width: '80px' }}>
                      <input
                        css={inputRange}
                        type="range"
                        min={1}
                        max={10}
                        step={0.5}
                        onChange={event => {
                          setPenWidth(Number(event.target.value));
                        }}
                        ref={penWidthSlideRef}
                        value={penWidth}
                        disabled={penMode !== 'pencil'}
                      />
                    </div>
                  </div>
                  <BasicPopup value={NOT_AVAILABLE_MSG} position="right">
                    <div className="option-item" css={{ opacity: '0.7' }}>
                      <span className="text">保存</span>
                      <div className="icon-workboard save">
                        <button type="button" data-testid="saveWorkboardButton">
                          &nbsp;
                        </button>
                      </div>
                    </div>
                  </BasicPopup>
                  <div className="option-item">
                    <span className="text">クリア</span>
                    <div className="icon-workboard delete">
                      <button ref={clearButtonRef} type="button" data-testid="clearWorkboardButton">
                        &nbsp;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
