/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { useAuthState } from 'react-firebase-hooks/auth';
import { PATH_DEMO, PATH_SIGN_IN_CLINKS } from 'RouteManager';
import { auth } from 'firebase/Instances';
import { RootState } from 'reducers/mainReducer';
import { commonActions } from 'actions/common/common';
import { setSplashDisplayed } from 'actions/splash/splash';
import Spinner from 'components/common/Spinner';
import { unSubscribeUserStatuses } from 'actions/home/subscriber';
import { SubscribeOnlineUsers } from './SubscribeOnlineUsers';

export const ServiceLoginManager: FC = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [authUser, loadingAuthState, authError] = useAuthState(auth);
  const loginFinished = useSelector<RootState, boolean>(state => state.auth.loginFinished);
  const splashDisplayed = useSelector<RootState, boolean>(state => state.splash.displayed);

  if (!loginFinished || loadingAuthState) {
    if (splashDisplayed) {
      return <div />;
    }

    return <Spinner text="ログインしています..." />;
  }

  if (authUser) {
    dispatch(setSplashDisplayed(false));

    return <SubscribeOnlineUsers authUser={authUser}>{children}</SubscribeOnlineUsers>;
  }

  if (authError) {
    dispatch(commonActions.error(authError.message));
  }

  dispatch(setSplashDisplayed(false));

  if (location.pathname === PATH_SIGN_IN_CLINKS) {
    return <Redirect to={PATH_SIGN_IN_CLINKS} />;
  }

  dispatch(unSubscribeUserStatuses.start());

  return <Redirect to={PATH_DEMO} />;
};
