/* eslint-disable global-require */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Home from 'components/home/Home';
import { RootState } from 'reducers/mainReducer';
import { CallingUser } from 'reducers/call/call';
import { showNotification } from 'common/Notification';
import { newMemberNotified } from 'actions/home/subscriber';

const HomeContainer: FC = () => {
  const dispatch = useDispatch();
  const nowJoiningTalkRoom = useSelector<RootState, boolean>(state => state.call.uiState.nowJoining);

  const localUser = useSelector<RootState, CallingUser | undefined>(state => state.call.localUser);
  const joined = localUser !== undefined;

  const isGuest = useSelector<RootState, boolean>(state => state.homeSubscriber.currentUserStatus.isGuest);
  const newGuestName = useSelector<RootState, string[]>(state => state.homeSubscriber.newGuestName);

  if (newGuestName.length > 0 && !isGuest) {
    newGuestName.forEach(newGuest => {
      showNotification({
        title: 'ゲストがログインしました',
        body: `ゲスト名：${newGuest}`,
        tag: newGuest,
      });
    });
    dispatch(newMemberNotified());
  }

  return <Home nowJoiningTalkRoom={nowJoiningTalkRoom} joined={joined} />;
};

export default HomeContainer;
