/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { User } from 'components/home/Home';
import { RootState } from 'reducers/mainReducer';
import OnlineMember from 'components/header/OnlineMember';

export const OnlineMemberContainer: FC = () => {
  const onlineMembers = useSelector<RootState, User[]>(state => state.homeSubscriber.onlineMembers);

  onlineMembers.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }

    return 0;
  });

  return <OnlineMember onlineMembers={onlineMembers} />;
};
