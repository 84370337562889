/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';

interface Props {
  mediaStream: MediaStream;
  parentRoomVolume: number;
}

export const Voice: FC<Props> = ({ mediaStream, parentRoomVolume }) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  // 音量用の useEffect
  useEffect(() => {
    const mediaNode = audioRef.current;
    if (mediaNode) {
      mediaNode.volume = parentRoomVolume;
    }
  }, [parentRoomVolume]);

  // 再生用の useEffect
  useEffect(() => {
    const mediaNode = audioRef.current;
    if (mediaNode) {
      mediaNode.srcObject = mediaStream;
      mediaNode.play().catch(error => console.log(error)); // eslint-disable-line no-console
    }
  }, [mediaStream]);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} />
    </div>
  );
};
