/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Dimmer, Image, Transition } from 'semantic-ui-react';
import ProductLogo from 'styles/image/logo_top.png';

const splashCss = css`
  background-color: #0f0f10;
  height: 100vh;
  width: 100vw;
`;

export const Splash: FC<{ displayed: boolean }> = ({ displayed }) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (displayed) {
      setVisible(true);
    }
  }, [displayed]);

  if (displayed) {
    return (
      <div css={splashCss}>
        <Dimmer active>
          <Transition visible={visible} animation="scale" duration={1000}>
            <Image size="big" src={ProductLogo} />
          </Transition>
        </Dimmer>
      </div>
    );
  }

  return <React.Fragment />;
};
