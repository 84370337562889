/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, cancel, put, select, take } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { SfuRoom } from 'skyway-js';
import { RootState } from 'reducers/mainReducer';
import { stopCallingActions } from 'actions/call/call';
import { CallingUser } from 'reducers/call/call';
import { analytics } from 'firebase/Instances';
import { clearWorkboardData } from 'actions/call/workboard';

/**
 * 通話の終了を待機するタスク。 通話終了時に発信中の招待があった場合には、watchSendingInvitationAction.ts の
 * subscribeLocalInvitation の unsubscribe 関数内でキャンセルを行っている。
 */
export function* watchLeavingRoomAction(uid: string) {
  while (true) {
    yield take(Action.STOP_CALLING_START);

    const room: SfuRoom = yield select((state: RootState) => state.call.room);
    if (!room) {
      continue; // eslint-disable-line no-continue
    }
    analytics.logEvent('leave_room', { uid, roomName: room.name });
    room.close();

    const remoteUsers: CallingUser[] = yield select((state: RootState) => state.call.remoteUsers);
    if (remoteUsers) {
      remoteUsers.forEach(u => u.stream?.stop());
    }

    const childTasks = yield select((state: RootState) => state.call.childTaskOfCall);
    if (childTasks) {
      yield cancel(childTasks);
    }
    yield call(api.updateMyCallStatusOnStopCalling);

    yield put(clearWorkboardData());

    yield put(stopCallingActions.succeed());
  }
}
