/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { TextArea } from 'semantic-ui-react';
import SendIcon from 'styles/image/call_send_chat.svg';
import { boldFont } from 'styles/style';
import { Spacer } from 'components/common/parts/Spacer';
import { useSendShortcut } from 'components/call/workboard/TextChat/useSendShortcut';

const chatFormCss = css`
  align-items: center;
  display: flex;

  .text-box {
    border: 1px solid #666;
    border-radius: 4px;
    height: 60px;
    padding: 10px;
    resize: none;
    width: 230px;
  }

  .send-button {
    background-image: url(${SendIcon});
    background-position: center;
    background-size: 30px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    margin-left: auto;
    outline: none;
    width: 30px;

    :disabled {
      filter: grayscale(100%);
    }
  }
`;

interface Props {
  sendTextChat: (text: string) => void;
}

export const ChatFooter: FC<Props> = ({ sendTextChat }) => {
  const [text, setText] = useState('');
  const { label, handleOnSendShortcut } = useSendShortcut(() => {
    sendTextChat(text);
    setText('');
  });

  const disabled = !text.length;

  return (
    <div>
      <div css={boldFont}>{label}</div>
      <Spacer height="5px" />
      <form css={chatFormCss} autoComplete="off">
        <TextArea
          type="text"
          name="textChat"
          onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setText(target.value);
          }}
          onKeyDown={handleOnSendShortcut}
          value={text}
          placeholder="メッセージを入力..."
          id="textChat"
          maxLength={500}
          className="text-box"
          autoComplete="off"
        />
        <button
          type="button"
          disabled={disabled}
          className="send-button"
          onClick={() => {
            sendTextChat(text);
            setText('');
          }}
        >
          &nbsp;
        </button>
      </form>
    </div>
  );
};
