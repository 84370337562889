/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import { CallableMember } from 'containers/call/modal/useCallableMembers';
import { MemberListItem } from 'components/call/modal/AddMemberModal/MemberListItem';
import { COLORS, roundedBottom, roundedTop } from 'styles/style';
import { BasicSearchBox } from 'components/common/parts/BasicSearchBox';

const teamListItem = css`
  border-bottom: solid 1px ${COLORS.gray};
  border-left: solid 1px ${COLORS.gray};
  border-right: solid 1px ${COLORS.gray};

  :first-of-type {
    ${roundedTop};
    border-top: solid 1px ${COLORS.gray};
  }

  :last-of-type {
    ${roundedBottom};
  }
`;

interface BaseProps {
  teamName: string;
  members: CallableMember[];
  right: JSX.Element;
}

const TeamListItemBase = ({ teamName, members, right }: BaseProps) => {
  return (
    <li css={teamListItem}>
      <div css={{ padding: '0 20px', display: 'flex', alignItems: 'center', height: 42, gap: 10 }}>
        <div css={{ flex: '1 1', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{teamName}</div>
        {right}
      </div>
      <ul css={{ backgroundColor: COLORS.lightGray }}>
        {members.length > 0 ? (
          members.map(member => <MemberListItem key={member.uid} member={member} />)
        ) : (
          <li css={{ height: 42, paddingLeft: 40, lineHeight: '42px', fontSize: '12px' }}>
            通話可能なメンバーがいません
          </li>
        )}
      </ul>
    </li>
  );
};

export const ALL_MEMBERS_ID = 'all';

interface Props {
  members: CallableMember[];
}

export const suppressAccordionOpenAndShut = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

export const AllTeamListItem = ({ members }: Props) => {
  const [queryStr, setQueryStr] = useState<string>('');

  return (
    <TeamListItemBase
      teamName="すべてのメンバー"
      members={members.filter(m => m.name.includes(queryStr))}
      right={
        <BasicSearchBox
          placeholder="名前を入力して検索..."
          onChange={e => setQueryStr(e.target.value)}
          boxSize="medium"
          onClick={suppressAccordionOpenAndShut}
        />
      }
    />
  );
};
