import * as ActionType from 'actions/ActionTypeConstants';
import { CanvasTmpData, ChatMessage, WorkboardFile } from 'reducers/call/workboard';
import { DrawingDataSender } from 'sagas/call/classes/DrawingDataSender';

export const joinWorkboard = {
  start: (roomId: string, creating: boolean) => ({
    type: ActionType.JOIN_WORKBOARD_START as typeof ActionType.JOIN_WORKBOARD_START,
    payload: { roomId, creating },
  }),
  success: () => ({
    type: ActionType.JOIN_WORKBOARD_SUCCESS as typeof ActionType.JOIN_WORKBOARD_SUCCESS,
  }),
};

export const workboardUploadFilesActions = {
  start: (file: File) => ({
    type: ActionType.WORKBOARD_UPLOAD_FILES_START as typeof ActionType.WORKBOARD_UPLOAD_FILES_START,
    payload: { file },
  }),
  success: () => ({
    type: ActionType.WORKBOARD_UPLOAD_FILES_SUCCESS as typeof ActionType.WORKBOARD_UPLOAD_FILES_SUCCESS,
  }),
  error: () => ({
    type: ActionType.WORKBOARD_UPLOAD_FILES_ERROR as typeof ActionType.WORKBOARD_UPLOAD_FILES_ERROR,
  }),
};

export const sendTextChatActions = {
  start: (text: string) => ({
    type: ActionType.SEND_TEXT_CHAT_START as typeof ActionType.SEND_TEXT_CHAT_START,
    payload: { text },
  }),
  success: () => ({
    type: ActionType.SEND_TEXT_CHAT_SUCCESS as typeof ActionType.SEND_TEXT_CHAT_SUCCESS,
  }),
  error: () => ({
    type: ActionType.SEND_TEXT_CHAT_ERROR as typeof ActionType.SEND_TEXT_CHAT_ERROR,
  }),
};

export const setTextChatScrollPoint = (point: number) => ({
  type: ActionType.SET_TEXT_CHAT_SCROLL_POINT as typeof ActionType.SET_TEXT_CHAT_SCROLL_POINT,
  payload: { point },
});

export const workboardDeleteFileActions = {
  start: (fileState: WorkboardFile) => ({
    type: ActionType.WORKBOARD_DELETE_FILE_START as typeof ActionType.WORKBOARD_DELETE_FILE_START,
    payload: { fileState },
  }),
  success: () => ({
    type: ActionType.WORKBOARD_DELETE_FILE_SUCCESS as typeof ActionType.WORKBOARD_DELETE_FILE_SUCCESS,
  }),
  error: () => ({
    type: ActionType.WORKBOARD_DELETE_FILE_ERROR as typeof ActionType.WORKBOARD_DELETE_FILE_ERROR,
  }),
};

export const setWorkboardOpenedAction = {
  sharingFiles: (isOpen: boolean) => ({
    type: ActionType.SET_WORKBOARD_OPENED_SHARING_FILES as typeof ActionType.SET_WORKBOARD_OPENED_SHARING_FILES,
    payload: { isOpen },
  }),
  textChat: (isOpen: boolean) => ({
    type: ActionType.SET_WORKBOARD_OPENED_TEXT_CHAT as typeof ActionType.SET_WORKBOARD_OPENED_TEXT_CHAT,
    payload: { isOpen },
  }),
  canvas: (isOpen: boolean) => ({
    type: ActionType.SET_WORKBOARD_OPENED_CANVAS as typeof ActionType.SET_WORKBOARD_OPENED_CANVAS,
    payload: { isOpen },
  }),
};

export const subscribeSharingFiles = (files: WorkboardFile[]) => ({
  type: ActionType.SUBSCRIBE_SHARING_FILES as typeof ActionType.SUBSCRIBE_SHARING_FILES,
  payload: { files },
});

export const subscribeTextChat = (chatMessage: ChatMessage[]) => ({
  type: ActionType.SUBSCRIBE_TEXT_CHAT as typeof ActionType.SUBSCRIBE_TEXT_CHAT,
  payload: { chatMessage },
});

export const clearSharingFilesNotice = () => ({
  type: ActionType.CLEAR_SHARING_FILES_NOTICE as typeof ActionType.CLEAR_SHARING_FILES_NOTICE,
});
export const clearTextChatNotice = () => ({
  type: ActionType.CLEAR_TEXT_CHAT_NOTICE as typeof ActionType.CLEAR_TEXT_CHAT_NOTICE,
});

export const getWorkboardFileUrlActions = {
  start: (fileState: WorkboardFile) => ({
    type: ActionType.GET_WORKBOARD_FILE_URL_START as typeof ActionType.GET_WORKBOARD_FILE_URL_START,
    payload: { fileState },
  }),
  success: (url: string) => ({
    type: ActionType.GET_WORKBOARD_FILE_URL_SUCCESS as typeof ActionType.GET_WORKBOARD_FILE_URL_SUCCESS,
    payload: { url },
  }),
  error: () => ({
    type: ActionType.GET_WORKBOARD_FILE_URL_ERROR as typeof ActionType.GET_WORKBOARD_FILE_URL_ERROR,
  }),
  clear: () => ({
    type: ActionType.GET_WORKBOARD_FILE_URL_CLEAR as typeof ActionType.GET_WORKBOARD_FILE_URL_CLEAR,
  }),
};

export const setCanvasSender = (sender: DrawingDataSender) => ({
  type: ActionType.SET_CANVAS_SENDER as typeof ActionType.SET_CANVAS_SENDER,
  payload: { sender },
});

export const sendCanvasTmpData = (tmpData: string) => ({
  type: ActionType.SEND_CANVAS_TMP_DATA as typeof ActionType.SEND_CANVAS_TMP_DATA,
  payload: { tmpData },
});

export const subscribeCanvasMembers = (members: string[]) => ({
  type: ActionType.SUBSCRIBE_CANVAS_MEMBERS as typeof ActionType.SUBSCRIBE_CANVAS_MEMBERS,
  payload: { members },
});

export const getCanvasHistoryActions = {
  start: () => ({
    type: ActionType.GET_CANVAS_HISTORY_START as typeof ActionType.GET_CANVAS_HISTORY_START,
  }),
  success: (tmpData: CanvasTmpData) => ({
    type: ActionType.GET_CANVAS_HISTORY_SUCCESS as typeof ActionType.GET_CANVAS_HISTORY_SUCCESS,
    payload: { tmpData },
  }),
  error: () => ({
    type: ActionType.GET_CANVAS_HISTORY_ERROR as typeof ActionType.GET_CANVAS_HISTORY_ERROR,
  }),
};

export const removeCanvasMember = () => ({
  type: ActionType.REMOVE_CANVAS_MEMBER as typeof ActionType.REMOVE_CANVAS_MEMBER,
});

export const clearWorkboardData = () => ({
  type: ActionType.CLEAR_WORKBOARD_DATA as typeof ActionType.CLEAR_WORKBOARD_DATA,
});
export type workboardAction =
  | ReturnType<typeof joinWorkboard.start>
  | ReturnType<typeof joinWorkboard.success>
  | ReturnType<typeof workboardUploadFilesActions.start>
  | ReturnType<typeof workboardUploadFilesActions.success>
  | ReturnType<typeof workboardUploadFilesActions.error>
  | ReturnType<typeof sendTextChatActions.start>
  | ReturnType<typeof sendTextChatActions.success>
  | ReturnType<typeof sendTextChatActions.error>
  | ReturnType<typeof setTextChatScrollPoint>
  | ReturnType<typeof workboardDeleteFileActions.start>
  | ReturnType<typeof workboardDeleteFileActions.success>
  | ReturnType<typeof workboardDeleteFileActions.error>
  | ReturnType<typeof setWorkboardOpenedAction.sharingFiles>
  | ReturnType<typeof setWorkboardOpenedAction.textChat>
  | ReturnType<typeof setWorkboardOpenedAction.canvas>
  | ReturnType<typeof subscribeSharingFiles>
  | ReturnType<typeof subscribeTextChat>
  | ReturnType<typeof clearSharingFilesNotice>
  | ReturnType<typeof clearTextChatNotice>
  | ReturnType<typeof getWorkboardFileUrlActions.start>
  | ReturnType<typeof getWorkboardFileUrlActions.success>
  | ReturnType<typeof getWorkboardFileUrlActions.error>
  | ReturnType<typeof getWorkboardFileUrlActions.clear>
  | ReturnType<typeof setCanvasSender>
  | ReturnType<typeof sendCanvasTmpData>
  | ReturnType<typeof subscribeCanvasMembers>
  | ReturnType<typeof getCanvasHistoryActions.start>
  | ReturnType<typeof getCanvasHistoryActions.success>
  | ReturnType<typeof getCanvasHistoryActions.error>
  | ReturnType<typeof removeCanvasMember>
  | ReturnType<typeof clearWorkboardData>;
