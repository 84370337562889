/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { css, jsx } from '@emotion/core';
import { CallableMember } from 'containers/call/modal/useCallableMembers';
import { CallButtonContainer } from 'containers/call/modal/CallButton';
import { VideoButtonContainer } from 'containers/call/modal/VideoButton';
import { getStatus } from 'components/common/Statuses';
import { COLORS } from 'styles/style';
import { Spacer } from 'components/common/parts/Spacer';
import { ICON_URL } from 'components/home/Home';

const createStatusLabel = (member: CallableMember): string => {
  if (member.isTalking) {
    return '通話中';
  }

  if (member.isCalling) {
    return '着信中';
  }

  return getStatus(member.status).label;
};

const memberListItem = css`
  align-items: center;
  display: flex;
  height: 42px;
  padding: 0 20px 0 40px;

  &:not(:last-of-type) {
    border-bottom: dashed 1px ${COLORS.darkGray};
  }
`;

interface Props {
  member: CallableMember;
}

export const MemberListItem = ({ member }: Props) => {
  return (
    <li css={memberListItem}>
      <img
        css={{
          backgroundColor: COLORS.white,
          border: 'solid 1px',
          borderRadius: 4,
          flex: '0 0 30px',
          height: 30,
          width: 30,
        }}
        src={ICON_URL}
        alt=""
      />
      <Spacer width="20px" />
      <div css={{ flex: '1 1', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{member.name}</div>
      <Spacer width="20px" />
      <div css={{ flex: '0 0 80px', fontSize: 12 }}>{createStatusLabel(member)}</div>
      <div css={{ flex: '0 0 50px', display: 'flex', alignItems: 'center' }}>
        <CallButtonContainer callableMembers={[member]} />
        <Spacer width="10px" />
        <VideoButtonContainer callableMembers={[member]} />
      </div>
    </li>
  );
};
