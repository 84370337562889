import * as ActionType from 'actions/ActionTypeConstants';

export interface UpdateCommentParams {
  comment: string;
  commentTime: number;
}

export const updateCommentActions = {
  start: (params: UpdateCommentParams) => ({
    type: ActionType.HOME_UPDATE_COMMENT,
    payload: params,
  }),
};

export type CommentAction = ReturnType<typeof updateCommentActions.start>;
