/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { CallModalBase } from 'components/call/modal/CallModalBase';
import { ModalBody } from 'components/call/modal/AddMemberModal/ModalBody';
import { CallableMember } from 'containers/call/modal/useCallableMembers';

interface Props {
  callableMembers: CallableMember[];
  onClose: () => void;
  onReload: () => void;
  loading: boolean;
  error: Error | undefined;
}

export const AddMemberModal: FC<Props> = ({ callableMembers, onClose, onReload, loading, error }) => {
  return (
    <CallModalBase
      title="通話メンバーを追加"
      handleOnClose={onClose}
      handleOnReload={loading ? undefined : onReload}
      height="400px"
    >
      <ModalBody callableMembers={callableMembers} loading={loading} error={error} />
    </CallModalBase>
  );
};
