/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { FC, SyntheticEvent, RefObject } from 'react';
import { rounded, COLORS, boldFont } from 'styles/style';

const commentBoxBaseCss = css`
  ${rounded};
  background-color: ${COLORS.navy};
  border: ${COLORS.white} solid 1px;
  color: ${COLORS.white};
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  padding-left: 10px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  span {
    display: inline-block;
    width: 40px;
  }
`;
const commentTextBoxCss = css`
  position: relative;

  .error-msg {
    ${boldFont};
    background-color: ${COLORS.white};
    bottom: 35px;
    color: ${COLORS.brown};
    font-size: 12px;
    left: 10px;
    position: absolute;
    width: 100%;
  }

  .error {
    background-color: #fefafa;
    color: ${COLORS.red};
  }

  input {
    ${commentBoxBaseCss};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    outline: none;

    ::placeholder {
      color: ${COLORS.white};
    }
  }
`;

const commentButtonCss = css`
  ${commentBoxBaseCss};
  outline: none;
`;

const EditableTextBox = ({
  changeComment,
  updateComment,
  currentString,
  inputRef,
  isError,
  msg,
}: {
  changeComment: (newComment: string) => void;
  updateComment: () => void;
  currentString: string;
  isError: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  msg?: string;
}) => {
  return (
    <div css={commentTextBoxCss}>
      {isError && <div className="error-msg">{msg}</div>}
      <input
        type="text"
        onChange={event => changeComment(event.target.value)}
        onBlur={updateComment}
        onKeyPress={e => {
          if (e.key === 'Enter') updateComment();
        }}
        value={currentString}
        ref={inputRef}
        data-testid="commentText"
        className={isError ? `error` : ''}
      />
    </div>
  );
};

const OthersComment = ({
  comment,
  commentTime,
  popUpVisible,
}: {
  comment: string;
  commentTime: string;
  popUpVisible: boolean;
}) => {
  if (comment && comment.length > 0) {
    return (
      <BasicPopup value={comment} position="top" color="lightBlue" forceOpened={popUpVisible}>
        <div css={commentBoxBaseCss}>
          <span>{`${commentTime.slice(0, -2)}:${commentTime.slice(-2)}`}</span>
          {comment}
        </div>
      </BasicPopup>
    );
  }

  return <div css={commentBoxBaseCss}>・・・</div>;
};

const EnteredCommentBox = ({
  comment,
  commentTime,
  startEdit,
  placeholder,
}: {
  comment: string;
  commentTime: string;
  startEdit?: () => void;
  placeholder: string;
}) => {
  if (comment && commentTime) {
    return (
      <BasicPopup value={comment} position="top" color="lightBlue">
        <button css={commentButtonCss} type="button" onClick={startEdit} data-testid="commentText">
          <span>{`${commentTime.slice(0, -2)}:${commentTime.slice(-2)}`}</span>
          {comment}
        </button>
      </BasicPopup>
    );
  }

  return (
    <button type="button" css={commentButtonCss} onClick={startEdit} data-testid="commentButton">
      <span>…</span>
      {placeholder}
    </button>
  );
};

interface CommentProps {
  updateComment?: () => void;
  changeComment?: (newComment: string, event?: SyntheticEvent) => void;
  startEdit?: () => void;
  comment: string;
  commentTime: string;
  currentString?: string;
  isOwn: boolean;
  isError?: boolean;
  isEditing?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  popUpVisible?: boolean;
}

const Comment: FC<CommentProps> = ({
  changeComment = () => {},
  updateComment = () => {},
  startEdit = () => {},
  comment,
  commentTime,
  currentString = '',
  isOwn = false,
  isError = false,
  isEditing = false,
  inputRef = undefined,
  popUpVisible = false,
}) => {
  if (!isOwn) return <OthersComment comment={comment} commentTime={commentTime} popUpVisible={popUpVisible} />;
  if (!isEditing)
    return (
      <EnteredCommentBox
        comment={comment}
        commentTime={commentTime}
        startEdit={startEdit}
        placeholder="クリックしてコメントを入力"
      />
    );

  return (
    <EditableTextBox
      changeComment={changeComment}
      updateComment={updateComment}
      currentString={currentString}
      inputRef={inputRef}
      isError={isError}
      msg="文字を減らしてください。"
    />
  );
};

export default Comment;
