import * as ActionType from 'actions/ActionTypeConstants';

export const commonActions = {
  clear: () => ({
    type: ActionType.COMMON_CLEAR as typeof ActionType.COMMON_CLEAR,
  }),
  success: (key: string, detail?: string) => ({
    type: ActionType.COMMON_SUCCESS as typeof ActionType.COMMON_SUCCESS,
    payload: { commonMsg: { key, detail } },
  }),
  error: (key: string, detail?: string) => ({
    type: ActionType.COMMON_ERROR as typeof ActionType.COMMON_ERROR,
    payload: { commonMsg: { key, detail } },
  }),
};

export type commonAction =
  | ReturnType<typeof commonActions.clear>
  | ReturnType<typeof commonActions.error>
  | ReturnType<typeof commonActions.success>;
