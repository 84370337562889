/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import { CallView } from 'components/call/CallView';
import { useDispatch, useSelector } from 'react-redux';
import { CallingUser } from 'reducers/call/call';
import { RootState } from 'reducers/mainReducer';
import { sendWhisperInvitationActions, setMaximizedUid, setNoticeForGuest } from 'actions/call/call';
import { WorkboardUiState } from 'reducers/call/workboard';
import { clearSharingFilesNotice } from 'actions/call/workboard';
import { commonActions } from 'actions/common/common';
import { CallNotification } from 'components/call/modal/CallNotification';
import { SharingFilesListener } from './workboard/listeners/SharingFilesListener';
import { TextChatListener } from './workboard/listeners/TextChatListener';

const widthHeight100 = css`
  height: 100%;
  width: 100%;
`;

export const CallViewContainer: FC = () => {
  const dispatch = useDispatch();
  const localUser = useSelector<RootState, CallingUser | undefined>(state => state.call.localUser);
  const remoteUsers = useSelector<RootState, CallingUser[]>(state => state.call.remoteUsers);
  const sentInvitations = remoteUsers.map(u => u.localInvitation);
  const whisperTargetUid = useSelector<RootState, string | undefined>(state => state.call.whisperTargetUid);
  const maximizedUid = useSelector<RootState, string | undefined>(state => state.call.uiState.maximizedUid);
  const workboardUiState = useSelector<RootState, WorkboardUiState>(state => state.workboard.uiState);
  const roomId = useSelector<RootState, string>(state => state.workboard.roomId);
  const noticed = useSelector<RootState, boolean>(state => state.call.noticed);
  const activated = useSelector<RootState, boolean>(state => state.workboard.activated);

  // ファイルが共有されたらメニューのワークボードアイコンに通知を出すeffect
  useEffect(() => {
    if (workboardUiState.fileAddNotice && !workboardUiState.sharingFilesOpened) {
      setTimeout(() => {
        dispatch(clearSharingFilesNotice());
      }, 3000);
    }
    if (workboardUiState.sharingFilesOpened) {
      dispatch(clearSharingFilesNotice());
    }
  }, [workboardUiState.fileAddNotice]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    return () => {
      dispatch(commonActions.clear());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // 自分の位置は左上固定にする
  const users = remoteUsers.slice();
  if (localUser) {
    users.unshift(localUser);
  }

  return (
    <div css={widthHeight100}>
      <CallView
        currentUserId={localUser?.uid || ''}
        sentInvitations={sentInvitations}
        users={users}
        maximizedUid={maximizedUid}
        handleOnExpandBtnClicked={uid => dispatch(setMaximizedUid(uid))}
        handleOnTileBtnClicked={() => dispatch(setMaximizedUid(undefined))}
        handleOnWhisperBtnClicked={(uid: string) => dispatch(sendWhisperInvitationActions.start(uid))}
        whisperTargetUid={whisperTargetUid}
        workboardUiState={workboardUiState}
      />
      {noticed && (
        <CallNotification
          msgKeys={['対応可能な担当者を呼び出しています。そのまましばらくお待ち下さい']}
          onClose={() => dispatch(setNoticeForGuest(false))}
        />
      )}
      {roomId && activated && (
        <div>
          <SharingFilesListener />
          <TextChatListener />
        </div>
      )}
    </div>
  );
};
