/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx, css } from '@emotion/core';
import UpdateLocationContainer from 'containers/home/Location';
import UpdateStatusContainer from 'containers/home/Status';
import AttendanceContainer from 'containers/home/Attendance';
import ImageViewContainer from 'containers/home/ImageView';
import CommentContainer from 'containers/home/Comment';
import CallingLabelContainer from 'containers/home/CallingLabel';
import { COLORS, boldFont } from 'styles/style';
import UpdateConditionContainer from 'containers/home/Condition';
import { User } from './Home';

const contentCss = css`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const personalSpacesCss = css`
  border-left: solid 1px ${COLORS.gray};
  box-sizing: border-box;
  width: 360px;

  @media (max-width: 779px) {
    width: 100%;
  }

  @media (min-width: 780px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: 33.33%;
  }

  @media (min-width: 1600px) {
    width: 25%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }
`;

const personalHeaderCss = css`
  ${boldFont};
  align-items: center;
  background: ${COLORS.black};
  box-sizing: border-box;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 10px;

  .name {
    color: ${COLORS.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* 全角の最大文字数に合わせた横幅としている */
    width: 145px;
  }

  .icon-wrap {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    line-height: 1;
  }
`;

const personalFooterCss = css`
  align-items: center;
  background-color: ${COLORS.navy};
  box-sizing: border-box;
  color: ${COLORS.white};
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: space-between;
  padding: 5px 10px;
  width: 100%;
`;

export interface MemberData extends User {
  isOwn: boolean;
}

export interface MemberDataProps {
  currentUserStatus: User;
  memberDataArray: MemberData[];
}

const MemberView: FC<MemberDataProps> = ({ currentUserStatus, memberDataArray }) => {
  const members = memberDataArray.map((memberData, index) => {
    if (memberData.isOnline) {
      return (
        <div css={personalSpacesCss} key={memberData.uid}>
          <div css={personalHeaderCss}>
            <div className="name" data-testid={`memberName_${index}`}>
              {memberData.name}
            </div>
            <div className="icon-wrap">
              <UpdateConditionContainer isOwn={memberData.isOwn} conditionCode={memberData.condition} />
              <UpdateLocationContainer isOwn={memberData.isOwn} locationCode={memberData.location} />
              <UpdateStatusContainer isOwn={memberData.isOwn} statusCode={memberData.status} />
              <AttendanceContainer
                isOwn={memberData.isOwn}
                onDuty={memberData.onDuty}
                startTime={memberData.startTime}
              />
            </div>
          </div>

          <ImageViewContainer
            uid={memberData.uid}
            isOwn={memberData.isOwn}
            demoUser={memberData.demoUser}
            isGuest={memberData.isGuest}
            myRoomId={currentUserStatus.roomId}
            roomId={memberData.roomId}
            status={memberData.status}
            breakStartTime={memberData.breakStartTime}
            userIsShade={memberData.isShade}
          />
          <div css={personalFooterCss}>
            <div css={{ width: 'calc(100% - 40px)' }}>
              <CommentContainer
                name={memberData.name}
                isOwn={memberData.isOwn}
                comment={memberData.comment}
                commentTime={memberData.commentTime}
              />
            </div>
            <CallingLabelContainer roomId={memberData.roomId} memberDataArray={memberDataArray} data-testid="Calling" />
          </div>
        </div>
      );
    }

    return null;
  });

  return <div css={contentCss}>{members}</div>;
};

export default MemberView;
