/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useRef } from 'react';
import { css, jsx } from '@emotion/core';
import { Image } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { Stream } from 'sagas/call/classes/Stream';
import { VideoType } from 'sagas/call/classes/RoomData';
import SpeakerAnimation from 'components/common/SpeakerAnimation';
import { baseZIndex } from 'styles/style';

const videoDivCss = css`
  height: 100%;
  position: relative;
  width: 100%;

  .video-block {
    height: 100%;
    width: 100%;
  }

  .speaker-frame {
    border: solid 4px #8cbe65;
    box-sizing: border-box;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    ${baseZIndex};
  }

  &.speaker {
    .sound-dots-wrap {
      background-color: rgba(24, 23, 31, 0.8);
      border-radius: 15px;
      bottom: 2px;
      display: block;
      left: 5px;
      padding: 13px 0 5px 20px;
      width: 65px;
    }

    .speaker-frame {
      display: block;
    }
  }

  &.whisper-on {
    border: solid 3px #f15340;
  }

  &.speaker.whisper-on {
    .sound-dots span {
      background-color: #fdce45;
    }
  }

  &::after {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.whisper-other::after {
    display: block;
  }

  &.sound-only {
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      object-fit: contain;
      width: 30%;
    }
  }
`;

const videoCssBase = css`
  height: 100%;
  width: 100%;
`;

const cameraStreamCss = css`
  ${videoCssBase};
  object-fit: cover;
`;

const cameraStreamMirrorCss = css`
  ${cameraStreamCss};
  transform: scaleX(-1);
`;

const displayStreamCss = css`
  ${videoCssBase};
  object-fit: contain;
`;

interface VideoProps {
  uid: string;
  iconUrl: string;
  stream?: Stream;
  videoType?: VideoType;
  videoEnabled?: boolean;
  nowSpeaking: boolean;
  myself: boolean;
  whisperTargetUid: string | undefined;
}

export const Video: FC<VideoProps> = ({
  uid,
  iconUrl,
  stream,
  videoType,
  videoEnabled,
  nowSpeaking,
  myself,
  whisperTargetUid,
}) => {
  const mediaRef = useRef<HTMLVideoElement>(null);
  const volume = useSelector<RootState, number>(state => state.call.parentRoomVolume);

  // 音量用の useEffect
  useEffect(() => {
    const mediaNode = mediaRef.current;
    if (mediaNode) {
      mediaNode.volume = volume;
    }
    // videoType も監視しておかないと音声 → 映像切替時などに音量がリセットされてしまう
  }, [volume, videoType]);

  useEffect(() => {
    const mediaNode = mediaRef.current;
    const mediaStream = stream?.getMediaStream();
    if (mediaNode && mediaStream) {
      mediaNode.srcObject = mediaStream;
      mediaNode
        .play()
        .then()
        .catch(e => {
          // メディアの再生開始 ~ 開始完了までの僅かな時間に通話メンバーの拡大縮小などによりアンマウントされると以下のエラーが発生する。
          // DOMException: The fetching process for the media resource was aborted by the user agent at the user's request.
          // Chrome, Safari ではコンソール上に出力されるだけだが、Firefox でのみスタックトレースが画面上に表示されてしまう。
          // そのため、例外的に DOMException の場合のみ外に throw しないようにしている。
          // REVISIT: メンバーの拡大縮小で本コンポーネントがアンマウントされないようにする、などの対応を実施して根本的な解決をするのが望ましい。
          if (e instanceof DOMException) {
            console.log(e); // eslint-disable-line no-console
          } else {
            throw e;
          }
        });
    }

    return () => {
      if (mediaNode) {
        mediaNode.pause();
        mediaNode.srcObject = null;
      }
    };
  }, [stream, videoType, uid]);

  const soundStyle = {
    display: 'none',
  };
  let videoCss;
  if (videoType === 'camera') {
    videoCss = myself ? cameraStreamMirrorCss : cameraStreamCss;
  } else {
    videoCss = displayStreamCss;
  }

  const whisperOther = whisperTargetUid ? !myself && whisperTargetUid !== uid : false;

  if (!stream || !videoEnabled) {
    return (
      <div css={videoDivCss} className={`${nowSpeaking && 'speaker'} sound-only ${whisperOther && 'whisper-other'}`}>
        <div className="video-block" style={soundStyle}>
          <audio css={videoCss} ref={mediaRef} muted={myself} />
        </div>
        <Image src={iconUrl} circular />
        <div className="speaker-frame">
          <SpeakerAnimation />
        </div>
      </div>
    );
  }

  return (
    <div css={videoDivCss} className={`${nowSpeaking && 'speaker'} ${whisperOther && 'whisper-other'}`}>
      <div className="video-block">
        <video css={videoCss} ref={mediaRef} muted={myself} playsInline preload="none" />
      </div>
      <div className="speaker-frame">
        <SpeakerAnimation />
      </div>
    </div>
  );
};
