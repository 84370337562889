/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorNotifyModal } from 'components/call/modal/ErrorNotifyModal';
import { RootState } from 'reducers/mainReducer';
import { clearMessages } from 'actions/call/call';
import { CallError } from 'reducers/call/call';

export const ErrorNotifyModalContainer: FC = () => {
  const dispatch = useDispatch();
  const errors = useSelector<RootState, CallError[]>(state => state.call.uiState.errors);

  const open = errors.length > 0;
  const handleOnClose = () => {
    dispatch(clearMessages());
  };

  return open ? <ErrorNotifyModal errors={errors} close={handleOnClose} /> : <div />;
};
