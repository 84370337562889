import * as ActionType from 'actions/ActionTypeConstants';
import { User } from 'components/home/Home';

export const subscribeMyUsers = {
  start: (currentUserStatus: User) => ({
    type: ActionType.HOME_SUBSCRIBE_MY_USERS_DATA as typeof ActionType.HOME_SUBSCRIBE_MY_USERS_DATA,
    payload: { currentUserStatus },
  }),
};

export const subscribeNowLoginUsers = {
  start: (nowLoginUser: User) => ({
    type: ActionType.HOME_SUBSCRIBE_NOW_LOGIN_USERS_DATA as typeof ActionType.HOME_SUBSCRIBE_NOW_LOGIN_USERS_DATA,
    payload: { nowLoginUser },
  }),
};

export const removeNowLoginUser = (uid: string) => ({
  type: ActionType.HOME_UNSUBSCRIBE_NOW_LOGIN_USERS_DATA as typeof ActionType.HOME_UNSUBSCRIBE_NOW_LOGIN_USERS_DATA,
  payload: { uid },
});

export const unSubscribeUserStatuses = {
  start: () => ({
    type: ActionType.HOME_UNSUBSCRIBE_USER_STATUSES as typeof ActionType.HOME_UNSUBSCRIBE_USER_STATUSES,
  }),
};

export const newMemberNotified = () => ({
  type: ActionType.HOME_NEW_MEMBER_NOTIFIED as typeof ActionType.HOME_NEW_MEMBER_NOTIFIED,
});

export type SubscribeAction =
  | ReturnType<typeof subscribeMyUsers.start>
  | ReturnType<typeof subscribeNowLoginUsers.start>
  | ReturnType<typeof removeNowLoginUser>
  | ReturnType<typeof unSubscribeUserStatuses.start>
  | ReturnType<typeof newMemberNotified>;
