/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { CallingUser, NONACTIVE_STATUSES } from 'reducers/call/call';
import { StandbyUser } from 'components/call/StandbyUser';
import { useDispatch } from 'react-redux';
import { removeRemoteUserAfter3Sec, storeCallError } from 'actions/call/call';

interface StandbyUserContainerProps {
  user: CallingUser;
  isSideView?: boolean;
}

export const StandbyUserContainer: FC<StandbyUserContainerProps> = ({ user, isSideView = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (NONACTIVE_STATUSES.includes(user.status)) {
      dispatch(removeRemoteUserAfter3Sec.start(user.uid));
    }

    return () => {};
  }, [dispatch, user.uid, user.status]);

  const onCancelClicked = () => {
    try {
      if (user.localInvitation) {
        user.localInvitation.cancel();
      }
    } catch (error) {
      dispatch(storeCallError(error));
    }
  };

  return (
    <StandbyUser
      iconUrl={user.iconUrl}
      status={user.status}
      onCancelClicked={onCancelClicked}
      isSideView={isSideView}
    />
  );
};
