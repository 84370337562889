/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeSharingFiles } from 'actions/call/workboard';
import { RootState } from 'reducers/mainReducer';
import { WorkboardFile } from 'reducers/call/workboard';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'firebase/Instances';
import { commonActions } from 'actions/common/common';

export const SharingFilesListener: FC = () => {
  const roomId = useSelector<RootState, string>(state => state.workboard.roomId);
  const currentFiles = useSelector<RootState, WorkboardFile[]>(state => state.workboard.uiState.files);
  const dispatch = useDispatch();

  const [fileStates, loading, error] = useCollectionData<WorkboardFile>(
    db
      .collection('workboards')
      .doc(roomId)
      .collection('files')
      .orderBy('dirPath', 'desc'),
    { idField: 'id' },
  );

  if (loading) {
    return <div />;
  }

  if (error) {
    dispatch(commonActions.error('ファイル情報の取得に失敗しました。'));
  }

  if (fileStates && fileStates?.length !== currentFiles.length) {
    dispatch(subscribeSharingFiles(fileStates));
  }

  return <div />;
};
