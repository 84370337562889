import { takeLatest, call } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { updateStatusActions } from 'actions/home/updateStatus';

function* runUpdateStatus(action: ReturnType<typeof updateStatusActions.start>) {
  const { status, breakStartTimeValue } = action.payload;

  try {
    const handler = api.updateStatusFactory();
    yield call(handler, status, breakStartTimeValue);
  } catch (error) {
    // nothing to do
  }
}

export function* watchUpdateStatus() {
  yield takeLatest(Action.HOME_UPDATE_STATUS, runUpdateStatus);
}
