/** @jsx jsx */
import { FC, useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/core';
import IconSelectBox from 'styles/image/icon_selectBox.svg';
import {
  boldFont,
  COLORS,
  fontSizePlaceholder,
  popUpAndToastZIndex,
  rounded,
  textOverflowEllipsis,
} from 'styles/style';
import { useOutsideClick } from 'hooks/useOutsideClick';

export type BasicSelectBoxOption = { key: string; label: string };

const labelCss = css`
  ${boldFont}
  margin-bottom: 4px;
`;

const selectBoxContainer = css`
  ${fontSizePlaceholder}
  color: ${COLORS.darkGray};
  position: relative;
  width: 100%;
`;

const selectedItem = css`
  ${rounded}
  align-items: center;
  background-color: ${COLORS.white};
  border: solid 1px ${COLORS.darkGray};
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 11px 14px;
  width: 100%;

  .selectedText {
    ${textOverflowEllipsis}
    flex: 1 1;
    padding-right: 10;
  }

  .rightIcon {
    flex: 0 0 14px;
    height: 12;
  }
`;

const list = css`
  ${rounded}
  ${fontSizePlaceholder}
  ${popUpAndToastZIndex}
  background-color: ${COLORS.white};
  border: solid 1px ${COLORS.darkGray};
  left: 0;
  margin-top: 3px;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  top: 40px;
  width: 100%;
`;

const listItem = css`
  align-items: center;
  display: flex;
  height: 36px;
  padding: 11px 14px;
  width: 100%;

  &:hover {
    background-color: #e8fcff;
  }

  .optionText {
    ${textOverflowEllipsis}
  }
`;

type OptionsProps = {
  options: BasicSelectBoxOption[];
  handleOnSelectItem: (key: string) => void;
  forceScrollInListOpened: boolean;
};

const BasicSelectBoxOptions: FC<OptionsProps> = ({ options, handleOnSelectItem, forceScrollInListOpened }) => {
  const optionsRef = useRef<HTMLDivElement>(null);

  // スクロールコンテナの最下部などでリストを開いた後に、リストの全容が見えるように自動でスクロールさせる
  useEffect(() => {
    const { current } = optionsRef;
    if (current && forceScrollInListOpened) {
      current.scrollIntoView();
    }
  }, [forceScrollInListOpened]);

  return (
    <div ref={optionsRef} css={list}>
      {options.map(option => {
        return (
          <div
            key={option.key}
            css={listItem}
            onClick={() => handleOnSelectItem(option.key)}
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
          >
            <div className="optionText">{option.label}</div>
          </div>
        );
      })}
    </div>
  );
};

/**
 * セレクトボックス
 * 指定したキーがリストにない場合、そのキーを直接ラベルとして表示します。
 *
 * @param selectedKey - 選択中 (初期値) のキー
 * @param selectList - 選択肢
 * @param handleOnSelectItem - 値が選択された時のアクション
 * @param label - セレクトボックス上部に表示するラベル (任意)
 * @param forceScrollInListOpened - true の場合リストを開いた際に見える位置にスクロールする (デフォルトは false)
 * @returns セレクトボックスを表示
 * @example
 * ```
 * <BasicSelectBox
 *   selected="初期値"
 *   selectList={[
 *   { key: '1', label: '選択肢1' },
 *   { key: '2', label: '選択肢2' },
 *   ]}
 *   handleOnSelectItem={key => handleOnSelectItem(key)}
 *   />
 * ```
 */
export const BasicSelectBox: FC<{
  selectedKey: string;
  selectList: BasicSelectBoxOption[];
  handleOnSelectItem: (key: string) => void;
  label?: string;
  forceScrollInListOpened?: boolean;
}> = ({ selectedKey, selectList, handleOnSelectItem, label, forceScrollInListOpened = false }) => {
  const [listOpened, setListOpened] = useState(false);
  const selectBoxRef = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: selectBoxRef, handleOnOutsideClick: () => setListOpened(false) });

  const currentSelectedLabel = selectList.find(s => s.key === selectedKey)?.label || selectedKey;

  return (
    <div>
      {label && <div css={labelCss}>{label}</div>}
      <div
        css={selectBoxContainer}
        ref={selectBoxRef}
        onClick={() => setListOpened(!listOpened)}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        <div css={selectedItem}>
          <div className="selectedText">{currentSelectedLabel}</div>
          <img className="rightIcon" src={IconSelectBox} alt="" />
        </div>
        {listOpened && selectBoxRef.current && (
          <BasicSelectBoxOptions
            options={selectList}
            handleOnSelectItem={handleOnSelectItem}
            forceScrollInListOpened={forceScrollInListOpened}
          />
        )}
      </div>
    </div>
  );
};
