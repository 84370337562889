/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import SharingScreenIcon from 'styles/image/call_sharing_screen.svg';
import SharingScreenEndIcon from 'styles/image/call_sharing_screen_end.svg';
import AddMemberIcon from 'styles/image/call_add_member.svg';
import AudioOnIcon from 'styles/image/call_audio_on.svg';
import AudioOffIcon from 'styles/image/call_audio_off.svg';
import VideoOnIcon from 'styles/image/call_video_on.svg';
import VideoOffIcon from 'styles/image/call_video_off.svg';
import VideoDisabledIcon from 'styles/image/call_video_disabled.svg';
import DisconnectIcon from 'styles/image/call_disconnect.svg';
import SettingIcon from 'styles/image/call_settings.svg';
import WhisperEndIcon from 'styles/image/call_whisper_end.svg';
import VolumeIcon from 'styles/image/call_volume.svg';
import SharingFileIcon from 'styles/image/call_sharing_file.svg';
import ChatIcon from 'styles/image/call_chat.svg';
import PaletteIcon from 'styles/image/call_palette.svg';
import BackToHomeIcon from 'styles/image/call_back_to_home.svg';
import { WorkboardUiState } from 'reducers/call/workboard';
import { NOT_AVAILABLE_MSG } from 'components/home/Home';
import { COLORS, rounded } from 'styles/style';
import { CallMenuButton } from './CallMenuButton';

const menuContainer = css`
  align-items: center;
  background-color: ${COLORS.black};
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  gap: 30px;
  height: 100%;
  justify-content: center;
  left: 0;
  width: 100%;
`;

const inputRangeCss = css`
  ${rounded};
  -webkit-appearance: none; /* これ無しだとスタイルがほぼ全く反映されないので注意 */
  appearance: none;
  border: solid 1px ${COLORS.darkGray}; /* バーまわりの線 */
  border-radius: 10px;
  cursor: pointer; /* カーソルを分かりやすく */
  display: block;
  height: 7px; /* バーの高さ */
  margin: 25px 0 20px 0;
  outline: 0; /* アウトラインを消して代わりにfocusのスタイルをあてる */
  width: 100%; /* スライダーの幅 */

  /* -webkit-向けのつまみ */
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* デフォルトのつまみのスタイルを解除 */
    background: #53aeff; /* 背景色 */
    border-radius: 50%; /* 円形に */
    height: 20px; /* 高さ */
    width: 20px; /* 幅 */
  }
  /* -moz-向けのつまみ */
  &::-moz-range-thumb {
    background: #53aeff; /* 背景色 */
    border: none; /* デフォルトの線を消す */
    border-radius: 50%; /* 円形に */
    height: 20px; /* 高さ */
    width: 20px; /* 幅 */
  }

  /* Firefoxで点線が周りに表示されてしまう問題の解消 */
  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    box-shadow: 0 0 3px rgb(0, 161, 255);
  }
`;

export interface CallViewMenuProps {
  isSharing: boolean;
  audioEnabled: boolean;
  videoEnabled: boolean;
  videoButtonEnabled: boolean;
  handleOnLeaveClick: () => void;
  handleOnScreenClick: () => void;
  handleOnStopScreenClick: () => void;
  handleOnAudioOffClick: () => void;
  handleOnAudioOnClick: () => void;
  handleOnVideoOffClick: () => void;
  handleOnVideoOnClick: () => void;
  handleOnAddMemberClick: () => void;
  handleOnDeviceSettingClick: () => void;
  nowWhispering: boolean;
  handleOnWhisperStopped: () => void;
  handleOnChangeParentRoomVolume: (volume: number) => void;
  parentRoomVolume: number;
  handleOnSharingFilesClick: () => void;
  handleOnTextChatClick: () => void;
  handleOnCanvasClick: () => void;
  workboardUiState: WorkboardUiState;
  handleOnBackClick: () => void;
  isGuest: boolean;
}

export const CallMenu: FC<CallViewMenuProps> = ({
  isSharing = false,
  audioEnabled = true,
  videoEnabled = true,
  videoButtonEnabled = true,
  handleOnLeaveClick = () => {},
  handleOnScreenClick = () => {},
  handleOnStopScreenClick = () => {},
  handleOnAudioOffClick = () => {},
  handleOnAudioOnClick = () => {},
  handleOnVideoOffClick = () => {},
  handleOnVideoOnClick = () => {},
  handleOnAddMemberClick = () => {},
  handleOnDeviceSettingClick = () => {},
  nowWhispering = true,
  handleOnChangeParentRoomVolume,
  handleOnWhisperStopped = () => {},
  parentRoomVolume,
  handleOnSharingFilesClick = () => {},
  handleOnTextChatClick = () => {},
  handleOnCanvasClick = () => {},
  workboardUiState,
  handleOnBackClick = () => {},
  isGuest,
}) => {
  const sharingScreenButton = !isSharing ? (
    <CallMenuButton
      iconUrl={SharingScreenIcon}
      onClick={handleOnScreenClick}
      popupContent={<div>画面共有を開始</div>}
    />
  ) : (
    <CallMenuButton
      iconUrl={SharingScreenEndIcon}
      onClick={handleOnStopScreenClick}
      popupContent={<div>画面共有を終了</div>}
    />
  );

  const addMemberButton = isGuest ? (
    <CallMenuButton
      iconUrl={AddMemberIcon}
      popupContent={<div>{NOT_AVAILABLE_MSG}</div>}
      disabled
      css={{ opacity: '0.7' }}
    />
  ) : (
    <CallMenuButton iconUrl={AddMemberIcon} onClick={handleOnAddMemberClick} popupContent={<div>メンバー追加</div>} />
  );

  const audioButton = audioEnabled ? (
    <CallMenuButton iconUrl={AudioOnIcon} onClick={handleOnAudioOnClick} popupContent={<div>マイクOFFにする</div>} />
  ) : (
    <CallMenuButton iconUrl={AudioOffIcon} onClick={handleOnAudioOffClick} popupContent={<div>マイクONにする</div>} />
  );

  const deviceSettingButton = (
    <CallMenuButton iconUrl={SettingIcon} onClick={handleOnDeviceSettingClick} popupContent={<div>デバイス設定</div>} />
  );

  // eslint-disable-next-line no-nested-ternary
  const videoButton = videoButtonEnabled ? (
    videoEnabled ? (
      <CallMenuButton iconUrl={VideoOnIcon} onClick={handleOnVideoOnClick} popupContent={<div>ビデオOFFにする</div>} />
    ) : (
      <CallMenuButton iconUrl={VideoOffIcon} onClick={handleOnVideoOffClick} popupContent={<div>ビデオONにする</div>} />
    )
  ) : (
    <CallMenuButton disabled iconUrl={VideoDisabledIcon} popupContent={<div>画面共有中は切り替えできません</div>} />
  );

  const stoppingWhisperButton = (
    <CallMenuButton
      iconUrl={WhisperEndIcon}
      onClick={handleOnWhisperStopped}
      popupContent={<div>ひそひそを終了</div>}
      size={40}
    />
  );

  const canvasButton = (
    <CallMenuButton
      iconUrl={PaletteIcon}
      onClick={handleOnCanvasClick}
      popupContent={workboardUiState.canvasOpened ? <div>ワークボードを閉じる</div> : <div>ワークボードを開く</div>}
    />
  );

  const sharingFilesButton = (
    <CallMenuButton
      iconUrl={SharingFileIcon}
      onClick={handleOnSharingFilesClick}
      popupContent={
        workboardUiState.fileAddNotice ? <div>新しいファイルが共有されました。</div> : <div>ファイル共有を開く</div>
      }
      forcePopupOpened={workboardUiState.fileAddNotice}
    />
  );

  const textChatButton = (
    <CallMenuButton
      iconUrl={ChatIcon}
      onClick={handleOnTextChatClick}
      popupContent={
        workboardUiState.textChatOpened ? <div>テキストチャットを閉じる</div> : <div>テキストチャットを開く</div>
      }
    />
  );

  const changeParentRoomVolume = (
    <CallMenuButton
      disabled
      iconUrl={VolumeIcon}
      popupContent={
        <label htmlFor="parent-volume">
          周りのメンバーの音量
          <input
            id="parent-volume"
            css={inputRangeCss}
            type="range"
            min="0"
            max="10"
            defaultValue={parentRoomVolume * 10}
            onChange={event => handleOnChangeParentRoomVolume(Number(event.target.value) / 10)}
          />
        </label>
      }
    />
  );

  const backButton = (
    <CallMenuButton iconUrl={BackToHomeIcon} onClick={handleOnBackClick} popupContent={<div>ホームへ戻る</div>} />
  );

  const leaveRoomButton = (
    <CallMenuButton iconUrl={DisconnectIcon} onClick={handleOnLeaveClick} popupContent={<div>退室</div>} size={40} />
  );

  return (
    <div css={menuContainer}>
      {!nowWhispering && sharingScreenButton}
      {!nowWhispering && addMemberButton}
      {!nowWhispering && audioButton}
      {!nowWhispering && videoButton}
      {!nowWhispering && canvasButton}
      {!nowWhispering && sharingFilesButton}
      {!nowWhispering && textChatButton}
      {!nowWhispering && deviceSettingButton}
      {!nowWhispering && backButton}
      {!nowWhispering && leaveRoomButton}
      {nowWhispering && changeParentRoomVolume}
      {nowWhispering && stoppingWhisperButton}
    </div>
  );
};
