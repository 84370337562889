/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { CallingUser } from 'reducers/call/call';
import { CallViewTile } from 'components/call/CallViewTile';
import { CallViewExpand } from 'components/call/CallViewExpand';
import { WorkboardUiState } from 'reducers/call/workboard';
import { CanvasContainer } from 'containers/call/workboard/Canvas';
import { ChatNoticeContainer } from 'containers/call/workboard/ChatNotice';

export interface Props {
  currentUserId: string;
  sentInvitations: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  users: CallingUser[];
  maximizedUid: string | undefined;
  handleOnExpandBtnClicked: (uid: string) => void;
  handleOnTileBtnClicked: () => void;
  handleOnWhisperBtnClicked: (uid: string) => void;
  whisperTargetUid: string | undefined;
  workboardUiState: WorkboardUiState;
}

export const MainArea: FC<Props> = ({
  currentUserId,
  users = [],
  maximizedUid,
  handleOnExpandBtnClicked,
  handleOnTileBtnClicked,
  handleOnWhisperBtnClicked,
  whisperTargetUid,
  workboardUiState,
}) => {
  const main = useMemo(() => {
    if (workboardUiState.canvasOpened) {
      return <CanvasContainer />;
    }

    const expandedUser = users.find(u => u.uid === maximizedUid);

    if (expandedUser) {
      return (
        <CallViewExpand
          currentUserId={currentUserId}
          expandedUser={expandedUser}
          handleOnExpandBtnClicked={handleOnExpandBtnClicked}
          handleOnTileBtnClicked={handleOnTileBtnClicked}
          handleOnWhisperBtnClicked={handleOnWhisperBtnClicked}
          whisperTargetUid={whisperTargetUid}
        />
      );
    }

    return (
      <CallViewTile
        currentUserId={currentUserId}
        users={users}
        handleOnExpandBtnClicked={handleOnExpandBtnClicked}
        handleOnWhisperBtnClicked={handleOnWhisperBtnClicked}
        whisperTargetUid={whisperTargetUid}
      />
    );
  }, [
    currentUserId,
    handleOnExpandBtnClicked,
    handleOnTileBtnClicked,
    handleOnWhisperBtnClicked,
    maximizedUid,
    users,
    whisperTargetUid,
    workboardUiState.canvasOpened,
  ]);

  return (
    <div css={{ height: '100%', width: '100%', position: 'relative' }}>
      <ChatNoticeContainer />
      {main}
    </div>
  );
};
