import { SfuRoom } from 'skyway-js';
import { CanvasData, CanvasStat } from './RoomData';

/**
 * 実際に WebSocket サーバへ送信するインターバル。
 * SkyWay のメッセージ送信は 100ms に一回までの制限があったので、描画イベント発生毎にそのまま送信し続けると相手側の反映ラグがすごいことになった。
 * そのため描画履歴はキューに溜めて起き 100ms 毎にまとめて送信する。
 */
const SEND_INTERVAL_MS = 100;

export class DrawingDataSender {
  readonly room: SfuRoom;

  lastSendTimeMs: number;

  informationQueue: CanvasData;

  payload: CanvasStat[];

  constructor(room: SfuRoom) {
    this.room = room;
    this.lastSendTimeMs = 0;
    this.informationQueue = { type: 'canvas', payload: [] };
    this.payload = [];
  }

  enqueueAndSend(data?: CanvasStat) {
    // キューに溜め込む間に配列の中身が更新されるため、スナップショットに変換して溜めておく
    if (data) {
      const deepCopied = JSON.parse(JSON.stringify(data));
      this.payload.push(deepCopied);
    }
    const currentTimeMs = Date.now();
    if (currentTimeMs - this.lastSendTimeMs > SEND_INTERVAL_MS && this.payload.length !== 0) {
      this.informationQueue = { type: 'canvas', payload: this.payload };
      this.room.send(this.informationQueue);
      this.lastSendTimeMs = currentTimeMs;
      this.informationQueue = { type: 'canvas', payload: [] };
      this.payload = [];
    }
  }
}
