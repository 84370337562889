/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { COLORS } from 'styles/style';
import { BasicModal } from 'components/common/modal/BasicModal';

interface ErrorNotifyModalProps {
  reconnect: () => void;
  close: () => void;
}

export const ReconnectionModal: FC<ErrorNotifyModalProps> = ({ reconnect, close }) => {
  return (
    <BasicModal
      open
      headerIconColor={COLORS.yellow}
      headerIconType="attention"
      header="通話サーバからの切断"
      contents="通話サーバから切断されました。再接続しますか？"
      okButtonLabel="再接続"
      cancelButtonLabel="キャンセル"
      handleOnOkClicked={reconnect}
      handleOnCancelClicked={close}
    />
  );
};
