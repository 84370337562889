/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PATH_HOME } from 'RouteManager';
import { ReceivingCallModal } from 'components/call/modal/ReceivingCallModal';
import { RootState } from 'reducers/mainReducer';
import { closeReceivingModal, joinRoomActions, removeRemoteInvitation, storeCallError } from 'actions/call/call';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';
import { InvitationContent } from 'sagas/call/classes/ConnectionData';

export const ReceivingCallModalContainer: FC = () => {
  const opened = useSelector<RootState, boolean>(state => state.call.uiState.receivingModalOpened);
  const invitationList = useSelector<RootState, RemoteInvitation[]>(state => state.call.receivedInvitationList);
  const dispatch = useDispatch();
  const history = useHistory();

  const onAccepted = (callerId: string) => {
    const clickedInvitation = invitationList.find(i => i.getRawConnection().remoteId === callerId);
    const otherInvitations = invitationList.filter(i => i.getRawConnection().remoteId !== callerId);
    const content = clickedInvitation?.getContent() as InvitationContent;
    if (!clickedInvitation || !content) {
      return;
    }
    try {
      clickedInvitation.accept();
      // クリックした招待と同じ部屋の人からの招待は全部受諾する
      const sameRoomInvitations = otherInvitations.filter(o => o.getContent()?.roomId === content.roomId);
      sameRoomInvitations.forEach(i => i.accept());
      const callback = () => {
        clickedInvitation.finishJoining();
        sameRoomInvitations.forEach(i => i.finishJoining());
      };
      dispatch(joinRoomActions.start(content.isVoiceOnly, content.roomId, callback));

      // 他は拒否する
      otherInvitations.forEach(i => {
        if (i.getContent()?.roomId !== content.roomId) {
          i.refuse();
        }
      });

      // 通話開始の時は、着信ダイアログは問答無用で閉じる
      dispatch(closeReceivingModal());
      history.push(PATH_HOME);
    } catch (error) {
      dispatch(storeCallError({ msgKey: `既に無効な招待です。: ${error.message}` }));
      dispatch(closeReceivingModal());
    }
  };

  const onRefused = (callerId: string) => {
    const invitationsFromSameUid = invitationList.filter(i => i.getRawConnection().remoteId === callerId);
    try {
      invitationsFromSameUid.forEach(i => i.refuse()); // 同じ人から複数招待が来てたらまとめて拒否してあげる
    } catch {
      // 相手側でキャンセルされていた場合など、招待が無効な状態で拒否するとエラーが発生するためここでキャッチする
      // 例外が発生した場合でもモーダルを閉じられれば良いので特に何もしない。
    }
    // 拒否の場合は拒否した招待リストだけを削除し、招待がなくなった場合のみダイアログを閉じる
    dispatch(removeRemoteInvitation(invitationsFromSameUid));
  };

  return (
    <ReceivingCallModal
      opened={opened}
      receivedInvitationList={invitationList}
      onAccepted={onAccepted}
      onRefused={onRefused}
    />
  );
};
