/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import { CallingUser, CallingUserStatus } from 'reducers/call/call';
import { Video } from 'components/call/Video';
import { StandbyUserContainer } from 'containers/call/StandbyUser';
import { PersonalHeader } from './PersonalHeader';

interface Props {
  currentUserId: string;
  expandedUser: CallingUser;
  handleOnExpandBtnClicked: (uid: string) => void;
  handleOnTileBtnClicked: () => void;
  handleOnWhisperBtnClicked: (uid: string) => void;
  whisperTargetUid: string | undefined;
}

const personalSpaceBaseCss = css`
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5) inset;
  box-sizing: border-box;
  position: relative;
`;

const videoImageCss = css`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const mainZoomCss = css`
  background-color: #fff;
  box-shadow: -19px 0 11px -16px rgba(255, 255, 255, 0.6) inset;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const mainPersonalSpaceCss = css`
  ${personalSpaceBaseCss};
  height: 100%;
  width: 100%;

  &.no-image {
    background-image: url('${process.env.PUBLIC_URL}/images/noimage.png');
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const CallViewExpand: FC<Props> = ({
  currentUserId,
  expandedUser,
  handleOnTileBtnClicked,
  handleOnWhisperBtnClicked,
  whisperTargetUid,
}) => {
  return (
    <div css={mainZoomCss}>
      <div css={mainPersonalSpaceCss}>
        <div css={videoImageCss}>
          {expandedUser.status === CallingUserStatus.JOINED ? (
            <Video
              uid={expandedUser.uid}
              iconUrl={expandedUser.iconUrl}
              stream={expandedUser.stream}
              videoType={expandedUser.streamStat?.videoType}
              videoEnabled={expandedUser.streamStat?.isVideoEnabled}
              nowSpeaking={expandedUser.nowSpeaking || false}
              myself={expandedUser.uid === currentUserId}
              whisperTargetUid={whisperTargetUid}
            />
          ) : (
            <StandbyUserContainer user={expandedUser} />
          )}
        </div>
        <PersonalHeader
          currentUserId={currentUserId}
          user={expandedUser}
          handleOnExpandBtnClicked={handleOnTileBtnClicked}
          handleOnWhisperBtnClicked={handleOnWhisperBtnClicked}
          whisperTargetUid={whisperTargetUid}
          isExpandedUser
        />
      </div>
    </div>
  );
};
