/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import firebase, { db } from 'firebase/Instances';
import { setSplashDisplayed } from 'actions/splash/splash';
import Maintenance from 'components/auth/Maintenance';
import { MaintenanceCheckFailed } from 'components/auth/MaintenanceCheckFailed';

interface SystemStatus {
  isActive: boolean;
  maintenanceStart: firebase.firestore.Timestamp;
  maintenanceEnd: firebase.firestore.Timestamp;
}

const convertToStr = (timestamp?: firebase.firestore.Timestamp): string => {
  if (!timestamp) {
    return '';
  }

  const date = new Date(timestamp.seconds * 1000);
  const dateStr = `${date.getFullYear()}/${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(
    -2,
  )} ${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}`;

  return dateStr;
};

export const MaintenanceChecker: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [systemStatus, loading, error] = useDocumentData<SystemStatus>(db.doc('system/statuses'));

  if (loading) {
    return <React.Fragment />;
  }

  if (systemStatus === undefined || error) {
    dispatch(setSplashDisplayed(false));

    return <MaintenanceCheckFailed />;
  }

  if (!systemStatus.isActive) {
    dispatch(setSplashDisplayed(false));

    return (
      <Maintenance
        startTime={convertToStr(systemStatus?.maintenanceStart)}
        endTime={convertToStr(systemStatus?.maintenanceEnd)}
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
