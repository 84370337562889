/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import { COLORS, overlayZIndex, rounded, roundedBottom, roundedTop, verticalScrollBar } from 'styles/style';
import { BasicIconButton } from 'components/common/parts/BasicIconButton';
import CloseIcon from 'styles/image/close_white.svg';
import ReloadIcon from 'styles/image/reload.svg';
import { BasicMessageBox } from 'components/common/parts/BasicMessageBox';
import { Loader } from 'semantic-ui-react';
import { Spacer } from 'components/common/parts/Spacer';

const loaderCss = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const CallModalLoader = ({ label }: { label: string }) => {
  return (
    <div css={loaderCss}>
      <Loader active inline size="small" />
      <Spacer height="10px" />
      {label}
    </div>
  );
};

const overlay = css`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  ${overlayZIndex};
`;

const modal = css`
  ${rounded};
  background-color: ${COLORS.white};
  width: 600px;
`;

const HEADER_H = '42px';

const header = css`
  ${roundedTop};
  align-items: center;
  background-color: ${COLORS.blue};
  color: ${COLORS.white};
  display: flex;
  height: ${HEADER_H};
  justify-content: space-between;
  padding: 0 24px;
`;

const modalScrollBar = css`
  ${verticalScrollBar};

  ::-webkit-scrollbar-track {
    background: ${COLORS.lightGray};
  }
`;

const body = css`
  ${roundedBottom};
  ${modalScrollBar};
  max-height: calc(400px - ${HEADER_H});
  padding: 20px 24px;
`;

const calcFixedHeightBodyCss = (height: string) => {
  return css`
    ${body};
    height: calc(${height} - ${HEADER_H});
    /* stylelint-disable-next-line */
  `;
};

type Props = {
  title: string;
  handleOnClose: () => void;
  handleOnReload?: () => void;
  error?: Error;
  height?: string;
  children: React.ReactNode;
};

export const CallModalBase = ({ title, handleOnClose, handleOnReload, error, height, children }: Props) => {
  useEffect(() => {
    const escListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleOnClose();
      }
    };

    document.addEventListener('keydown', escListener);

    return () => {
      document.removeEventListener('keydown', escListener);
    };
  }, [handleOnClose]);

  const bodyCss = useMemo(() => {
    return height ? calcFixedHeightBodyCss(height) : body;
  }, [height]);

  return (
    <div css={overlay} onClick={handleOnClose}>
      <div css={modal} onClick={event => event.stopPropagation()}>
        <div css={header}>
          <div>{title}</div>
          {error && <BasicMessageBox color="red">{error.message}</BasicMessageBox>}
          <div css={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            {handleOnReload && <BasicIconButton iconPath={ReloadIcon} pxSize={14} onClick={handleOnReload} />}
            <BasicIconButton iconPath={CloseIcon} pxSize={14} onClick={handleOnClose} />
          </div>
        </div>
        <div css={bodyCss}>{children}</div>
      </div>
    </div>
  );
};
