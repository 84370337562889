import notificationIcon from 'styles/image/notificationIcon.png';

export interface NotificationParams {
  title?: string;
  body: string;
  tag?: string;
  silent?: boolean;
  onclick?: () => void;
}

/**
 * OS への通知を出す関数
 *
 * @param title 一番上に太字で出力される文言。
 * @param body Title の下に出力される文言。
 * @param tag 同じ tag で呼び出された場合は新たに通知を出さず、既存の通知内容の書き換えのみを行う。指定しない場合は毎回新しい通知を出す。
 * @param silent 通知を出した際に効果音を出すか出さないか
 * @param onclick OS 通知をクリックした際の動作。デフォルトでは ZaiTark のタブを開くだけ。
 */
export const showNotification = ({
  title = 'ZaiTark からの通知',
  body,
  tag,
  silent = false,
  onclick = () => window.focus(),
}: NotificationParams) => {
  if (Notification.permission === 'granted') {
    const options: NotificationOptions = {
      body,
      icon: notificationIcon,
      silent,
    };
    if (tag) {
      options.tag = tag;
    }
    const notification = new Notification(title, options); // eslint-disable-line no-new
    notification.onclick = onclick;
  }
};

export const requestNotificationPermission = () => {
  if ('Notification' in window) {
    const { permission } = Notification;

    if (!(permission === 'denied' || permission === 'granted')) {
      Notification.requestPermission().then(r => r);
    }
  }
};
