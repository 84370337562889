/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { RootState } from 'reducers/mainReducer';

const TITLE_DEFAULT = 'ZaiTark';
const FAVICON_URL_DEFAULT = `${process.env.PUBLIC_URL}/images/favicon.ico`;
const FAVICON_URL_NOTIFIED = `${process.env.PUBLIC_URL}/images/favicon_notified.ico`;

/**
 * favicon やタブのテキストを管理するコンポーネント
 *
 * 現状は着信があった場合に title と favicon を変更しているのみ。
 */
export const HtmlHeaderManager: FC = () => {
  const messages = useSelector<RootState, string[]>(state => state.htmlHeader.messages);
  const [title, setTitle] = useState<string>(TITLE_DEFAULT);
  const [faviconUrl, setFaviconUrl] = useState<string>(FAVICON_URL_DEFAULT);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(undefined);
    }

    if (messages.length !== 0) {
      setFaviconUrl(FAVICON_URL_NOTIFIED);
      const rotationMessages = messages.slice();
      rotationMessages.unshift(TITLE_DEFAULT);

      let targetIdx = 0;
      const id = setInterval(() => {
        if (rotationMessages.length === targetIdx) {
          targetIdx = 0;
        }
        const newTitle = rotationMessages[targetIdx];
        setTitle(newTitle);
        targetIdx += 1;
      }, 2500);
      setTimerId(id);
    }

    if (messages.length === 0) {
      setTitle(TITLE_DEFAULT);
      setFaviconUrl(FAVICON_URL_DEFAULT);
    }
  }, [messages]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* defer={false} はタブを開いていない状態でも Head を更新するに必要なワークアラウンド */}
      {/* 参考: https://github.com/nfl/react-helmet/issues/315 */}
      <Helmet defer={false}>
        <title>{title}</title>
        <link id="favicon" rel="icon" type="image/png" href={faviconUrl} />
      </Helmet>
    </div>
  );
};
