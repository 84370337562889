/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/Instances';
import { Attendance } from 'components/home/Attendance';
import { updateAttendanceActions } from 'actions/home/attendance';

export interface AttendanceProps {
  isOwn: boolean;
  onDuty: boolean;
  startTime: firebase.firestore.Timestamp;
}

const AttendanceContainer: FC<AttendanceProps> = ({ isOwn, onDuty, startTime }) => {
  const dispatch = useDispatch();

  // 出退勤時間の登録
  const updateAttendance = () => dispatch(updateAttendanceActions.start(!onDuty));

  return <Attendance isOwn={isOwn} startTime={startTime} onDuty={onDuty} updateAttendance={updateAttendance} />;
};

export default AttendanceContainer;
