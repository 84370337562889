/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FC, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { DeviceList } from 'components/settings/DeviceList';
import { updateStreamWithNewMediaDeviceActions } from 'actions/call/call';

const MediaDeviceListContainer: FC = () => {
  const [currentDeviceId, setCurrentDeviceId] = useState(localStorage.getItem('mediaDeviceId') || '');
  const [deviceInfoArray, setDeviceInfoArray] = useState<MediaDeviceInfo[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      setDeviceInfoArray(devices.filter(info => info.kind === 'videoinput'));
    });

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateDevice = (selectedId: string) => {
    setCurrentDeviceId(selectedId);
    dispatch(updateStreamWithNewMediaDeviceActions.start(selectedId));
  };

  return (
    <div>
      <DeviceList
        label="カメラ"
        currentDeviceId={currentDeviceId}
        deviceInfoArray={deviceInfoArray}
        handleOnChangeDevice={updateDevice}
      />
    </div>
  );
};

export default MediaDeviceListContainer;
