/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { CallButton } from 'components/call/modal/CallButton';
import { CallableMember } from 'containers/call/modal/useCallableMembers';
import { setCallableMemberCalling } from 'actions/call/call';
import { useDispatch } from 'react-redux';
import { useInvitationSender } from './useInvitationSender';

export interface CallButtonContainerProps {
  callableMembers: CallableMember[];
}

export const isAvailable = (member: CallableMember) => {
  return member.status === 'presence' && !member.isTalking && !member.isCalling;
};

export const CallButtonContainer: FC<CallButtonContainerProps> = ({ callableMembers }) => {
  const dispatch = useDispatch();
  const available = !!callableMembers.find(m => isAvailable(m));
  const [sendInvitation] = useInvitationSender(callableMembers, true);

  const callMember = () => {
    dispatch(setCallableMemberCalling(callableMembers[0].uid));
    sendInvitation();
  };

  return <CallButton onClick={() => callMember()} available={available} />;
};
