/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import HeaderContainer from 'containers/header/Header';
import MemberViewContainer from 'containers/home/MemberView';
import { CallViewContainer } from 'containers/call/CallView';
import { JoinConfirmationModalContainer } from 'containers/call/modal/JoinConfirmationModal';
import Spinner from 'components/common/Spinner';
import { StatusCode } from 'components/common/Statuses';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { LocationCode } from 'actions/home/updateLocation';
import firebase from 'firebase/Instances';
import { ConditionCode } from 'components/common/Conditions';
import { verticalScrollBar, COLORS } from 'styles/style';

const mainCss = css`
  height: calc(100vh - 60px);
  overflow: hidden;

  &:hover {
    ${verticalScrollBar};
    overflow-y: overlay;

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${COLORS.gray};
    }
  }
`;
export const NOT_AVAILABLE_MSG = '本機能はデモでは利用できません';
export const ICON_URL = `${process.env.PUBLIC_URL}/images/userDefault.png`;
// ユーザーステータス
export interface User {
  uid: string;
  breakStartTime: number;
  comment: string;
  commentTime: number;
  condition: ConditionCode;
  isCalling: boolean;
  isOnline: boolean;
  isShade: boolean;
  isTalking: boolean;
  location: LocationCode;
  name: string;
  onDuty: boolean;
  startTime: firebase.firestore.Timestamp;
  roomId: string;
  status: StatusCode;
  isGuest: boolean;
  demoUser: boolean;
}

export interface HomeProps {
  nowJoiningTalkRoom: boolean;
  joined: boolean;
}

const Home: FC<HomeProps> = ({ nowJoiningTalkRoom, joined }) => {
  const isDispCallView = useSelector<RootState, boolean>(state => state.call.isDispCallView);

  if (nowJoiningTalkRoom) {
    return (
      <div>
        <HeaderContainer />
        <div css={mainCss}>
          <Spinner text="通話に参加しています..." />
        </div>
      </div>
    );
  }

  if (joined && isDispCallView) {
    return <CallViewContainer />;
  }

  return (
    <div>
      <HeaderContainer isHomeView />
      <div css={mainCss}>
        <MemberViewContainer />
        <JoinConfirmationModalContainer />
      </div>
    </div>
  );
};

export default Home;
