import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { htmlHeaderAction } from 'actions/htmlHeader/htmlHeader';

export interface HtmlHeaderState {
  messages: string[];
}

export const initialState: HtmlHeaderState = {
  messages: [],
};

export const htmlHeaderReducer: Reducer<HtmlHeaderState, htmlHeaderAction> = (
  state: HtmlHeaderState = initialState,
  action: htmlHeaderAction,
): HtmlHeaderState => {
  switch (action.type) {
    case ActionType.PUSH_HTML_HEADER_MESSAGES: {
      return {
        ...state,
        messages: state.messages.concat(action.payload.messages),
      };
    }
    case ActionType.REMOVE_HTML_HEADER_MESSAGES: {
      return {
        ...state,
        messages: state.messages.filter(m => m !== action.payload.removeTarget),
      };
    }
    case ActionType.CLEAR_HTML_HEADER_MESSAGES: {
      return {
        ...state,
        messages: [],
      };
    }
    default: {
      return state;
    }
  }
};
