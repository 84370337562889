/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { OnlineMemberContainer } from 'containers/header/OnlineMember';
import { MemberSearchContainer } from 'containers/header/MemberSearch';
import { BlurContainer } from 'containers/header/Blur';
import { Setting } from 'components/header/Setting';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { setCallViewDisp } from 'actions/call/call';
import { PATH_HOME } from 'RouteManager';
import IconSignOut from 'styles/image/icon_side_signout.svg';
import iconTop from 'styles/image/logo_top.png';
import { BasicIconButton } from 'components/common/parts/BasicIconButton';
import { Spacer } from 'components/common/parts/Spacer';
import { boldFont, COLORS, textOverflowEllipsis } from 'styles/style';
import { TeamCall } from './TeamCall';
import { SharingTeamList } from './SharingTeamList';
import { BackToCallView } from './BackToCallView';

const headerCss = css`
  align-items: center;
  background-color: ${COLORS.blue};
  color: ${COLORS.white};
  display: flex;
  flex-wrap: nowrap;
  height: 60px;
  justify-content: space-between;
  padding: 16px 40px;
  width: 100vw;

  div {
    flex-shrink: 0;
  }
`;

export const iconBaseCss = css`
  button {
    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
`;

const headerLeftBlockCss = css`
  ${boldFont};
  ${textOverflowEllipsis};
  align-items: center;
  display: flex;
  font-size: 24px;
  margin-right: auto;
  max-width: calc(100vw - 674px);
  padding-top: 12px;
  text-align: center;
`;

const headerRightBlockCss = css`
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  padding: 4px 0;
`;

export const iconTopCss = css`
  height: 36px;
  margin-right: 20px;
`;

export const iconCss = (clickable: boolean) => {
  return css`
    ${iconBaseCss};

    button {
      cursor: ${clickable ? 'pointer' : 'default'};
    }
    /* stylelint-disable-next-line */
  `;
};

export const HeaderIcon = ({
  onClick,
  iconUrl,
  disabled,
}: {
  iconUrl: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <div css={[iconCss(!!onClick)]}>
      <BasicIconButton iconPath={iconUrl} pxSize={20} onClick={onClick} disabled={disabled}>
        &nbsp;
      </BasicIconButton>
    </div>
  );
};
const Header: FC<{ isHomeView: boolean; signOut: () => void; isGuest: boolean }> = ({
  isHomeView = false,
  signOut,
  isGuest,
}) => {
  const dispatch = useDispatch();
  const isDispCallView = useSelector<RootState, boolean>(state => state.call.isDispCallView);
  const history = useHistory();
  const handleOnClick = () => {
    dispatch(setCallViewDisp(true));
    history.push(PATH_HOME);
  };

  return (
    <div css={headerCss}>
      <img src={iconTop} alt="ZaiTark" css={iconTopCss} />
      <div css={[headerLeftBlockCss]}>Demo</div>
      <div css={headerRightBlockCss}>
        {!isDispCallView && (
          <React.Fragment>
            <BackToCallView handleOnClick={handleOnClick} />
            <Spacer width="20px" />
          </React.Fragment>
        )}
        <TeamCall voiceOnly />
        <Spacer width="20px" />
        <TeamCall voiceOnly={false} />
        {isHomeView && (
          <React.Fragment>
            <Spacer width="20px" />
            <Setting />
          </React.Fragment>
        )}
        <Spacer width="20px" />
        <OnlineMemberContainer />
        {isHomeView && (
          <React.Fragment>
            <Spacer width="40px" />
            <SharingTeamList sharingType="screen" />
            <Spacer width="20px" />
            <SharingTeamList sharingType="camera" />
            <Spacer width="20px" />
            <BlurContainer />
            <Spacer width="40px" />
          </React.Fragment>
        )}
      </div>
      {isHomeView && <MemberSearchContainer />}
      {!isGuest && isDispCallView && (
        <React.Fragment>
          <Spacer width="20px" />
          <HeaderIcon onClick={() => signOut()} iconUrl={IconSignOut} />
        </React.Fragment>
      )}
    </div>
  );
};

export default Header;
