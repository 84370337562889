/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { BasicErrorModal } from 'components/common/modal/BasicErrorModal';
import { CallError } from 'reducers/call/call';

interface ErrorNotifyModalProps {
  errors: CallError[];
  close: () => void;
}

export const ErrorNotifyModal: FC<ErrorNotifyModalProps> = ({ errors, close }) => {
  const errorMessages: string[] = [];
  errors.forEach(error => {
    errorMessages.push(error.msgKey);
    if (error.detail) errorMessages.push(error.detail);
  });

  return <BasicErrorModal open errors={errorMessages} handleOnCloseClicked={() => close()} />;
};
