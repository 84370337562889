/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useRef, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { Image } from 'semantic-ui-react';
import { COLORS } from 'styles/style';
import { showNotification } from 'common/Notification';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';
import { handleAudioPlayingError } from 'components/call/RoomSoundEffect';
import { BasicModal } from 'components/common/modal/BasicModal';

const receivingCallSoundUrl = `${process.env.PUBLIC_URL}/se/whisper.mp3`;

interface ReceivingWhisperCallModalProps {
  receivedInvitation: RemoteInvitation;
  onAccepted: () => void;
  onRefused: () => void;
}

export const ReceivingWhisperModal: FC<ReceivingWhisperCallModalProps> = ({
  receivedInvitation,
  onAccepted,
  onRefused,
}) => {
  const [starting, setStarting] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audioNode = audioRef.current;
    if (audioNode != null) {
      audioNode.src = receivingCallSoundUrl;
      audioNode.play().catch(handleAudioPlayingError);

      const content = receivedInvitation.getContent();
      showNotification({
        title: `${content?.name} さんから着信中`,
        body: `${content?.name} さんからひそひそ通話の着信中です。`,
        silent: true,
      });
    }
  }, [receivedInvitation]);

  const content = receivedInvitation.getContent();

  const onAcceptClicked = () => {
    onAccepted();
    setStarting(true);
  };

  return (
    <BasicModal
      open
      header="着信中..."
      headerIconColor={COLORS.orange}
      handleOnOkClicked={onAcceptClicked}
      handleOnCancelClicked={onRefused}
      contents={
        <div>
          <audio ref={audioRef} preload="auto" />
          {starting ? (
            <div>
              <span>開始しています...</span>
            </div>
          ) : (
            <div>
              <Image src={content?.iconUrl} avatar />
              {content?.name} さんからひそひそ通話の着信中です。
            </div>
          )}
        </div>
      }
    />
  );
};
