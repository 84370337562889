import * as ActionType from 'actions/ActionTypeConstants';

export const updateAttendanceActions = {
  start: (onDuty: boolean) => ({
    type: ActionType.HOME_UPDATE_ATTENDANCE,
    payload: { onDuty },
  }),
};

export type AttendanceAction = ReturnType<typeof updateAttendanceActions.start>;
