/** @jsx jsx */
import { FC } from 'react';
import { css, jsx } from '@emotion/core';

const common = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  padding: 60px 0;
`;

const withHeader = css`
  ${common};
  height: calc(100vh - 60px);
`;

const noHeader = css`
  ${common}
  height: 100vh;
`;

/**
 * ヘッダの有無などを考慮したコンテンツボックスを利用するページ
 *
 * @param headerDisplayed - ヘッダが見えているかどうか
 * @returns コンテンツボックスを含むページ
 */
export const ContentsBoxContainerPage: FC<{ headerDisplayed: boolean }> = ({ headerDisplayed, children }) => {
  return <div css={headerDisplayed ? withHeader : noHeader}>{children}</div>;
};
