/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { closeWhisperReceivingModal, storeCallError } from 'actions/call/call';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';
import { ReceivingWhisperModal } from 'components/call/modal/ReceivingWhisperModal';

export const ReceivingWhisperModalContainer: FC = () => {
  const receivedInvitation = useSelector<RootState, RemoteInvitation | undefined>(
    state => state.call.receivedWhisperInvitation,
  );
  const dispatch = useDispatch();

  const onAccepted = () => {
    const content = receivedInvitation?.getContent();
    if (!receivedInvitation || !content) {
      return;
    }
    const { remoteId } = receivedInvitation.getRawConnection();
    try {
      receivedInvitation.accept();
    } catch (error) {
      dispatch(storeCallError({ msgKey: `既に無効な招待です。: ${error.message}` }));
      dispatch(closeWhisperReceivingModal(remoteId));
    }
  };

  const onRefused = () => {
    if (!receivedInvitation) {
      return;
    }
    const { remoteId } = receivedInvitation.getRawConnection();
    try {
      receivedInvitation.refuse();
      dispatch(closeWhisperReceivingModal(remoteId));
    } catch {
      // nothing to do
    }
  };

  if (receivedInvitation) {
    return (
      <ReceivingWhisperModal receivedInvitation={receivedInvitation} onAccepted={onAccepted} onRefused={onRefused} />
    );
  }

  return <div />;
};
