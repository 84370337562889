/**
 * stop などを簡易的に呼べるようにした MediaStream の Wrapper
 */
export class Stream {
  private audioTrack: MediaStreamTrack;

  private videoTrack: MediaStreamTrack | undefined;

  private readonly mediaStream: MediaStream | undefined;

  constructor(audioTrack: MediaStreamTrack, videoTrack?: MediaStreamTrack) {
    this.audioTrack = audioTrack;
    this.videoTrack = videoTrack;
    this.mediaStream = new MediaStream();
    if (audioTrack) {
      this.mediaStream.addTrack(audioTrack);
    }
    if (videoTrack) {
      this.mediaStream.addTrack(videoTrack);
    }
  }

  stop() {
    this.stopAudio();
    this.stopVideo();
  }

  stopAudio() {
    if (this.audioTrack) {
      this.audioTrack.stop();
    }
  }

  stopVideo() {
    if (this.videoTrack) {
      this.videoTrack.stop();
    }
  }

  setAudioEnabled(enabled: boolean) {
    if (this.audioTrack) {
      this.audioTrack.enabled = enabled;
    }
  }

  getMediaStream(): MediaStream {
    if (this.mediaStream) {
      return this.mediaStream;
    }

    const mediaStream = new MediaStream();
    if (this.audioTrack) {
      mediaStream.addTrack(this.audioTrack);
    }
    if (this.videoTrack) {
      mediaStream.addTrack(this.videoTrack);
    }

    return mediaStream;
  }

  setOrReplaceAudioTrack(track: MediaStreamTrack) {
    if (this.audioTrack) {
      this.audioTrack.stop();
      if (this.mediaStream) {
        this.mediaStream.removeTrack(this.audioTrack);
      }
    }

    this.audioTrack = track;
    this.mediaStream?.addTrack(track); // eslint-disable-line no-unused-expressions
  }

  setOrReplaceVideoTrack(track: MediaStreamTrack) {
    if (this.videoTrack) {
      this.videoTrack.stop();
      if (this.mediaStream) {
        this.mediaStream.removeTrack(this.videoTrack);
      }
    }

    this.videoTrack = track;
    this.mediaStream?.addTrack(track); // eslint-disable-line no-unused-expressions
  }

  static createAudioTrack = (
    deviceId?: string,
  ): Promise<{ audioTrack: MediaStreamTrack } | { audioDeviceError: Error }> => {
    const id = deviceId || localStorage.getItem('audioDeviceId') || '';

    return navigator.mediaDevices
      .getUserMedia({ audio: { deviceId: id, noiseSuppression: true } })
      .then(stream => ({ audioTrack: stream.getAudioTracks()[0] }))
      .catch(error => ({ audioDeviceError: error }));
  };

  static createVideoTrack = (
    deviceId?: string,
  ): Promise<{ videoTrack: MediaStreamTrack } | { videoDeviceError: Error }> => {
    const id = deviceId || localStorage.getItem('mediaDeviceId') || '';

    return navigator.mediaDevices
      .getUserMedia({ video: { deviceId: id, height: { ideal: 180 }, width: { ideal: 320 }, frameRate: 15 } })
      .then(stream => ({ videoTrack: stream.getVideoTracks()[0] }))
      .catch(error => ({ videoDeviceError: error }));
  };

  static createDisplayTrack = (): Promise<{ displayTrack: MediaStreamTrack } | { createDisplayTrackError: Error }> => {
    const mediaDevices = navigator.mediaDevices as MyMediaDevices;

    return mediaDevices
      .getDisplayMedia({ audio: false, video: true })
      .then(stream => ({ displayTrack: stream.getVideoTracks()[0] }))
      .catch(error => ({ createDisplayTrackError: error }));
  };
}

/**
 * 202010 現在、MediaDevices に getDisplayMedia が未実装なので、型を利用するために独自で定義
 */
interface MyMediaDevices extends MediaDevices {
  getDisplayMedia({ audio, video }: { audio: boolean; video: boolean }): Promise<MediaStream>;
}
