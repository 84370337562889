import { call, cancelled, put, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import Peer, { DataConnection } from 'skyway-js';
import { analytics } from 'firebase/Instances';
import * as api from 'services/firebase/api';
import {
  addReceivedCallHistory,
  CallAction,
  closeWhisperReceivingModal,
  connectCallServerActions,
  openReceivingModal,
  openReconnectionModal,
  openWhisperReceivingModal,
  refuseOrOpenWhisperReceivingModal,
  removeRemoteInvitation,
  renewCredential,
  startWhisperActions,
  stopCallingActions,
  storeCallError,
  updateLocalAudioEnabled,
} from 'actions/call/call';
import { REFUSE_OR_OPEN_WHISPER_RECEIVING_MODAL } from 'actions/ActionTypeConstants';
import { pushHtmlHeaderMessages } from 'actions/htmlHeader/htmlHeader';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';
import { RootState } from 'reducers/mainReducer';
import { clearWorkboardData } from 'actions/call/workboard';

const subscribe = (peer: Peer) =>
  eventChannel(emitter => {
    peer.on('connection', async (conn: DataConnection) => {
      const { metadata } = conn;

      conn.on('error', error => {
        analytics.logEvent('data_connection_error', { uid: peer.id, remoteId: conn.remoteId, metadata, error });
        throw error;
      });

      switch (metadata.type) {
        case 'invitation': {
          const remoteInvitation = new RemoteInvitation(conn);

          conn.on('RemoteInvitationReceived', async () => {
            // TODOここで他のルームに入っているひとがいるかどうかを確認して、自分が不要であればキャンセルする？conn.closeする
            await api.setMyCallStatus(true);
            emitter(openReceivingModal(remoteInvitation));
          });

          conn.on('RemoteInvitationCanceled', () => {
            conn.removeAllListeners();
            conn.close(true);
            const content = remoteInvitation.getContent();
            if (content) {
              const { name, iconUrl, isVoiceOnly } = content;
              emitter(addReceivedCallHistory(name, iconUrl, isVoiceOnly, new Date()));
              emitter(pushHtmlHeaderMessages([`${name} から着信がありました`]));
            }
            emitter(removeRemoteInvitation([remoteInvitation]));
          });

          conn.on('RemoteInvitationFailure', () => {
            conn.removeAllListeners();
            conn.close(true);
            emitter(removeRemoteInvitation([remoteInvitation]));
          });

          conn.on('RemoteInvitationClosed', () => {
            conn.removeAllListeners();
            conn.close(true);
            emitter(removeRemoteInvitation([remoteInvitation]));
          });

          break;
        }
        case 'whisper-invitation': {
          const whisperInvitation = new RemoteInvitation(conn);

          conn.on('RemoteInvitationReceived', async () => {
            emitter(refuseOrOpenWhisperReceivingModal(whisperInvitation));
          });

          conn.on('RemoteInvitationCanceled', () => {
            conn.removeAllListeners();
            conn.close(true);
            emitter(closeWhisperReceivingModal(conn.remoteId));
          });

          conn.on('RemoteInvitationFailure', () => {
            conn.removeAllListeners();
            conn.close(true);
            emitter(closeWhisperReceivingModal(conn.remoteId));
          });

          conn.on('RemoteInvitationClosed', () => {
            conn.removeAllListeners();
            conn.close(true);
            emitter(closeWhisperReceivingModal(conn.remoteId));
          });

          break;
        }
        default: {
          // nothing to do
        }
      }
    });

    peer.on('call', async conn => {
      emitter(updateLocalAudioEnabled.start(false));

      // TODO: エラーハンドリング
      const deviceId = localStorage.getItem('audioDeviceId') || '';
      const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } });
      conn.answer(stream);
      emitter(startWhisperActions.start(conn, stream));
    });

    peer.on('expiresin', (sec: number) => {
      console.log(`credential will expire after ${sec} sec...`); // eslint-disable-line no-console
      emitter(renewCredential());
    });

    // v3.1.0 において、ドキュメントには type プロパティからカテゴリを取得できるとあるが型定義には存在しなかったため any を使用する。
    // ドキュメント: https://webrtc.ecl.ntt.com/api-reference/javascript.html#events
    // 型定義ファイル: https://github.com/skyway/skyway-js-sdk/blob/master/skyway-js.d.ts#L117
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    peer.on('error', (error: any) => {
      console.log(`Peer on error. type: ${error.type}, name: ${error.name}, message: ${error.message}`); // eslint-disable-line no-console
      analytics.logEvent('peer_error', { uid: peer.id, error });

      switch (error.type) {
        case 'socket-error': {
          // TODO: スリープ時のネットワーク接続は OS の設定によるので OS 通知は必要かもしれない
          // TODO: アプリ全体に通知できる何かがあると便利なのかも
          emitter(openReconnectionModal());
          break;
        }
        case 'unavailable-id': {
          // Peer ID 重複時のエラー
          emitter(
            storeCallError({
              msgKey: `通話用サーバから切断されました。このタブをリロードするか、別タブなどで新たにログインしている場合はこのタブを閉じてください。`,
            }),
          );
          break;
        }
        case 'peer-unavailable': {
          // 相手が通話サーバから切断されているなどで招待が送れなかったとき
          emitter(
            storeCallError({
              msgKey: `招待の送信に失敗しました。相手が通話用サーバから切断されている可能性があります。`,
            }),
          );
          break;
        }
        case 'authentication': {
          // 認証情報が切れてしまった場合。通常であれば認証情報の更新がされるはずなので、以下のようなケースのときに発生する想定
          // 1. スリープ中やオフライン中などに更新タイミングが訪れてしまい、更新がスキップされた
          // 2. スリープ中やオフライン中などに認証情報の失効時間を迎えた
          // TODO: 通話中だった場合は一度切断されてしまうので何かしらの通知はしてあげなきゃダメ
          emitter(stopCallingActions.start());
          emitter(clearWorkboardData());
          emitter(connectCallServerActions.start());
          break;
        }
        default: {
          emitter(
            storeCallError({
              msgKey: `Peer on error. type: ${error.type}, name: ${error.name}, message: ${error.message}`,
            }),
          );
        }
      }
    });

    return () => {};
  });

/**
 * Peer のイベントを監視する。
 * @param peer 通話用 client
 */
export function* subscribePeerEvents(peer: Peer) {
  const channel = yield call(subscribe, peer);
  try {
    while (true) {
      const action: CallAction = yield take(channel);

      switch (action.type) {
        case REFUSE_OR_OPEN_WHISPER_RECEIVING_MODAL: {
          const { remoteInvitation } = action.payload;
          const nowSendingWhisperInvitation = yield select((state: RootState) => state.call.sentWhisperInvitation);
          const nowWhisperReceiving = yield select((state: RootState) => state.call.receivedWhisperInvitation);
          const nowWhispering = yield select((state: RootState) => state.call.whisperTargetUid);

          if (nowSendingWhisperInvitation || nowWhisperReceiving || nowWhispering) {
            remoteInvitation.refuseByAlreadyWhispering();
          } else {
            yield put(openWhisperReceivingModal(remoteInvitation));
          }
          break;
        }
        default: {
          yield put(action);
        }
      }
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey:
          '通話サーバとの通信でエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}
