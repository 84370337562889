import * as ActionType from 'actions/ActionTypeConstants';

export const signInActions = {
  googleStart: () => ({
    type: ActionType.SIGN_IN_GOOGLE as typeof ActionType.SIGN_IN_GOOGLE,
  }),
  error: () => ({
    type: ActionType.SIGN_IN_ERROR as typeof ActionType.SIGN_IN_ERROR,
  }),
  success: (user: firebase.User) => ({
    type: ActionType.SIGN_IN_SUCCESS as typeof ActionType.SIGN_IN_SUCCESS,
    payload: { user },
  }),
};

export const signInAnonymouslyActions = {
  start: (userName: string) => ({
    type: ActionType.SIGN_IN_ANONYMOUSLY_START as typeof ActionType.SIGN_IN_ANONYMOUSLY_START,
    payload: { userName },
  }),
  success: () => ({
    type: ActionType.SIGN_IN_ANONYMOUSLY_SUCCESS as typeof ActionType.SIGN_IN_ANONYMOUSLY_SUCCESS,
  }),
  error: (message: string) => ({
    type: ActionType.SIGN_IN_ANONYMOUSLY_ERROR as typeof ActionType.SIGN_IN_ANONYMOUSLY_ERROR,
    payload: { message },
  }),
};
