import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { User } from 'components/home/Home';
import { SubscribeAction } from 'actions/home/subscriber';
import firebase from 'firebase/Instances';
import { Conditions } from 'components/common/Conditions';
import { Statuses } from 'components/common/Statuses';

export interface SubscribeState {
  currentUserStatus: User;
  onlineMembers: User[];
  salesMembers: User[];
  newGuestName: string[];
}

export const initialState: SubscribeState = {
  currentUserStatus: {
    uid: '',
    onDuty: false,
    startTime: firebase.firestore.Timestamp.fromDate(new Date()),
    isOnline: false,
    status: 'presence',
    location: 'home',
    comment: '',
    commentTime: 0,
    condition: 'on_track',
    isTalking: false,
    isCalling: false,
    roomId: '',
    name: '',
    isShade: false,
    breakStartTime: 0,
    isGuest: false,
    demoUser: false,
  },
  onlineMembers: [],
  salesMembers: [],
  newGuestName: [],
};

const sampleUserName = ['一郎', '花子', '次郎', '三郎', '四郎', '五郎', '六男', '七子', '八郎', '九美'];

const homeSubscribeReducer: Reducer<SubscribeState, SubscribeAction> = (
  state: SubscribeState = initialState,
  action: SubscribeAction,
): SubscribeState => {
  switch (action.type) {
    case ActionType.HOME_SUBSCRIBE_MY_USERS_DATA: {
      return {
        ...state,
        currentUserStatus: action.payload.currentUserStatus,
      };
    }

    case ActionType.HOME_SUBSCRIBE_NOW_LOGIN_USERS_DATA: {
      const newLoginUser = action.payload.nowLoginUser;
      const currentLoginUser = state.currentUserStatus;
      const targetUid = newLoginUser.uid;
      const newMembers = state.onlineMembers.slice();
      const newGuestName = state.newGuestName.slice();
      const index = newMembers.findIndex(u => u.uid === targetUid);

      if (index > -1) {
        newMembers[index] = newLoginUser;
      } else {
        newMembers.push(newLoginUser);
        if (
          newLoginUser.isGuest &&
          newLoginUser.isOnline &&
          !currentLoginUser.isGuest &&
          targetUid !== currentLoginUser.uid
        ) {
          newGuestName.push(newLoginUser.name);
        }
      }

      const sales = newMembers.filter(member => !member.isGuest && !member.demoUser && member.isOnline);
      const demoUsers = newMembers.filter(member => member.demoUser).length;
      // ゲストかつオフラインのユーザーが残っている場合は削除
      const filteredOfflineUser = newMembers.filter(member => !(!member.isOnline && member.isGuest));
      const date = new Date();

      if (demoUsers < 10) {
        date.setHours(9, Math.floor(Math.random() * 60));
        const location = demoUsers % 4 === 0 ? 'office' : 'home';
        const statusIndex = demoUsers === 3 || demoUsers === 9 ? 2 : 0;
        const conditionIndex = demoUsers < 6 ? demoUsers : demoUsers - 6;

        filteredOfflineUser.push({
          uid: `demo${demoUsers + 1}`,
          breakStartTime: 1200 + demoUsers * 5,
          comment: '',
          commentTime: 0,
          condition: Conditions[conditionIndex].code,
          isCalling: false,
          isOnline: true,
          isShade: false,
          isTalking: false,
          location,
          name: `ザイターク ${sampleUserName[demoUsers]}`,
          onDuty: true,
          startTime: firebase.firestore.Timestamp.fromDate(date),
          roomId: '',
          status: Statuses[demoUsers === 4 ? 1 : statusIndex].code,
          isGuest: false,
          demoUser: true,
        });
      }

      return {
        ...state,
        onlineMembers: filteredOfflineUser,
        salesMembers: sales,
        newGuestName,
      };
    }

    case ActionType.HOME_UNSUBSCRIBE_NOW_LOGIN_USERS_DATA: {
      const removeId = action.payload.uid;
      const newUsers: User[] = [];

      state.onlineMembers.forEach(member => {
        if (member.uid !== removeId) {
          newUsers.push(member);
        }
      });

      return {
        ...state,
        onlineMembers: newUsers,
      };
    }
    case ActionType.HOME_NEW_MEMBER_NOTIFIED: {
      return {
        ...state,
        newGuestName: [],
      };
    }

    case ActionType.HOME_UNSUBSCRIBE_USER_STATUSES: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default homeSubscribeReducer;
