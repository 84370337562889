/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeTextChat } from 'actions/call/workboard';
import { RootState } from 'reducers/mainReducer';
import { ChatMessage } from 'reducers/call/workboard';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'firebase/Instances';
import { commonActions } from 'actions/common/common';

export const TextChatListener: FC = () => {
  const dispatch = useDispatch();
  const roomId = useSelector<RootState, string>(state => state.workboard.roomId);
  const currentTextChats = useSelector<RootState, ChatMessage[]>(state => state.workboard.uiState.chatMessage);

  const [chatMessage, loading, error] = useCollectionData<ChatMessage>(
    db
      .collection('workboards')
      .doc(roomId)
      .collection('textChat')
      .orderBy('timestamp', 'asc'),
  );

  if (loading) {
    return <div />;
  }

  if (error) {
    dispatch(commonActions.error('チャット情報の取得に失敗しました。'));
  }

  if (chatMessage) {
    // Firestoreに格納された直後はtimestampがまだnullのままの場合があるため、一旦除外する
    const newMessage = chatMessage.filter(chat => chat.timestamp);
    if (newMessage && currentTextChats.length !== newMessage.length) {
      dispatch(subscribeTextChat(newMessage));
    }
  }

  return <div />;
};
