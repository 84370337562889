/** @jsx jsx */
import { FC, useState, useRef } from 'react';
import { jsx, css } from '@emotion/core';
import { buttonAnimation, buttonInteraction, buttonStyle, COLORS, rounded } from 'styles/style';

const buttonCss = css`
  ${buttonAnimation};
  display: contents;

  button {
    ${buttonStyle};
    ${rounded};
    border: none;
    height: 36px;
    outline: none;
    overflow: hidden;
    padding: 0 24px;
    position: relative;
    width: auto;
  }

  .effected {
    ::before {
      ${buttonInteraction};
    }
  }

  .white {
    background: ${COLORS.white};
    border: 1px solid ${COLORS.darkGray};
    color: ${COLORS.darkGray};

    &:hover {
      background: ${COLORS.lightGray};
      border: 1px solid ${COLORS.darkGray};
    }
  }

  .blue {
    background: ${COLORS.blue};
    border: 1px solid ${COLORS.blue};
    color: ${COLORS.white};

    &:hover {
      background: ${COLORS.navy};
      border: 1px solid ${COLORS.navy};
    }
  }

  .red {
    background: ${COLORS.red};
    border: 1px solid ${COLORS.red};
    color: ${COLORS.white};

    &:hover {
      background: ${COLORS.brown};
      border: 1px solid ${COLORS.brown};
    }
  }

  .gray {
    background: ${COLORS.darkGray};
    border: 1px solid ${COLORS.darkGray};
    color: ${COLORS.white};

    &:hover {
      opacity: 0.7;
    }
  }

  button:disabled {
    background: ${COLORS.darkGray};
    border: 1px solid ${COLORS.darkGray};
    color: ${COLORS.white};

    &:hover {
      background: ${COLORS.darkGray};
      border: 1px solid ${COLORS.darkGray};
      color: ${COLORS.white};
      cursor: unset;
    }
  }
`;

type ButtonProps = JSX.IntrinsicElements['button'];
type Props = ButtonProps & { label?: string; color?: 'white' | 'blue' | 'red' | 'gray' };
/**
 * ボタン
 * @param label - ボタンの表示名称 (省略した場合は children を使用する)
 * @param color - 任意。ボタン色 white,blue,red の３色から選択 デフォルトは blue
 * @param buttonProps - 任意。ボタンに渡すオプション（onClick等）
 * @returns ボタンを表示
 * @example
 * ```
 * <BasicButton label="更新" color="blue" onClick={() => handleOnClick()} />
 * ```
 */
export const BasicButton: FC<Props> = Props => {
  const { label, color, type, onClick, children, ...buttonProps } = Props;
  const [effected, setEffected] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div css={buttonCss}>
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        type={type || 'button'}
        ref={buttonRef}
        {...buttonProps}
        className={`${color || 'blue'} ${(effected && 'effected') || ''}`}
        onClick={event => {
          setEffected(true);
          setTimeout(() => {
            if (buttonRef.current) {
              setEffected(false);
            }
          }, 300);
          if (onClick) {
            onClick(event);
          }
        }}
      >
        {label !== undefined ? label : children}
      </button>
    </div>
  );
};
