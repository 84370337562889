/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { closeJoinConfirmationModal, joinRoomActions } from 'actions/call/call';
import { JoinConfirmationModalProps } from 'reducers/call/call';
import { BasicModal } from 'components/common/modal/BasicModal';
import { COLORS } from 'styles/style';

export const JoinConfirmationModalContainer: FC = () => {
  const open = useSelector<RootState, boolean>(state => state.call.uiState.joinConfirmationModalOpened);
  const props = useSelector<RootState, JoinConfirmationModalProps | undefined>(
    state => state.call.uiState.joinConfirmationModalProps,
  );
  const targetName = useSelector<RootState, string>(
    state => state.homeSubscriber.onlineMembers.find(u => u.uid === props?.targetUid)?.name || '',
  );
  const dispatch = useDispatch();

  const header = '通話へ参加';
  const mainContent = `${targetName || ''} さんの通話に参加しますか？`;
  const joinRoom = () => {
    if (props) {
      dispatch(joinRoomActions.start(props.isVoiceOnly, props.targetRoomId));
      dispatch(closeJoinConfirmationModal());
    }
  };
  const backToHome = () => {
    dispatch(closeJoinConfirmationModal());
  };

  return (
    <BasicModal
      open={open}
      header={header}
      headerIconColor={COLORS.yellow}
      headerIconType="attention"
      contents={mainContent}
      handleOnOkClicked={joinRoom}
      handleOnCancelClicked={backToHome}
    />
  );
};
