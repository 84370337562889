import firebase, {
  auth,
  db,
  callJoinWorkboard,
  callFetchCallableMembers,
  callCheckSalesDomain,
  callGetStorageFileUrl,
  callDeleteStorageFile,
  callRelocateStorageFile,
} from 'firebase/Instances';
import { User } from 'components/home/Home';
import { formatDate } from 'common/Util';
import { LocationCode } from 'actions/home/updateLocation';
import { FileSharingType } from 'components/common/FileSharing';

export const signOut = async () => {
  const uid = firebase.auth().currentUser?.uid || '';
  if (uid) {
    const realTimeDb = firebase.database();
    const userStatusDbRef = realTimeDb.ref(`/statuses/${uid}`);

    userStatusDbRef
      .set({ isOnline: false, lastChanged: firebase.database.ServerValue.TIMESTAMP })
      .catch(error => ({ error }))
      .then(() => {
        db.doc(`users/${uid}`).update({ isOnline: false });
      })
      .then(() => {
        firebase.auth().signOut();
      });
  }
};

const createUsers = async (isGuest: boolean, name?: string) => {
  const date = new Date();
  const now = formatDate(date, 'HHmm');
  date.setHours(9, Math.floor(Math.random() * 60));
  const uid = firebase.auth().currentUser?.uid;
  const usersRef = db.doc(`users/${uid}`);
  const sales = await db
    .collection(`users`)
    .where('demoUser', '==', false)
    .where('isGuest', '==', false)
    .get();
  // 営業担当者は名前を固定、出勤状態固定
  const userName = name || `カスタマーサポート${sales.size + 1}`;
  const onDuty = !isGuest;
  type UserWithoutId = Omit<User, 'uid'>;

  const user: UserWithoutId = {
    breakStartTime: 0,
    comment: !isGuest ? '通話ボタンを押すと通話が可能です！お気軽にお声掛けください。' : '',
    commentTime: Number(now),
    condition: 'on_track',
    isCalling: false,
    isOnline: true,
    isShade: false,
    isTalking: false,
    location: 'home',
    name: userName,
    onDuty,
    startTime: firebase.firestore.Timestamp.fromDate(date),
    roomId: '',
    status: 'presence',
    isGuest,
    demoUser: false,
  };

  await usersRef.set(user);
};

export const signInGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  const credential = await auth.signInWithPopup(provider);
  const { user } = credential;

  const result = await callCheckSalesDomain();

  if (result.data.error) {
    await signOut();
    throw new Error(result.data.error);
  }

  const usersDoc = await db.doc(`users/${user?.uid}`).get();
  const date = new Date();
  const now = formatDate(date, 'HHmm');

  if (!usersDoc.exists) {
    await createUsers(false);
  } else {
    await db.doc(`users/${user?.uid}`).update({
      comment: '通話ボタンを押すと通話が可能です！お気軽にお声掛けください。',
      commentTime: Number(now),
      isCalling: false,
      isOnline: true,
      isTalking: false,
      roomId: '',
    });
  }

  return { user };
};

// 勤務地の更新
export const updateLocationFactory = () => {
  const updateLocation = async (location: LocationCode) => {
    const currentUserId = firebase.auth().currentUser?.uid;
    await db.doc(`users/${currentUserId}`).update({ location });
  };

  return updateLocation;
};

// ステータスの更新
export const updateStatusFactory = () => {
  const updateStatus = async (status: string, breakStartTime: number) => {
    const currentUserId = firebase.auth().currentUser?.uid;
    await db.doc(`users/${currentUserId}`).update({ status, breakStartTime });
  };

  return updateStatus;
};

export const setMyCallStatus = async (isCalling: boolean) => {
  const currentUserId = firebase.auth().currentUser?.uid;
  await db
    .collection('users')
    .doc(currentUserId)
    .update({ isCalling });
};

export const updateMyCallStatusOnTalking = async (roomId: string) => {
  const currentUserId = firebase.auth().currentUser?.uid;
  await db
    .collection('users')
    .doc(currentUserId)
    .update({ isCalling: false, isTalking: true, roomId });
};

export const updateMyCallStatusOnStopCalling = async () => {
  const currentUserId = firebase.auth().currentUser?.uid;
  await db
    .collection('users')
    .doc(currentUserId)
    .update({ isCalling: false, isTalking: false, roomId: '' });
};

// 勤務中かどうか＆出退勤時間の更新
export const updateAttendance = async (onDuty: boolean) => {
  const currentUserId = firebase.auth().currentUser?.uid;
  const usersRef = db.doc(`users/${currentUserId}`);
  await usersRef.update({ onDuty });
};

// コメントの更新
export const updateComment = async (comment: string, commentTime: number) => {
  const currentUserId = firebase.auth().currentUser?.uid;

  await db.doc(`users/${currentUserId}`).update({ comment, commentTime });
};

// View情報の共有画像ぼかしの有無の更新
export const updateUserViewsIsShade = async (isShade: boolean) => {
  const currentUserId = firebase.auth().currentUser?.uid;

  await db
    .collection('users')
    .doc(currentUserId)
    .update({ isShade });
};

// StorageにBlobデータをアップロード (デフォルトバケット)
export const uploadStorage = async (path: string, data: Blob) => {
  const storage = firebase.app().storage();
  const storageRef = storage.ref();
  const uploadSnapshotRef = storageRef.child(path);

  const snapshot = await uploadSnapshotRef.put(data);
  const url = await snapshot.ref.getDownloadURL();

  return url;
};

export const getCurrentUserId = (): string => {
  return firebase.auth().currentUser?.uid || '';
};

/**
 * 通話画面で必要な相手の情報を取得する。
 *
 * 表示名、アイコン URL 連携情報
 * @param uid
 */
export const getUserInfoInCallView = async (uid: string) => {
  try {
    const userDoc = await db.doc(`users/${uid}`).get();

    const user = userDoc.data() as User; // こっちは確実に存在するはず

    return [user.name, user.isTalking];
  } catch (error) {
    // ここでエラーになっても通話自体は可能であるため空で返してあげる
    console.log(error); // eslint-disable-line no-console

    return ['', '', undefined];
  }
};

/**
 * オンラインメンバーを取得する。
 * @return オンラインメンバー
 */
export const fetchCallableMembers = async () => {
  // そのスペースのメンバー一覧を取得 → メンバー数分 users を取得では通信回数が増えるため、
  // Functions 経由にして直接 firestore.collection('users') をしている。
  const result = await callFetchCallableMembers();
  if (result.data.error) {
    throw new Error(result.data.error);
  }
  const { onlineMembers } = result.data;

  return onlineMembers;
};

export const uploadWorkboardFile = async (file: File, roomId: string) => {
  const uid = firebase.auth().currentUser?.uid;
  const date = new Date();
  const dirPath = formatDate(date, 'yyyyMMddHHmmssSSS');
  const fileName = file.name;
  const fileLocate = `tmp/${uid}/${dirPath}/${fileName}`;
  const relocateFilePath = `workboards/${roomId}/${dirPath}/${fileName}`;

  // 以下の理由で一度クライアントから自分だけが書き込みできるディレクトリに格納後、Functionsで会議参加者向けのディレクトリに移動している
  // 1. StorageのルールからFirestoreを参照できないため、アップロード時に会議参加者かどうかをチェックすることができない
  // 2. Functionsの HTTP リクエストの本文のサイズが 10 MB に制限されるため、Functions経由でのアップロードができない
  try {
    await uploadStorage(fileLocate, file);
    await callRelocateStorageFile({
      checkKey: roomId,
      tmpFilePath: fileLocate,
      relocateFilePath,
      fileSharingType: 'call',
    });
    await db
      .collection('workboards')
      .doc(roomId)
      .collection('files')
      .add({ name: file.name, dirPath, owner: uid });
  } catch (error) {
    throw new Error('ファイルをアップロードする権限がありません。'); // 外部化済み
  }
};

export const createWorkboard = async (roomId: string) => {
  const date = new Date();
  const formattedDate = formatDate(date, 'yyyyMMddHHmmssSSS');
  const workboardName = `通話_${formattedDate}`;

  const result = await callJoinWorkboard({ roomId });

  if (result.data) {
    const workboardRef = db.collection('workboards').doc(roomId);

    await workboardRef.set({
      workboardName,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } else {
    throw new Error('ワークボードに参加する権限がありません。');
  }
};

export const joinWorkboard = async (roomId: string) => {
  const result = await callJoinWorkboard({ roomId });
  if (result.data.error) throw new Error('ワークボードに参加する権限がありません。');
};

export const deleteStorageFile = async (
  checkKey: string,
  filePath: string,
  docPath: string,
  fileSharingType: FileSharingType,
) => {
  try {
    await callDeleteStorageFile({
      checkKey,
      filePath,
      docPath,
      fileSharingType,
    });
  } catch (error) {
    throw new Error('ファイルにアクセスする権限がありません。'); // 外部化済み
  }
};

export const getStorageFileUrl = async (checkKey: string, filePath: string, fileSharingType: FileSharingType) => {
  try {
    const result = await callGetStorageFileUrl({ checkKey, filePath, fileSharingType });

    return result.data.url;
  } catch (error) {
    throw new Error('ファイルにアクセスする権限がありません。'); // 外部化済み
  }
};
export const sendTextChat = async (text: string, roomId: string, name: string) => {
  const uid = firebase.auth().currentUser?.uid;
  await db
    .collection('workboards')
    .doc(roomId)
    .collection('textChat')
    .add({ uid, text, name, timestamp: firebase.firestore.FieldValue.serverTimestamp() });
};
// ワークボードの一時データを保存する
export const sendTmpCanvasData = async (tmpData: string, roomId: string) => {
  await db
    .collection('workboards')
    .doc(roomId)
    .collection('canvases')
    .doc('tmpData')
    .set({ tmpData }, { merge: true });
};

// ワークボードの一時データを取得する（その際、ワークボードに参加しているメンバーの配列に自分のUIDを追加する）
export const getCanvasHistory = async (roomId: string) => {
  const members: string[] = [];
  let tmpData = '';

  const uid = firebase.auth().currentUser?.uid || '';

  const canvasTmpDataDoc = await db
    .collection('workboards')
    .doc(roomId)
    .collection('canvases')
    .doc('tmpData')
    .get();

  const canvasMembersDoc = await db
    .collection('workboards')
    .doc(roomId)
    .collection('canvases')
    .doc('members')
    .get();

  if (canvasTmpDataDoc.exists) {
    const tmpDataDoc = canvasTmpDataDoc.data() as firebase.firestore.DocumentData;
    tmpData = tmpDataDoc.tmpData;
  }
  if (canvasMembersDoc.exists) {
    const membersDoc = canvasMembersDoc.data() as firebase.firestore.DocumentData;
    const canvasMembers: string[] = membersDoc.members;
    canvasMembers.forEach(memberId => members.push(memberId));
  }
  members.push(uid);
  await db
    .collection('workboards')
    .doc(roomId)
    .collection('canvases')
    .doc('members')
    .set({ members }, { merge: true });

  return { tmpData, members };
};

// ワークボードの参加メンバーから外れる
export const removeCanvasMember = async (roomId: string, members: string[]) => {
  await db
    .collection('workboards')
    .doc(roomId)
    .collection('canvases')
    .doc('members')
    .set({ members }, { merge: true });
};

export const signInAnonymously = async (userName: string) => {
  // 匿名ログインの場合永続性をセッション（タブ・ウインドウを閉じるまで）とする
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  firebase
    .auth()
    .signInAnonymously()
    .then(() => {
      createUsers(true, userName);
    })
    .catch(error => {
      return { error };
    });
};

// コンディションの更新
export const updateConditionFactory = () => {
  const updateCondition = async (condition: string) => {
    const currentUserId = firebase.auth().currentUser?.uid;
    await db.doc(`users/${currentUserId}`).update({ condition });
  };

  return updateCondition;
};
