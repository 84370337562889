/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useCallback, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import { ChatMessage } from 'reducers/call/workboard';
import { formatDate } from 'common/Util';
import { CallingUser } from 'reducers/call/call';
import { FormatChatTextTag } from 'components/common/FormatChatTextTag';
import { boldFont, COLORS, popUpAndToastZIndex, rounded } from 'styles/style';
import { Spacer } from 'components/common/parts/Spacer';

const messageCommon = css`
  ${rounded};
  border: solid 1px #707070;
  display: inline-block;
  font-size: 12px;
  padding: 10px 14px;
  position: relative;
  width: 200px;
  word-wrap: break-word;
`;

const otherMessage = css`
  ${messageCommon};
  background: ${COLORS.lightGray};

  &::before,
  &::after {
    border: 7px solid transparent;
    border-right: 8px solid;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    border-right-color: ${COLORS.lightGray};
    left: -15px;
    ${popUpAndToastZIndex};
  }

  &::after {
    border-right-color: #707070;
    left: -16px;
  }
`;

const ownMessage = css`
  ${messageCommon};
  background: ${COLORS.paleBlue};

  &::before,
  &::after {
    border: 7px solid transparent;
    border-left: 8px solid;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    border-left-color: ${COLORS.paleBlue};
    right: -15px;
    ${popUpAndToastZIndex};
  }

  &::after {
    border-left-color: #707070;
    right: -16px;
  }
`;

const UserIcon = ({ iconUrl }: { iconUrl: string }) => {
  return <img css={{ borderRadius: 4, border: 'solid 1px', height: 30, width: 30 }} src={iconUrl} alt="" />;
};

const Text = ({ text }: { text: string }) => {
  return <FormatChatTextTag text={text} />;
};

const Footer = ({ name, time }: { name: string; time: string }) => {
  return (
    <div css={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: 10 }}>
      <div css={boldFont}>{name}</div>
      <div>{time}</div>
    </div>
  );
};

interface TextChatProps {
  currentUserId: string;
  currentUserIconUrl?: string;
  remoteUsers: CallingUser[];
  chatMessage: ChatMessage[];
  chatRef: React.RefObject<HTMLDivElement>;
  chatEndRef: React.RefObject<HTMLDivElement>;
  setPoint: (event: React.UIEvent<HTMLDivElement>) => void;
}

export const ChatMessages: FC<TextChatProps> = ({
  currentUserId,
  currentUserIconUrl,
  remoteUsers,
  chatMessage,
  chatRef,
  chatEndRef,
  setPoint,
}) => {
  const getRemoteUserIconUrl = useCallback(
    (textChatUid: string) => {
      return remoteUsers.find(remoteUser => remoteUser.uid === textChatUid)?.iconUrl;
    },
    [remoteUsers],
  );

  const chat = useMemo(
    () =>
      chatMessage?.map(textChat => {
        const timestamp = textChat.timestamp && formatDate(textChat.timestamp.toDate(), 'HH:mm');

        if (currentUserId === textChat.uid) {
          return (
            <div key={Number(textChat.timestamp)}>
              <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 20 }}>
                <div css={ownMessage}>
                  <Text text={textChat.text} />
                </div>
                <UserIcon iconUrl={currentUserIconUrl || ''} />
              </div>
              <Spacer height="4px" />
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Footer name={textChat.name} time={timestamp} />
              </div>
            </div>
          );
        }

        const iconUrl = getRemoteUserIconUrl(textChat.uid) || '';

        return (
          <div key={Number(textChat.timestamp)}>
            <div css={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <UserIcon iconUrl={iconUrl} />
              <div css={otherMessage}>
                <Text text={textChat.text} />
              </div>
            </div>
            <Spacer height="4px" />
            <Footer name={textChat.name} time={timestamp} />
          </div>
        );
      }),
    [chatMessage, currentUserIconUrl, currentUserId, getRemoteUserIconUrl],
  );

  return (
    <div css={{ height: '100%', width: '100%' }} ref={chatRef} onScroll={e => setPoint(e)}>
      <div css={{ display: 'flex', flexDirection: 'column', gap: 40 }}>{chat}</div>
      <div ref={chatEndRef} />
    </div>
  );
};
