/** @jsx jsx */
import { FC, useState, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';
import Comment from 'components/home/Comment';
import { updateCommentActions, UpdateCommentParams } from 'actions/home/comment';
import { showNotification } from 'common/Notification';

const MaxByteLength = 1000;
const NotificationCommentMaxLength = 30;

interface CommentProps {
  name: string;
  isOwn: boolean;
  comment: string;
  commentTime: number;
}

const CommentContainer: FC<CommentProps> = ({ name, isOwn, comment, commentTime }) => {
  const dispatch = useDispatch();
  const [tmpString, setTempString] = useState(comment);
  const [error, setError] = useState(false);
  const [editing, setEditing] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const prevCommentRef = useRef(comment);
  const prevComment = prevCommentRef.current;

  // 変更があった時のみ通知するため前回レンダリング時のコメントを保持しておく
  useEffect(() => {
    prevCommentRef.current = comment;
  });

  useEffect(() => {
    if (!isOwn) {
      if (name && comment !== prevComment) {
        if ('Notification' in window && Notification.permission === 'granted') {
          if (comment.length !== 0) {
            let noticeComment = comment;
            if (comment.length > NotificationCommentMaxLength) {
              noticeComment = `${comment.substr(0, NotificationCommentMaxLength)}…`;
            }
            showNotification({
              body: `${name}さんがコメントを更新しました。\r\n${noticeComment}`,
              tag: `comment_${name}}`,
            });
          }
        }
      }
      if (comment) {
        // 20秒間ポップアップを表示して消す
        setPopUpVisible(true);
        const timeOutId = setTimeout(() => setPopUpVisible(false), 20000);

        return () => {
          return clearTimeout(timeOutId);
        };
      }
    }
  }, [isOwn, name, comment, prevComment]);

  useEffect(() => {
    setTempString(comment);
  }, [comment]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    inputRef.current?.focus();
  }, [editing]);

  if (isOwn) {
    const updateComment = () => {
      if (comment !== tmpString && Buffer.byteLength(tmpString) <= MaxByteLength) {
        let nowTime = 0;
        if (tmpString.length > 0) {
          const now = new Date();
          nowTime = now.getHours() * 100 + now.getMinutes();
        }
        const param: UpdateCommentParams = { comment: tmpString, commentTime: nowTime };
        setTempString(tmpString);
        dispatch(updateCommentActions.start(param));
      }
      setEditing(false);
    };
    const changeComment = (currentString: string) => {
      if (Buffer.byteLength(currentString) > MaxByteLength) {
        setError(true);
      } else {
        setError(false);
      }
      setTempString(currentString);
    };

    const startEdit = () => {
      setEditing(true);
    };

    return (
      <Comment
        updateComment={updateComment}
        changeComment={changeComment}
        startEdit={startEdit}
        comment={comment}
        commentTime={commentTime.toString()}
        currentString={tmpString}
        isOwn={isOwn}
        isError={error}
        isEditing={editing}
        inputRef={inputRef}
      />
    );
  }

  return <Comment comment={comment} commentTime={commentTime.toString()} isOwn={isOwn} popUpVisible={popUpVisible} />;
};

export default CommentContainer;
