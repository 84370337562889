import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { splashAction } from 'actions/splash/splash';

export interface SplashState {
  displayed: boolean;
}
export const initialState: SplashState = {
  displayed: true,
};

export const splashReducer: Reducer<SplashState, splashAction> = (
  state: SplashState = initialState,
  action: splashAction,
): SplashState => {
  switch (action.type) {
    case ActionType.SET_SPLASH_DISPLAYED: {
      return {
        ...state,
        displayed: action.payload.displayed,
      };
    }
    default: {
      return state;
    }
  }
};
