import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { workboardAction } from 'actions/call/workboard';
import firebase from 'firebase/Instances';
import { DrawingDataSender } from 'sagas/call/classes/DrawingDataSender';

/**
 * DnDでやりとりするファイルのインターフェース
 */
export interface WorkboardFile {
  id?: string;
  name: string;
  dirPath: string;
  owner: string;
}
/**
 * テキストチャットのインターフェース
 */
export interface ChatMessage {
  uid: string;
  text: string;
  name: string;
  timestamp: firebase.firestore.Timestamp;
}
/**
 * 保存されたキャンバスのインターフェース
 */
export interface WorkboardCanvas {
  canvasName: string;
  dirPath: string;
  timestamp: firebase.firestore.Timestamp;
  owner: string;
}

/**
 * キャンバスの一時データ保存に使用するインターフェース
 * 一時データ（base64)と参加メンバーを保持する
 */
export interface CanvasTmpData {
  tmpData: string;
  members: string[];
  isInitialized: boolean;
}

/**
 * ワークボードの開閉,データ増監視
 */
export interface WorkboardUiState {
  sharingFilesOpened: boolean;
  textChatOpened: boolean;
  canvasOpened: boolean;
  files: WorkboardFile[];
  fileAddNotice: boolean;
  chatMessage: ChatMessage[];
  chatAddNotice: boolean;
  chatScrollPoint: number;
  downloadUrl: string;
  loading: boolean;
}

export interface WorkboardState {
  roomId: string;
  loading: boolean;
  uiState: WorkboardUiState;
  sender: DrawingDataSender | undefined;
  canvasTmpData: CanvasTmpData;
  activated: boolean;
}

export const initialState: WorkboardState = {
  roomId: '',
  loading: false,
  uiState: {
    sharingFilesOpened: false,
    textChatOpened: false,
    canvasOpened: false,
    files: [],
    fileAddNotice: false,
    chatMessage: [],
    chatAddNotice: false,
    chatScrollPoint: 0,
    downloadUrl: '',
    loading: false,
  },
  sender: undefined,
  canvasTmpData: { tmpData: '', members: [], isInitialized: false },
  activated: false,
};

export const workboardReducer: Reducer<WorkboardState, workboardAction> = (
  state: WorkboardState = initialState,
  action: workboardAction,
): WorkboardState => {
  switch (action.type) {
    case ActionType.JOIN_WORKBOARD_START: {
      return {
        ...initialState,
        roomId: action.payload.roomId,
        activated: false,
      };
    }
    case ActionType.JOIN_WORKBOARD_SUCCESS: {
      return {
        ...state,
        activated: true,
      };
    }
    case ActionType.WORKBOARD_UPLOAD_FILES_START: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: true,
        },
      };
    }
    case ActionType.WORKBOARD_UPLOAD_FILES_SUCCESS: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: false,
        },
      };
    }
    case ActionType.WORKBOARD_UPLOAD_FILES_ERROR: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: false,
        },
      };
    }
    case ActionType.GET_WORKBOARD_FILE_URL_START: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: true,
        },
      };
    }
    case ActionType.GET_WORKBOARD_FILE_URL_SUCCESS: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          downloadUrl: action.payload.url,
          loading: false,
        },
      };
    }
    case ActionType.GET_WORKBOARD_FILE_URL_ERROR: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: false,
        },
      };
    }
    case ActionType.GET_WORKBOARD_FILE_URL_CLEAR: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          downloadUrl: '',
        },
      };
    }
    case ActionType.WORKBOARD_DELETE_FILE_START: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: true,
        },
      };
    }
    case ActionType.WORKBOARD_DELETE_FILE_SUCCESS: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: false,
        },
      };
    }
    case ActionType.WORKBOARD_DELETE_FILE_ERROR: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: false,
        },
      };
    }
    case ActionType.SET_WORKBOARD_OPENED_SHARING_FILES: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          sharingFilesOpened: action.payload.isOpen,
        },
      };
    }
    case ActionType.SET_WORKBOARD_OPENED_TEXT_CHAT: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          textChatOpened: action.payload.isOpen,
        },
      };
    }
    case ActionType.SET_WORKBOARD_OPENED_CANVAS: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          canvasOpened: action.payload.isOpen,
        },
      };
    }
    case ActionType.SUBSCRIBE_SHARING_FILES: {
      const fileCountDiff = action.payload.files.length - state.uiState.files.length;

      const fileAddNotice = fileCountDiff > 0;

      return {
        ...state,
        uiState: {
          ...state.uiState,
          files: action.payload.files,
          fileAddNotice,
        },
      };
    }
    case ActionType.CLEAR_SHARING_FILES_NOTICE: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          fileAddNotice: false,
        },
      };
    }
    case ActionType.SUBSCRIBE_TEXT_CHAT: {
      const textCountDiff = action.payload.chatMessage.length - state.uiState.chatMessage.length;

      const chatAddNotice = textCountDiff > 0;

      return {
        ...state,
        uiState: {
          ...state.uiState,
          chatMessage: action.payload.chatMessage,
          chatAddNotice,
        },
      };
    }
    case ActionType.CLEAR_TEXT_CHAT_NOTICE: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          chatAddNotice: false,
        },
      };
    }
    case ActionType.SET_TEXT_CHAT_SCROLL_POINT: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          chatScrollPoint: action.payload.point,
        },
      };
    }
    case ActionType.SET_CANVAS_SENDER: {
      return {
        ...state,
        sender: action.payload.sender,
      };
    }

    case ActionType.SEND_CANVAS_TMP_DATA: {
      return {
        ...state,
        canvasTmpData: {
          ...state.canvasTmpData,
          tmpData: '',
          isInitialized: true,
        },
      };
    }
    case ActionType.GET_CANVAS_HISTORY_SUCCESS: {
      return {
        ...state,
        canvasTmpData: {
          tmpData: action.payload.tmpData.tmpData,
          members: action.payload.tmpData.members,
          isInitialized: true,
        },
      };
    }
    case ActionType.SUBSCRIBE_CANVAS_MEMBERS: {
      return {
        ...state,
        canvasTmpData: {
          tmpData: '',
          members: action.payload.members,
          isInitialized: state.canvasTmpData.isInitialized,
        },
      };
    }
    case ActionType.CLEAR_WORKBOARD_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
