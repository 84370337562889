/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { ContentsBox } from 'components/common/ContentsBox';
import { ContentsBoxContainerPage } from 'components/common/ContentsBoxContainerPage';
import { AuthPageHeader } from './AuthPageHeader';

const Maintenance: FC<{ startTime: string; endTime: string }> = ({ startTime, endTime }) => {
  return (
    <div>
      <AuthPageHeader />
      <ContentsBoxContainerPage headerDisplayed={false}>
        <ContentsBox title="メンテナンス">
          <p>ただいまメンテナンス中です。</p>
          <p>
            メンテナンス時刻は {startTime} ～ {endTime} を予定しています。
          </p>
          <p>恐れ入りますがメンテナンス終了後再度アクセスしてください。</p>
        </ContentsBox>
      </ContentsBoxContainerPage>
    </div>
  );
};

export default Maintenance;
