/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { BasicSelectBox, BasicSelectBoxOption } from 'components/common/parts/BasicSelectBox';

interface Props {
  label: string;
  currentDeviceId: string;
  deviceInfoArray: MediaDeviceInfo[];
  handleOnChangeDevice: (newDeviceId: string) => void;
}

// 選択中アイテムとして表示するデバイスのキーを取得する
const getSelectedDeviceId = (mediaDeviceOptions: BasicSelectBoxOption[], currentMediaDeviceId: string): string => {
  // ローカルストレージ保存済みデバイス ID がない場合は既定のデバイスを返す
  const currentDevice = mediaDeviceOptions.find(o => o.key === currentMediaDeviceId);
  const selectedDevice = currentDevice || mediaDeviceOptions.find(o => o.key === 'default');

  return selectedDevice?.key || '';
};

export const DeviceList: FC<Props> = ({ label, currentDeviceId, deviceInfoArray, handleOnChangeDevice }) => {
  const selectList = deviceInfoArray.map(d => ({ key: d.deviceId, label: d.label }));
  const selectedKey = getSelectedDeviceId(selectList, currentDeviceId);

  return (
    <BasicSelectBox
      selectedKey={selectedKey}
      selectList={selectList}
      handleOnSelectItem={key => handleOnChangeDevice(key)}
      label={label}
      forceScrollInListOpened
    />
  );
};
