import { call, put, select, take } from 'redux-saga/effects';
import { SfuRoom } from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import { storeCallError, storeMessage, updateLocalAudioEnabled, updateLocalStream } from 'actions/call/call';
import { RootState } from 'reducers/mainReducer';
import { StreamStat } from 'sagas/call/classes/RoomData';
import { Stream } from 'sagas/call/classes/Stream';
import { sendStreamStat } from 'sagas/call/tasks/watchJoiningRoomAction';

/**
 * マイクの有効化 or 無効化で dispatch されるアクションを監視するタスク
 */
export function* watchUpdatingAudioTrackEnabledAction(room: SfuRoom) {
  while (true) {
    const action = yield take(Action.UPDATE_LOCAL_AUDIO_ENABLED_START);
    const stream: Stream = yield select((state: RootState) => state.call.localUser?.stream);
    const stat: StreamStat = yield select((state: RootState) => state.call.localUser?.streamStat);
    stream.setAudioEnabled(action.payload.enabled);
    const newStat: StreamStat = {
      ...stat,
      isAudioEnabled: action.payload.enabled,
    };
    try {
      sendStreamStat(room, newStat);
      yield put(updateLocalAudioEnabled.succeed(newStat));
    } catch (error) {
      yield put(
        storeMessage(`マイクが使用できませんでした。一度通話を終了し、デバイス設定でカメラの確認をしてください。`),
      );
    }
  }
}

/**
 * ビデオを ON にする際に dispatch されるアクションを監視するタスク。
 * 画面共有時にはビデオの ON/OFF は変更させないので、カメラの場合のみを考慮する。
 *
 * @param room
 */
export function* watchEnablingVideoTrackAction(room: SfuRoom) {
  try {
    while (true) {
      const action = yield take(Action.ADD_VIDEO_TRACK);
      const { stream, stat }: { stream: Stream; stat: StreamStat } = action.payload;
      const { videoTrack, videoDeviceError } = yield call(Stream.createVideoTrack);
      if (videoDeviceError) {
        yield put(storeMessage('カメラの取得に失敗しました。デバイス設定で使用可能なカメラを選択してください。'));
        continue; // eslint-disable-line no-continue
      }
      stream.setOrReplaceVideoTrack(videoTrack);
      room.replaceStream(stream.getMediaStream());
      const newStat: StreamStat = {
        videoType: 'camera',
        isAudioEnabled: stat.isAudioEnabled,
        isVideoEnabled: true,
      };
      sendStreamStat(room, newStat);
      yield put(updateLocalStream(stream, newStat));
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey: '映像の送信中にエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  }
}
