import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCallableMembersActions } from 'actions/call/call';
import { RootState } from 'reducers/mainReducer';
import { StatusCode } from 'components/common/Statuses';

// REVISIT: Functions 側でも利用しているので統合を検討してください
export interface CallableMember {
  uid: string;
  name: string;
  status: StatusCode;
  isCalling: boolean;
  isTalking: boolean;
}

/**
 * 通話メンバー追加画面に必要な以下の情報を詰め込んだオブジェクトを取得する Custom Hook。
 * 1. 自分の所属するチームの id と name
 * 2. そのチームに所属するメンバーの id, name, その他情報
 */
export const useCallableMembers = (): [CallableMember[], boolean, () => void, Error?] => {
  const callableMembers = useSelector<RootState, CallableMember[]>(
    state => state.call.uiState.addMemberModal.callableMembers,
  );
  const opened = useSelector<RootState, boolean>(state => state.call.uiState.addMemberModal.opened);
  const loading = useSelector<RootState, boolean>(state => state.call.uiState.addMemberModal.loading);
  const error = useSelector<RootState, Error | undefined>(state => state.call.uiState.addMemberModal.error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (opened) {
      dispatch(fetchCallableMembersActions.start());
    }

    return () => {
      dispatch(fetchCallableMembersActions.clear());
    };
  }, [opened, dispatch]);

  const reload = () => {
    dispatch(fetchCallableMembersActions.start());
  };

  return [callableMembers, loading, reload, error];
};
