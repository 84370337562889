/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import SingIn from 'components/auth/SingIn';
import Home from 'containers/home/HomeContainer';
import { SignInAnonymouslyContainer } from 'containers/auth/SignInAnonymously';

export const PATH_DEMO = '/demo';
export const PATH_SIGN_IN_CLINKS = '/sign-in/clinks';
export const PATH_HOME = '/home';

export const RouteManager: FC = () => {
  const loginFinished = useSelector<RootState, boolean>(state => state.auth.loginFinished);
  const currentUserId = useSelector<RootState, string>(state => state.homeSubscriber.currentUserStatus.uid);

  const firebaseLogin = loginFinished && currentUserId;

  return (
    <Switch>
      <Route exact path={PATH_SIGN_IN_CLINKS} component={SingIn} />
      <Route exact path={PATH_DEMO} component={SignInAnonymouslyContainer} />
      {firebaseLogin && <Route exact path={PATH_HOME} component={Home} />}
    </Switch>
  );
};
