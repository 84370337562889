/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import { ChatMessage } from 'reducers/call/workboard';
import { CallingUser } from 'reducers/call/call';
import { COLORS, verticalScrollBar } from 'styles/style';
import { ChatHeader } from 'components/call/workboard/TextChat/ChatHeader';
import { ChatFooter } from 'components/call/workboard/TextChat/ChatFooter';
import { ChatMessages } from 'components/call/workboard/TextChat/ChatMesages';
import { ChatError } from 'components/call/workboard/TextChat/ChatError';

const chatScrollBar = css`
  ${verticalScrollBar};

  ::-webkit-scrollbar {
    width: 7px;
  }
`;

const chatContainer = css`
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  padding: 20px 10px 20px 20px;
  width: 100%;

  /* 固定 */
  .header {
    flex: 0 0 20px;
  }

  .error {
    flex: 0 0;
  }

  /* 可変 + 入り切らない場合はスクロール */
  .messages {
    ${chatScrollBar};
    flex: 1 1 auto;
    padding-right: 5px;
  }

  /* 固定 */
  .footer {
    flex: 0 0 80px;
  }
`;

interface Props {
  currentUserId: string;
  currentUserIconUrl?: string;
  remoteUsers: CallingUser[];
  chatMessage?: ChatMessage[];
  sendTextChat: (text: string) => void;
  handleOnCloseTextChat: () => void;
  chatRef: React.RefObject<HTMLDivElement>;
  chatEndRef: React.RefObject<HTMLDivElement>;
  setPoint: (event: React.UIEvent<HTMLDivElement>) => void;
}

export const TextChat: FC<Props> = ({
  currentUserId,
  currentUserIconUrl,
  remoteUsers,
  chatMessage,
  sendTextChat,
  handleOnCloseTextChat,
  chatRef,
  chatEndRef,
  setPoint,
}) => {
  return (
    <div css={chatContainer}>
      <div className="header">
        <ChatHeader handleOnCloseTextChat={handleOnCloseTextChat} />
      </div>
      <ChatError />
      <div className="messages">
        <ChatMessages
          currentUserId={currentUserId}
          currentUserIconUrl={currentUserIconUrl}
          chatMessage={chatMessage ?? []}
          remoteUsers={remoteUsers}
          chatRef={chatRef}
          chatEndRef={chatEndRef}
          setPoint={setPoint}
        />
      </div>
      <div className="footer">
        <ChatFooter sendTextChat={sendTextChat} />
      </div>
    </div>
  );
};
