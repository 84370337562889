import { call, cancelled, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import Peer from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import {
  addRemoteUserWithInvitation,
  CallAction,
  cancelOtherInvitation,
  setCallableMemberTalking,
  storeCallError,
  storeMessage,
  updateCallingUserStatus,
} from 'actions/call/call';
import { User } from 'components/home/Home';
import { CallingUser, CallingUserStatus } from 'reducers/call/call';
import { RootState } from 'reducers/mainReducer';
import { LocalInvitation } from 'sagas/call/classes/LocalInvitation';
import { InvitationContent } from 'sagas/call/classes/ConnectionData';
import { analytics } from 'firebase/Instances';
import { getUserInfoInCallView } from 'services/firebase/api';

// 営業の誰か一人に繋がったら、最初に繋がった一人以外の招待をキャンセルする
function* runCancelOtherInvitation(action: ReturnType<typeof cancelOtherInvitation>) {
  const remoteId = action.payload.targetUid;
  const remoteUsers: CallingUser[] = yield select(state => state.call.remoteUsers);
  remoteUsers.forEach(user => {
    if (user.uid !== remoteId) {
      if (user.localInvitation) user.localInvitation.cancel();
    }
  });
}

export function* watchCancelOtherInvitation() {
  yield takeLatest(Action.CANCEL_OTHER_INVITATION, runCancelOtherInvitation);
}

const subscribeLocalInvitation = (localInvitation: LocalInvitation, targetName: string) =>
  eventChannel(emitter => {
    const conn = localInvitation.getRawConnection();
    conn.on('LocalInvitationReceivedByPeer', (remoteId: string) => {
      emitter(updateCallingUserStatus(remoteId, CallingUserStatus.CALLING));
    });

    conn.on('LocalInvitationAccepted', (remoteId: string) => {
      emitter(cancelOtherInvitation(remoteId));
      emitter(updateCallingUserStatus(remoteId, CallingUserStatus.ACCEPTED));
    });

    conn.on('LocalInvitationRefused', (remoteId: string) => {
      emitter(updateCallingUserStatus(remoteId, CallingUserStatus.REFUSED));
      localInvitation.close();
    });

    conn.on('LocalInvitationJoinFinished', (remoteId: string) => {
      emitter(updateCallingUserStatus(remoteId, CallingUserStatus.JOINED));
      localInvitation.close();
    });

    conn.on('LocalInvitationJoinFailure', (remoteId: string) => {
      emitter(updateCallingUserStatus(remoteId, CallingUserStatus.JOIN_FAILURE));
      localInvitation.close();
    });

    conn.on('LocalInvitationCanceled', (remoteId: string) => {
      emitter(updateCallingUserStatus(remoteId, CallingUserStatus.CANCELED));
    });

    conn.on('LocalInvitationClosedByAccident', (remoteId: string) => {
      emitter(updateCallingUserStatus(remoteId, CallingUserStatus.FAILURE));
    });

    conn.on('LocalInvitationFailure', (remoteId: string, error: Error) => {
      emitter(updateCallingUserStatus(remoteId, CallingUserStatus.FAILURE));
      console.log(`Failed to invite. target: ${targetName}, Reason: ${error}`); // eslint-disable-line no-console
      conn.close(true);
    });

    return () => {
      if (conn.open) {
        localInvitation.cancel();
      }
    };
  });

function* watchLocalInvitationEvents(localInvitation: LocalInvitation, targetName: string) {
  const channel = yield call(subscribeLocalInvitation, localInvitation, targetName);
  try {
    while (true) {
      const action: CallAction = yield take(channel);
      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export interface ConnectionMetadata {
  type: 'invitation' | 'whisper-invitation';
}

/**
 * 通話中の招待送信を監視するタスク。
 * 招待送信後は、その送信した招待に対して EventChannel を張り、招待の状況を監視する。
 *
 * @param peer
 */
export function* watchSendingInvitationAction(peer: Peer) {
  try {
    while (true) {
      const action = yield take(Action.SEND_INVITATION_START);
      const { targetIds, isVoiceOnly, roomId } = action.payload;

      const currentUser: User = yield select((state: RootState) => state.homeSubscriber.currentUserStatus);

      // yield を使うため。
      // eslint-disable-next-line no-restricted-syntax
      for (const targetId of targetIds) {
        const [targetName, isTalking] = yield call(getUserInfoInCallView, targetId);
        if (isTalking) {
          yield put(setCallableMemberTalking(targetId));
          yield put(storeMessage(`${targetName} さんはすでに通話中です。`));
          continue; // eslint-disable-line no-continue
        }
        const metadata: ConnectionMetadata = { type: 'invitation' };
        const iconUrl = `${process.env.PUBLIC_URL}/images/userDefault.png`;
        const invitationContent: InvitationContent = {
          name: currentUser.name,
          iconUrl,
          isVoiceOnly,
          roomId,
        };

        try {
          const conn = peer.connect(targetId, { metadata });
          const localInvitation = new LocalInvitation(conn);
          yield fork(watchLocalInvitationEvents, localInvitation, targetName);
          conn.on('open', () => {
            localInvitation.invite(invitationContent, 30000); // 30 秒
          });
          yield put(addRemoteUserWithInvitation(targetId, targetName, iconUrl, localInvitation));
        } catch (error) {
          analytics.logEvent('send_invitation_failure', { uid: peer.id, remoteId: targetId, error });
          yield put(storeCallError({ msgKey: `招待に失敗しました: ${error.message}` }));
        }
      }
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey: '招待の送信でエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  }
}
