/** @jsx jsx */
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { jsx } from '@emotion/core';
import { connectCallServerActions, disconnectCallServerActions } from 'actions/call/call';

const ConnectionInitializer: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(connectCallServerActions.start());

    return () => {
      dispatch(disconnectCallServerActions.start());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div />;
};

export default ConnectionInitializer;
