/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { MemberSearch } from 'components/common/MemberSearch';
import { setMemberViewSearchField } from 'actions/home/headerMemberSearch';

export const MemberSearchContainer: FC = () => {
  const dispatch = useDispatch();
  const handleOnChanged = (inputStr: string) => {
    dispatch(setMemberViewSearchField.start(inputStr));
  };

  return <MemberSearch handleOnChanged={handleOnChanged} />;
};
