/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { InvitationStage } from 'sagas/call/classes/ConnectionData';
import { COLORS } from 'styles/style';
import { BasicModal } from 'components/common/modal/BasicModal';

interface SendingWhisperInvitationModalProps {
  stage: InvitationStage;
  onCancel: () => void;
}

const convertToContent = (stage: InvitationStage): string => {
  switch (stage) {
    case 'sending': {
      return 'ひそひそ通話を開始しています...';
    }
    case 'received': {
      return '発信中です...';
    }
    case 'refused': {
      return '拒否されました。';
    }
    case 'accepted': {
      return '準備中です...';
    }
    case 'refusedByWhispering': {
      return 'すでに別のメンバーとひそひそ中です。';
    }
    default: {
      return 'ひそひその開始に失敗しました。';
    }
  }
};

export const SendingWhisperInvitationModal: FC<SendingWhisperInvitationModalProps> = ({ stage, onCancel }) => {
  const content = convertToContent(stage);
  const buttonLabel = stage === 'sending' || stage === 'received' ? 'キャンセル' : '閉じる';

  return (
    <BasicModal
      open
      header="ひそひそ通話..."
      headerIconColor={COLORS.orange}
      contents={<p>{content}</p>}
      handleOnCancelClicked={onCancel}
      cancelButtonLabel={buttonLabel}
    />
  );
};
