import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { routeAction } from 'actions/route/route';

export interface RouteState {
  redirectTo: string;
}
export const initialState: RouteState = {
  redirectTo: '',
};

export const routeReducer: Reducer<RouteState, routeAction> = (
  state: RouteState = initialState,
  action: routeAction,
): RouteState => {
  switch (action.type) {
    case ActionType.SET_REDIRECT_PATH: {
      return {
        ...state,
        redirectTo: action.payload.redirectTo,
      };
    }
    default: {
      return state;
    }
  }
};
