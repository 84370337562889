/** @jsx jsx */
import { FC, ReactElement } from 'react';
import { jsx, css } from '@emotion/core';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { COLORS, boldFont, backGroundZIndex } from 'styles/style';

const iconBoxBaseCss = css`
  background: ${COLORS.white};
  border-radius: 3px;
  display: inline-block;
  padding: 3px 4px;
`;

export const iconBoxCss = css`
  ${iconBoxBaseCss};
  height: 20px;
  width: 22px;
`;

const attendanceIconBoxCss = css`
  ${iconBoxBaseCss};
  border: none;
  font-size: 12px;
  height: 20px;
  max-width: 110px;
  min-width: 70px;
  outline: 0;
  vertical-align: text-top;

  img {
    float: left;
    height: 14px;
    margin-right: 5px;
    width: 14px;
  }
`;

export const selectButtonInteraction = css`
  background-color: #00b5de;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  ${backGroundZIndex};
`;

export const selectButtonBaseCss = css`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: auto;
  position: relative;
`;

const selectButtonCss = css`
  ${boldFont};
  ${selectButtonBaseCss};
  align-items: flex-start;
  color: ${COLORS.darkGray};
  font-size: 12px;
  max-width: 160px;
  min-width: 86px;
  outline: 0;
  padding: 8px 10px;
  text-align: center;
  width: 100%;

  img {
    height: 14px;
    margin-right: 6px;
    min-width: 14px;
    width: 14px;
  }

  div {
    max-width: 120px;
    text-align: center;
    width: 100%;
  }

  &:hover::before {
    ${selectButtonInteraction};
    opacity: 0.15;
  }
`;
export const currentSelectedCss = css`
  &::before {
    ${selectButtonInteraction};
    opacity: 0.3;
  }
`;

const isOwnCss = css`
  cursor: pointer;
  outline: 0;
`;

// 個人スペースヘッダーのアイコンアイコンボックス
export const PersonalHeaderIconBox: FC<{ iconPath: string }> = ({ iconPath }) => {
  return (
    <div css={iconBoxCss}>
      <img src={iconPath} css={{ height: '14px', width: '14px' }} alt="" />
    </div>
  );
};

// 個人スペースヘッダーの出退勤ボタン
export const AttendanceButton: FC<{ iconPath: string; isOwn?: boolean; onClick?: () => void; colored?: boolean }> = ({
  iconPath,
  children,
  isOwn,
  onClick,
  colored,
}) => {
  return (
    <button
      type="button"
      css={[attendanceIconBoxCss, isOwn && isOwnCss, colored && { color: COLORS.red }]}
      onClick={onClick}
    >
      <img src={iconPath} alt="" />
      {children}
    </button>
  );
};

// 個人スペースヘッダーの選択肢用Popup（BasicPopupをベースにしてHome向けのCSSを適用している）
export const MemberViewPopup: FC<{ value: ReactElement }> = ({ value, children }) => {
  return (
    <BasicPopup value={value} position="bottom" color="white" padding="0">
      {children}
    </BasicPopup>
  );
};

// 個人スペースヘッダーの選択肢用ボタン
export const SelectButton: FC<{
  value: string;
  onClick: () => void;
  iconPath?: string;
  currentSelected?: boolean;
}> = ({ value, iconPath, onClick, currentSelected }) => {
  return (
    <button type="button" css={[selectButtonCss, currentSelected && currentSelectedCss]} onClick={onClick}>
      {iconPath && <img src={iconPath} alt="" />}
      <div className="value">{value}</div>
    </button>
  );
};
