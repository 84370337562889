/**
 * Stringのバイト数を返す
 *
 * @param str 対象の文字列
 */
export const getStringLengthByByte = (str: string) => {
  let length = 0;
  for (let i = 0; i < str.length; i += 1) {
    const c = str.charCodeAt(i);
    if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
      length += 1;
    } else {
      length += 2;
    }
  }

  return length;
};

/**
 * Stringの左から○バイト分の文字を返す
 *
 * @param str 対象の文字列
 * @param byte 返したいバイト数
 */
export const getStringLeftByByte = (str: string, byte: number) => {
  let length = 0;
  let left = '';
  for (let i = 0; i < str.length; i += 1) {
    const c = str.charCodeAt(i);
    if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
      length += 1;
    } else {
      length += 2;
    }
    if (length > byte) {
      return `${left}…`;
    }

    left += String.fromCharCode(c);
  }

  return left;
};

/**
 * URLをクリップボードにコピーする
 *
 * @param url 対象のURL
 */
export const copyUrl = (url: string) => {
  const input = document.createElement('input');
  input.setAttribute('id', 'copyinput');
  document.body.appendChild(input);
  input.value = url;
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);

  return 'URLをクリップボードにコピーしました。';
};

/**
 * Date を指定したフォーマットに変換して返す関数
 *
 * @param date 文字列に変換したい Date
 * @param format フォーマット文字列。例: yyyy/MM/dd HH:mm:ss.SSS
 */
export const formatDate = (date: Date, format: string): string => {
  return format
    .replace(/yyyy/g, `${date.getFullYear()}`)
    .replace(/MM/g, `0${date.getMonth() + 1}`.slice(-2))
    .replace(/dd/g, `0${date.getDate()}`.slice(-2))
    .replace(/HH/g, `0${date.getHours()}`.slice(-2))
    .replace(/mm/g, `0${date.getMinutes()}`.slice(-2))
    .replace(/ss/g, `0${date.getSeconds()}`.slice(-2))
    .replace(/SSS/g, `00${date.getMilliseconds()}`.slice(-3));
};

/**
 * 指定した時間待機する関数
 *
 * @param ms 待機する時間 (Milli Seconds)
 */
export const sleep = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

/**
 * 画面共有取得時のエラーを適切なメッセージに変換する
 *
 * @param error
 */
export const convertCaptureErrorToMsg = (error: Error): string | null => {
  if (error.name === 'NotAllowedError') {
    if (error.message.includes('by system')) {
      // Mac OS の権限設定により失敗した場合。メッセージ依存した分岐でかなりイケてないが、現状これしか判別方法がない。
      // ただ単にダイアログでキャンセルした場合: error.message -> Permission denied
      // OS の設定により共有開始に失敗した場合: error.message -> Permission denied by system
      return '画面共有を開始できませんでした。OS の権限設定などを確認してください。';
    }

    if (error.message.includes('not allowed by the user agent')) {
      // ブラウザによって拒否していた場合 (Firefox と Safari)
      return '画面共有を開始できませんでした。ブラウザの設定を確認してください。';
    }

    // 共有ウィンドウ選択ダイアログでキャンセルした場合は特に何もしない
    return null;
  }

  return '画面共有を開始できませんでした。';
};

/**
 * Safari かどうかを判定する関数
 */
export const isSafari = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
    return false;
  }
  if (userAgent.indexOf('edge') !== -1) {
    return false;
  }
  if (userAgent.indexOf('chrome') !== -1) {
    return false;
  }
  if (userAgent.indexOf('safari') !== -1) {
    return true;
  }
  if (userAgent.indexOf('firefox') !== -1) {
    return false;
  }

  return false;
};
