import { DataConnection } from 'skyway-js';
import { ConnectionData, InvitationContent } from 'sagas/call/classes/ConnectionData';
import { analytics } from 'firebase/Instances';

/**
 * DataConnection をラップし、受信側からみた招待を表すようにしたクラス
 */
export class RemoteInvitation {
  private readonly conn: DataConnection;

  private content?: InvitationContent;

  constructor(conn: DataConnection) {
    this.conn = conn;

    conn.on('data', (data: ConnectionData) => {
      switch (data.type) {
        case 'invitation': {
          switch (data.payload.stage) {
            case 'sending': {
              this.content = data.payload.content;
              this.received();
              conn.emit('RemoteInvitationReceived');
              break;
            }
            case 'canceled': {
              conn.emit('RemoteInvitationCanceled', conn.remoteId);
              break;
            }
            default: {
              conn.emit('RemoteInvitationFailure', conn.remoteId);
            }
          }
          break;
        }
        default: {
          conn.emit('LocalInvitationFailure', `不明なデータを受信しました。type: ${data.type}`);
        }
      }
    });

    conn.on('close', () => {
      conn.emit('RemoteInvitationClosed', conn.remoteId);
    });

    conn.on('error', error => {
      analytics.logEvent('data_connection_error', { uid: conn.id, remoteId: conn.remoteId, error });
      conn.emit('RemoteInvitationFailure', conn.remoteId, error);
    });
  }

  getRawConnection(): DataConnection {
    return this.conn;
  }

  getContent(): InvitationContent | undefined {
    return this.content;
  }

  received() {
    const data: ConnectionData = { type: 'invitation', payload: { stage: 'received' } };
    this.send(data);
  }

  accept() {
    const data: ConnectionData = { type: 'invitation', payload: { stage: 'accepted' } };
    this.send(data);
  }

  finishJoining() {
    const data: ConnectionData = { type: 'invitation', payload: { stage: 'joinFinished' } };
    this.send(data);
  }

  failedToJoin() {
    const data: ConnectionData = { type: 'invitation', payload: { stage: 'joinFailure' } };
    this.send(data);
  }

  refuse() {
    const data: ConnectionData = { type: 'invitation', payload: { stage: 'refused' } };
    this.send(data);
  }

  refuseByAlreadyWhispering() {
    const data: ConnectionData = { type: 'invitation', payload: { stage: 'refusedByWhispering' } };
    this.send(data);
  }

  send(data: ConnectionData) {
    if (this.conn.open) {
      this.conn.send(data);
    } else {
      this.conn.on('open', () => {
        this.conn.send(data);
      });
    }
  }
}
