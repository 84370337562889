/* eslint-disable no-unused-expressions */
import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { CallAction } from 'actions/call/call';
import Peer, { MediaConnection, SfuRoom } from 'skyway-js';
import { Stream } from 'sagas/call/classes/Stream';
import { StreamStat } from 'sagas/call/classes/RoomData';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';
import { LocalInvitation } from 'sagas/call/classes/LocalInvitation';
import { CallableMember } from 'containers/call/modal/useCallableMembers';
import { ICON_URL } from 'components/home/Home';

const calcMaximizedUid = (
  uid: string,
  prevStat: StreamStat | undefined,
  newStat: StreamStat,
  prevMaximizedUid: string | undefined,
): string | undefined => {
  const prevVideoType = prevStat?.videoType;

  // 拡大画面共有していた人が画面共有を停止した場合、拡大表示を終了
  if (prevVideoType === 'screen' && (newStat.videoType === 'camera' || newStat.videoType === 'dummy')) {
    if (prevMaximizedUid === uid) {
      return undefined;
    }
  }

  // 新たに画面共有を開始した人がいた場合は拡大表示にする
  if (newStat.videoType === 'screen' && (prevVideoType === 'camera' || prevVideoType === 'dummy')) {
    return uid;
  }

  return prevMaximizedUid;
};

export enum CallingUserStatus {
  CONNECTING = 'CONNECTING',
  CALLING = 'CALLING',
  JOINING = 'JOINING', // Room には JOIN が完了して、デバイスリソースを取得している状態
  JOINED = 'JOINED',
  JOIN_FAILURE = 'JOIN_FAILURE',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  CANCELED = 'CANCELED',
  FAILURE = 'FAILURE',
}

export const NONACTIVE_STATUSES: CallingUserStatus[] = [
  CallingUserStatus.REFUSED,
  CallingUserStatus.JOIN_FAILURE,
  CallingUserStatus.CANCELED,
  CallingUserStatus.FAILURE,
];

/**
 * 通話ユーザ (招待中も含む) を表すインタフェース。
 */
export interface CallingUser {
  uid: string;
  name: string;
  iconUrl: string;
  stream?: Stream;
  streamStat?: StreamStat;
  status: CallingUserStatus;
  localInvitation?: LocalInvitation;
  nowSpeaking?: boolean;
}

/**
 * 既存通話へ参加する際に表示するモーダルへ渡すプロパティ。
 * モーダルでの「参加」を押したタイミングで参加になるため、モーダルを開く前に押していたユーザや
 * ルームの情報を記憶しておく必要があるためストアで保持している。
 */
export interface JoinConfirmationModalProps {
  targetUid: string;
  targetRoomId: string;
  isVoiceOnly: boolean;
}

export interface ReceivedCallHistory {
  from: string;
  iconUrl: string;
  isVoiceOnly: boolean;
  calledAt: Date;
}

export interface CallError {
  msgKey: string;
  detail?: string;
}
/**
 * UI 表示非表示に関する情報を集めたインタフェース
 */
export interface CallUiState {
  maximizedUid: string | undefined;
  receivingModalOpened: boolean;
  menuDisplayed: boolean;
  addMemberModal: {
    opened: boolean;
    callableMembers: CallableMember[];
    loading: boolean;
    error?: Error;
  };
  deviceSettingModalOpened: boolean;
  joinConfirmationModalOpened: boolean;
  joinConfirmationModalProps: JoinConfirmationModalProps | undefined;
  callToTeamConfirmationModalOpened: boolean;
  reconnectionModalOpened: boolean;
  nowJoining: boolean;
  msgKeys: string[];
  errors: CallError[];
}

/**
 * 通話関連のストア
 */
export interface CallState {
  peer: Peer | undefined;
  room: SfuRoom | undefined;
  receivedInvitationList: RemoteInvitation[];
  localUser: CallingUser | undefined;
  remoteUsers: CallingUser[];
  isSharingScreen: boolean;
  uiState: CallUiState;
  childTaskOfCall: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  dummyVideoTrack: MediaStreamTrack | undefined;
  sentWhisperInvitation: LocalInvitation | undefined;
  receivedWhisperInvitation: RemoteInvitation | undefined;
  whisperConnection: MediaConnection | undefined;
  whisperStream: MediaStream | undefined;
  whisperTargetUid: string | undefined;
  receivedCallHistories: ReceivedCallHistory[];
  parentRoomVolume: number;
  isDispCallView: boolean;
  noticed: boolean;
}

export const initialState: CallState = {
  peer: undefined,
  room: undefined,
  receivedInvitationList: [],
  localUser: undefined,
  remoteUsers: [],
  isSharingScreen: false,
  uiState: {
    maximizedUid: undefined,
    receivingModalOpened: false,
    menuDisplayed: false,
    addMemberModal: {
      opened: false,
      callableMembers: [],
      loading: true,
    },
    deviceSettingModalOpened: false,
    joinConfirmationModalOpened: false,
    joinConfirmationModalProps: undefined,
    callToTeamConfirmationModalOpened: false,
    reconnectionModalOpened: false,
    nowJoining: false,
    msgKeys: [],
    errors: [],
  },
  childTaskOfCall: undefined,
  dummyVideoTrack: undefined,
  sentWhisperInvitation: undefined,
  receivedWhisperInvitation: undefined,
  whisperConnection: undefined,
  whisperStream: undefined,
  whisperTargetUid: undefined,
  receivedCallHistories: [],
  parentRoomVolume: 1,
  isDispCallView: true,
  noticed: false,
};

export const callReducer: Reducer<CallState, CallAction> = (
  state: CallState = initialState,
  action: CallAction,
): CallState => {
  switch (action.type) {
    case ActionType.CONNECT_CALL_SERVER_FAIL: {
      const errors = state.uiState.errors.slice();
      errors.push({ msgKey: action.payload.msgKey });

      return {
        ...state,
        uiState: {
          ...state.uiState,
          errors,
        },
      };
    }
    case ActionType.RECONNECT_CALL_SERVER: {
      return {
        ...initialState,
        dummyVideoTrack: state.dummyVideoTrack,
      };
    }
    case ActionType.DISCONNECT_CALL_SERVER: {
      return {
        ...initialState,
        dummyVideoTrack: state.dummyVideoTrack,
      };
    }
    case ActionType.STORE_DUMMY_STREAM: {
      return {
        ...state,
        dummyVideoTrack: action.payload.dummyVideoTrack,
      };
    }
    case ActionType.STORE_ROOM: {
      return {
        ...state,
        room: action.payload.room,
      };
    }
    case ActionType.ADD_REMOTE_USER_WITH_INVITATION: {
      const { uid, name, invitation } = action.payload;
      const newRemoteUsers = state.remoteUsers.slice();
      const found = newRemoteUsers.find(u => u.uid === uid);

      if (found) {
        if (found.localInvitation) {
          found.localInvitation.close();
        }
        found.localInvitation = invitation;
      } else {
        newRemoteUsers.push({
          uid,
          name,
          iconUrl: ICON_URL,
          localInvitation: invitation,
          status: CallingUserStatus.CONNECTING,
        });
      }

      return {
        ...state,
        remoteUsers: newRemoteUsers,
      };
    }
    case ActionType.UPDATE_CALLING_USER_STATUS: {
      const { targetUid, status } = action.payload;
      const newRemoteUsers = state.remoteUsers.slice();
      const found = newRemoteUsers.find(u => u.uid === targetUid);
      if (found) {
        found.status = status;
      }

      return {
        ...state,
        remoteUsers: newRemoteUsers,
      };
    }
    case ActionType.JOIN_ROOM_START: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          nowJoining: true,
        },
      };
    }
    case ActionType.JOIN_ROOM_SUCCEED: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          nowJoining: false,
        },
      };
    }
    case ActionType.JOIN_ROOM_FAIL: {
      const errorMessages = state.uiState.errors.slice();
      errorMessages.push({ msgKey: action.payload.msgKey });

      return {
        ...state,
        uiState: {
          ...state.uiState,
          nowJoining: false,
          errors: errorMessages,
        },
      };
    }
    case ActionType.STORE_CHILD_TASK_OF_CALL: {
      const { childTask } = action.payload;

      return {
        ...state,
        childTaskOfCall: childTask,
      };
    }
    case ActionType.SET_LOCAL_USER: {
      const { uid, name, stream, streamStat } = action.payload;

      return {
        ...state,
        localUser: { uid, name, iconUrl: ICON_URL, status: CallingUserStatus.JOINED, stream, streamStat },
      };
    }
    case ActionType.UPDATE_LOCAL_STREAM: {
      const { stream, stat } = action.payload;
      const currentLocalUser = state.localUser;

      if (!currentLocalUser) {
        return state;
      }

      return {
        ...state,
        localUser: {
          ...currentLocalUser,
          stream,
          streamStat: stat,
        },
      };
    }
    case ActionType.SET_REMOTE_USER: {
      const { uid, name } = action.payload;
      const newRemoteUsers = state.remoteUsers.slice();
      const found = newRemoteUsers.find(u => u.uid === uid);

      if (found) {
        return { ...state };
      }

      // 他人が招待して入室してきた場合は remoteUsers にいない状態なので、追加
      newRemoteUsers.push({ uid, name, iconUrl: ICON_URL, status: CallingUserStatus.JOINING });

      return {
        ...state,
        remoteUsers: newRemoteUsers,
      };
    }
    case ActionType.ADD_REMOTE_STREAM: {
      const { uid, stream } = action.payload;
      const newRemoteUsers = state.remoteUsers.slice();
      const targetUser = newRemoteUsers.find(s => s.uid === uid);

      if (!targetUser) {
        return { ...state };
      }

      targetUser.status = CallingUserStatus.JOINED;
      targetUser.stream = stream;

      return {
        ...state,
        remoteUsers: newRemoteUsers,
      };
    }
    case ActionType.SET_REMOTE_STAT: {
      const { uid, stat } = action.payload;
      const newRemoteUsers = state.remoteUsers.slice();
      const targetUser = newRemoteUsers.find(s => s.uid === uid);

      if (!targetUser) {
        return { ...state };
      }

      const newMaximizedUid = calcMaximizedUid(uid, targetUser.streamStat, stat, state.uiState.maximizedUid);

      targetUser.streamStat = stat;

      return {
        ...state,
        remoteUsers: newRemoteUsers,
        uiState: {
          ...state.uiState,
          maximizedUid: newMaximizedUid,
        },
      };
    }
    case ActionType.REMOVE_REMOTE_INVITATION: {
      const newRemoteInvitations: RemoteInvitation[] = [];
      const { removeTargetInvitations } = action.payload;
      state.receivedInvitationList.forEach(i => {
        const shouldDelete = removeTargetInvitations.find(
          target => target.getRawConnection().remoteId === i.getRawConnection().remoteId,
        );
        if (!shouldDelete) {
          newRemoteInvitations.push(i);
        }
      });

      return {
        ...state,
        receivedInvitationList: newRemoteInvitations,
        uiState: {
          ...state.uiState,
          receivingModalOpened: newRemoteInvitations.length !== 0,
        },
      };
    }
    case ActionType.REMOVE_REMOTE_USER: {
      const newRemoteUsers: CallingUser[] = [];
      // IRemoteTrack には close がないので、利用側では明示的に閉じる必要はなし。
      // state.remoteStreams から要素を削って、レンダリング側を削除するだけで OK (多分)
      state.remoteUsers.forEach(s => {
        if (s.uid !== action.payload.targetUid) {
          newRemoteUsers.push(s);
        }
      });

      const maximizedUid =
        state.uiState.maximizedUid === action.payload.targetUid ? undefined : state.uiState.maximizedUid;

      return {
        ...state,
        remoteUsers: newRemoteUsers,
        uiState: {
          ...state.uiState,
          maximizedUid,
        },
      };
    }
    case ActionType.SHARE_SCREEN_SUCCESS: {
      return {
        ...state,
        isSharingScreen: true,
      };
    }
    case ActionType.STOP_SHARE_SCREEN_START: {
      return {
        ...state,
        isSharingScreen: false,
      };
    }
    case ActionType.STOP_CALLING_SUCCEED: {
      if (state.localUser?.stream) {
        state.localUser?.stream.stop();
      }

      return {
        ...initialState,
        dummyVideoTrack: state.dummyVideoTrack,
      };
    }
    case ActionType.UPDATE_LOCAL_USER_SPEAKING: {
      const { nowSpeaking } = action.payload;

      if (!state.localUser) {
        return { ...state };
      }

      return {
        ...state,
        localUser: {
          ...state.localUser,
          nowSpeaking,
        },
      };
    }
    case ActionType.UPDATE_REMOTE_USER_SPEAKING: {
      const { targetUid, nowSpeaking } = action.payload;

      const newRemoteUsers: CallingUser[] = [];
      state.remoteUsers.forEach(u => {
        if (u.uid === targetUid) {
          newRemoteUsers.push({
            ...u,
            nowSpeaking,
          });
        } else {
          newRemoteUsers.push(u);
        }
      });

      return {
        ...state,
        remoteUsers: newRemoteUsers,
      };
    }
    case ActionType.UPDATE_LOCAL_AUDIO_ENABLED_SUCCEED: {
      if (state.localUser) {
        return {
          ...state,
          localUser: {
            ...state.localUser,
            streamStat: action.payload.stat,
          },
        };
      }

      return {
        ...state,
      };
    }
    case ActionType.DISABLE_LOCAL_VIDEO_START: {
      if (state.localUser) {
        return {
          ...state,
          localUser: {
            ...state.localUser,
            streamStat: action.payload.stat,
          },
        };
      }

      return {
        ...state,
      };
    }
    case ActionType.OPEN_RECEIVING_MODAL: {
      const { remoteInvitation } = action.payload;
      const newInvitationList = state.receivedInvitationList.slice();

      const existedIdx = newInvitationList.findIndex(
        i => remoteInvitation.getRawConnection().remoteId === i.getRawConnection().remoteId,
      );
      if (existedIdx === -1) {
        newInvitationList.push(remoteInvitation);
      } else {
        newInvitationList[existedIdx] = remoteInvitation;
      }

      return {
        ...state,
        receivedInvitationList: newInvitationList,
        uiState: {
          ...state.uiState,
          receivingModalOpened: true,
        },
      };
    }
    case ActionType.CLOSE_RECEIVING_MODAL: {
      return {
        ...state,
        receivedInvitationList: [],
        uiState: {
          ...state.uiState,
          receivingModalOpened: false,
        },
      };
    }
    case ActionType.OPEN_WHISPER_RECEIVING_MODAL: {
      return {
        ...state,
        receivedWhisperInvitation: action.payload.remoteInvitation,
      };
    }
    case ActionType.CLOSE_WHISPER_RECEIVING_MODAL: {
      if (state.receivedWhisperInvitation?.getRawConnection().remoteId === action.payload.targetRemoteId) {
        return {
          ...state,
          receivedWhisperInvitation: undefined,
        };
      }

      // 囁き中に別のひそひそ着信があり自動で拒否した場合、モーダルを開くのに利用している招待はそのままであってほしいので何もしない
      return { ...state };
    }
    case ActionType.OPEN_JOIN_CONFIRMATION_MODAL: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          joinConfirmationModalOpened: true,
          joinConfirmationModalProps: action.payload.joinConfirmationModalProps,
        },
      };
    }
    case ActionType.CLOSE_JOIN_CONFIRMATION_MODAL: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          joinConfirmationModalOpened: false,
          joinConfirmationModalProps: undefined,
        },
      };
    }
    case ActionType.SET_ADD_MEMBER_MODAL_OPENED: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          addMemberModal: {
            opened: action.payload.opened,
            callableMembers: [],
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionType.FETCH_CALLABLE_MEMBERS_START: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          addMemberModal: {
            ...state.uiState.addMemberModal,
            loading: true,
          },
        },
      };
    }
    case ActionType.FETCH_CALLABLE_MEMBERS_SUCCEED: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          addMemberModal: {
            ...state.uiState.addMemberModal,
            callableMembers: action.payload.callableMembers,
            loading: false,
          },
        },
      };
    }
    case ActionType.FETCH_CALLABLE_MEMBERS_FAIL: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          addMemberModal: {
            ...state.uiState.addMemberModal,
            callableMembers: [],
            loading: false,
            error: action.payload.error,
          },
        },
      };
    }
    case ActionType.FETCH_CALLABLE_MEMBERS_CLEAR: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          addMemberModal: {
            ...state.uiState.addMemberModal,
            callableMembers: [],
            loading: false,
          },
        },
      };
    }
    case ActionType.SET_CALLABLE_MEMBER_CALLING: {
      const { targetId } = action.payload;
      const { callableMembers } = state.uiState.addMemberModal;

      const found = callableMembers.find(member => member.uid === targetId);
      if (found) {
        found.isCalling = true;
        callableMembers.filter(member => member.uid !== targetId);
      }

      return {
        ...state,
        uiState: {
          ...state.uiState,
          addMemberModal: {
            ...state.uiState.addMemberModal,
            callableMembers,
          },
        },
      };
    }
    case ActionType.SET_CALLABLE_MEMBER_TALKING: {
      const { targetId } = action.payload;
      const { callableMembers } = state.uiState.addMemberModal;

      const found = callableMembers.find(member => member.uid === targetId);
      if (found) {
        found.isTalking = true;
        callableMembers.filter(member => member.uid !== targetId);
        callableMembers.push(found);
      }

      return {
        ...state,
        uiState: {
          ...state.uiState,
          addMemberModal: {
            ...state.uiState.addMemberModal,
            callableMembers,
          },
        },
      };
    }
    case ActionType.SET_DEVICE_SETTING_MODAL_OPENED: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          deviceSettingModalOpened: action.payload.opened,
        },
      };
    }
    case ActionType.OPEN_RECONNECTION_MODAL: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          reconnectionModalOpened: true,
        },
      };
    }
    case ActionType.CLOSE_RECONNECTION_MODAL: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          reconnectionModalOpened: false,
        },
      };
    }
    case ActionType.OPEN_SENDING_WHISPER_INVITATION_MODAL: {
      return {
        ...state,
        sentWhisperInvitation: action.payload.invitation,
      };
    }
    case ActionType.CLOSE_SENDING_WHISPER_INVITATION_MODAL: {
      return {
        ...state,
        sentWhisperInvitation: undefined,
      };
    }
    case ActionType.START_WHISPER_START: {
      return {
        ...state,
        whisperConnection: action.payload.conn,
        whisperStream: action.payload.localStream,
      };
    }
    case ActionType.START_WHISPER_SUCCEED: {
      return {
        ...state,
        whisperTargetUid: action.payload.targetUid,
      };
    }
    case ActionType.STOP_WHISPER_START: {
      return {
        ...state,
        whisperStream: undefined,
        whisperConnection: undefined,
        whisperTargetUid: undefined,
        parentRoomVolume: 1,
        uiState: {
          ...state.uiState,
          msgKeys: ['ひそひそ通話が終了しました。'],
        },
      };
    }
    case ActionType.STORE_MESSAGE: {
      const newMessage = state.uiState.msgKeys.slice();
      newMessage.push(action.payload.message);

      return {
        ...state,
        uiState: {
          ...state.uiState,
          msgKeys: newMessage,
        },
      };
    }
    case ActionType.STORE_ERROR_MESSAGE: {
      const errors = state.uiState.errors.slice();
      errors.push(action.payload.error);

      return {
        ...state,
        uiState: {
          ...state.uiState,
          errors,
        },
      };
    }
    case ActionType.CLEAR_MESSAGES: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          msgKeys: [],
          errors: [],
        },
      };
    }
    case ActionType.SET_MAXIMIZED_UID: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          maximizedUid: action.payload.uid,
        },
      };
    }
    case ActionType.ADD_RECEIVED_CALL_HISTORY: {
      const histories = state.receivedCallHistories.slice();
      histories.push({
        from: action.payload.name,
        iconUrl: ICON_URL,
        isVoiceOnly: action.payload.isVoiceOnly,
        calledAt: action.payload.date,
      });

      return {
        ...state,
        receivedCallHistories: histories,
      };
    }
    case ActionType.CLEAR_RECEIVED_CALL_HISTORY: {
      return {
        ...state,
        receivedCallHistories: [],
      };
    }
    case ActionType.SET_PARENT_ROOM_VOLUME: {
      return {
        ...state,
        parentRoomVolume: action.payload.volume,
      };
    }
    case ActionType.CHANGE_CALL_VIEW_DISP: {
      return {
        ...state,
        isDispCallView: action.payload.dispState,
      };
    }
    case ActionType.SET_NOTICE_FOR_GUEST: {
      return {
        ...state,
        noticed: action.payload.noticed,
      };
    }
    case ActionType.SET_JOIN_WORKBOARD_ERROR: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          errors: action.payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
};
