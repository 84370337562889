/** @jsx jsx */
import { FC, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { updateConditionActions, UpdateConditionParams } from 'actions/home/updateCondition';
import { Condition } from 'components/home/Condition';
import { ConditionCode } from 'components/common/Conditions';

interface ConditionProps {
  isOwn: boolean;
  conditionCode: ConditionCode;
}

const UpdateConditionContainer: FC<ConditionProps> = ({ isOwn, conditionCode }) => {
  const dispatch = useDispatch();

  const updateCondition = useCallback(
    (condition: string) => {
      if (conditionCode !== condition) {
        const params: UpdateConditionParams = { condition };
        dispatch(updateConditionActions.start(params));
      }
    },
    [conditionCode, dispatch],
  );

  return <Condition updateCondition={updateCondition} conditionCode={conditionCode} isOwn={isOwn} />;
};

export default UpdateConditionContainer;
