/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx, css } from '@emotion/core';
import { Conditions, getStatus, ConditionCode } from 'components/common/Conditions';
import { Emoji } from 'emoji-mart';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { COLORS, popUpAndToastZIndex, rounded } from 'styles/style';
import {
  currentSelectedCss,
  iconBoxCss,
  MemberViewPopup,
  selectButtonBaseCss,
  selectButtonInteraction,
} from './MemberViewButtons';

const selectButtonCss = css`
  ${selectButtonBaseCss};
  align-items: center;
  display: flex;
  float: left;
  height: 36px;
  justify-content: center;
  position: relative;
  width: 38px;

  &:hover::before {
    ${selectButtonInteraction};
    opacity: 0.15;
  }

  .popup {
    ${rounded};
    background-color: ${COLORS.black};
    color: ${COLORS.white};
    font-size: 12px;
    height: 20px;
    left: auto;
    padding: 4px 3px;
    position: absolute;
    top: 45px;
    visibility: hidden;
    width: max-content;
  }

  &:hover .popup {
    visibility: visible;
    ${popUpAndToastZIndex};
  }
`;

export interface ConditionProps {
  updateCondition?: (condition: string) => void;
  conditionCode?: ConditionCode;
  isOwn?: boolean;
}

const DisplayIcon = React.memo(({ isEmoji, icon, size }: { isEmoji: boolean; icon: string; size: number }) => {
  return isEmoji ? (
    <Emoji emoji={icon} size={size} set="google" />
  ) : (
    <img src={icon} css={{ width: `${size}px`, height: `${size}px`, padding: '1px' }} alt="" />
  );
});

const ConditionList = React.memo(
  ({ code, updateCondition }: { code: string; updateCondition: (code: string) => void }) => {
    return (
      <>
        {Conditions.map(c => (
          <button
            key={c.code}
            css={[selectButtonCss, c.code === code && currentSelectedCss]}
            type="button"
            onClick={() => updateCondition(String(c.code))}
          >
            <div className="popup">{c.messageKey}</div>
            <DisplayIcon isEmoji={c.isEmoji} icon={c.icon} size={22} />
          </button>
        ))}
      </>
    );
  },
);

export const Condition: FC<ConditionProps> = ({
  updateCondition = () => {},
  conditionCode = 'on_track',
  isOwn = false,
}) => {
  const condition = getStatus(conditionCode);

  if (isOwn) {
    return (
      <MemberViewPopup
        value={
          <div css={{ width: '76px' }}>
            <ConditionList code={condition.code} updateCondition={updateCondition} />
          </div>
        }
      >
        <div css={iconBoxCss}>
          <DisplayIcon isEmoji={condition.isEmoji} icon={condition.icon} size={14} />
        </div>
      </MemberViewPopup>
    );
  }

  return (
    <BasicPopup value={condition.messageKey} position="bottom">
      <div css={iconBoxCss}>
        <DisplayIcon isEmoji={condition.isEmoji} icon={condition.icon} size={14} />
      </div>
    </BasicPopup>
  );
};
