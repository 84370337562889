/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { COLORS, rounded } from 'styles/style';
import { ReceivedCallHistory } from 'reducers/call/call';
import { formatDate } from 'common/Util';
import { BasicModal } from 'components/common/modal/BasicModal';
import { Spacer } from 'components/common/parts/Spacer';
import AudioIcon from 'styles/image/icon_call_history_audio.svg';
import VideoIcon from 'styles/image/icon_call_history_video.svg';

export const ReceivedCallHistoryModal: FC<{
  opened: boolean;
  histories: ReceivedCallHistory[];
  onClose: () => void;
}> = ({ opened, histories, onClose }) => {
  if (!opened) {
    return <div />;
  }

  return (
    <BasicModal
      open
      header="着信履歴"
      headerIconColor={COLORS.orange}
      contents={
        <div css={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
          {histories?.map(history => {
            return (
              <div
                key={history.from + history.calledAt.getTime()}
                css={[
                  {
                    display: 'flex',
                    alignItems: 'center',
                    height: 60,
                    border: `solid 1px ${COLORS.lightGray}`,
                    padding: '0 10px',
                  },
                  rounded,
                ]}
              >
                <img
                  src={history.iconUrl}
                  css={[
                    { height: 30, width: 30, border: `solid 1px ${COLORS.black}`, backgroundColor: COLORS.white },
                    rounded,
                  ]}
                  alt=""
                />
                <Spacer width="10px" />
                <div>{history.from}</div>
                <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 10 }}>
                  <img src={history.isVoiceOnly ? AudioIcon : VideoIcon} css={{ height: 14, width: 14 }} alt="" />
                  <span>{formatDate(history.calledAt, 'yyyy/MM/dd HH:mm')}</span>
                </div>
              </div>
            );
          })}
        </div>
      }
      handleOnOkClicked={onClose}
    />
  );
};
