/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import { CallingUser, CallingUserStatus } from 'reducers/call/call';
import { StandbyUserContainer } from 'containers/call/StandbyUser';
import { Video } from 'components/call/Video';
import { PersonalHeader } from './PersonalHeader';

interface Props {
  currentUserId: string;
  users: CallingUser[];
  handleOnExpandBtnClicked: (uid: string) => void;
  handleOnWhisperBtnClicked: (uid: string) => void;
  whisperTargetUid: string | undefined;
}

const callViewTileCss = css`
  background: repeating-linear-gradient(-45deg, #2b2d2d 0, #2b2d2d 10px, #383838 10px, #383838 11px);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 10%;
  width: 100%;
`;

const personalSpaceCss = css`
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5) inset;
  box-sizing: border-box;
  max-height: 50vh;
  max-width: 50vw;
  position: relative;
`;

const videoImageCss = css`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const membersCss = css`
  align-items: center;
  background: repeating-linear-gradient(-45deg, #2b2d2d 0, #2b2d2d 10px, #383838 10px, #383838 11px);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CallViewTile: FC<Props> = ({
  currentUserId,
  users = [],
  handleOnExpandBtnClicked,
  handleOnWhisperBtnClicked,
  whisperTargetUid,
}) => {
  // できるだけ正方形に近いグリッドを作り出すために「要素数の平方根の小数点切り上げ」をカラム数としている
  // 例えば、4 人通話の際には 2 行 2 列、16 人通話の際には 4 行 4 列に画面が分割されるようにしている
  const userCount = users.length;
  const columnCount = Math.ceil(Math.sqrt(userCount));
  const rowCount = Math.ceil(userCount / columnCount);

  const personalSpaceStyle = {
    height: `${100 / rowCount}%`,
    width: `${100 / columnCount}%`,
  };

  return (
    <div css={callViewTileCss}>
      <ul css={membersCss}>
        {users.map(user => (
          <li key={user.uid} css={personalSpaceCss} style={personalSpaceStyle}>
            <div css={videoImageCss}>
              {user.status === CallingUserStatus.JOINED ? (
                <Video
                  uid={user.uid}
                  iconUrl={user.iconUrl}
                  stream={user.stream}
                  videoType={user.streamStat?.videoType}
                  videoEnabled={user.streamStat?.isVideoEnabled}
                  nowSpeaking={user.nowSpeaking || false}
                  myself={user.uid === currentUserId}
                  whisperTargetUid={whisperTargetUid}
                />
              ) : (
                <StandbyUserContainer user={user} />
              )}
            </div>
            <PersonalHeader
              currentUserId={currentUserId}
              user={user}
              handleOnExpandBtnClicked={handleOnExpandBtnClicked}
              handleOnWhisperBtnClicked={handleOnWhisperBtnClicked}
              whisperTargetUid={whisperTargetUid}
              isExpandedUser={false}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
