/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import { CallingUser } from 'reducers/call/call';
import IconMicOff from 'styles/image/icon_video_mic_off_red.svg';
import { PersonalAreaButtons } from 'components/call/PersonalAreaButtons';

interface Props {
  currentUserId: string;
  user: CallingUser;
  handleOnExpandBtnClicked: (uid: string) => void;
  handleOnWhisperBtnClicked: (uid: string) => void;
  whisperTargetUid: string | undefined;
  isExpandedUser: boolean;
}

const personalHeaderCss = css`
  display: flex;
  height: 30px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const headerLeftArea = css`
  align-items: center;
  display: flex;
  flex: 0 0 calc(100% - 120px);
  width: calc(100% - 120px);
`;

const name = css`
  background-color: #222;
  color: #fff;
  line-height: 30px;
  max-width: calc(100% - 100px);
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const blackTriangle = css`
  border-bottom: 30px solid transparent;
  border-left: 30px solid #222;
  content: '';
  height: 30px;
  width: 30px;
`;

const muteIcon = css`
  background-color: #fff;
  background-image: url(${IconMicOff});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: solid 1px #f15340;
  border-radius: 50%;
  box-sizing: border-box;
  flex: 0 0 26px;
  height: 26px;
  margin-left: 5px;
  width: 26px;
`;

const headerRightArea = css`
  margin-right: 10px;
  margin-top: 5px;
  width: 120px;
`;

export const PersonalHeader: FC<Props> = ({
  currentUserId,
  user,
  handleOnExpandBtnClicked,
  handleOnWhisperBtnClicked,
  whisperTargetUid,
  isExpandedUser,
}) => {
  return (
    <div css={personalHeaderCss}>
      <div css={headerLeftArea}>
        <div css={name}>{user.name}</div>
        <div css={blackTriangle} />
        {(!user.stream || !user.streamStat?.isAudioEnabled) && <div css={muteIcon} />}
      </div>
      <div css={headerRightArea}>
        <PersonalAreaButtons
          currentUserId={currentUserId}
          user={user}
          handleOnExpandBtnClicked={() => handleOnExpandBtnClicked(user.uid)}
          handleOnWhisperBtnClicked={() => handleOnWhisperBtnClicked(user.uid)}
          whisperTargetUid={whisperTargetUid}
          isExpandedUser={isExpandedUser}
          scaleButtonDisplayed
        />
      </div>
    </div>
  );
};
