import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { HomeAction } from 'actions/home/home';

export interface HomeState {
  memberViewSearchField: string;
}

export const initialState: HomeState = {
  memberViewSearchField: '',
};

const homeReducer: Reducer<HomeState, HomeAction> = (
  state: HomeState = initialState,
  action: HomeAction,
): HomeState => {
  switch (action.type) {
    case ActionType.HOME_SET_MEMBER_VIEW_SEARCH_FIELD:
      return {
        ...state,
        memberViewSearchField: action.payload.memberViewSearchField,
      };
    default: {
      return state;
    }
  }
};

export default homeReducer;
