/** @jsx jsx */
import { FC, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import { buttonStyle, rounded } from 'styles/style';

const calcButtonCss = (size: number) => css`
  ${buttonStyle};
  ${rounded};
  background-color: transparent;
  border: none;
  height: ${size}px;
  outline: none;
  width: ${size}px;

  &:hover {
    opacity: 0.7;
  }

  :disabled {
    &:hover {
      cursor: unset;
      opacity: 1;
    }
  }
`;

type ButtonProps = JSX.IntrinsicElements['button'];
type Props = ButtonProps & { iconPath: string; pxSize: number };
/**
 * ボタン
 * @param iconPath - ボタンのアイコンにするパス
 * @param pxSize - サイズ (px)
 * @param buttonProps - 任意。ボタンに渡すオプション（onClick等）
 * @returns ボタンを表示
 * @example
 * ```
 * <BasicButton label="更新" color="blue" onClick={() => handleOnClick()} />
 * ```
 */
export const BasicIconButton: FC<Props> = ({ iconPath, pxSize, ...buttonProps }) => {
  const buttonCss = useMemo(() => {
    return calcButtonCss(pxSize);
  }, [pxSize]);

  return (
    <button css={buttonCss} {...buttonProps} type="button">
      <img css={{ height: pxSize, width: pxSize }} src={iconPath} alt="" />
    </button>
  );
};
