import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { CallableMember } from 'containers/call/modal/useCallableMembers';
import { sendInvitationActions } from 'actions/call/call';

/**
 * Invitation を送る関数を取得する Hooks
 */
export const useInvitationSender = (callableMembers: CallableMember[], isVoiceOnly: boolean): [() => void] => {
  const roomId = useSelector<RootState, string>(state => state.homeSubscriber.currentUserStatus.roomId);
  const dispatch = useDispatch();

  const sendInvitation = () => {
    const targetIds = callableMembers.filter(cm => !cm.isTalking).map(cm => cm.uid);
    dispatch(sendInvitationActions.start(targetIds, isVoiceOnly, roomId));
  };

  return [sendInvitation];
};
