/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { Statuses, getStatus, StatusCode } from 'components/common/Statuses';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { MemberViewPopup, PersonalHeaderIconBox, SelectButton } from './MemberViewButtons';

export interface StatusProps {
  updateStatus?: (status: string) => void;
  statusCode?: StatusCode;
  isOwn?: boolean;
}

const StatusesList = React.memo(
  ({ updateStatus, currentStatus }: { updateStatus: (code: StatusCode) => void; currentStatus: StatusCode }) => {
    return (
      <>
        {Statuses.map(s => (
          <SelectButton
            iconPath={s.icon}
            value={s.label}
            key={s.code}
            onClick={() => updateStatus(s.code)}
            currentSelected={s.code === currentStatus}
          />
        ))}
      </>
    );
  },
);

export const Status: FC<StatusProps> = ({ updateStatus = () => {}, statusCode = 'presence', isOwn = false }) => {
  const status = getStatus(statusCode);

  if (isOwn) {
    return (
      <MemberViewPopup value={<StatusesList updateStatus={updateStatus} currentStatus={statusCode} />}>
        <PersonalHeaderIconBox iconPath={status.icon} />
      </MemberViewPopup>
    );
  }

  return (
    <BasicPopup value={status.label} position="bottom">
      <PersonalHeaderIconBox iconPath={status.icon} />
    </BasicPopup>
  );
};
