/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { closeReconnectionModal, reconnectCallServerActions } from 'actions/call/call';
import { ReconnectionModal } from 'components/call/modal/ReconnectionModal';

export const ReconnectionModalContainer: FC = () => {
  const dispatch = useDispatch();
  const opened = useSelector<RootState, boolean>(state => state.call.uiState.reconnectionModalOpened);

  const reconnect = () => {
    dispatch(reconnectCallServerActions.start());
  };

  const close = () => {
    dispatch(closeReconnectionModal());
  };

  return opened ? <ReconnectionModal reconnect={reconnect} close={close} /> : <div />;
};
