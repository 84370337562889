import { call, delay, put, select } from 'redux-saga/effects';
import { updateMyCallStatusOnTalking } from 'services/firebase/api';
import { storeCallError } from 'actions/call/call';
import { User } from 'components/home/Home';
import { RootState } from 'reducers/mainReducer';

/**
 * 通話中に 「WiFi 切断 -> 復帰」や「別な WiFi に切り替え」をした際の対処を行うタスク。
 *
 * ネットワークが瞬断しても通話は継続されるが、Firestore 側のオンライン/オフラインは一度リセットされ、
 * 通話しているのに isTalking が false という状態が出来上がってしまうので、その時に isTalking を true に復旧させる。
 *
 * REVISIT: Firestore の detect online presence 拡張がリリースされた場合はそちらの動作を確認し、不要であればこの処理は削除してください。
 */
export function* watchRecoveringTalkingStatusAction(roomId: string) {
  try {
    while (true) {
      yield delay(3000);
      const currentUser: User = yield select((state: RootState) => state.homeSubscriber.currentUserStatus);
      if (!currentUser.isTalking) {
        yield call(updateMyCallStatusOnTalking, roomId);
      }
    }
  } catch (error) {
    yield put(storeCallError({ msgKey: 'ステータス復旧処理でエラーが発生しました', detail: error.message }));
  }
}
