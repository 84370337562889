/** @jsx jsx */
import { FC, ReactElement, useMemo } from 'react';
import { jsx, css } from '@emotion/core';
import { COLORS, popUpAndToastZIndex, rounded } from 'styles/style';

// 吹き出しの先の共通CSS
const commonDirectionCss = css`
  content: '';
  display: block;
  height: 0;
  margin: 0 auto;
  position: absolute;
  width: 0;
`;

// 吹き出しの先（top,bottom)の共通CSS
const topBottomDirectionCss = css`
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 0;
  right: 0;
`;

// 吹き出しの先（right,left)の共通CSS
const leftRightDirectionCss = css`
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  top: calc(50% - 8px);
`;

const topBefore = css`
  ${commonDirectionCss}
  ${topBottomDirectionCss}
  bottom:-9px;
`;

const bottomBefore = css`
  ${commonDirectionCss}
  ${topBottomDirectionCss}
  top: -9px;
`;

const leftBefore = css`
  ${commonDirectionCss}
  ${leftRightDirectionCss}
  right: -9px;
`;

const rightBefore = css`
  ${commonDirectionCss}
  ${leftRightDirectionCss}
  left: -9px;
`;

const topAfter = css`
  ${commonDirectionCss}
  ${topBottomDirectionCss}
  bottom: -8px;
`;

const bottomAfter = css`
  ${commonDirectionCss}
  ${topBottomDirectionCss}
  top: -8px;
`;

const leftAfter = css`
  ${commonDirectionCss}
  ${leftRightDirectionCss}
  right: -8px;
`;

const rightAfter = css`
  ${commonDirectionCss}
  ${leftRightDirectionCss}
  left: -8px;
`;

// 色
const lightColors = {
  lightBlue: '#f5f9fd',
  lightYellow: '#fffaec',
  lightPink: '#fdf3f3',
  borderColor: '#707070',
  lightGray: COLORS.lightGray,
};

const commonBalloonCss = css`
  ${rounded};
  display: none;
  height: auto;
  margin: 0 auto;
  max-width: 300px;
  position: absolute;
  width: max-content;
  word-wrap: break-word;
  ${popUpAndToastZIndex};
`;

const calcPopupCss = (padding: string) => css`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin: auto;
  position: relative;

  .display-top {
    ${commonBalloonCss};
    bottom: 120%;
    padding: ${padding};
  }

  .display-bottom {
    ${commonBalloonCss};
    padding: ${padding};
    top: 120%;
  }

  .display-right {
    ${commonBalloonCss};
    left: 120%;
    padding: ${padding};
  }

  .display-left {
    ${commonBalloonCss};
    padding: ${padding};
    right: 120%;
  }

  .dark {
    background: ${COLORS.black} 0 0 no-repeat padding-box;
    color: ${COLORS.white};

    .top::before {
      border-top: 9px solid ${COLORS.black};
      ${topBefore}
    }

    .bottom::before {
      border-bottom: 9px solid ${COLORS.black};
      ${bottomBefore}
    }

    .left::before {
      border-left: 9px solid ${COLORS.black};
      ${leftBefore}
    }

    .right::before {
      border-right: 9px solid ${COLORS.black};
      ${rightBefore}
    }
  }

  .lightBlue {
    background: ${lightColors.lightBlue} 0 0 no-repeat padding-box;
    border: 1px solid ${lightColors.borderColor};
    color: ${COLORS.darkGray};

    .top::before {
      border-top: 9px solid ${lightColors.borderColor};
      ${topBefore}
    }

    .top::after {
      border-top: 9px solid ${lightColors.lightBlue};
      ${topAfter}
    }

    .bottom::before {
      border-bottom: 9px solid ${lightColors.borderColor};
      ${bottomBefore}
    }

    .bottom::after {
      border-bottom: 9px solid ${lightColors.lightBlue};
      ${bottomAfter}
    }

    .left::before {
      border-left: 9px solid ${lightColors.borderColor};
      ${leftBefore}
    }

    .left::after {
      border-left: 9px solid ${lightColors.lightBlue};
      ${leftAfter}
    }

    .right::before {
      border-right: 9px solid ${lightColors.borderColor};
      ${rightBefore}
    }

    .right::after {
      border-right: 9px solid ${lightColors.lightBlue};
      ${rightAfter}
    }
  }

  .lightYellow {
    background: ${lightColors.lightYellow} 0 0 no-repeat padding-box;
    border: 1px solid ${lightColors.borderColor};
    color: ${COLORS.darkGray};

    .top::before {
      border-top: 9px solid ${lightColors.borderColor};
      ${topBefore}
    }

    .top::after {
      border-top: 9px solid ${lightColors.lightYellow};
      ${topAfter}
    }

    .bottom::before {
      border-bottom: 9px solid ${lightColors.borderColor};
      ${bottomBefore}
    }

    .bottom::after {
      border-bottom: 9px solid ${lightColors.lightYellow};
      ${bottomAfter}
    }

    .left::before {
      border-left: 9px solid ${lightColors.borderColor};
      ${leftBefore}
    }

    .left::after {
      border-left: 9px solid ${lightColors.lightYellow};
      ${leftAfter}
    }

    .right::before {
      border-right: 9px solid ${lightColors.borderColor};
      ${rightBefore}
    }

    .right::after {
      border-right: 9px solid ${lightColors.lightYellow};
      ${rightAfter}
    }
  }

  .lightPink {
    background: ${lightColors.lightPink} 0 0 no-repeat padding-box;
    border: 1px solid ${lightColors.borderColor};
    color: ${COLORS.darkGray};

    .top::before {
      border-top: 9px solid ${lightColors.borderColor};
      ${topBefore}
    }

    .top::after {
      border-top: 9px solid ${lightColors.lightPink};
      ${topAfter}
    }

    .bottom::before {
      border-bottom: 9px solid ${lightColors.borderColor};
      ${bottomBefore}
    }

    .bottom::after {
      border-bottom: 9px solid ${lightColors.lightPink};
      ${bottomAfter}
    }

    .left::before {
      border-left: 9px solid ${lightColors.borderColor};
      ${leftBefore}
    }

    .left::after {
      border-left: 9px solid ${lightColors.lightPink};
      ${leftAfter}
    }

    .right::before {
      border-right: 9px solid ${lightColors.borderColor};
      ${rightBefore}
    }

    .right::after {
      border-right: 9px solid ${lightColors.lightPink};
      ${rightAfter}
    }
  }

  .lightGray {
    background: ${lightColors.lightGray} 0 0 no-repeat padding-box;
    border: 1px solid ${COLORS.darkGray};
    color: ${COLORS.darkGray};

    .top::before {
      border-top: 9px solid ${COLORS.darkGray};
      ${topBefore}
    }

    .top::after {
      border-top: 9px solid ${lightColors.lightGray};
      ${topAfter}
    }

    .bottom::before {
      border-bottom: 9px solid ${COLORS.darkGray};
      ${bottomBefore}
    }

    .bottom::after {
      border-bottom: 9px solid ${lightColors.lightGray};
      ${bottomAfter}
    }

    .left::before {
      border-left: 9px solid ${COLORS.darkGray};
      ${leftBefore}
    }

    .left::after {
      border-left: 9px solid ${lightColors.lightGray};
      ${leftAfter}
    }

    .right::before {
      border-right: 9px solid ${COLORS.darkGray};
      ${rightBefore}
    }

    .right::after {
      border-right: 9px solid ${lightColors.lightGray};
      ${rightAfter}
    }
  }

  .white {
    background: ${COLORS.white} 0 0 no-repeat padding-box;
    border: 1px solid ${COLORS.darkGray};
    color: ${COLORS.darkGray};

    .top::before {
      border-top: 9px solid ${COLORS.darkGray};
      ${topBefore}
    }

    .top::after {
      border-top: 9px solid ${COLORS.white};
      ${topAfter}
    }

    .bottom::before {
      border-bottom: 9px solid ${COLORS.darkGray};
      ${bottomBefore}
    }

    .bottom::after {
      border-bottom: 9px solid ${COLORS.white};
      ${bottomAfter}
    }

    .left::before {
      border-left: 9px solid ${COLORS.darkGray};
      ${leftBefore}
    }

    .left::after {
      border-left: 9px solid ${COLORS.white};
      ${leftAfter}
    }

    .right::before {
      border-right: 9px solid ${COLORS.darkGray};
      ${rightBefore}
    }

    .right::after {
      border-right: 9px solid ${COLORS.white};
      ${rightAfter}
    }
  }

  .trigger {
    position: relative;
    width: 100%;
  }

  &:hover .popup,
  .opened {
    display: block;
  }
`;

/**
 * ポップアップ
 * トリガーとなるコンポーネントをこのタグで包含して使用
 * @param value - ポップアップに表示する文字
 * @param position - ポップアップを表示する位置 top, bottom, right, left から選択
 * @param color - 任意 背景色 dark, lightBlue, lightYellow, lightPink, lightGray, white から選択 デフォルト dark
 * @param padding - 任意 ポップアップ内の padding
 * @param children - トリガー
 * @returns ポップアップを表示
 * @example
 * ```
 *  <BasicPopup value={<div>Pop up!!</div>} position="top" color="lightBlue">
 *    <div>Hover here to pop up</div>
 *  </BasicPopup>
 * ```
 */
export const BasicPopup: FC<{
  value: string | ReactElement;
  position: 'top' | 'bottom' | 'right' | 'left';
  color?: 'dark' | 'lightBlue' | 'lightYellow' | 'lightPink' | 'lightGray' | 'white';
  padding?: string;
  forceOpened?: boolean;
}> = ({ value, position, color, padding, children, forceOpened }) => {
  const popupCss = useMemo(() => {
    return calcPopupCss(padding ?? '15px');
  }, [padding]);

  return (
    <div css={popupCss}>
      <div className={`popup display-${position} ${color || 'dark'}${forceOpened ? ' opened' : ''}`}>
        <div className={position}>{value}</div>
      </div>
      <div className="trigger">{children}</div>
    </div>
  );
};
