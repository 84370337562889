/** @jsx jsx */
import { jsx } from '@emotion/core';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { FC } from 'react';
import IconBackToCallView from 'styles/image/icon_header_back_call.svg';
import { HeaderIcon } from './Header';

export const BackToCallView: FC<{ handleOnClick: () => void }> = ({ handleOnClick }) => {
  return (
    <BasicPopup value="通話画面に戻る" position="bottom">
      <HeaderIcon iconUrl={IconBackToCallView} onClick={handleOnClick} />
    </BasicPopup>
  );
};
