/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { BasicMessageBox } from 'components/common/parts/BasicMessageBox';
import { BasicIconButton } from 'components/common/parts/BasicIconButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { CommonMessage } from 'reducers/common/common';
import { commonActions } from 'actions/common/common';
import CloseIcon from 'styles/image/close.svg';

const messageContainer = css`
  left: 50%;
  position: absolute;
  top: 20px;
  transform: translate(-50%);

  .message-item {
    margin-bottom: 20px;
  }

  .item-container {
    align-items: center;
    display: flex;
  }

  .text {
    display: inline-block;
  }

  button {
    margin-left: 20px;
  }
`;

interface Props {
  disappear: boolean;
}

export const CallCommonMessages: FC<Props> = ({ disappear = false }) => {
  const dispatch = useDispatch();
  const successMessage = useSelector<RootState, CommonMessage | undefined>(state => state.common.successMsg);
  const errorMessage = useSelector<RootState, CommonMessage | undefined>(state => state.common.errorMsg);

  useEffect(() => {
    if (disappear && (successMessage || errorMessage)) {
      const timeoutId = setInterval(() => {
        dispatch(commonActions.clear());
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return () => {};
  });

  const messageItem = (message: CommonMessage, color: 'blue' | 'red') => {
    return (
      <div className="message-item">
        <BasicMessageBox color={color}>
          <div className="item-container">
            <div className="text">
              {message.key}
              {message.detail}
            </div>
            <BasicIconButton iconPath={CloseIcon} pxSize={14} onClick={() => dispatch(commonActions.clear())} />
          </div>
        </BasicMessageBox>
      </div>
    );
  };

  return (
    <div>
      <div css={messageContainer}>
        {successMessage && messageItem(successMessage, 'blue')}
        {errorMessage && messageItem(errorMessage, 'red')}
      </div>
    </div>
  );
};
