/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import IconSetting from 'styles/image/icon_header_setting.svg';
import { NOT_AVAILABLE_MSG } from 'components/home/Home';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { HeaderIcon } from './Header';

export const Setting: FC = () => {
  return (
    <BasicPopup value={NOT_AVAILABLE_MSG} position="bottom">
      <HeaderIcon iconUrl={IconSetting} disabled />
    </BasicPopup>
  );
};
