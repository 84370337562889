/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { CallMenu } from 'components/call/CallMenu';
import {
  addVideoTrack,
  disableLocalVideo,
  setAddMemberModalOpened,
  setCallViewDisp,
  setDeviceSettingModalOpened,
  setParentRoomVolume,
  shareScreenActions,
  stopCallingActions,
  stopShareScreenActions,
  stopWhisperActions,
  updateLocalAudioEnabled,
} from 'actions/call/call';
import { RootState } from 'reducers/mainReducer';
import { Stream } from 'sagas/call/classes/Stream';
import { StreamStat } from 'sagas/call/classes/RoomData';
import { WorkboardUiState } from 'reducers/call/workboard';
import { setWorkboardOpenedAction } from 'actions/call/workboard';

export const CallMenuContainer: FC = () => {
  const dispatch = useDispatch();
  const isSharingScreen = useSelector<RootState, boolean>(state => state.call.isSharingScreen);
  const localStream = useSelector<RootState, Stream | undefined>(state => state.call.localUser?.stream);
  const streamStat = useSelector<RootState, StreamStat | undefined>(state => state.call.localUser?.streamStat);
  const whisperTargetUid = useSelector<RootState, string | undefined>(state => state.call.whisperTargetUid);
  const parentRoomVolume = useSelector<RootState, number>(state => state.call.parentRoomVolume);
  const workboardUiState = useSelector<RootState, WorkboardUiState>(state => state.workboard.uiState);
  const isGuest = useSelector<RootState, boolean>(state => state.homeSubscriber.currentUserStatus.isGuest);
  const nowWhispering = !!whisperTargetUid;

  const updateAudioEnabled = (enabled: boolean) => {
    if (!localStream || !streamStat) {
      return;
    }
    dispatch(updateLocalAudioEnabled.start(enabled));
  };

  const updateVideoEnabled = (enabled: boolean) => {
    if (!localStream || !streamStat) {
      return;
    }
    if (enabled) {
      dispatch(addVideoTrack.start(localStream, { ...streamStat, isVideoEnabled: true }));
    } else {
      dispatch(disableLocalVideo.start(localStream, { ...streamStat, isVideoEnabled: false }));
    }
  };

  const handleOnBackClick = () => {
    dispatch(setCallViewDisp(false));
  };

  return (
    <CallMenu
      isSharing={isSharingScreen}
      audioEnabled={streamStat ? streamStat.isAudioEnabled : false}
      videoEnabled={streamStat ? streamStat.isVideoEnabled : false}
      videoButtonEnabled={streamStat ? streamStat.videoType !== 'screen' : false}
      handleOnLeaveClick={() => dispatch(stopCallingActions.start())}
      handleOnScreenClick={() => dispatch(shareScreenActions.start())}
      handleOnStopScreenClick={() => dispatch(stopShareScreenActions.start())}
      handleOnAudioOnClick={() => updateAudioEnabled(false)} // on アイコンをクリックした際に off にする
      handleOnAudioOffClick={() => updateAudioEnabled(true)} // off アイコンをクリックした際に on にする
      handleOnVideoOnClick={() => updateVideoEnabled(false)} // on アイコンをクリックした際に off にする
      handleOnVideoOffClick={() => updateVideoEnabled(true)} // off アイコンをクリックした際に on にする
      handleOnAddMemberClick={() => dispatch(setAddMemberModalOpened(true))}
      handleOnDeviceSettingClick={() => dispatch(setDeviceSettingModalOpened(true))}
      nowWhispering={nowWhispering}
      handleOnWhisperStopped={() => dispatch(stopWhisperActions.start())}
      parentRoomVolume={parentRoomVolume}
      handleOnChangeParentRoomVolume={volume => dispatch(setParentRoomVolume(volume))}
      handleOnSharingFilesClick={() =>
        dispatch(setWorkboardOpenedAction.sharingFiles(!workboardUiState.sharingFilesOpened))
      }
      handleOnTextChatClick={() => dispatch(setWorkboardOpenedAction.textChat(!workboardUiState.textChatOpened))}
      handleOnCanvasClick={() => dispatch(setWorkboardOpenedAction.canvas(!workboardUiState.canvasOpened))}
      workboardUiState={workboardUiState}
      handleOnBackClick={handleOnBackClick}
      isGuest={isGuest}
    />
  );
};
