/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { css, jsx } from '@emotion/core';
import { CallViewPopup } from 'components/call/CallViewPopup';

const button = css`
  background-color: black;
  border: none;
  outline: none;

  :not(:disabled):hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

type Props = JSX.IntrinsicElements['button'] & {
  iconUrl: string;
  popupContent: JSX.Element;
  forcePopupOpened?: boolean;
  size?: number;
};

export const CallMenuButton = ({ iconUrl, popupContent, forcePopupOpened, size = 30, ...props }: Props) => {
  return (
    <div css={{ width: size }}>
      <CallViewPopup popupContent={popupContent} position="top" forcePopupOpened={forcePopupOpened}>
        <button css={button} type="button" {...props}>
          <img src={iconUrl} height={size} width={size} alt="" />
        </button>
      </CallViewPopup>
    </div>
  );
};
