import FocusOnTaskIcon from 'styles/image/icon_condition_focus_on_task.svg';

export type ConditionCode = 'talk' | 'chat' | 'help' | 'task' | 'available' | 'on_track';

interface Condition {
  code: ConditionCode;
  messageKey: string;
  icon: string;
  isEmoji: boolean;
}

export const Conditions: Condition[] = [
  { code: 'on_track', messageKey: '順調', icon: 'grinning', isEmoji: true },
  { code: 'available', messageKey: '余裕あり', icon: 'blush', isEmoji: true },
  { code: 'task', messageKey: '集中作業中', icon: FocusOnTaskIcon, isEmoji: false },
  { code: 'help', messageKey: 'ヘルプ', icon: 'sos', isEmoji: true },
  { code: 'talk', messageKey: '話しかけてOK', icon: 'ok_woman', isEmoji: true },
  { code: 'chat', messageKey: '雑談希望', icon: 'speaking_head_in_silhouette', isEmoji: true },
];

export function getStatus(conditionCode: ConditionCode): Condition {
  const condition = Conditions.find(c => c.code === conditionCode);
  if (condition) {
    return condition;
  }

  return Conditions[0];
}
