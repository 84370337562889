import { call, takeLatest } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { updateAttendanceActions } from 'actions/home/attendance';

export function* runUpdateAttendance(action: ReturnType<typeof updateAttendanceActions.start>) {
  try {
    yield call(api.updateAttendance, action.payload.onDuty);
  } catch (error) {
    // nothing to do
  }
}

export function* watchUpdateAttendance() {
  yield takeLatest(Action.HOME_UPDATE_ATTENDANCE, runUpdateAttendance);
}
