/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { getCanvasHistoryActions, removeCanvasMember, sendCanvasTmpData } from 'actions/call/workboard';
import { RootState } from 'reducers/mainReducer';
import { Canvas } from 'components/call/workboard/Canvas';
import { SfuRoom } from 'skyway-js';
import { DrawingDataSender } from 'sagas/call/classes/DrawingDataSender';
import { commonActions } from 'actions/common/common';
import { CanvasTmpData, WorkboardUiState } from 'reducers/call/workboard';
import Spinner from 'components/common/Spinner';
import { CanvasListener } from './listeners/CanvasListener';

export const CanvasContainer: FC = () => {
  const dispatch = useDispatch();
  const uiState = useSelector<RootState, WorkboardUiState>(state => state.workboard.uiState);
  const room = useSelector<RootState, SfuRoom | undefined>(state => state.call.room);
  const sender = useSelector<RootState, DrawingDataSender | undefined>(state => state.workboard.sender);
  const tmpData = useSelector<RootState, CanvasTmpData>(state => state.workboard.canvasTmpData);
  const currentUserId = useSelector<RootState, string>(state => state.homeSubscriber.currentUserStatus.uid);
  const activated = useSelector<RootState, boolean>(state => state.workboard.activated);

  useEffect(() => {
    if (activated) {
      // マウント時にこれまでの描画履歴を取得する
      dispatch(getCanvasHistoryActions.start());
      // 描画データはSender呼び出し時にキューに溜めておき、100msに1回サーバーに送信している
      // 描画時間が100ms未満の場合に相手に送られないデータがキューに残ってしまうので、キューのデータを定期的に確認して送信する
      const sendData = setInterval(() => {
        if (sender && sender?.payload.length > 0) {
          return sender?.enqueueAndSend();
        }

        return () => {};
      }, 3000);

      // アンマウント時にキャンバス更新メンバーから自分を除外する
      return () => {
        dispatch(removeCanvasMember());
        clearInterval(sendData);
      };
    }

    return () => {};
  }, [activated, dispatch, sender]);

  if (!room || !sender) {
    dispatch(commonActions.error('キャンバスの初期化に失敗しました。'));
  }

  return (
    <React.Fragment>
      <Canvas
        sender={sender}
        room={room}
        tmpData={tmpData}
        currentUserId={currentUserId}
        sendCanvasTmpData={(data: string) => dispatch(sendCanvasTmpData(data))}
      />
      {activated && <CanvasListener />}
      {uiState.loading && !uiState.sharingFilesOpened && <Spinner text="アップロード中…" />}
    </React.Fragment>
  );
};
