/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { User } from 'components/home/Home';
import Blur from 'components/header/Blur';
import { updateUserViewsIsShadeActions } from 'actions/home/headerBlur';

export const BlurContainer: FC = () => {
  const dispatch = useDispatch();

  const updateIsShade = (isShade: boolean) => {
    dispatch(updateUserViewsIsShadeActions.start(isShade));
  };

  const currentUserStatus = useSelector<RootState, User>(state => state.homeSubscriber.currentUserStatus);
  const onlineMembers = useSelector<RootState, User[]>(state => state.homeSubscriber.onlineMembers);

  const isShade = onlineMembers.find(om => om.uid === currentUserStatus.uid)?.isShade as boolean;

  return <Blur isShade={isShade} updateIsShade={updateIsShade} />;
};
