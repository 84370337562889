import { takeLatest, call } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { updateConditionActions } from 'actions/home/updateCondition';

export function* runUpdateCondition(action: ReturnType<typeof updateConditionActions.start>) {
  const { condition: conditionCode } = action.payload;

  try {
    const handler = api.updateConditionFactory();
    yield call(handler, conditionCode);
  } catch (error) {
    // nothing to do
  }
}

export function* watchUpdateCondition() {
  yield takeLatest(Action.HOME_UPDATE_CONDITION, runUpdateCondition);
}
