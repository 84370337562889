/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { User } from 'components/home/Home';
import { RootState } from 'reducers/mainReducer';
import MemberView, { MemberData } from 'components/home/MemberView';

const compareFn = (a: MemberData, b: MemberData) => {
  // 自分は先頭
  if (!a.isOwn && b.isOwn) {
    return 1;
  }
  if (a.isOwn && !b.isOwn) {
    return -1;
  }
  // デモユーザーは下の方でいい
  if (!a.demoUser && b.demoUser) {
    return -1;
  }
  if (a.demoUser && !b.demoUser) {
    return 1;
  }
  // カスタマーサポートが近くに来るように
  if (!a.isGuest && b.isGuest) {
    return -1;
  }
  if (a.isGuest && !b.isGuest) {
    return 1;
  }

  // 同じ通話部屋のメンバーを隣り合わせに
  if (a.roomId && b.roomId) {
    if (a.roomId > b.roomId) {
      return 1;
    }
    if (a.roomId < b.roomId) {
      return -1;
    }
  }

  // 通話中メンバーを上に
  if (a.roomId && !b.roomId) {
    return -1;
  }
  if (!a.roomId && b.roomId) {
    return 1;
  }

  // 名前の順
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }

  return 0;
};

const MemberViewContainer: FC = () => {
  const currentUserStatus = useSelector<RootState, User>(state => state.homeSubscriber.currentUserStatus);
  const onlineMembers = useSelector<RootState, User[]>(state => state.homeSubscriber.onlineMembers);
  const memberViewSearchField = useSelector<RootState, string>(state => state.home.memberViewSearchField);
  const memberDataArray: MemberData[] = [];

  onlineMembers.forEach(member => {
    if (member.name.includes(memberViewSearchField)) {
      const isOwn = member.uid === currentUserStatus.uid;
      memberDataArray.push({ ...member, isOwn });
    }
  });

  memberDataArray.sort(compareFn);

  return <MemberView currentUserStatus={currentUserStatus} memberDataArray={memberDataArray} />;
};

export default MemberViewContainer;
