/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as Action from 'actions/ActionTypeConstants';
import { put, takeLatest } from 'redux-saga/effects';
import { signOutActions } from 'actions/auth/signOut';

function* runSignOut() {
  yield put(signOutActions.start());
}

export function* watchUnsubscribe() {
  yield takeLatest(Action.HOME_UNSUBSCRIBE_USER_STATUSES, runSignOut);
}
