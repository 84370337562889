/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';

import SignInGoogle from 'components/auth/SignInGoogle';
import { signInActions } from 'actions/auth/signIn';

const SignInGoogleContainer: FC = () => {
  const dispatch = useDispatch();

  const signInWithGoogle = () => {
    dispatch(signInActions.googleStart());
  };

  return <SignInGoogle signInWithGoogle={signInWithGoogle} />;
};

export default SignInGoogleContainer;
