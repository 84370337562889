import { call, put, take } from 'redux-saga/effects';
import Peer from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import { storeCallError } from 'actions/call/call';
import { createCredential } from 'sagas/call/call';

/**
 * 認証情報の更新アクション発行を監視するタスク。
 */
export function* watchRenewTokenAction(peer: Peer) {
  try {
    while (true) {
      yield take(Action.RENEW_CREDENTIAL);
      const credential = yield call(createCredential, peer.id);
      peer.updateCredential(credential);
      console.log('credential renewed.'); // eslint-disable-line no-console
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey: '通話用認証情報の更新に失敗しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  }
}
