import { call, select, take } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import { setMyCallStatus } from 'services/firebase/api';
import { RootState } from 'reducers/mainReducer';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';

/**
 * 着信を拒否して、現在かかってきている着信がなくなった場合に自分の isCalling を false にするためのタスク
 */
export function* watchRemovingRemoteInvitationAction() {
  while (true) {
    yield take(Action.REMOVE_REMOTE_INVITATION);
    const list: RemoteInvitation[] = yield select((state: RootState) => state.call.receivedInvitationList);
    if (list.length === 0) {
      try {
        yield call(setMyCallStatus, false);
      } catch (error) {
        // 失敗したとしても大きな問題ではないので特に何もしない
      }
    }
  }
}
