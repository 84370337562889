/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { ReceivedCallHistory } from 'reducers/call/call';
import { clearReceivedCallHistory } from 'actions/call/call';
import { clearHtmlHeaderMessages } from 'actions/htmlHeader/htmlHeader';
import { ReceivedCallHistoryModal } from 'components/call/modal/ReceivedCallHistoryModal';

export const ReceivedCallHistoryModalContainer: FC = () => {
  const dispatch = useDispatch();
  const histories = useSelector<RootState, ReceivedCallHistory[]>(state => state.call.receivedCallHistories);

  const clearHistories = () => {
    dispatch(clearReceivedCallHistory());
    dispatch(clearHtmlHeaderMessages());
  };

  return <ReceivedCallHistoryModal opened={histories.length > 0} histories={histories} onClose={clearHistories} />;
};
