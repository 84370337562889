/** @jsx jsx */
import { FC, useMemo } from 'react';
import { jsx, css } from '@emotion/core';
import { COLORS, rounded } from 'styles/style';

type messageBoxColors = 'white' | 'gray' | 'blue' | 'red';

const commonCss = css`
  ${rounded}
  padding: 14px 24px;
`;

const white = css`
  ${commonCss}
  background: ${COLORS.white} 0 0 no-repeat padding-box;
  border: 1px solid ${COLORS.darkGray};
`;

const gray = css`
  ${commonCss}
  background: #f8f8f8 0 0 no-repeat padding-box;
  border: 1px solid ${COLORS.darkGray};
`;
const blue = css`
  ${commonCss}
  background: ${COLORS.white} 0 0 no-repeat padding-box;
  border: 1px solid ${COLORS.blue};
  color: ${COLORS.blue};
`;

const red = css`
  ${commonCss}
  background: #fefafa 0 0 no-repeat padding-box;
  border: 1px solid ${COLORS.red};
  color: ${COLORS.red};
`;

const messageBoxCss = (messageBoxColor?: messageBoxColors) => {
  switch (messageBoxColor) {
    case 'white': {
      return white;
    }
    case 'gray': {
      return gray;
    }
    case 'blue': {
      return blue;
    }
    case 'red': {
      return red;
    }
    default: {
      return white;
    }
  }
};
/**
 * メッセージボックス
 * @param color - 任意 メッセージボックスの色 white, gray, blue, red から選択 デフォルト white
 * @param children - ボックスに表示する内容
 * @returns メッセージボックスを表示
 * @example
 * ```
 * <BasicMessageBox color="red">ここにメッセージを表示する</BasicMessageBox>
 * ```
 */
export const BasicMessageBox: FC<{ color?: messageBoxColors }> = ({ color, children }) => {
  const calculatedCss = useMemo(() => messageBoxCss(color), [color]);

  return <div css={calculatedCss}>{children}</div>;
};
