import * as ActionType from 'actions/ActionTypeConstants';
import { CallError, CallingUserStatus, JoinConfirmationModalProps } from 'reducers/call/call';
import { MediaConnection, SfuRoom } from 'skyway-js';
import { Stream } from 'sagas/call/classes/Stream';
import { StreamStat } from 'sagas/call/classes/RoomData';
import { LocalInvitation } from 'sagas/call/classes/LocalInvitation';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';
import { CallableMember } from 'containers/call/modal/useCallableMembers';

export const connectCallServerActions = {
  start: () => ({
    type: ActionType.CONNECT_CALL_SERVER as typeof ActionType.CONNECT_CALL_SERVER,
  }),
  fail: (msgKey: string) => ({
    type: ActionType.CONNECT_CALL_SERVER_FAIL as typeof ActionType.CONNECT_CALL_SERVER_FAIL,
    payload: { msgKey },
  }),
};

export const reconnectCallServerActions = {
  start: () => ({
    type: ActionType.RECONNECT_CALL_SERVER as typeof ActionType.RECONNECT_CALL_SERVER,
  }),
};

export const disconnectCallServerActions = {
  start: () => ({
    type: ActionType.DISCONNECT_CALL_SERVER as typeof ActionType.DISCONNECT_CALL_SERVER,
  }),
};

export const renewCredential = () => ({
  type: ActionType.RENEW_CREDENTIAL as typeof ActionType.RENEW_CREDENTIAL,
});

export const sendInvitationActions = {
  start: (targetIds: string[], isVoiceOnly: boolean, roomId?: string) => ({
    type: ActionType.SEND_INVITATION_START as typeof ActionType.SEND_INVITATION_START,
    payload: { targetIds, isVoiceOnly, roomId },
  }),
};

export const sendWhisperInvitationActions = {
  start: (targetId: string) => ({
    type: ActionType.SEND_WHISPER_INVITATION_START as typeof ActionType.SEND_WHISPER_INVITATION_START,
    payload: { targetId },
  }),
};

export const stopCallingActions = {
  start: () => ({
    type: ActionType.STOP_CALLING_START as typeof ActionType.STOP_CALLING_START,
  }),
  succeed: () => ({
    type: ActionType.STOP_CALLING_SUCCEED as typeof ActionType.STOP_CALLING_SUCCEED,
  }),
};

export const storeDummyVideoTrack = (dummyVideoTrack: MediaStreamTrack) => ({
  type: ActionType.STORE_DUMMY_STREAM as typeof ActionType.STORE_DUMMY_STREAM,
  payload: { dummyVideoTrack },
});

export const storeRoom = (room: SfuRoom) => ({
  type: ActionType.STORE_ROOM as typeof ActionType.STORE_ROOM,
  payload: { room },
});

export const joinRoomActions = {
  start: (isVoiceOnly: boolean, invitedRoomId?: string, callbackAfterJoining?: (createdRoomId: string) => void) => ({
    type: ActionType.JOIN_ROOM_START as typeof ActionType.JOIN_ROOM_START,
    payload: { invitedRoomId, isVoiceOnly, callbackAfterJoining },
  }),
  succeed: (warnings: string[]) => ({
    type: ActionType.JOIN_ROOM_SUCCEED as typeof ActionType.JOIN_ROOM_SUCCEED,
    payload: { warnings },
  }),
  fail: (msgKey: string) => ({
    type: ActionType.JOIN_ROOM_FAIL as typeof ActionType.JOIN_ROOM_FAIL,
    payload: { msgKey },
  }),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const storeChildTaskOfCall = (childTask: any) => ({
  type: ActionType.STORE_CHILD_TASK_OF_CALL as typeof ActionType.STORE_CHILD_TASK_OF_CALL,
  payload: { childTask },
});

export const sendLocalStreamStat = () => ({
  type: ActionType.SEND_LOCAL_STREAM_STAT as typeof ActionType.SEND_LOCAL_STREAM_STAT,
});

export const remotePeerJoined = (peerId: string) => ({
  type: ActionType.REMOTE_PEER_JOINED as typeof ActionType.REMOTE_PEER_JOINED,
  payload: { uid: peerId },
});

export const receiveRemoteStream = (peerId: string, stream: Stream) => ({
  type: ActionType.RECEIVE_REMOTE_STREAM as typeof ActionType.RECEIVE_REMOTE_STREAM,
  payload: { uid: peerId, stream },
});

export const receiveRemoteStreamStats = (peerId: string, stat: StreamStat) => ({
  type: ActionType.RECEIVE_REMOTE_STREAM_STATS as typeof ActionType.RECEIVE_REMOTE_STREAM_STATS,
  payload: { uid: peerId, stat },
});

export const setLocalUser = (uid: string, name: string, iconUrl: string, stream: Stream, streamStat: StreamStat) => ({
  type: ActionType.SET_LOCAL_USER as typeof ActionType.SET_LOCAL_USER,
  payload: { uid, name, iconUrl, stream, streamStat },
});

export const updateLocalStream = (stream: Stream, stat: StreamStat) => ({
  type: ActionType.UPDATE_LOCAL_STREAM as typeof ActionType.UPDATE_LOCAL_STREAM,
  payload: { stream, stat },
});

export const setRemoteUser = (uid: string, name: string, iconUrl: string) => ({
  type: ActionType.SET_REMOTE_USER as typeof ActionType.SET_REMOTE_USER,
  payload: { uid, name, iconUrl },
});

export const addRemoteStream = (uid: string, stream: Stream) => ({
  type: ActionType.ADD_REMOTE_STREAM as typeof ActionType.ADD_REMOTE_STREAM,
  payload: { uid, stream },
});

export const setRemoteStat = (uid: string, stat: StreamStat) => ({
  type: ActionType.SET_REMOTE_STAT as typeof ActionType.SET_REMOTE_STAT,
  payload: { uid, stat },
});

export const updateStreamWithNewMediaDeviceActions = {
  start: (deviceId: string) => ({
    type: ActionType.UPDATE_MEDIA_DEVICE as typeof ActionType.UPDATE_MEDIA_DEVICE,
    payload: { deviceId },
  }),
};

export const updateStreamWithNewAudioDeviceActions = {
  start: (deviceId: string) => ({
    type: ActionType.UPDATE_AUDIO_DEVICE as typeof ActionType.UPDATE_AUDIO_DEVICE,
    payload: { deviceId },
  }),
};

export const updateLocalAudioEnabled = {
  start: (enabled: boolean) => ({
    type: ActionType.UPDATE_LOCAL_AUDIO_ENABLED_START as typeof ActionType.UPDATE_LOCAL_AUDIO_ENABLED_START,
    payload: { enabled },
  }),
  succeed: (stat: StreamStat) => ({
    type: ActionType.UPDATE_LOCAL_AUDIO_ENABLED_SUCCEED as typeof ActionType.UPDATE_LOCAL_AUDIO_ENABLED_SUCCEED,
    payload: { stat },
  }),
};

export const disableLocalVideo = {
  start: (stream: Stream, stat: StreamStat) => ({
    type: ActionType.DISABLE_LOCAL_VIDEO_START as typeof ActionType.DISABLE_LOCAL_VIDEO_START,
    payload: { stream, stat },
  }),
};

export const shareScreenActions = {
  start: () => ({
    type: ActionType.SHARE_SCREEN_START as typeof ActionType.SHARE_SCREEN_START,
  }),
  success: () => ({
    type: ActionType.SHARE_SCREEN_SUCCESS as typeof ActionType.SHARE_SCREEN_SUCCESS,
  }),
};

export const stopShareScreenActions = {
  start: () => ({
    type: ActionType.STOP_SHARE_SCREEN_START as typeof ActionType.STOP_SHARE_SCREEN_START,
  }),
};

export const updateLocalUserSpeaking = (nowSpeaking: boolean) => ({
  type: ActionType.UPDATE_LOCAL_USER_SPEAKING as typeof ActionType.UPDATE_LOCAL_USER_SPEAKING,
  payload: { nowSpeaking },
});

export const updateRemoteUserSpeaking = (targetUid: string, nowSpeaking: boolean) => ({
  type: ActionType.UPDATE_REMOTE_USER_SPEAKING as typeof ActionType.UPDATE_REMOTE_USER_SPEAKING,
  payload: { targetUid, nowSpeaking },
});

export const addRemoteUserWithInvitation = (
  uid: string,
  name: string,
  iconUrl: string,
  invitation: LocalInvitation,
) => ({
  type: ActionType.ADD_REMOTE_USER_WITH_INVITATION as typeof ActionType.ADD_REMOTE_USER_WITH_INVITATION,
  payload: { uid, name, iconUrl, invitation },
});

export const cancelOtherInvitation = (targetUid: string) => ({
  type: ActionType.CANCEL_OTHER_INVITATION as typeof ActionType.CANCEL_OTHER_INVITATION,
  payload: { targetUid },
});

export const updateCallingUserStatus = (targetUid: string, status: CallingUserStatus) => ({
  type: ActionType.UPDATE_CALLING_USER_STATUS as typeof ActionType.UPDATE_CALLING_USER_STATUS,
  payload: { targetUid, status },
});

export const removeRemoteUser = (targetUid: string) => ({
  type: ActionType.REMOVE_REMOTE_USER as typeof ActionType.REMOVE_REMOTE_USER,
  payload: { targetUid },
});

export const removeRemoteUserAfter3Sec = {
  start: (targetUid: string) => ({
    type: ActionType.REMOVE_REMOTE_USER_AFTER_3_SEC as typeof ActionType.REMOVE_REMOTE_USER_AFTER_3_SEC,
    payload: { targetUid },
  }),
};

export const removeRemoteInvitation = (removeTargetInvitations: RemoteInvitation[]) => ({
  type: ActionType.REMOVE_REMOTE_INVITATION as typeof ActionType.REMOVE_REMOTE_INVITATION,
  payload: { removeTargetInvitations },
});

export const addVideoTrack = {
  start: (stream: Stream, stat: StreamStat) => ({
    type: ActionType.ADD_VIDEO_TRACK as typeof ActionType.ADD_VIDEO_TRACK,
    payload: { stream, stat },
  }),
};

export const openReceivingModal = (remoteInvitation: RemoteInvitation) => ({
  type: ActionType.OPEN_RECEIVING_MODAL as typeof ActionType.OPEN_RECEIVING_MODAL,
  payload: { remoteInvitation },
});

export const closeReceivingModal = () => ({
  type: ActionType.CLOSE_RECEIVING_MODAL as typeof ActionType.CLOSE_RECEIVING_MODAL,
});

export const openJoinConfirmationModal = (joinConfirmationModalProps: JoinConfirmationModalProps) => ({
  type: ActionType.OPEN_JOIN_CONFIRMATION_MODAL as typeof ActionType.OPEN_JOIN_CONFIRMATION_MODAL,
  payload: { joinConfirmationModalProps },
});

export const closeJoinConfirmationModal = () => ({
  type: ActionType.CLOSE_JOIN_CONFIRMATION_MODAL as typeof ActionType.CLOSE_JOIN_CONFIRMATION_MODAL,
});

export const setAddMemberModalOpened = (opened: boolean) => ({
  type: ActionType.SET_ADD_MEMBER_MODAL_OPENED as typeof ActionType.SET_ADD_MEMBER_MODAL_OPENED,
  payload: { opened },
});

export const fetchCallableMembersActions = {
  start: () => ({
    type: ActionType.FETCH_CALLABLE_MEMBERS_START as typeof ActionType.FETCH_CALLABLE_MEMBERS_START,
  }),
  succeed: (callableMembers: CallableMember[]) => ({
    type: ActionType.FETCH_CALLABLE_MEMBERS_SUCCEED as typeof ActionType.FETCH_CALLABLE_MEMBERS_SUCCEED,
    payload: { callableMembers },
  }),
  fail: (error: Error) => ({
    type: ActionType.FETCH_CALLABLE_MEMBERS_FAIL as typeof ActionType.FETCH_CALLABLE_MEMBERS_FAIL,
    payload: { error },
  }),
  clear: () => ({
    type: ActionType.FETCH_CALLABLE_MEMBERS_CLEAR as typeof ActionType.FETCH_CALLABLE_MEMBERS_CLEAR,
  }),
};

export const setCallableMemberCalling = (targetId: string) => ({
  type: ActionType.SET_CALLABLE_MEMBER_CALLING as typeof ActionType.SET_CALLABLE_MEMBER_CALLING,
  payload: { targetId },
});

export const setCallableMemberTalking = (targetId: string) => ({
  type: ActionType.SET_CALLABLE_MEMBER_TALKING as typeof ActionType.SET_CALLABLE_MEMBER_TALKING,
  payload: { targetId },
});

export const setDeviceSettingModalOpened = (opened: boolean) => ({
  type: ActionType.SET_DEVICE_SETTING_MODAL_OPENED as typeof ActionType.SET_DEVICE_SETTING_MODAL_OPENED,
  payload: { opened },
});

export const openReconnectionModal = () => ({
  type: ActionType.OPEN_RECONNECTION_MODAL as typeof ActionType.OPEN_RECONNECTION_MODAL,
});

export const closeReconnectionModal = () => ({
  type: ActionType.CLOSE_RECONNECTION_MODAL as typeof ActionType.CLOSE_RECONNECTION_MODAL,
});

export const openSendingWhisperInvitationModal = (invitation: LocalInvitation) => ({
  type: ActionType.OPEN_SENDING_WHISPER_INVITATION_MODAL as typeof ActionType.OPEN_SENDING_WHISPER_INVITATION_MODAL,
  payload: { invitation },
});

export const closeSendingWhisperInvitationModal = () => ({
  type: ActionType.CLOSE_SENDING_WHISPER_INVITATION_MODAL as typeof ActionType.CLOSE_SENDING_WHISPER_INVITATION_MODAL,
});

export const refuseOrOpenWhisperReceivingModal = (remoteInvitation: RemoteInvitation) => ({
  type: ActionType.REFUSE_OR_OPEN_WHISPER_RECEIVING_MODAL as typeof ActionType.REFUSE_OR_OPEN_WHISPER_RECEIVING_MODAL,
  payload: { remoteInvitation },
});

export const openWhisperReceivingModal = (remoteInvitation: RemoteInvitation) => ({
  type: ActionType.OPEN_WHISPER_RECEIVING_MODAL as typeof ActionType.OPEN_WHISPER_RECEIVING_MODAL,
  payload: { remoteInvitation },
});

export const closeWhisperReceivingModal = (targetRemoteId: string) => ({
  type: ActionType.CLOSE_WHISPER_RECEIVING_MODAL as typeof ActionType.CLOSE_WHISPER_RECEIVING_MODAL,
  payload: { targetRemoteId },
});

export const startWhisperActions = {
  start: (conn: MediaConnection, localStream: MediaStream) => ({
    type: ActionType.START_WHISPER_START as typeof ActionType.START_WHISPER_START,
    payload: { conn, localStream },
  }),
  succeed: (targetUid: string) => ({
    type: ActionType.START_WHISPER_SUCCEED as typeof ActionType.START_WHISPER_SUCCEED,
    payload: { targetUid },
  }),
};

export const stopWhisperActions = {
  start: () => ({
    type: ActionType.STOP_WHISPER_START as typeof ActionType.STOP_WHISPER_START,
  }),
};

export const storeMessage = (message: string) => ({
  type: ActionType.STORE_MESSAGE as typeof ActionType.STORE_MESSAGE,
  payload: { message },
});

export const storeCallError = (error: CallError) => ({
  type: ActionType.STORE_ERROR_MESSAGE as typeof ActionType.STORE_ERROR_MESSAGE,
  payload: { error },
});

export const clearMessages = () => ({
  type: ActionType.CLEAR_MESSAGES as typeof ActionType.CLEAR_MESSAGES,
});

export const setMaximizedUid = (uid: string | undefined) => ({
  type: ActionType.SET_MAXIMIZED_UID as typeof ActionType.SET_MAXIMIZED_UID,
  payload: { uid },
});

export const addReceivedCallHistory = (name: string, iconUrl: string, isVoiceOnly: boolean, date: Date) => ({
  type: ActionType.ADD_RECEIVED_CALL_HISTORY as typeof ActionType.ADD_RECEIVED_CALL_HISTORY,
  payload: { name, iconUrl, isVoiceOnly, date },
});

export const clearReceivedCallHistory = () => ({
  type: ActionType.CLEAR_RECEIVED_CALL_HISTORY as typeof ActionType.CLEAR_RECEIVED_CALL_HISTORY,
});

export const setParentRoomVolume = (volume: number) => ({
  type: ActionType.SET_PARENT_ROOM_VOLUME as typeof ActionType.SET_PARENT_ROOM_VOLUME,
  payload: { volume },
});

export const setCallViewDisp = (dispState: boolean) => ({
  type: ActionType.CHANGE_CALL_VIEW_DISP as typeof ActionType.CHANGE_CALL_VIEW_DISP,
  payload: { dispState },
});

export const setNoticeForGuest = (noticed: boolean) => ({
  type: ActionType.SET_NOTICE_FOR_GUEST as typeof ActionType.SET_NOTICE_FOR_GUEST,
  payload: { noticed },
});

export const setJoinWorkboardError = (error: CallError[]) => ({
  type: ActionType.SET_JOIN_WORKBOARD_ERROR as typeof ActionType.SET_JOIN_WORKBOARD_ERROR,
  payload: { error },
});

export type CallAction =
  | ReturnType<typeof connectCallServerActions.start>
  | ReturnType<typeof connectCallServerActions.fail>
  | ReturnType<typeof reconnectCallServerActions.start>
  | ReturnType<typeof disconnectCallServerActions.start>
  | ReturnType<typeof renewCredential>
  | ReturnType<typeof storeDummyVideoTrack>
  | ReturnType<typeof storeRoom>
  | ReturnType<typeof sendInvitationActions.start>
  | ReturnType<typeof sendWhisperInvitationActions.start>
  | ReturnType<typeof addRemoteUserWithInvitation>
  | ReturnType<typeof updateCallingUserStatus>
  | ReturnType<typeof removeRemoteUser>
  | ReturnType<typeof cancelOtherInvitation>
  | ReturnType<typeof removeRemoteUserAfter3Sec.start>
  | ReturnType<typeof removeRemoteInvitation>
  | ReturnType<typeof stopCallingActions.start>
  | ReturnType<typeof stopCallingActions.succeed>
  | ReturnType<typeof joinRoomActions.start>
  | ReturnType<typeof joinRoomActions.succeed>
  | ReturnType<typeof joinRoomActions.fail>
  | ReturnType<typeof storeChildTaskOfCall>
  | ReturnType<typeof sendLocalStreamStat>
  | ReturnType<typeof remotePeerJoined>
  | ReturnType<typeof receiveRemoteStreamStats>
  | ReturnType<typeof receiveRemoteStream>
  | ReturnType<typeof setLocalUser>
  | ReturnType<typeof updateLocalStream>
  | ReturnType<typeof setRemoteUser>
  | ReturnType<typeof addRemoteStream>
  | ReturnType<typeof setRemoteStat>
  | ReturnType<typeof updateStreamWithNewMediaDeviceActions.start>
  | ReturnType<typeof updateStreamWithNewAudioDeviceActions.start>
  | ReturnType<typeof updateLocalAudioEnabled.start>
  | ReturnType<typeof updateLocalAudioEnabled.succeed>
  | ReturnType<typeof disableLocalVideo.start>
  | ReturnType<typeof shareScreenActions.success>
  | ReturnType<typeof stopShareScreenActions.start>
  | ReturnType<typeof updateLocalUserSpeaking>
  | ReturnType<typeof updateRemoteUserSpeaking>
  | ReturnType<typeof addVideoTrack.start>
  | ReturnType<typeof openReceivingModal>
  | ReturnType<typeof closeReceivingModal>
  | ReturnType<typeof openJoinConfirmationModal>
  | ReturnType<typeof closeJoinConfirmationModal>
  | ReturnType<typeof setAddMemberModalOpened>
  | ReturnType<typeof fetchCallableMembersActions.start>
  | ReturnType<typeof fetchCallableMembersActions.succeed>
  | ReturnType<typeof fetchCallableMembersActions.fail>
  | ReturnType<typeof fetchCallableMembersActions.clear>
  | ReturnType<typeof setCallableMemberCalling>
  | ReturnType<typeof setCallableMemberTalking>
  | ReturnType<typeof setDeviceSettingModalOpened>
  | ReturnType<typeof openReconnectionModal>
  | ReturnType<typeof closeReconnectionModal>
  | ReturnType<typeof openSendingWhisperInvitationModal>
  | ReturnType<typeof closeSendingWhisperInvitationModal>
  | ReturnType<typeof refuseOrOpenWhisperReceivingModal>
  | ReturnType<typeof openWhisperReceivingModal>
  | ReturnType<typeof closeWhisperReceivingModal>
  | ReturnType<typeof startWhisperActions.start>
  | ReturnType<typeof startWhisperActions.succeed>
  | ReturnType<typeof stopWhisperActions.start>
  | ReturnType<typeof storeMessage>
  | ReturnType<typeof storeCallError>
  | ReturnType<typeof clearMessages>
  | ReturnType<typeof setMaximizedUid>
  | ReturnType<typeof addReceivedCallHistory>
  | ReturnType<typeof clearReceivedCallHistory>
  | ReturnType<typeof setParentRoomVolume>
  | ReturnType<typeof setCallViewDisp>
  | ReturnType<typeof setNoticeForGuest>
  | ReturnType<typeof setJoinWorkboardError>;
