/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { CallingUser } from 'reducers/call/call';
import { Voice } from 'components/call/Voice';

export const VoiceContainer: FC = () => {
  const callViewDisplayed = useSelector<RootState, boolean>(state => state.call.isDispCallView);
  const remoteUsers = useSelector<RootState, CallingUser[]>(state => state.call.remoteUsers);
  const parentRoomVolume = useSelector<RootState, number>(state => state.call.parentRoomVolume);

  if (callViewDisplayed) {
    return <div />;
  }

  const mediaStreams: MediaStream[] = remoteUsers
    .map(u => u.stream?.getMediaStream())
    .filter((s): s is Required<MediaStream> => s !== undefined);

  return (
    <div>
      {mediaStreams.map(s => (
        <Voice key={s.id} mediaStream={s} parentRoomVolume={parentRoomVolume} />
      ))}
    </div>
  );
};
