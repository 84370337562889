import { call, put, take } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { fetchCallableMembersActions, storeCallError } from 'actions/call/call';
import { CallableMember } from 'containers/call/modal/useCallableMembers';

/**
 * 通話メンバー追加ダイアログ用のメンバー一覧を取得するタスク。
 */
export function* watchFetchingCallableMembersAction() {
  try {
    while (true) {
      yield take(Action.FETCH_CALLABLE_MEMBERS_START);

      try {
        const onlineMembers: CallableMember[] = yield call(api.fetchCallableMembers);

        yield put(fetchCallableMembersActions.succeed(onlineMembers));
      } catch (e) {
        yield put(fetchCallableMembersActions.fail(e));
      }
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey:
          '通話可能メンバー取得イベントの監視でエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  }
}
