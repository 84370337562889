import { combineReducers } from 'redux';
import { authReducer } from './auth/auth';
import homeSubscribeReducer from './home/subscriber';
import homeReducer from './home/home';
import { callReducer } from './call/call';
import { commonReducer } from './common/common';
import { routeReducer } from './route/route';
import { splashReducer } from './splash/splash';
import { workboardReducer } from './call/workboard';
import { htmlHeaderReducer } from './htmlHeader/htmlHeader';

// まとめたいReducerをここに追加する。
const mainReducer = combineReducers({
  auth: authReducer,
  homeSubscriber: homeSubscribeReducer,
  home: homeReducer,
  call: callReducer,
  common: commonReducer,
  route: routeReducer,
  splash: splashReducer,
  htmlHeader: htmlHeaderReducer,
  workboard: workboardReducer,
});

export type RootState = ReturnType<typeof mainReducer>;

export default mainReducer;
