import { EventEmitter } from 'events';
// eslint-disable-next-line global-require
const hark = require('hark') as Hark;

export type Hark = (stream: MediaStream, options?: HarkOptions) => Harker;
interface HarkOptions {
  interval?: number;
  threshold?: number;
  play?: boolean;
  audioContext?: AudioContext;
}

interface Harker {
  stop(): void;
  setInterval(interval: number): void;
  setThreshold(db: number): void;
  on(ev: 'speaking', cb: () => void): this;
  on(ev: 'stopped_speaking', cb: () => void): this;
  on(ev: 'volume_change', cb: (db: number) => void): this;
}

/**
 * 指定した MediaStream の AudioTrack のボリュームを取得するためのクラスです。
 * EventEmitter を継承しており、 volume リスナーを張ることで 0.1 秒ごとに音量を取得できます。
 *
 * ```typescript
 * const indicator = new AudioVolumeIndicator(stream);
 * indicator.on('speaking', () => {
 *  console.log('now speaking');
 * });
 * indicator.on('stopped_speaking', () => {
 *  console.log('stopped_speaking');
 * });
 * ```
 *
 * 一度生成した後に AudioTrack を更新した場合には restartObservingVolume を呼ぶ必要があります。
 */
export class AudioVolumeIndicator extends EventEmitter {
  private harker: Harker;

  private close: () => void;

  constructor(stream: MediaStream) {
    super();
    this.harker = hark(stream);
    this.close = this.startObservingVolume();
  }

  private startObservingVolume() {
    this.harker.on('speaking', () => {
      this.emit('speaking');
    });

    this.harker.on('stopped_speaking', () => {
      this.emit('stopped_speaking');
    });

    return () => {
      this.harker.stop();
    };
  }

  restartObservingVolume(stream: MediaStream) {
    this.closeIndicator();
    this.harker = hark(stream);
    this.close = this.startObservingVolume();
  }

  closeIndicator() {
    this.close();
  }
}
