/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { jsx } from '@emotion/core';
import { BasicMessageBox } from 'components/common/parts/BasicMessageBox';
import { CommonMessage } from 'reducers/common/common';
import { RootState } from 'reducers/mainReducer';
import { useSelector } from 'react-redux';

export const ChatError = () => {
  const commonMsg = useSelector<RootState, CommonMessage | undefined>(state => state.common.errorMsg);

  if (!commonMsg) {
    return null;
  }

  return (
    <div className="error">
      <BasicMessageBox color="red">
        <div>{commonMsg}</div>
      </BasicMessageBox>
    </div>
  );
};
