import { RefObject, useCallback, useEffect } from 'react';

export const useOutsideClick = ({
  ref,
  handleOnOutsideClick,
}: {
  ref: RefObject<HTMLElement>;
  handleOnOutsideClick: () => void;
}) => {
  const handleOnFocusOut = useCallback(
    (e: MouseEvent) => {
      if (e.target && ref.current && !ref.current.contains(e.target as Node)) {
        handleOnOutsideClick();
      }
    },
    [ref, handleOnOutsideClick],
  );

  useEffect(() => {
    const listener = (e: MouseEvent) => handleOnFocusOut(e);
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [handleOnFocusOut]);
};
