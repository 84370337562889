// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { SignInAnonymously } from 'components/auth/SignInAnonymously';
import { useDispatch } from 'react-redux';
import { signInAnonymouslyActions } from 'actions/auth/signIn';

export const SignInAnonymouslyContainer: FC = () => {
  const dispatch = useDispatch();

  return (
    <SignInAnonymously signInAnonymously={(userName: string) => dispatch(signInAnonymouslyActions.start(userName))} />
  );
};
