/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx, css } from '@emotion/core';
import SignInGoogle from 'containers/auth/SignInGoogle';
import { ContentsBox } from 'components/common/ContentsBox';
import { ContentsBoxContainerPage } from 'components/common/ContentsBoxContainerPage';
import { useSelector } from 'react-redux';
import { CommonMessage } from 'reducers/common/common';
import { RootState } from 'reducers/mainReducer';
import { AuthPageHeader } from './AuthPageHeader';

export const pageStyle = css`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;

const SingIn: FC = () => {
  const commonMsg = useSelector<RootState, CommonMessage | undefined>(state => state.common.errorMsg);
  const errorMessage: string[] = [];
  if (commonMsg) errorMessage.push(String(commonMsg));

  return (
    <div>
      <AuthPageHeader />
      <div css={pageStyle}>
        <ContentsBoxContainerPage headerDisplayed>
          <ContentsBox title="CLINKS社員向けログイン" errorMessages={errorMessage}>
            <SignInGoogle />
          </ContentsBox>
        </ContentsBoxContainerPage>
      </div>
    </div>
  );
};

export default SingIn;
