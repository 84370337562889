/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import ErrorNotifyModal from 'components/auth/modal/ErrorNotifyModal';
import { RootState } from 'reducers/mainReducer';

const ErrorNotifyModalContainer: FC = () => {
  const errorMessages = useSelector<RootState, string[]>(state => state.auth.errorMessages);

  const open = errorMessages.length > 0;

  return open ? <ErrorNotifyModal errorMessages={errorMessages} /> : <div />;
};

export default ErrorNotifyModalContainer;
