import * as ActionType from 'actions/ActionTypeConstants';

export type LocationCode = 'home' | 'office' | 'goOut';
export type LocationType = {
  code: LocationCode;
  name: string;
};

export const Locations: LocationType[] = [
  { code: 'home', name: '在宅' },
  { code: 'office', name: '出社' },
  { code: 'goOut', name: '外出' },
];

export const updateLocationActions = {
  start: (location: LocationCode) => ({
    type: ActionType.HOME_UPDATE_LOCATION as typeof ActionType.HOME_UPDATE_LOCATION,
    payload: { location },
  }),
};
