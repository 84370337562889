import { call, takeEvery } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { updateUserViewsIsShadeActions } from 'actions/home/headerBlur';

export function* runUpdateUserViewsIsShade(action: ReturnType<typeof updateUserViewsIsShadeActions.start>) {
  try {
    yield call(api.updateUserViewsIsShade, action.payload.isShade);
  } catch (error) {
    // nothing to do
  }
}

export function* watchUpdateUserViewsIsShade() {
  yield takeEvery(Action.HOME_UPDATE_USER_VIEWS_IS_SHADE, runUpdateUserViewsIsShade);
}
