import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { commonAction } from 'actions/common/common';

export interface CommonMessage {
  key: string;
  detail?: string;
}

export interface CommonState {
  successMsg?: CommonMessage;
  errorMsg?: CommonMessage;
}

export const initialState: CommonState = {
  successMsg: undefined,
  errorMsg: undefined,
};

export const commonReducer: Reducer<CommonState, commonAction> = (
  state: CommonState = initialState,
  action: commonAction,
): CommonState => {
  switch (action.type) {
    case ActionType.COMMON_CLEAR:
      return initialState;
    case ActionType.COMMON_SUCCESS:
      return {
        successMsg: action.payload.commonMsg,
        errorMsg: undefined,
      };
    case ActionType.COMMON_ERROR:
      return {
        successMsg: undefined,
        errorMsg: action.payload.commonMsg,
      };
    default: {
      return state;
    }
  }
};
