import StayIcon from 'styles/image/icon_status_stay.svg';
import StopIcon from 'styles/image/icon_status_ng.svg';
import BreakIcon from 'styles/image/icon_status_break.svg';

// REVISIT: Functions 側でも利用しているので統合を検討してください
export type StatusCode = 'presence' | 'busy' | 'break';

interface Status {
  code: StatusCode;
  label: string;
  icon: string;
}

export const Statuses: Status[] = [
  { code: 'presence', label: '在席', icon: StayIcon },
  { code: 'busy', label: '取込中', icon: StopIcon },
  { code: 'break', label: '休憩', icon: BreakIcon },
];

export function getStatus(statusCode: StatusCode): Status {
  const status = Statuses.find(s => s.code === statusCode);
  if (status) {
    return status;
  }

  return Statuses[0];
}
