/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import CallingIcon from 'styles/image/icon_home_calling.svg';
import React, { FC } from 'react';
import { boldFont, rounded } from 'styles/style';

const baseCss = css`
  display: inline-block;
  height: 30px;
  margin: 0;
  width: 30px;
`;
const callingCss = css`
  ${rounded};
  ${baseCss};
  background: url(${CallingIcon}) no-repeat center;
  background-size: 22px;
  padding: 4px;
`;

export interface CallingLabelProps {
  roomId: string;
  roomMemberNames?: string[];
  labelColor: string;
}

const RoomMembers = React.memo(({ memberNames }: { memberNames: string[] }) => {
  return (
    <div>
      <div css={boldFont}>参加者</div>
      {memberNames.map(name => (
        <div key={name}>{name}</div>
      ))}
    </div>
  );
});

const CallingLabel: FC<CallingLabelProps> = ({ roomId, roomMemberNames = [], labelColor }) => {
  if (roomId) {
    return (
      <div css={baseCss}>
        <BasicPopup position="top" value={<RoomMembers memberNames={roomMemberNames} />}>
          <div css={[callingCss, { backgroundColor: labelColor }]} />
        </BasicPopup>
      </div>
    );
  }

  return <div css={baseCss} />;
};

export default CallingLabel;
