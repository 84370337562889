/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import BlurOnIcon from 'styles/image/icon_header_blur.svg';
import BlurOffIcon from 'styles/image/icon_header_blur_off.svg';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { COLORS } from 'styles/style';
import { HeaderIcon } from './Header';

const itemSwitchCss = css`
  background-color: transparent;
  border: solid 1px ${COLORS.white};
  border-radius: 11px;
  display: inline-block;
  line-height: 16px;
  margin-left: 5px;
  outline: 0;
  padding: 1px;
  width: 72px;

  span {
    color: ${COLORS.white};
    cursor: pointer;
    display: inline-block;
    font-size: 0.8em;
    line-height: 16px;
    margin: 0 1px;
    text-align: center;
    width: 32px;

    &.selected {
      background-color: ${COLORS.white};
      border-radius: 9px;
      color: ${COLORS.black};
      cursor: default;
    }
  }
`;

export interface HeaderBlurProps {
  isShade: boolean;
  updateIsShade: (isShade: boolean) => void;
}

const Blur: FC<HeaderBlurProps> = ({ isShade, updateIsShade }) => {
  return (
    <BasicPopup
      position="bottom"
      value={
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div>ぼかし</div>
          <button
            type="button"
            css={itemSwitchCss}
            onClick={() => updateIsShade(!isShade)}
            data-testid="headerBlurButton"
          >
            <span className={`${isShade && 'selected'}`} data-testid="headerBlurOnButton">
              On
            </span>
            <span className={`${!isShade && 'selected'}`} data-testid="headerBlurOffButton">
              Off
            </span>
          </button>
        </div>
      }
    >
      <HeaderIcon iconUrl={isShade ? BlurOnIcon : BlurOffIcon} onClick={() => updateIsShade(!isShade)} />
    </BasicPopup>
  );
};

export default Blur;
