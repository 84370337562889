/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { COLORS } from 'styles/style';
import { BasicModal, buttonCss } from './BasicModal';
import { BasicButton } from '../parts/BasicButton';

interface BasicErrorModalProps {
  open: boolean;
  errors: string[];
  handleOnCloseClicked: () => void;
}

/**
 * エラーモーダル
 * @param open - オープン状態
 * @param errors - エラー配列
 * @param handleOnCloseClicked - 閉じるボタンonClick
 * @returns モーダルを表示
 * @example
 * ```
 * <BasicErrorModal open={open} errors={errors} handleOnCloseClicked={() => handleOnClick()} />
 * ```
 */
export const BasicErrorModal: FC<BasicErrorModalProps> = ({ open, errors = [], handleOnCloseClicked }) => {
  return (
    <BasicModal
      open={open}
      header="エラー"
      headerIconColor={COLORS.red}
      contents={
        <div>
          {errors.map(error => {
            return <div key={error}>{error}</div>;
          })}
          <div css={buttonCss}>
            <BasicButton color="red" onClick={() => handleOnCloseClicked()}>
              閉じる
            </BasicButton>
          </div>
        </div>
      }
    />
  );
};
