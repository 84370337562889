import * as ActionType from 'actions/ActionTypeConstants';

export interface UpdateStatusParams {
  status: string;
  breakStartTimeValue: number;
}

export const updateStatusActions = {
  start: (params: UpdateStatusParams) => ({
    type: ActionType.HOME_UPDATE_STATUS as typeof ActionType.HOME_UPDATE_STATUS,
    payload: params,
  }),
};

export type StatusAction = ReturnType<typeof updateStatusActions.start>;
