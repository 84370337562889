/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import { WorkboardUiState } from 'reducers/call/workboard';
import { FormatChatTextTag } from 'components/common/FormatChatTextTag';
import { boldFont, COLORS, popUpAndToastZIndex, rounded } from 'styles/style';
import CloseIcon from 'styles/image/close.svg';

const fadeUpContainer = css`
  animation: fadeUp 0.5s ease;
  bottom: 10px;
  position: absolute;
  right: 10px;
  ${popUpAndToastZIndex};

  @keyframes fadeUp {
    0% {
      opacity: 0.5;
      transform: translateY(30px);
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const notification = css`
  ${rounded};
  background-color: ${COLORS.lightGray};
  border: solid 1px ${COLORS.darkGray};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 130px;
  padding: 14px;
  width: 300px;
`;

const title = css`
  ${boldFont};
  color: ${COLORS.black};
`;

const closeButton = css`
  background-color: transparent;
  border: none;
  outline: none;

  &:enabled {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const senderName = css`
  ${boldFont};
  font-size: 12px;
`;

const text = css`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 12px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-wrap: break-word;
`;

interface TextChatNoticeProps {
  uiState: WorkboardUiState;
  clearMessage: () => void;
  openChat: () => void;
}

export const ChatNotice: FC<TextChatNoticeProps> = ({ uiState, clearMessage, openChat }) => {
  const message = uiState.chatMessage.slice(-1)[0];

  return (
    <div css={fadeUpContainer}>
      <div css={notification} onClick={openChat} onKeyPress={openChat} tabIndex={0} role="link">
        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div css={title}>新着メッセージ</div>
          <button
            css={closeButton}
            type="button"
            onClick={event => {
              event.stopPropagation();
              clearMessage();
            }}
          >
            <img css={{ height: 10, width: 10 }} src={CloseIcon} alt="" />
          </button>
        </div>
        <div css={senderName}>{message.name}</div>
        <div css={text}>
          <FormatChatTextTag text={message.text} />
        </div>
      </div>
    </div>
  );
};
