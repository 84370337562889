import * as ActionType from 'actions/ActionTypeConstants';

export interface UpdateConditionParams {
  condition: string;
}

export const updateConditionActions = {
  start: (params: UpdateConditionParams) => ({
    type: ActionType.HOME_UPDATE_CONDITION as typeof ActionType.HOME_UPDATE_CONDITION,
    payload: params,
  }),
};

export type ConditionAction = ReturnType<typeof updateConditionActions.start>;
