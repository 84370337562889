/* eslint-disable no-console */
import { call, fork, put, retry, take, takeLatest } from 'redux-saga/effects';
import { analytics, createSkyWayCredential } from 'firebase/Instances';
import Peer from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { closeReconnectionModal, connectCallServerActions } from 'actions/call/call';
import { subscribePeerEvents } from 'sagas/call/tasks/subscribePeerEvents';
import { watchJoiningRoomAction } from 'sagas/call/tasks/watchJoiningRoomAction';
import { watchLeavingRoomAction } from 'sagas/call/tasks/watchLeavingRoomAction';
import { watchRemovingRemoteInvitationAction } from 'sagas/call/tasks/watchRemovingRemoteInvitainAction';
import { watchRenewTokenAction } from 'sagas/call/tasks/watchRenewingCredential';

export const SKYWAY_APP_KEY = `${process.env.REACT_APP_SKYWAY_APP_KEY}`;

export function* createCredential(currentUserId: string) {
  const response = yield retry(3, 1000, createSkyWayCredential, { peerId: currentUserId });
  const { credential } = response.data;

  return credential;
}

/**
 * ネットワーク切断や認証情報の失効により SkyWay サーバとの通信が切れた後、再接続するためのアクションを監視するタスク。
 *
 * @param uid
 */
export function* watchReconnectingCallServer(uid: string) {
  while (true) {
    yield take(Action.RECONNECT_CALL_SERVER);
    analytics.logEvent('reconnect_call_server', { uid });
    // peer.reconnect では credential が失効されている場合に復帰する手段がなかったので Peer 作り直す。
    // redux-saga の takeLatest の仕様により既存のタスクは自動でキャンセルされるのでメモリリークの心配はない (はず)。
    yield put(connectCallServerActions.start());
    yield put(closeReconnectionModal());
  }
}

export function* connectCallServer() {
  const currentUserId = api.getCurrentUserId();
  if (currentUserId === '') {
    return;
  }

  try {
    const credential = yield call(createCredential, currentUserId);
    const peer = new Peer(currentUserId, { key: SKYWAY_APP_KEY, credential });

    yield fork(subscribePeerEvents, peer);
    yield fork(watchRenewTokenAction, peer);
    yield fork(watchJoiningRoomAction, peer);
    yield fork(watchLeavingRoomAction, currentUserId);
    yield fork(watchRemovingRemoteInvitationAction);
    yield fork(watchReconnectingCallServer, currentUserId);

    analytics.logEvent('connect_call_server_succeed', { uid: currentUserId });

    // 切断処理
    yield take(Action.DISCONNECT_CALL_SERVER);
    peer.disconnect();
  } catch (error) {
    analytics.logEvent('connect_call_server_fail', { uid: currentUserId });
    yield put(
      connectCallServerActions.fail(
        `通話用サーバとの接続に失敗しました。お手数ですが、通信環境をご確認の上一度リロードしてください: ${error.message}`,
      ),
    );
  }
}

export function* watchConnectingCallServer() {
  yield takeLatest(Action.CONNECT_CALL_SERVER, connectCallServer);
}
