// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

type ShortCut = { label: string; handleOnSendShortcut: (event: KeyboardEvent) => void };

export const useSendShortcut = (handleOnPressed: () => void): ShortCut => {
  const isMacOS = navigator.userAgent.toLowerCase().includes(`mac os`);

  return {
    label: isMacOS ? '⌘ + Return で送信' : 'Ctrl + Enter で送信',
    handleOnSendShortcut: event => {
      const ctrlKey = isMacOS ? event.metaKey : event.ctrlKey;
      if (ctrlKey && event.key === 'Enter') {
        event.preventDefault();
        handleOnPressed();
      }
    },
  };
};
