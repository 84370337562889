/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import Dropzone from 'react-dropzone';
import { WorkboardFile } from 'reducers/call/workboard';
import IconDnd from 'styles/image/icon_workboard_dnd.svg';
import DeleteIcon from 'styles/image/close.svg';
import { BasicMessageBox } from 'components/common/parts/BasicMessageBox';
import { CommonMessage } from 'reducers/common/common';
import { rounded, COLORS } from 'styles/style';
import { BasicIconButton } from 'components/common/parts/BasicIconButton';
import { CallModalLoader, CallModalBase } from '../modal/CallModalBase';

const fileDnDCss = css`
  background-image: linear-gradient(-45deg, #f9f8f7 25%, #fff 25%, #fff 50%, #f9f8f7 50%, #f9f8f7 75%, #fff 75%, #fff);
  background-size: 40px 40px;
  border: dashed 3px rgba(0, 0, 0, 0.5);
  height: 160px;
  width: 100%;

  .mouse-leave {
    line-height: 160px;
    text-align: center;
  }

  .mouse-on {
    background-image: url(${IconDnd});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60px 60px;
    cursor: pointer;
    min-height: 11em;
  }
`;

const uploadedFileList = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const uploadedFileListItem = css`
  ${rounded};
  align-items: center;
  background-color: ${COLORS.lightGray};
  display: flex;
  gap: 10px;
  height: 30px;
  justify-content: space-between;
  padding: 0 10px;

  .fileName {
    border: none;
    color: ${COLORS.darkGray};
    cursor: pointer;
    outline: none;
  }
`;

interface Props {
  currentUserId: string;
  uploadFiles: (files: File[]) => void;
  fileState: WorkboardFile[];
  loading: boolean;
  handleOnClose: () => void;
  handleOnDeleteFile: (fileState: WorkboardFile) => void;
  handleOnGetFileUrl: (fileState: WorkboardFile) => void;
  commonError: CommonMessage | undefined;
}

export const SharingFiles: FC<Props> = ({
  currentUserId,
  uploadFiles,
  fileState,
  loading,
  handleOnClose,
  handleOnDeleteFile,
  handleOnGetFileUrl,
  commonError,
}) => {
  const onDrop = (acceptedFiles: File[]) => {
    uploadFiles(acceptedFiles);
  };

  const files = fileState.map(file => {
    return (
      <div key={file.id} css={uploadedFileListItem}>
        <button className="fileName" type="button" onClick={() => handleOnGetFileUrl(file)}>
          {file.name}
        </button>
        {currentUserId === file.owner && (
          <BasicIconButton pxSize={10} iconPath={DeleteIcon} onClick={() => handleOnDeleteFile(file)} />
        )}
      </div>
    );
  });

  const Body = () => {
    if (loading) {
      return (
        <div css={{ height: 200 }}>
          <CallModalLoader label="処理中" />
        </div>
      );
    }

    return (
      <React.Fragment>
        {(commonError?.key || commonError?.detail) && (
          <div css={{ marginBottom: 30 }}>
            <BasicMessageBox color="red">
              <div>{commonError.key}</div>
              <div>{commonError.detail}</div>
            </BasicMessageBox>
          </div>
        )}
        <Dropzone onDrop={onDrop} noClick>
          {({ getRootProps, isDragActive }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...getRootProps()}>
              <div css={fileDnDCss}>
                {isDragActive ? (
                  <div className="mouse-on" />
                ) : (
                  <div className="mouse-leave">ここにファイルをドラッグ＆ドロップしてください。</div>
                )}
              </div>
            </div>
          )}
        </Dropzone>
        {fileState.length > 0 && (
          <div css={{ marginTop: 30 }}>
            <p>共有されたファイル</p>
            <div css={uploadedFileList}>{files}</div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <CallModalBase title="ファイル共有" handleOnClose={handleOnClose}>
      <Body />
    </CallModalBase>
  );
};
