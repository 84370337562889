/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { jsx } from '@emotion/core';
import { BasicPopup } from 'components/common/parts/BasicPopup';

type Props = {
  popupContent: JSX.Element;
  position: 'top' | 'bottom' | 'right' | 'left';
  forcePopupOpened?: boolean;
  children: React.ReactNode;
};

export const CallViewPopup = ({ popupContent, position, forcePopupOpened, children }: Props) => {
  return (
    <BasicPopup
      value={popupContent}
      position={position}
      color="lightGray"
      forceOpened={forcePopupOpened}
      padding="10px 14px"
    >
      {children}
    </BasicPopup>
  );
};
