/** @jsx jsx */
import { FC, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import Header from 'components/header/Header';
import { setMemberViewSearchField } from 'actions/home/headerMemberSearch';
import { signOutActions } from 'actions/auth/signOut';
import { RootState } from 'reducers/mainReducer';

interface HeaderProps {
  isHomeView?: boolean;
}

const HeaderContainer: FC<HeaderProps> = ({ isHomeView = false }) => {
  const dispatch = useDispatch();
  const isGuest = useSelector<RootState, boolean>(state => state.homeSubscriber.currentUserStatus.isGuest);

  useEffect(() => {
    dispatch(setMemberViewSearchField.start(''));
  }, [dispatch]);

  const signOut = () => dispatch(signOutActions.start());

  return <Header isHomeView={isHomeView} signOut={signOut} isGuest={isGuest} />;
};

export default HeaderContainer;
