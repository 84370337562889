/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { CallButtonContainerProps, isAvailable } from 'containers/call/modal/CallButton';
import { VideoButton } from 'components/call/modal/VideoButton';
import { useDispatch } from 'react-redux';
import { setCallableMemberCalling } from 'actions/call/call';
import { useInvitationSender } from './useInvitationSender';

export const VideoButtonContainer: FC<CallButtonContainerProps> = ({ callableMembers }) => {
  const available = !!callableMembers.find(m => isAvailable(m));
  const dispatch = useDispatch();
  const [sendInvitation] = useInvitationSender(callableMembers, false);

  const callMember = () => {
    dispatch(setCallableMemberCalling(callableMembers[0].uid));
    sendInvitation();
  };

  return <VideoButton onClick={() => callMember()} available={available} />;
};
