/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { DeviceSettingModal } from 'components/call/modal/DeviceSettingModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { setDeviceSettingModalOpened } from 'actions/call/call';

export const DeviceSettingModalContainer: FC = () => {
  const open = useSelector<RootState, boolean>(state => state.call.uiState.deviceSettingModalOpened);
  const dispatch = useDispatch();

  return <div>{open && <DeviceSettingModal onClose={() => dispatch(setDeviceSettingModalOpened(false))} />}</div>;
};
