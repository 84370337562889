/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import IconVideoCall from 'styles/image/icon_action_camera.svg';
import IconVoiceCall from 'styles/image/icon_action_tel.svg';
import { NOT_AVAILABLE_MSG } from 'components/home/Home';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { HeaderIcon } from './Header';

export const TeamCall: FC<{
  voiceOnly: boolean;
}> = ({ voiceOnly }) => {
  return (
    <BasicPopup value={NOT_AVAILABLE_MSG} position="bottom">
      <HeaderIcon iconUrl={voiceOnly ? IconVoiceCall : IconVideoCall} disabled />
    </BasicPopup>
  );
};
