/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useRef, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { CallingUser } from 'reducers/call/call';

const JOIN_SOUND_URL = `${process.env.PUBLIC_URL}/se/join.mp3`;
const LEAVE_SOUND_URL = `${process.env.PUBLIC_URL}/se/leave.mp3`;

export const handleAudioPlayingError = (error: Error) => {
  console.log(`Failed to play audio: ${error.name}`); // eslint-disable-line no-console
};

/**
 * 通話中の RemoteStream の増減を監視して、効果音を鳴らすためのコンポーネント
 */
export const RoomSoundEffect: FC = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  // 前回の remoteStream の数
  const prevCountRef = useRef(0);
  const prevCount = prevCountRef.current;
  // 前回音を鳴らした時の時間 (既存のルームに入室した際に人数分音が鳴るのを抑制するために使う)
  const prevSoundedTimeMsRef = useRef(0);
  const prevSoundedTimeMs = prevSoundedTimeMsRef.current;
  const remoteUsers = useSelector<RootState, CallingUser[]>(state => state.call.remoteUsers);

  useEffect(() => {
    const currentTimeMs = new Date().getTime();
    const audioNode = audioRef.current; // eslint-disable-line @typescript-eslint/no-explicit-any

    // 前回鳴らしてから 1 秒以上の間隔が空いていない場合は音を鳴らさない
    if (audioNode !== null && currentTimeMs - prevSoundedTimeMs > 1000) {
      if (prevCount < remoteUsers.length) {
        // remoteUsers.length が増えているなら入室サウンド
        audioNode.src = JOIN_SOUND_URL;
        audioNode.play().catch(handleAudioPlayingError);
      } else if (prevCount > remoteUsers.length) {
        // remoteUsers.length が減っているなら退室サウンド
        audioNode.src = LEAVE_SOUND_URL;
        audioNode.play().catch(handleAudioPlayingError);
      }
      prevSoundedTimeMsRef.current = currentTimeMs;
    }
    prevCountRef.current = remoteUsers.length;
  }, [remoteUsers, prevCount, prevSoundedTimeMs]);

  return <audio ref={audioRef} preload="auto" />;
};
