import { all, fork } from 'redux-saga/effects';
import { watchSignInGoogle, watchSignOut, watchSignInAnonymously } from './auth/auth';
import { watchUnsubscribe } from './home/subscriber';
import { watchUpdateLocation } from './home/updateLocation';
import { watchUpdateStatus } from './home/updateStatus';
import { watchUpdateAttendance } from './home/attendance';
import { watchUpdateComment } from './home/comment';
import { watchUpdateUserViewsIsShade } from './home/headerBlur';
import { watchConnectingCallServer } from './call/call';
import {
  watchWorkboardUploadFiles,
  watchWorkboardDeleteFile,
  watchSendTextChat,
  watchGetWorkboardFileUrl,
  watchSendCanvasTmpData,
  watchGetCanvasHistory,
  watchRemoveCanvasMember,
  watchJoinWorkBoard,
} from './call/workboard';
import { watchCancelOtherInvitation } from './call/tasks/watchSendingInvitationAction';
import { watchUpdateCondition } from './home/updateCondition';

// watchしたいSagaの処理をここに追加する
export default function* rootSaga() {
  yield all([
    // auth
    fork(watchSignOut),
    fork(watchSignInGoogle),
    fork(watchSignInAnonymously),
    // Home
    fork(watchUpdateLocation),
    fork(watchUpdateStatus),
    fork(watchUpdateAttendance),
    fork(watchUpdateComment),
    fork(watchUpdateUserViewsIsShade),
    fork(watchUnsubscribe),
    fork(watchUpdateCondition),

    // Call
    fork(watchConnectingCallServer),
    fork(watchCancelOtherInvitation),

    // Workboard
    fork(watchWorkboardUploadFiles),
    fork(watchWorkboardDeleteFile),
    fork(watchSendTextChat),
    fork(watchGetWorkboardFileUrl),
    fork(watchSendCanvasTmpData),
    fork(watchGetCanvasHistory),
    fork(watchRemoveCanvasMember),
    fork(watchJoinWorkBoard),
  ]);
}
