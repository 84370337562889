import { call, put, select, take } from 'redux-saga/effects';
import { SfuRoom } from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import { storeCallError, storeMessage, updateLocalStream } from 'actions/call/call';
import { RootState } from 'reducers/mainReducer';
import { StreamStat } from 'sagas/call/classes/RoomData';
import { Stream } from 'sagas/call/classes/Stream';
import { sendStreamStat } from 'sagas/call/tasks/watchJoiningRoomAction';
import { AudioVolumeIndicator } from 'sagas/call/classes/AudioVolumeIndicator';

/**
 * 通話中のカメラデバイス更新アクションを監視するタスク。
 * カメラがオフになっている場合や、画面共有中の時にはローカルストレージの設定デバイス更新のみを行う。
 *
 * @param room: SkyWay の SfuRoom オブジェクト
 */
export function* watchChangingMediaDeviceAction(room: SfuRoom) {
  try {
    while (true) {
      const action = yield take(Action.UPDATE_MEDIA_DEVICE);
      const { deviceId } = action.payload;

      localStorage.setItem('mediaDeviceId', deviceId);

      const stream: Stream = yield select((state: RootState) => state.call.localUser?.stream);
      const streamStat: StreamStat = yield select((state: RootState) => state.call.localUser?.streamStat);
      if (!streamStat.isVideoEnabled || streamStat.videoType !== 'camera') {
        continue; // eslint-disable-line no-continue
      }

      const { videoTrack, videoDeviceError } = yield call(Stream.createVideoTrack, deviceId);
      if (videoDeviceError) {
        yield put(storeMessage('カメラの取得に失敗しました。使用可能なカメラを選択してください。'));
        const dummyVideoTrack = yield select((store: RootState) => store.call.dummyVideoTrack);
        stream.setOrReplaceVideoTrack(dummyVideoTrack);
        streamStat.videoType = 'dummy';
        streamStat.isVideoEnabled = false;
        sendStreamStat(room, streamStat);
      } else {
        stream.setOrReplaceVideoTrack(videoTrack);
      }

      room.replaceStream(stream.getMediaStream());
      yield put(updateLocalStream(stream, streamStat));
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey:
          'カメラデバイスの更新でエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  }
}

/**
 * 通話中のオーディオデバイス更新アクションを監視するタスク。既存の AudioTrack が存在しない場合はなにもしない。
 *
 * @param room: SkyWay の SfuRoom オブジェクト
 * @param stream: 通話開始時に生成した MediaStream
 * @param indicator: 通話開始時に生成した AudioVolumeIndicator
 */
export function* watchChangingAudioDeviceAction(room: SfuRoom, stream: Stream, indicator: AudioVolumeIndicator) {
  try {
    while (true) {
      const action = yield take(Action.UPDATE_AUDIO_DEVICE);
      const { deviceId } = action.payload;

      localStorage.setItem('audioDeviceId', deviceId);

      const streamStat: StreamStat = yield select((state: RootState) => state.call.localUser?.streamStat);

      const { audioTrack, audioDeviceError }: { audioTrack: MediaStreamTrack; audioDeviceError: Error } = yield call(
        Stream.createAudioTrack,
        deviceId,
      );
      if (audioDeviceError) {
        yield put(storeMessage('マイクの取得に失敗しました。使用可能なマイクを選択してください。'));
        continue; // eslint-disable-line no-continue
      }

      audioTrack.enabled = streamStat.isAudioEnabled;

      stream.setOrReplaceAudioTrack(audioTrack);

      indicator.restartObservingVolume(stream.getMediaStream());

      room.replaceStream(stream.getMediaStream());
      yield put(updateLocalStream(stream, streamStat));
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey:
          'オーディオデバイスの更新でエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.stack,
      }),
    );
  }
}
