/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useRef, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { COLORS, popFont } from 'styles/style';
import { showNotification } from 'common/Notification';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';
import { BasicModal } from 'components/common/modal/BasicModal';
import YellowIcon from 'styles/image/icon_confirmation_yellow.svg';
import ReceiveVideoCallIcon from 'styles/image/call_receive_video.png';
import ReceiveVoiceCallIcon from 'styles/image/call_receive.png';
import { BasicButton } from 'components/common/parts/BasicButton';
import AcceptingIcon from 'styles/image/call_accept.png';
import RefusingIcon from 'styles/image/call_refuse.png';

const receivingCallSoundUrl = `${process.env.PUBLIC_URL}/se/calling.mp3`;

interface ReceivingCallModalProps {
  opened?: boolean;
  receivedInvitationList?: RemoteInvitation[];
  onAccepted?: (callerId: string) => void;
  onRefused?: (callerId: string) => void;
}

const callList = css`
  display: flex;
  flex-flow: column;
  gap: 12px;
`;

const cautionBlock = css`
  align-items: center;
  display: flex;

  .icon {
    background-image: url(${YellowIcon});
    background-position: center;
    background-repeat: no-repeat;
    height: 14px;
    margin-right: 20px;
    width: 14px;
  }
`;

export const ReceivingCallModal: FC<ReceivingCallModalProps> = ({
  opened = true,
  receivedInvitationList = [],
  onAccepted = () => {},
  onRefused = () => {},
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [displayNotification, setDisplayNotification] = useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    const audioNode = audioRef.current;
    if (audioNode != null) {
      audioNode.src = receivingCallSoundUrl;
      const playSoundEffect = () => {
        audioNode
          .play()
          .then(() => setDisplayNotification(false))
          .catch((error: Error) => {
            if (error.name === 'NotAllowedError') {
              // Safari において、「環境設定 -> 自動再生」が「全てのメディアを自動再生」になっていないと発生する
              setDisplayNotification(true);
            }
          });
      };
      playSoundEffect();
      interval = setInterval(playSoundEffect, 2000);

      if (receivedInvitationList?.length === 1) {
        const content = receivedInvitationList[0].getContent();
        showNotification({
          title: `${content?.name} さんから着信中`,
          body: `${content?.name} さんから${content?.isVoiceOnly ? '音声通話' : 'ビデオ通話'}の着信中です。`,
          silent: true,
        });
      } else {
        showNotification({ title: '複数人から着信中', body: `着信中です。`, silent: true });
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [opened, receivedInvitationList]);

  if (!opened) {
    return <div />;
  }

  return (
    <div>
      <audio ref={audioRef} preload="auto" />
      <BasicModal
        open={opened}
        header="今話したいです！"
        headerIconColor={COLORS.orange}
        contents={
          <div css={callList}>
            {receivedInvitationList?.map(invitation => {
              const content = invitation.getContent();
              const { remoteId } = invitation.getRawConnection();
              const receiveIcon = content?.isVoiceOnly ? ReceiveVoiceCallIcon : ReceiveVideoCallIcon;

              return (
                <div key={remoteId}>
                  <div css={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                    <img src={receiveIcon} css={{ height: 70, width: 70 }} alt="" />
                    <div css={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <img src={content?.iconUrl} css={{ height: 40, width: 40 }} alt="" />
                      <span css={{ fontSize: 20 }}>{content?.name}</span>
                    </div>
                  </div>
                  <div
                    css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20, marginTop: 30 }}
                  >
                    <BasicButton color="blue" onClick={() => onAccepted(remoteId)}>
                      <div css={[{ display: 'flex', alignItems: 'center', gap: 10 }, popFont]}>
                        <img src={AcceptingIcon} alt="" css={{ height: 20, width: 20 }} />
                        いいですよ！
                      </div>
                    </BasicButton>
                    <BasicButton color="red" onClick={() => onRefused(remoteId)}>
                      <div css={[{ display: 'flex', alignItems: 'center', gap: 10 }, popFont]}>
                        <img className="icon" src={RefusingIcon} alt="" css={{ height: 20, width: 20 }} />
                        後ほどお願いします。
                      </div>
                    </BasicButton>
                  </div>
                </div>
              );
            })}
            {displayNotification && (
              <span css={cautionBlock}>
                <img className="icon" src={YellowIcon} alt="" />
                ブラウザの設定により着信音の再生ができませんでした。
              </span>
            )}
          </div>
        }
      />
    </div>
  );
};
