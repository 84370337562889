/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useState, ChangeEvent } from 'react';
import { css, jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { CommonMessage } from 'reducers/common/common';
import { ContentsBoxContainerPage } from 'components/common/ContentsBoxContainerPage';
import { Spacer } from 'components/common/parts/Spacer';
import { ContentsBox } from 'components/common/ContentsBox';
import { AuthPageHeader } from './AuthPageHeader';
import { BasicMessageBox } from '../common/parts/BasicMessageBox';
import { BasicTextBox } from '../common/parts/BasicTextBox';
import { BasicButton } from '../common/parts/BasicButton';

export const pageStyle = css`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;

export const SignInAnonymously: FC<{ signInAnonymously: (userName: string) => void }> = ({ signInAnonymously }) => {
  const [userName, setUserName] = useState('');
  const commonMsg = useSelector<RootState, CommonMessage | undefined>(state => state.common.errorMsg);
  const errorMessage: string[] = [];
  if (commonMsg) errorMessage.push(String(commonMsg));

  const disabled = !userName.length;

  return (
    <div>
      <AuthPageHeader />
      <div css={pageStyle}>
        <ContentsBoxContainerPage headerDisplayed>
          <ContentsBox title="試してみる" errorMessages={errorMessage}>
            <BasicMessageBox color="blue">
              <p>営業時間中は営業担当がログインしています（10〜19時）</p>
              <p>
                「カスタマーサポート」と記載されたメンバーと音声・ビデオ通話ができますので、お気軽にお声掛けください。
              </p>
            </BasicMessageBox>
            <Spacer height="30px" />
            <BasicTextBox
              label="デモ画面に表示する名前を入力してください"
              onChange={(event: ChangeEvent<HTMLInputElement>) => setUserName(event.target.value)}
              value={userName}
            />
            <Spacer height="30px" />
            <BasicButton type="button" disabled={disabled} onClick={() => signInAnonymously(userName)}>
              デモを利用する
            </BasicButton>
          </ContentsBox>
        </ContentsBoxContainerPage>
      </div>
    </div>
  );
};
