/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import { StatusCode } from 'components/common/Statuses';
import VoiceCallIcon from 'styles/image/icon_action_tel.svg';
import VideoCallIcon from 'styles/image/icon_action_camera.svg';
import ChatIcon from 'styles/image/icon_action_chat.svg';
import { CallingUserStatus } from 'reducers/call/call';
import { NOT_AVAILABLE_MSG } from 'components/home/Home';
import { BasicIconButton } from 'components/common/parts/BasicIconButton';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { baseZIndex, COLORS, backGroundZIndex, boldFont } from 'styles/style';

const personalMainWrapCss = css`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  .personal-main {
    background: center no-repeat;
    background-size: contain;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    ${baseZIndex};

    .action-item {
      display: none;
      height: 100%;
      width: 100%;
    }
  }

  &:hover {
    .action-item {
      display: block;
    }
  }

  .no-blur {
    background-color: ${COLORS.white};
  }

  .blur::before {
    background: inherit;
    background-color: ${COLORS.white};
    bottom: -6px;
    content: '';
    filter: blur(6px);
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
    ${backGroundZIndex}
  }
`;

const actionIconCss = css`
  display: flex;
  gap: 30px;

  button {
    background-color: ${COLORS.blue};
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    padding: 10px;
    width: 40px;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      background-color: ${COLORS.gray};
    }
  }
`;

const displayCoverCss = css`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  width: 100%;

  div {
    .popup {
      max-width: 250px;
    }
  }
`;

const displayInPresenceCss = css`
  ${displayCoverCss};
  position: relative;

  ::before {
    background-color: ${COLORS.black};
    content: '';
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const displayInAbsenceCss = css`
  ${displayCoverCss};
  ${boldFont};
  background-color: ${COLORS.white};
  color: #888;
  flex-direction: column;
  font-size: 20px;
  position: relative;
  text-align: center;
`;

interface ImageViewProps {
  uid: string;
  isOwn: boolean;
  isGuestOwn: boolean;
  demoUser: boolean;
  isGuest: boolean;
  myRoomId: string;
  roomId: string;
  status: StatusCode;
  breakStartTime: number;
  snapshotUrl: string;
  isShade: boolean;
  handleOnCallClick: (targetId: string) => void;
  handleOnVideoClick: (targetId: string) => void;
  callUserStatus?: CallingUserStatus;
}

interface CallButtonProps {
  popupLabel: string;
  disabled?: boolean;
  handleOnCallClick?: () => void;
}
const VoiceCallButton = ({ popupLabel, disabled, handleOnCallClick }: CallButtonProps) => {
  return (
    <BasicPopup value={popupLabel} position="top">
      <BasicIconButton iconPath={VoiceCallIcon} pxSize={20} onClick={handleOnCallClick} disabled={disabled} />
    </BasicPopup>
  );
};

const VideoCallButton = ({ popupLabel, disabled, handleOnCallClick }: CallButtonProps) => {
  return (
    <BasicPopup value={popupLabel} position="top">
      <BasicIconButton iconPath={VideoCallIcon} pxSize={20} onClick={handleOnCallClick} disabled={disabled} />
    </BasicPopup>
  );
};
const ChatButton = () => {
  return (
    <BasicPopup value={NOT_AVAILABLE_MSG} position="top">
      <BasicIconButton iconPath={ChatIcon} pxSize={20} disabled />
    </BasicPopup>
  );
};

const ImageViewWrapper = ({
  children,
  blur,
  snapshotUrl,
}: {
  children: React.ReactNode;
  snapshotUrl: string;
  blur: string;
}) => {
  return (
    <div css={personalMainWrapCss}>
      <div className={`personal-main ${blur}`} style={{ backgroundImage: `url(${snapshotUrl})` }}>
        {children}
      </div>
    </div>
  );
};

const ImageView: FC<ImageViewProps> = ({
  uid,
  isOwn,
  isGuestOwn,
  demoUser,
  isGuest,
  myRoomId,
  roomId,
  breakStartTime,
  status,
  snapshotUrl,
  isShade,
  handleOnCallClick,
  handleOnVideoClick,
  callUserStatus,
}) => {
  const isTalking = !!(myRoomId && roomId);
  const isCalling = callUserStatus && callUserStatus === CallingUserStatus.CALLING;
  const unavailableMsg = () => {
    if (isTalking) return '通話中';
    if (isCalling) return '発信中';

    return '';
  };

  const blurCss = isShade ? 'blur' : 'no-blur';
  const breakStartTimeString = String(`0${breakStartTime}`).slice(-4);

  if (status === 'presence') {
    // 通話ができない相手の場合（デモユーザーとゲストに対しては通話不可）
    if (demoUser || isGuest || !isGuestOwn) {
      return (
        <ImageViewWrapper blur={blurCss} snapshotUrl={snapshotUrl}>
          {!isOwn && (
            <div className="action-item">
              <div css={displayInPresenceCss}>
                <div css={actionIconCss}>
                  <VoiceCallButton popupLabel={NOT_AVAILABLE_MSG} disabled />
                  <VideoCallButton popupLabel={NOT_AVAILABLE_MSG} disabled />
                  <ChatButton />
                </div>
              </div>
            </div>
          )}
        </ImageViewWrapper>
      );
    }
    if (!isGuest) {
      return (
        <ImageViewWrapper blur={blurCss} snapshotUrl={snapshotUrl}>
          {!isOwn && (
            <div className="action-item">
              <div css={displayInPresenceCss}>
                <div css={actionIconCss}>
                  <VoiceCallButton
                    popupLabel={
                      !isTalking && !isCalling
                        ? 'ここを押すとカスタマーサポートと音声通話をすることができます'
                        : unavailableMsg()
                    }
                    disabled={isCalling || isTalking}
                    handleOnCallClick={() => handleOnCallClick(uid)}
                  />
                  <VideoCallButton
                    popupLabel={
                      !isTalking && !isCalling
                        ? 'ここを押すとカスタマーサポートとビデオ通話をすることができます'
                        : unavailableMsg()
                    }
                    disabled={isCalling || isTalking}
                    handleOnCallClick={() => handleOnVideoClick(uid)}
                  />
                  <ChatButton />
                </div>
              </div>
            </div>
          )}
        </ImageViewWrapper>
      );
    }
  }
  if (status === 'break') {
    return (
      <ImageViewWrapper blur={blurCss} snapshotUrl={snapshotUrl}>
        <div css={displayInAbsenceCss}>
          <div>休憩</div>
          <div>{`${breakStartTimeString.substr(0, 2)}:${breakStartTimeString.substr(2, 2)}`}〜</div>
        </div>
      </ImageViewWrapper>
    );
  }

  return (
    <ImageViewWrapper blur={blurCss} snapshotUrl={snapshotUrl}>
      <div css={displayInAbsenceCss}>取込中</div>
    </ImageViewWrapper>
  );
};

export default ImageView;
