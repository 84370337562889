/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import ScreenIcon from 'styles/image/icon_header_screen.svg';
import CameraIcon from 'styles/image/icon_header_camera.svg';
import { NOT_AVAILABLE_MSG } from 'components/home/Home';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { HeaderIcon } from './Header';

interface SharingTeamListProps {
  sharingType: 'screen' | 'camera';
}

export const SharingTeamList: FC<SharingTeamListProps> = ({ sharingType }) => {
  return (
    <BasicPopup
      position="bottom"
      value={
        <div>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minWidth: '250px' }}>
            <div>{sharingType === 'screen' ? '画面共有' : 'カメラ共有'}</div>
          </div>
          <div css={{ marginTop: '10px' }}>{NOT_AVAILABLE_MSG}</div>
        </div>
      }
    >
      <React.Fragment>
        <HeaderIcon iconUrl={sharingType === 'screen' ? ScreenIcon : CameraIcon} disabled />
      </React.Fragment>
    </BasicPopup>
  );
};
