/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx, css } from '@emotion/core';

const soundDotsCss = css`
  @keyframes dotMove1 {
    10% {
      height: 10px;
      left: -2.5px;
      width: 10px;
    }

    20% {
      height: 4px;
      left: 0.5px;
      width: 4px;
    }

    40% {
      height: 4px;
      left: 0.5px;
      width: 4px;
    }

    50% {
      height: 10px;
      left: -2.5px;
      width: 10px;
    }

    60% {
      height: 5px;
      left: 0;
      width: 5px;
    }

    70% {
      height: 0;
      left: 2.5px;
      width: 0;
    }
  }

  @keyframes dotMove2 {
    10% {
      height: 8px;
      left: 8px;
      width: 8px;
    }

    20% {
      height: 4px;
      left: 10.5px;
      width: 4px;
    }

    40% {
      height: 4px;
      left: 10.5px;
      width: 4px;
    }

    50% {
      height: 8px;
      left: 8px;
      width: 8px;
    }

    60% {
      height: 5px;
      left: 10px;
      width: 5px;
    }

    70% {
      height: 0;
      left: 12.5px;
      width: 0;
    }
  }

  @keyframes dotMove3 {
    10% {
      height: 8px;
      left: 18px;
      width: 8px;
    }

    20% {
      height: 4px;
      left: 20.5px;
      width: 4px;
    }

    40% {
      height: 4px;
      left: 20.5px;
      width: 4px;
    }

    50% {
      height: 8px;
      left: 18px;
      width: 8px;
    }

    60% {
      height: 5px;
      left: 20px;
      width: 5px;
    }

    70% {
      height: 0;
      left: 22.5px;
      width: 0;
    }
  }

  .sound-dots-wrap {
    background-color: rgba(24, 23, 31, 0.8);
    border-radius: 15px;
    bottom: 2px;
    display: none;
    left: 5px;
    padding: 13px 0 5px 20px;
    position: absolute;
    width: 65px;

    .sound-dots {
      position: relative;

      span {
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-color: #44a340;
        border-radius: 50%;
        bottom: 0;
        display: block;
        height: 4px;
        position: absolute;
        width: 4px;

        &:nth-of-type(1) {
          animation-delay: 0s;
          animation-name: dotMove1;
          left: 0;
        }

        &:nth-of-type(2) {
          animation-delay: 0.1s;
          animation-name: dotMove2;
          left: 10px;
        }

        &:nth-of-type(3) {
          animation-delay: 0.2s;
          animation-name: dotMove3;
          left: 20px;
        }
      }
    }
  }
`;

const SpeakerAnimation: FC = () => {
  return (
    <div css={soundDotsCss}>
      <div className="sound-dots-wrap">
        <div className="sound-dots">
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );
};
export default SpeakerAnimation;
