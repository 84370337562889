/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { css, jsx } from '@emotion/core';
import { COLORS, rounded } from 'styles/style';
import ChatDisabledIcon from 'styles/image/icon_video_chat_disabled.svg';
import WhisperIcon from 'styles/image/icon_video_whisper_bk.svg';
import WhisperDisabledIcon from 'styles/image/icon_video_whisper_disabled.svg';
import ZoomInIcon from 'styles/image/icon_video_zoomin_bk.svg';
import ZoomOutIcon from 'styles/image/icon_video_zoomout_bk.svg';
import { CallingUser, CallingUserStatus } from 'reducers/call/call';
import { Popup } from 'semantic-ui-react';
import { NOT_AVAILABLE_MSG } from 'components/home/Home';

const centerPlaced = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

// Semantic UI React の Popup で、トリガーを非活性ボタンにするとポップアップがクリックするまで消えなくなる問題のワークアラウンド。
// ボタン自体の pointer-events を消して、更にトリガー要素を div でラップすることで回避できる。
// see: https://github.com/Semantic-Org/Semantic-UI-React/issues/3975
const workaround = css`
  &:disabled {
    pointer-events: none;
  }
`;

const button = css`
  ${workaround}
  ${rounded};
  ${centerPlaced};
  background-color: ${COLORS.lightGray};
  border: solid 1px #707070;
  height: 30px;
  outline: none;
  width: 30px;

  &:enabled {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

type ButtonProps = JSX.IntrinsicElements['button'] & { popupContent: JSX.Element; iconPath: string };

/**
 * 各通話メンバー上部に配置されるアクションボタン。

 * BasicPopup を利用すると最上部のメンバーや右端のメンバーのポップアップ表示が見切れてしまう。
 * そのため、BasicPopup を Semantic UI に置き換えようとしたが、矢印部分の色を動的に変更することが不可能だったので、
 * ここだけ例外的に Semantic UI を利用する。
 *
 * see: https://github.com/Semantic-Org/Semantic-UI-React/issues/2282
 */
const PersonalAreaButton = ({ popupContent, iconPath, ...buttonProps }: ButtonProps) => {
  return (
    <Popup
      trigger={
        <div>
          <button css={button} type="button" {...buttonProps}>
            <img css={{ height: 20, width: 20 }} src={iconPath} alt="" />
          </button>
        </div>
      }
      style={{
        backgroundColor: COLORS.lightGray,
        border: `solid 1px ${COLORS.darkGray}`,
        borderRadius: 4,
        boxShadow: 'none',
        color: COLORS.darkGray,
        maxWidth: 300,
        padding: '10px 14px',
        wordWrap: 'break-word',
      }}
      position="top center"
      basic
    >
      {popupContent}
    </Popup>
  );
};

type Props = {
  currentUserId: string;
  user: CallingUser;
  handleOnExpandBtnClicked: () => void;
  handleOnWhisperBtnClicked: () => void;
  whisperTargetUid: string | undefined;
  isExpandedUser: boolean;
  scaleButtonDisplayed: boolean;
};

export const PersonalAreaButtons = ({
  currentUserId,
  user,
  handleOnExpandBtnClicked,
  handleOnWhisperBtnClicked,
  whisperTargetUid,
  isExpandedUser,
  scaleButtonDisplayed,
}: Props) => {
  const whisperIconDisplayed = user.uid !== currentUserId;
  const whisperEnabled = user.status === CallingUserStatus.JOINED && whisperTargetUid === undefined;

  return (
    <div css={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
      <PersonalAreaButton popupContent={<div>{NOT_AVAILABLE_MSG}</div>} iconPath={ChatDisabledIcon} disabled />
      {whisperIconDisplayed && (
        <PersonalAreaButton
          popupContent={<div>ひそひそ通話を開始する</div>}
          iconPath={whisperEnabled ? WhisperIcon : WhisperDisabledIcon}
          disabled={!whisperEnabled}
          onClick={() => handleOnWhisperBtnClicked()}
        />
      )}
      {scaleButtonDisplayed && (
        <PersonalAreaButton
          popupContent={isExpandedUser ? <div>拡大表示を解除する</div> : <div>拡大表示する</div>}
          iconPath={isExpandedUser ? ZoomOutIcon : ZoomInIcon}
          onClick={() => handleOnExpandBtnClicked()}
        />
      )}
    </div>
  );
};
