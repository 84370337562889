/** @jsx jsx */
import { FC } from 'react';
import { css, jsx } from '@emotion/core';
import ProductIcon from 'styles/image/product_icon_small_white.svg';
import { COLORS, fontSizeSubTitle, roundedBottom, roundedTop } from 'styles/style';
import { Spacer } from 'components/common/parts/Spacer';
import { BasicMessageBox } from 'components/common/parts/BasicMessageBox';

const containerCss = css`
  width: 960px;
`;

const titleCss = css`
  ${roundedTop};
  ${fontSizeSubTitle};
  align-items: center;
  background-color: ${COLORS.blue};
  color: ${COLORS.white};
  display: flex;
  height: 60px;
  padding: 20px 34px;

  img {
    height: 20px;
  }
`;

const bodyCss = css`
  ${roundedBottom};
  background-color: ${COLORS.white};
  padding: 50px 34px;
`;

interface Props {
  title: string;
  errorMessages?: string[];
  successMessages?: string[];
}

/**
 * コンテンツボックス
 * 子コンポーネントがコンテンツボディになります。
 *
 * @param title - タイトル (必須)
 * @param errorMessage - コンテンツ上部に表示するエラーメッセージ (任意)
 * @param successMessages - コンテンツ上部に表示する成功メッセージ (任意)
 * @example
 * ```
 * <ContentsBox title="タイトルです">
 *   <div>本文です。</div>
 * </ContentsBox>
 * ```
 */
export const ContentsBox: FC<Props> = ({ title, errorMessages = [], successMessages = [], children }) => {
  return (
    <div css={containerCss}>
      <div css={titleCss}>
        <img src={ProductIcon} alt="" />
        <Spacer width="15px" />
        <div>{title}</div>
      </div>
      <div css={bodyCss}>
        {errorMessages.length > 0 && (
          <div css={{ marginBottom: '30px' }}>
            <BasicMessageBox color="red">
              {errorMessages.map(m => (
                <div key={m}>{m}</div>
              ))}
            </BasicMessageBox>
          </div>
        )}
        {successMessages.length > 0 && (
          <div css={{ marginBottom: '30px' }}>
            <BasicMessageBox color="blue">
              {successMessages.map(m => (
                <div key={m}>{m}</div>
              ))}
            </BasicMessageBox>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
