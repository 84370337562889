/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useRef } from 'react';
import { jsx, css } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { storeDummyVideoTrack } from 'actions/call/call';

/**
 * 202010 現在、HTMLCanvasElement に captureStream が未実装なので独自で定義
 */
interface CanvasElement extends HTMLCanvasElement {
  captureStream(frameRate?: number): MediaStream;
}

const canvasCss = css`
  height: 1px;
  width: 1px;
`;

/**
 * SkyWay の制約 (https://webrtc.ecl.ntt.com/api-reference/javascript.html#methods-5) を乗り越えるために
 * 音声通話の場合でもダミーの映像を送信して対応する。
 */
export const DummyStreamCreator: FC = () => {
  const canvasRef = useRef<CanvasElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      // Firefox 81 では getContext のあとに captureStream を呼ばないと NS_ERROR_NOT_INITIALIZED エラーが発生する。
      // 参考: https://github.com/w3c/mediacapture-fromelement/issues/31
      // この issue は古い + クローズ済みではあるものの、実際に getContext を呼んだあとはエラーが発生しなくなった。
      canvas.getContext('2d');
      const dummy = canvas.captureStream(1);
      const dummyVideoTrack = dummy.getVideoTracks()[0];
      dummyVideoTrack.stop();
      dispatch(storeDummyVideoTrack(dummyVideoTrack));
    }
  }, [dispatch]);

  return <canvas css={canvasCss} ref={canvasRef} hidden />;
};
