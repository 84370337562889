/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocationActions, LocationCode } from 'actions/home/updateLocation';
import { RootState } from 'reducers/mainReducer';
import { Location } from 'components/home/Location';

const UpdateLocationContainer: FC<{ isOwn: boolean; locationCode: LocationCode }> = ({ isOwn, locationCode }) => {
  const dispatch = useDispatch();
  const currentLocation = useSelector<RootState, LocationCode>(
    state => state.homeSubscriber.currentUserStatus.location,
  );

  const updateLocation = (location: LocationCode) => {
    if (location !== currentLocation) {
      dispatch(updateLocationActions.start(location));
    }
  };

  return <Location updateLocation={updateLocation} locationCode={locationCode} isOwn={isOwn} />;
};

export default UpdateLocationContainer;
