/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { clearMessages } from 'actions/call/call';
import { CallNotification } from 'components/call/modal/CallNotification';

export const CallNotificationContainer: FC = () => {
  const messages = useSelector<RootState, string[]>(state => state.call.uiState.msgKeys);
  const open = messages.length > 0;
  const dispatch = useDispatch();

  if (open) {
    return <CallNotification msgKeys={messages} onClose={() => dispatch(clearMessages())} />;
  }

  return <div />;
};
