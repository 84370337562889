/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import CallingLabel from 'components/home/CallingLabel';
import { MemberData } from 'components/home/MemberView';
import { COLORS } from 'styles/style';

const colors = [
  COLORS.darkPink,
  COLORS.orange,
  COLORS.teal,
  COLORS.yellow,
  COLORS.green,
  COLORS.red,
  COLORS.brown,
  COLORS.blue,
];

export interface CallingLabelProps {
  roomId: string;
  memberDataArray: MemberData[];
}

const CallingLabelContainer: FC<CallingLabelProps> = ({ roomId, memberDataArray }) => {
  const roomMemberNames: string[] = [];
  if (roomId) {
    const roomMember = memberDataArray.filter(m => m.roomId === roomId);
    roomMember.forEach(member => {
      roomMemberNames.push(member.name);
    });
    roomMemberNames.sort();
  }

  // すべての Room を配列に入れ重複を排除し、その配列のインデックスをもとに色を設定
  // REVISIT: 新しい Room が増えたタイミングで自分の部屋の色が切り替わる可能性があるが、大きな影響はないのでひとまずこのまま
  const roomIds: string[] = [];
  memberDataArray.forEach(data => {
    if (data.roomId) {
      roomIds.push(data.roomId);
    }
  });
  const distinctRoomIds = roomIds.filter((x, i, self) => self.indexOf(x) === i);
  const myRoomColor = distinctRoomIds.findIndex(id => id === roomId);

  return (
    <CallingLabel roomId={roomId} roomMemberNames={roomMemberNames} labelColor={colors[myRoomColor % colors.length]} />
  );
};

export default CallingLabelContainer;
