import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const analytics = firebase.analytics();
export const db = firebase.firestore();
export const realtimeDb = firebase.database();
export const functions = app.functions('asia-northeast1');
export const createSkyWayCredential = functions.httpsCallable('createSkyWayCredential');
export const callInquireInvitation = functions.httpsCallable('invitation-inquire');

export const callJoinWorkboard = functions.httpsCallable('joinWorkboard');
export const callFetchCallableMembers = functions.httpsCallable('fetchCallableMembers');
export const callCheckSalesDomain = functions.httpsCallable('checkSalesDomain');

export const callGetStorageFileUrl = functions.httpsCallable('fileSharing-getStorageFileUrl');
export const callDeleteStorageFile = functions.httpsCallable('fileSharing-deleteStorageFile');
export const callRelocateStorageFile = functions.httpsCallable('fileSharing-relocateStorageFile');

export default firebase;
