/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useCallback, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import firebase, { realtimeDb } from 'firebase/Instances';

/**
 * ユーザのオンライン状況を管理するコンポーネント。
 *
 * 1. 複数タブも考慮したオンライン / オフラインの状態変更
 * 2. 切断時の処理が動かなかった場合の暫定対処 (詳細は下記)
 *
 * などをしている。 Firestore の detect online presence 拡張がリリースされた場合はそちらの動作を確認し、不要であればこれらの処理は削除してください。
 */
export const OnlineStatusManager: FC = () => {
  const uid = useSelector<RootState, string>(state => state.homeSubscriber.currentUserStatus.uid);
  const myConnectionRef = realtimeDb.ref(`/users/${uid}/connection`);
  const userStatusDbRef = realtimeDb.ref(`/statuses/${uid}`);
  const connectedRef = realtimeDb.ref('.info/connected');

  // オンライン / オフラインの状態変更を実施する useEffect
  useEffect(() => {
    if (uid) {
      // 接続状態を保持している .info/connected にリスナーを張る
      connectedRef.on('value', async snapshot => {
        if (snapshot.val() === true) {
          const connectionId = uuidv4();
          // 接続状態になった場合は user.connection に ID を記録 + status.isOnline を true にする
          await myConnectionRef.set({ connectionId, connectedAt: firebase.database.ServerValue.TIMESTAMP });
          await userStatusDbRef.set({ isOnline: true, lastChanged: firebase.database.ServerValue.TIMESTAMP });

          const [myConnectionOnDisconnect, statusOnDisconnect] = await Promise.all([
            myConnectionRef.onDisconnect(),
            userStatusDbRef.onDisconnect(),
          ]);

          myConnectionRef.on('value', async snap => {
            const data = snap.val();
            if (data !== null) {
              if (data.connectionId === connectionId) {
                // 切断時には status.idOnline を false にする + connectionId を消去するよう設定しておく
                await Promise.all([
                  statusOnDisconnect.set({ isOnline: false, lastChanged: firebase.database.ServerValue.TIMESTAMP }),
                  myConnectionOnDisconnect.set(null),
                ]);
              } else {
                // 別タブなどによって connectionId が上書きされた場合は上記の切断時の処理をキャンセルする
                // SkyWay へのログインが後勝ちであるため、こちらも後勝ちとしている。
                await Promise.all([statusOnDisconnect.cancel(), myConnectionOnDisconnect.cancel()]);
              }
            }
          });
        } else {
          myConnectionRef.off();
        }
      });
    }

    return () => {
      connectedRef.off();
      myConnectionRef.off();
    };
  }, [connectedRef, myConnectionRef, userStatusDbRef]); // eslint-disable-line react-hooks/exhaustive-deps

  // 切断時の処理が動かなかった場合の暫定対処を実施する useEffect。
  // 稀にアプリを閉じてもオフラインに戻らない問題の対処のために、 Functions の correctOnlineStatus にて、
  // 10 分以上更新がないユーザを強制的にオフラインに更新している。
  // アプリを開いている際には 5 分毎に更新し、Functions で拾われないようにしている。
  const setUsers = useCallback(async () => {
    await userStatusDbRef.set({ isOnline: true, lastChanged: firebase.database.ServerValue.TIMESTAMP });
  }, [userStatusDbRef]);

  useEffect(() => {
    const timerId = setInterval(setUsers, 5 * 60 * 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [setUsers]);

  return <div />;
};
