/** @jsx jsx */
import { FC, useMemo } from 'react';
import { jsx, css } from '@emotion/core';
import { COLORS, Colors, modalZIndex, overlayZIndex, rounded } from 'styles/style';
import { BasicButton } from 'components/common/parts/BasicButton';
import CheckIcon from 'styles/image/check_no_circle.svg';

const mainCss = css`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-weight: normal;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  ${overlayZIndex};
`;

const contentsCss = css`
  ${rounded}
  background: ${COLORS.white};
  max-height: 80%;
  overflow: auto;
  padding: 30px;
  width: 600px;
  ${modalZIndex};
`;

const headerCss = css`
  align-items: center;
  border-bottom: 1px solid ${COLORS.gray};
  display: flex;
  margin-bottom: 20px;
  padding: 3px 0 20px;

  .header-name {
    font-size: 20px;
  }
`;

export const buttonCss = css`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;

  .button-item {
    align-items: center;
    display: flex;
  }

  .icon {
    height: 12px;
    margin-right: 8px;
    width: 12px;
  }
`;

const iconCss = (iconColor: Colors) => css`
  height: 20px;
  margin-right: 10px;
  width: 20px;

  .bg {
    fill: ${iconColor};
  }

  .mark {
    fill: ${COLORS.white};
  }
`;
const closeIconCss = css`
  .red {
    fill: ${COLORS.white};
  }

  .white {
    fill: ${COLORS.darkGray};
  }
`;

interface BasicModalButtonProps {
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  okButtonColor?: 'blue' | 'red';
  cancelButtonColor?: 'red' | 'white';
  handleOnOkClicked?: () => void;
  handleOnCancelClicked?: () => void;
}
export const BasicModalButton: FC<BasicModalButtonProps> = ({
  handleOnOkClicked,
  okButtonLabel,
  okButtonColor = 'blue',
  handleOnCancelClicked,
  cancelButtonLabel,
  cancelButtonColor = 'red',
}) => {
  const closeIcon = useMemo(() => {
    return (
      <div className="button-item" css={closeIconCss}>
        <svg
          className="icon"
          id="レイヤー_1"
          data-name="レイヤー 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <polygon
            id="バツ"
            className={cancelButtonColor}
            points="100 13.19 86.8 0 50 36.8 13.19 0 0 13.19 36.8 50 0 86.8 13.19 100 50 63.19 86.8 100 100 86.8 63.19 50 100 13.19"
          />
        </svg>
      </div>
    );
  }, [cancelButtonColor]);

  return (
    <div css={buttonCss}>
      {handleOnOkClicked && (
        <BasicButton color={okButtonColor} onClick={() => handleOnOkClicked()}>
          <div className="button-item">
            <img className="icon" src={CheckIcon} alt="" />
            {okButtonLabel}
          </div>
        </BasicButton>
      )}
      {handleOnCancelClicked && (
        <BasicButton color={cancelButtonColor} onClick={() => handleOnCancelClicked()}>
          <div className="button-item">
            {closeIcon}
            {cancelButtonLabel}
          </div>
        </BasicButton>
      )}
    </div>
  );
};

interface BasicModalProps extends BasicModalButtonProps {
  open: boolean;
  header: string;
  headerIconType?: 'info' | 'attention';
  headerIconColor?: Colors;
  contents: string | JSX.Element;
}

/**
 * 共通モーダル
 * @param open - オープン状態
 * @param header - ヘッダ
 * @param headerIconType - 任意。ヘッダアイコンの形 info, attention から選択 デフォルトは info
 * @param headerIconColor - 任意。ヘッダアイコン色 COLORSの中から選択 デフォルトは blue
 * @param contents - 内容
 * @param okButtonLabel - 任意。OKボタンラベル デフォルトは OK
 * @param okButtonColor - 任意。OKボタン色 blue red から選択 デフォルトは blue
 * @param handleOnOkClicked - 任意。 OKボタンonClick
 * @param cancelButtonLabel - 任意。キャンセルボタンラベル デフォルトは キャンセル
 * @param cancelButtonColor - 任意。キャンセルボタン色 red white から選択 デフォルトは red
 * @param handleOnCancelClicked - 任意。 キャンセルボタンonClick 省略すると1ボタンになります。
 * @returns モーダルを表示
 */
export const BasicModal: FC<BasicModalProps> = ({
  open,
  header,
  headerIconType = 'info',
  headerIconColor = COLORS.blue,
  contents,
  okButtonLabel = 'OK',
  okButtonColor = 'blue',
  handleOnOkClicked,
  cancelButtonLabel,
  cancelButtonColor = 'red',
  handleOnCancelClicked,
}) => {
  const headerIcon = useMemo(() => {
    return (
      <div css={iconCss(headerIconColor)}>
        {headerIconType === 'attention' ? (
          <svg id="注意" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.86 91.32">
            <path
              id="三角形"
              className="bg"
              d="M42.7,6.76l-41,74.5A8.4,8.4,0,0,0,9.05,93.72h82a8.41,8.41,0,0,0,7.37-12.46l-41-74.5A8.41,8.41,0,0,0,42.7,6.76Z"
              transform="translate(-0.63 -2.41)"
            />
            <g id="ビックリマーク">
              <path
                className="mark"
                d="M42.37,29.15H57.66V42.07L54.71,68H45.25L42.37,42.07ZM42.9,72H57.17V85.85H42.9Z"
                transform="translate(-0.63 -2.41)"
              />
            </g>
          </svg>
        ) : (
          <svg id="確認アイコン" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 99">
            <circle className="bg" cx="49.5" cy="49.5" r="49.5" />
            <path
              id="ビックリマーク上"
              className="mark"
              d="M45.43,63.06a4.27,4.27,0,0,0,1.41,3,4.6,4.6,0,0,0,6.22,0,4.22,4.22,0,0,0,1.4-3l2.81-33.61.1-6a6.92,6.92,0,0,0-2.1-5.32,7.41,7.41,0,0,0-10.54,0,6.92,6.92,0,0,0-2.1,5.32l.1,6Z"
              transform="translate(-0.5 -0.5)"
            />
            <path
              id="ビックリマーク下"
              className="mark"
              d="M55.52,72.62a8.22,8.22,0,0,0-11,0A7.12,7.12,0,0,0,42.22,78a7,7,0,0,0,2.26,5.35,8.33,8.33,0,0,0,11,0A7,7,0,0,0,57.78,78,7.12,7.12,0,0,0,55.52,72.62Z"
              transform="translate(-0.5 -0.5)"
            />
          </svg>
        )}
      </div>
    );
  }, [headerIconColor, headerIconType]);

  return (
    <div>
      {open && (
        <div css={mainCss}>
          <div css={contentsCss}>
            <div css={headerCss}>
              {headerIcon}
              <div className="header-name">{header}</div>
            </div>
            <div>{contents}</div>
            {(handleOnOkClicked || handleOnCancelClicked) && (
              <BasicModalButton
                handleOnOkClicked={handleOnOkClicked}
                handleOnCancelClicked={handleOnCancelClicked}
                okButtonLabel={okButtonLabel}
                okButtonColor={okButtonColor}
                cancelButtonLabel={cancelButtonLabel || 'キャンセル'}
                cancelButtonColor={cancelButtonColor}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
