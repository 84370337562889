/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import IconCamera from 'styles/image/icon_action_camera.svg';
import IconCameraGray from 'styles/image/icon_action_camera_gray.svg';
import { CallButtonProps } from './CallButton';

const videoButtonCss = css`
  display: inline-block;

  .call-button {
    background-color: #006fcd;
    background-image: url(${IconCamera});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    outline: none;
    width: 20px;

    &.unable {
      background-color: #cecece;
      background-image: url(${IconCameraGray});
      cursor: not-allowed;
    }
  }
`;

export const VideoButton: FC<CallButtonProps> = ({ onClick, available }) => {
  return (
    <div css={videoButtonCss}>
      <button
        type="button"
        className={`call-button video ${!available && 'unable'}`}
        onClick={onClick}
        disabled={!available}
      >
        &nbsp;
      </button>
    </div>
  );
};
