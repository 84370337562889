/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx, css } from '@emotion/core';

const buttonStyle = css`
  img {
    width: 240px;
  }

  .signin-img {
    background-color: #eee;
    border: none;
    border-radius: 3px;

    &:hover {
      background-color: #b4c8e8;
    }
  }
`;

const banner = `${process.env.PUBLIC_URL}/images/btn_google_signin_light_normal_web@2x.png`;

const SignInGoogle: FC<{ signInWithGoogle: () => void }> = ({ signInWithGoogle = () => {} }) => {
  return (
    <div css={buttonStyle}>
      <button type="button" onClick={signInWithGoogle} className="signin-img" data-testid="signInWithGoogleButton">
        <img src={banner} alt="google login" />
      </button>
    </div>
  );
};
export default SignInGoogle;
