import { call, cancelled, put, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { CallAction, storeCallError, updateLocalUserSpeaking } from 'actions/call/call';
import { AudioVolumeIndicator } from 'sagas/call/classes/AudioVolumeIndicator';

const subscribe = (indicator: AudioVolumeIndicator) =>
  eventChannel(emitter => {
    indicator.on('speaking', () => {
      emitter(updateLocalUserSpeaking(true));
    });

    indicator.on('stopped_speaking', () => {
      emitter(updateLocalUserSpeaking(false));
    });

    return async () => {
      await indicator.closeIndicator();
    };
  });

/**
 * 自身の声の音量を購読する。
 *
 * @param indicator 通話開始時の MediaStream から生成した AudioVolumeIndicator
 */
export function* subscribeLocalVolume(indicator: AudioVolumeIndicator) {
  const channel = yield call(subscribe, indicator);
  try {
    while (true) {
      const action: CallAction = yield take(channel);
      yield put(action);
    }
  } catch (error) {
    yield put(storeCallError({ msgKey: '音量計算処理でエラーが発生しました。', detail: error.message }));
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}
