/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { AddMemberModal } from 'components/call/modal/AddMemberModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { setAddMemberModalOpened } from 'actions/call/call';
import { useCallableMembers } from 'containers/call/modal/useCallableMembers';

export const AddMemberModalContainer: FC = () => {
  const open = useSelector<RootState, boolean>(state => state.call.uiState.addMemberModal.opened);
  const [callableMembers, loading, reload, error] = useCallableMembers();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setAddMemberModalOpened(false));
  };

  if (open) {
    return (
      <AddMemberModal
        onClose={closeModal}
        onReload={reload}
        callableMembers={callableMembers}
        loading={loading}
        error={error}
      />
    );
  }

  return <div />;
};
