/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';

import { useDispatch, useSelector } from 'react-redux';
import {
  setWorkboardOpenedAction,
  sendTextChatActions,
  setTextChatScrollPoint,
  clearTextChatNotice,
} from 'actions/call/workboard';
import { RootState } from 'reducers/mainReducer';
import { ChatMessage } from 'reducers/call/workboard';
import { TextChat } from 'components/call/workboard/TextChat/index';
import { CallingUser } from 'reducers/call/call';

export const TextChatContainer: FC = () => {
  const dispatch = useDispatch();
  const currentUserId = useSelector<RootState, string>(state => state.homeSubscriber.currentUserStatus.uid);
  const chatMessage = useSelector<RootState, ChatMessage[]>(state => state.workboard.uiState.chatMessage);
  const chatAddNotice = useSelector<RootState, boolean>(state => state.workboard.uiState.chatAddNotice);
  const scrollPoint = useSelector<RootState, number>(state => state.workboard.uiState.chatScrollPoint);
  const chatRef = useRef<HTMLDivElement>(null);
  const chatEndRef = useRef<HTMLDivElement>(null);
  // iconUrl取得用
  const localUser = useSelector<RootState, CallingUser | undefined>(state => state.call.localUser);
  const remoteUsers = useSelector<RootState, CallingUser[]>(state => state.call.remoteUsers);

  // マウント時に前回スクロール位置に移動する
  useEffect(() => {
    const currentChatRef = chatRef.current;
    if (currentChatRef) {
      currentChatRef.scrollTo(0, scrollPoint);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 新しいチャットが来たらスクロール位置を下に移動する
  useEffect(() => {
    const currentChatEndRef = chatEndRef.current;
    if (chatAddNotice) {
      if (currentChatEndRef) {
        currentChatEndRef.scrollIntoView({
          behavior: 'auto',
          block: 'end',
        });
      }
      dispatch(clearTextChatNotice());
    }
  }, [chatAddNotice]); // eslint-disable-line react-hooks/exhaustive-deps

  const setPoint = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget as HTMLDivElement;
    dispatch(setTextChatScrollPoint(target.scrollTop));
  };

  return (
    <TextChat
      currentUserId={currentUserId}
      currentUserIconUrl={localUser?.iconUrl}
      remoteUsers={remoteUsers}
      chatMessage={chatMessage}
      sendTextChat={(text: string) => dispatch(sendTextChatActions.start(text))}
      handleOnCloseTextChat={() => dispatch(setWorkboardOpenedAction.textChat(false))}
      chatRef={chatRef}
      setPoint={setPoint}
      chatEndRef={chatEndRef}
    />
  );
};
