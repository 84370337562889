/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { Global, jsx } from '@emotion/core';
import { globalStyle } from 'styles/style';
import { RouteManager } from 'RouteManager';
import { requestNotificationPermission } from 'common/Notification';
import ConnectionInitializer from 'containers/connection/ConnectionInitializer';
import { RoomSoundEffect } from 'components/call/RoomSoundEffect';
import { ReceivingCallModalContainer } from 'containers/call/modal/ReceivingCallModal';
import { ErrorNotifyModalContainer } from 'containers/call/modal/ErrorNotifyModal';
import AuthErrorNotifyModalContainer from 'containers/auth/modal/ErrorNotifyModal';
import { ReconnectionModalContainer } from 'containers/call/modal/ReconnectionModal';
import { DummyStreamCreator } from 'components/call/DummyStreamCreator';
import { ServiceLoginManager } from 'components/auth/ServiceLoginManager';
import { SplashContainer } from 'containers/common/Splash';
import { ReceivedCallHistoryModalContainer } from 'containers/call/modal/ReceivedCallHistoryModal';
import { HtmlHeaderManager } from 'components/common/HtmlHeaderManager';
import { ReceivingWhisperModalContainer } from 'containers/call/modal/ReceivingWhisperModal';
import { SendingWhisperInvitationModalContainer } from 'containers/call/modal/SendingWhisperInvitationModal';
import { VoiceContainer } from 'containers/call/Voice';
import { OnlineStatusManager } from 'components/auth/OnlineStatusManager';
import { MaintenanceChecker } from 'containers/auth/MaintenanceChecker';
import { Background } from 'components/common/Background';

const App: FC = () => {
  const startDate = new Date().getTime().toString();
  localStorage.setItem('duplicate', '0');
  localStorage.setItem('startDate', startDate);
  sessionStorage.setItem('startDate', startDate);

  requestNotificationPermission();

  return (
    <div>
      <Global styles={globalStyle} />
      <Background />
      <SplashContainer />
      <HtmlHeaderManager />
      <MaintenanceChecker>
        <RouteManager />
        <ServiceLoginManager>
          <OnlineStatusManager />
          <ConnectionInitializer />
          <RoomSoundEffect />
          <DummyStreamCreator />
          <ReceivingCallModalContainer />
          <ReceivingWhisperModalContainer />
          <SendingWhisperInvitationModalContainer />
          <ReceivedCallHistoryModalContainer />
          <ErrorNotifyModalContainer />
          <AuthErrorNotifyModalContainer />
          <ReconnectionModalContainer />
          <VoiceContainer />
        </ServiceLoginManager>
      </MaintenanceChecker>
    </div>
  );
};

export default App;
