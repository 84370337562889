/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import { User } from 'components/home/Home';
import IconTeamMember from 'styles/image/icon_header_member.svg';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { textOverflowEllipsis } from 'styles/style';
import { HeaderIcon } from './Header';

const teamMemberCss = css`
  margin: 2px 0;
`;

const memberMarkCss = css`
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 0.8em;
  margin-right: 4px;
  width: 0.8em;
`;

const onlineMemberCss = css`
  ${teamMemberCss}
  font-weight: bold;

  ::before {
    ${memberMarkCss};
    background-color: #0072b2;
  }
`;

const offlineMemberCss = css`
  ${teamMemberCss}
  color: #ddd;

  ::before {
    ${memberMarkCss};
    background-color: #1b1c1d;
    border: 2px solid #888;
  }
`;
export interface MemberProps {
  uid: string;
  teamMemberName: string;
  isOnline: boolean;
  status: string;
  isTalking: boolean;
}

const OnlineMember: FC<{ onlineMembers: User[] }> = ({ onlineMembers }) => {
  return (
    <div
      css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      data-testid="headerTeamMembersIcon"
    >
      <BasicPopup
        position="bottom"
        value={
          <div css={{ display: 'content' }}>
            {onlineMembers.map(onlineMember => (
              <div
                css={[onlineMember.isOnline ? onlineMemberCss : offlineMemberCss, textOverflowEllipsis]}
                key={onlineMember.uid}
              >
                {onlineMember.name}
              </div>
            ))}
          </div>
        }
      >
        <HeaderIcon iconUrl={IconTeamMember} />
      </BasicPopup>
      <div css={{ marginLeft: '5px' }} data-testid="headerTeamMembersCount">
        {onlineMembers.length}
      </div>
    </div>
  );
};

export default OnlineMember;
