import { css } from '@emotion/core';

/* colors */
export const COLORS = {
  blue: '#006fcd',
  red: '#cc1d14',
  navy: '#00437b',
  white: '#fff',
  brown: '#9d1913',
  paleBlue: '#d7eafa',
  black: '#000',
  darkGray: '#666',
  gray: '#ccc',
  lightGray: '#f1f1f1',
  orange: '#ffa200',
  teal: '#18ae90',
  yellow: '#ffd043',
  green: '#77ca4f',
  lightBlue: '#11c3ec',
  darkPink: '#ee5e63',
} as const;
export type Colors = typeof COLORS[keyof typeof COLORS];

/* scroll bar */
export const verticalScrollBar = css`
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${COLORS.gray};
    border: none;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.darkGray};
    border-radius: 10px;
    box-shadow: none;
  }
`;

// ベースよりも横幅を広くして目立たせる
export const inlineContainerScrollBar = css`
  ${verticalScrollBar};

  ::-webkit-scrollbar {
    width: 15px;
  }
`;

/* font styles */
export const basicFontStyle = css`
  font-size: 14px;
  font-family: 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Meiryo UI', 'メイリオ', 'Meiryo', Arial,
    sans-serif;
  color: ${COLORS.darkGray};
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

// 着信モーダル、着信拒否時などのポップなフォント
const popFontUrl = `${process.env.PUBLIC_URL}/fonts/Corporate-Logo-Medium-ver2.woff`;
// noinspection Stylelint,CssNoGenericFontName
export const popFont = css`
  @font-face {
    font-family: 'popFont';
    src: url(${popFontUrl}) format('woff');
  }

  font-family: 'popFont';
`;

export const fontSizeTitle = css`
  font-size: 26px;
`;

export const fontSizeSubTitle = css`
  font-size: 20px;
`;

export const fontSizePlaceholder = css`
  font-size: 12px;
`;

export const boldFont = css`
  font-weight: bold;
`;

/* round */
export const roundedTopLeft = css`
  border-top-left-radius: 4px;
`;

export const roundedTopRight = css`
  border-top-right-radius: 4px;
`;

export const roundedTop = css`
  ${roundedTopLeft};
  ${roundedTopRight};
`;

export const roundedBottomLeft = css`
  border-bottom-left-radius: 4px;
`;

export const roundedBottomRight = css`
  border-bottom-right-radius: 4px;
`;

export const roundedBottom = css`
  ${roundedBottomLeft};
  ${roundedBottomRight};
`;

export const rounded = css`
  ${roundedTop};
  ${roundedBottom};
`;

/* button */
export const buttonStyle = css`
  ${fontSizePlaceholder};
  cursor: pointer;
  transition: all 0.3s;
`;

/* ボタンをクリックしたときにふわっと広がるインタラクション */
export const buttonInteraction = css`
  animation: 1s circleAnime forwards;
  background-color: rgb(100, 100, 100, 0.5);
  border-radius: 50%;
  box-sizing: inherit;
  content: '';
  height: 200px;
  left: 0;
  position: absolute;
  top: -75px;
  width: 100%;
`;

/* 波形が広がるアニメーション
アニメーションを使用するCSS内にインポートする */
export const buttonAnimation = css`
  @keyframes circleAnime {
    0% {
      transform: scale(0.5);
    }

    100% {
      opacity: 0;
      transform: scale(3);
    }
  }
`;

// 表示文字列が長くなった場合に省略して `…` を表示
export const textOverflowEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// チーム名を並べて表示するときなどに使用する、薄グレー地の角丸ラベル
export const roundsGrayLabel = css`
  background-color: ${COLORS.lightGray};
  color: ${COLORS.darkGray};
  border-radius: 20px;
  display: inline-block;
  line-height: 1;
  margin: 3px;
  min-height: 24px;
  min-width: 24px;
  padding: 6px;
  text-align: center;
  vertical-align: baseline;
`;

export const globalStyle = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  body {
    ${basicFontStyle};
    background-color: ${COLORS.lightGray};
  }

  li {
    list-style: none;
  }
`;

// z-index 定義
// 背景
export const backGroundZIndex = css`
  z-index: -1;
`;
// ベース
export const baseZIndex = css`
  z-index: 0;
`;
// 要素の上に重ねるボタン等
export const buttonZIndex = css`
  z-index: 1;
`;
// ポップアップやトースト等、稼働時に他の要素の上に表示されるもの
export const popUpAndToastZIndex = css`
  z-index: 2;
`;
// サイドメニューに使う
export const sideMenuZIndex = css`
  z-index: 3;
`;
// モーダルなどのオーバーレイに使う
export const overlayZIndex = css`
  z-index: 4;
`;
// モーダル等、表示時には他のどの要素よりも上にくるオブジェクトに使う
export const modalZIndex = css`
  z-index: 5;
`;
