// --- AUTH ---//
export const SIGN_IN_GOOGLE = 'SIGN_IN_GOOGLE';
export const SIGN_IN_GOOGLE_REDIRECT = 'SIGN_IN_GOOGLE_REDIRECT';
export const GET_GOOGLE_REDIRECT_RESULT = 'GET_GOOGLE_REDIRECT_RESULT';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const SIGN_IN_ANONYMOUSLY_START = 'SIGN_IN_ANONYMOUSLY_START';
export const SIGN_IN_ANONYMOUSLY_SUCCESS = 'SIGN_IN_ANONYMOUSLY_SUCCESS';
export const SIGN_IN_ANONYMOUSLY_ERROR = 'SIGN_IN_ANONYMOUSLY_ERROR';

export const SIGN_OUT = 'SIGN_OUT';

export const GET_ACCOUNT_EDIT_INFO = 'GET_ACCOUNT_EDIT_INFO';
export const GET_ACCOUNT_EDIT_INFO_SUCCESS = 'GET_ACCOUNT_EDIT_INFO_SUCCESS';
export const GET_ACCOUNT_EDIT_INFO_ERROR = 'GET_ACCOUNT_EDIT_INFO_ERROR';
export const UPDATE_ACCOUNT_EDIT_NAME = 'UPDATE_ACCOUNT_EDIT_NAME';
export const UPDATE_ACCOUNT_EDIT_NAME_SUCCESS = 'UPDATE_ACCOUNT_EDIT_NAME_SUCCESS';
export const UPDATE_ACCOUNT_EDIT_NAME_ERROR = 'UPDATE_ACCOUNT_EDIT_NAME_ERROR';
export const UPDATE_ACCOUNT_EDIT_ICON = 'UPDATE_ACCOUNT_EDIT_ICON';
export const UPDATE_ACCOUNT_EDIT_ICON_SUCCESS = 'UPDATE_ACCOUNT_EDIT_ICON_SUCCESS';
export const UPDATE_ACCOUNT_EDIT_ICON_ERROR = 'UPDATE_ACCOUNT_EDIT_ICON_ERROR';
export const SET_FROM_BACK_BUTTON = 'SET_FROM_BACK_BUTTON';

// -- HOME -- //
export const HOME_SUBSCRIBE_MY_USERS_DATA = 'HOME_SUBSCRIBE_MY_USERS_DATA';
export const HOME_SUBSCRIBE_NOW_LOGIN_USERS_DATA = 'HOME_SUBSCRIBE_NOW_LOGIN_USERS_DATA';
export const HOME_UNSUBSCRIBE_USER_STATUSES = 'HOME_UNSUBSCRIBE_USER_STATUSES';
export const HOME_UNSUBSCRIBE_NOW_LOGIN_USERS_DATA = 'HOME_UNSUBSCRIBE_NOW_LOGIN_USERS_DATA';
export const HOME_NEW_MEMBER_NOTIFIED = 'HOME_NEW_MEMBER_NOTIFIED';

export const HOME_UPDATE_LOCATION = 'HOME_UPDATE_LOCATION';
export const HOME_UPDATE_STATUS = 'HOME_UPDATE_STATUS';
export const HOME_UPDATE_ATTENDANCE = 'HOME_UPDATE_ATTENDANCE';
export const HOME_UPDATE_COMMENT = 'HOME_UPDATE_COMMENT';
export const HOME_UPDATE_CONDITION = 'HOME_UPDATE_CONDITION';
export const HOME_UPDATE_USER_VIEWS_IS_SHADE = 'HOME_UPDATE_USER_VIEWS_IS_SHADE';

export const HOME_SET_MEMBER_VIEW_SEARCH_FIELD = 'HOME_SET_MEMBER_VIEW_SEARCH_FIELD';

// --- CALL --- //
export const STORE_DUMMY_STREAM = 'STORE_DUMMY_STREAM';
export const CONNECT_CALL_SERVER = 'CONNECT_CALL_SERVER';
export const RECONNECT_CALL_SERVER = 'RECONNECT_CALL_SERVER';
export const DISCONNECT_CALL_SERVER = 'DISCONNECT_CALL_SERVER';
export const CONNECT_CALL_SERVER_FAIL = 'CONNECT_CALL_SERVER_FAIL';
export const RENEW_CREDENTIAL = 'RENEW_CREDENTIAL';
export const SEND_INVITATION_START = 'SEND_INVITATION_START';
export const SEND_WHISPER_INVITATION_START = 'SEND_WHISPER_INVITATION_START';
export const ADD_REMOTE_USER_WITH_INVITATION = 'ADD_REMOTE_USER_INVITATION';
export const UPDATE_CALLING_USER_STATUS = 'UPDATE_CALLING_USER_STATUS';
export const CANCEL_OTHER_INVITATION = 'CANCEL_OTHER_INVITATION';
export const REMOVE_REMOTE_INVITATION = 'REMOVE_REMOTE_INVITATION_START';
export const STOP_CALLING_START = 'STOP_CALLING_START';
export const STOP_CALLING_SUCCEED = 'STOP_CALLING_SUCCEED';
export const JOIN_ROOM_START = 'JOIN_ROOM_START';
export const JOIN_ROOM_SUCCEED = 'JOIN_ROOM_SUCCEED';
export const JOIN_ROOM_FAIL = 'JOIN_ROOM_FAIL';
export const STORE_ROOM = 'STORE_ROOM';
export const STORE_CHILD_TASK_OF_CALL = 'CHILD_TASK_OF_CALL';
export const SET_USER_WITH_OTHER_INFO = 'SET_USER_WITH_OTHER_INFO';
export const SEND_LOCAL_STREAM_STAT = 'SEND_LOCAL_STREAM_STAT';
export const SET_LOCAL_USER = 'SET_LOCAL_USER';
export const UPDATE_LOCAL_STREAM = 'UPDATE_LOCAL_STREAM';
export const SET_REMOTE_USER = 'SET_REMOTE_USER';
export const REMOTE_PEER_JOINED = 'REMOTE_PEER_JOINED';
export const RECEIVE_REMOTE_STREAM = 'RECEIVE_REMOTE_STREAM';
export const RECEIVE_REMOTE_STREAM_STATS = 'RECEIVE_REMOTE_STREAM_STATS';
export const ADD_REMOTE_STREAM = 'ADD_REMOTE_STREAM';
export const SET_REMOTE_STAT = 'SET_REMOTE_STAT';
export const ADD_VIDEO_TRACK = 'ADD_VIDEO_TRACK';
export const REMOVE_REMOTE_USER = 'REMOVE_REMOTE_USER';
export const REMOVE_REMOTE_USER_AFTER_3_SEC = 'REMOVE_REMOTE_USER_AFTER_3_SEC';
export const UPDATE_LOCAL_AUDIO_ENABLED_START = 'UPDATE_LOCAL_AUDIO_ENABLED_START ';
export const UPDATE_LOCAL_AUDIO_ENABLED_SUCCEED = 'UPDATE_LOCAL_AUDIO_ENABLED_SUCCEED ';
export const DISABLE_LOCAL_VIDEO_START = 'DISABLE_LOCAL_VIDEO_START ';
export const SHARE_SCREEN_START = 'SHARE_SCREEN_START';
export const SHARE_SCREEN_SUCCESS = 'SHARE_SCREEN_SUCCESS';
export const STOP_SHARE_SCREEN_START = 'STOP_SHARE_SCREEN_START';
export const UPDATE_LOCAL_USER_SPEAKING = 'UPDATE_LOCAL_USER_SPEAKING';
export const UPDATE_REMOTE_USER_SPEAKING = 'UPDATE_REMOTE_USER_SPEAKING';
export const UPDATE_MEDIA_DEVICE = 'UPDATE_MEDIA_DEVICE';
export const UPDATE_AUDIO_DEVICE = 'UPDATE_AUDIO_DEVICE';
export const OPEN_RECEIVING_MODAL = `OPEN_RECEIVING_MODAL`;
export const CLOSE_RECEIVING_MODAL = `CLOSE_RECEIVING_MODAL`;
export const OPEN_JOIN_CONFIRMATION_MODAL = `OPEN_JOIN_CONFIRMATION_MODAL`;
export const CLOSE_JOIN_CONFIRMATION_MODAL = `CLOSE_JOIN_CONFIRMATION_MODAL`;
export const OPEN_CALL_TO_TEAM_CONFIRMATION_MODAL = `OPEN_CALL_TO_TEAM_CONFIRMATION_MODAL`;
export const CLOSE_CALL_TO_TEAM_CONFIRMATION_MODAL = `CLOSE_CALL_TO_TEAM_CONFIRMATION_MODAL`;
export const SET_ADD_MEMBER_MODAL_OPENED = `SET_ADD_MEMBER_MODAL_OPENED`;
export const FETCH_CALLABLE_MEMBERS_START = `FETCH_CALLABLE_MEMBERS_START`;
export const FETCH_CALLABLE_MEMBERS_SUCCEED = `FETCH_CALLABLE_MEMBERS_SUCCEED`;
export const FETCH_CALLABLE_MEMBERS_FAIL = `FETCH_CALLABLE_MEMBERS_FAIL`;
export const FETCH_CALLABLE_MEMBERS_CLEAR = `FETCH_CALLABLE_MEMBERS_CLEAR`;
export const SET_CALLABLE_MEMBER_CALLING = `SET_CALLABLE_MEMBER_CALLING`;
export const SET_CALLABLE_MEMBER_TALKING = `SET_CALLABLE_MEMBER_TALKING`;
export const SET_DEVICE_SETTING_MODAL_OPENED = `SET_DEVICE_SETTING_MODAL_OPENED`;
export const OPEN_RECONNECTION_MODAL = 'OPEN_RECONNECTION_MODAL';
export const CLOSE_RECONNECTION_MODAL = 'CLOSE_RECONNECTION_MODAL';
export const OPEN_SENDING_WHISPER_INVITATION_MODAL = `OPEN_SENDING_WHISPER_INVITATION_MODAL`;
export const CLOSE_SENDING_WHISPER_INVITATION_MODAL = `CLOSE_SENDING_WHISPER_INVITATION_MODAL`;
export const REFUSE_OR_OPEN_WHISPER_RECEIVING_MODAL = `REFUSE_OR_OPEN_WHISPER_RECEIVING_MODAL`;
export const OPEN_WHISPER_RECEIVING_MODAL = `OPEN_WHISPER_RECEIVING_MODAL`;
export const CLOSE_WHISPER_RECEIVING_MODAL = `CLOSE_WHISPER_RECEIVING_MODAL`;
export const START_WHISPER_START = 'START_WHISPER_START';
export const START_WHISPER_SUCCEED = 'START_WHISPER_SUCCEED';
export const STOP_WHISPER_START = 'STOP_WHISPER_START';
export const STORE_MESSAGE = 'STORE_MESSAGE';
export const STORE_ERROR_MESSAGE = 'STORE_ERROR_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const SET_MAXIMIZED_UID = 'SET_MAXIMIZED_UID';
export const ADD_RECEIVED_CALL_HISTORY = 'ADD_RECEIVED_CALL_HISTORY';
export const CLEAR_RECEIVED_CALL_HISTORY = 'CLEAR_RECEIVED_CALL_HISTORY';
export const SET_PARENT_ROOM_VOLUME = 'SET_PARENT_ROOM_VOLUME';
export const CHANGE_CALL_VIEW_DISP = 'CHANGE_CALL_VIEW_DISP';
export const SET_NOTICE_FOR_GUEST = 'SET_NOTICE_FOR_GUEST';
export const SET_JOIN_WORKBOARD_ERROR = 'SET_JOIN_WORKBOARD_ERROR';

// --- WORKBOARD --- //
export const JOIN_WORKBOARD_START = 'JOIN_WORKBOARD_START';
export const JOIN_WORKBOARD_SUCCESS = 'JOIN_WORKBOARD_SUCCESS';
export const JOIN_WORKBOARD_ERROR = 'JOIN_WORKBOARD_ERROR';
export const SET_WORKBOARD_OPENED_SHARING_FILES = 'SET_WORKBOARD_OPENED_SHARING_FILES';
export const SET_WORKBOARD_OPENED_TEXT_CHAT = 'SET_WORKBOARD_OPENED_TEXT_CHAT';
export const SET_WORKBOARD_OPENED_CANVAS = 'SET_WORKBOARD_OPENED_CANVAS';
export const WORKBOARD_UPLOAD_FILES_START = 'WORKBOARD_UPLOAD_FILES_START';
export const WORKBOARD_UPLOAD_FILES_SUCCESS = 'WORKBOARD_UPLOAD_FILES_SUCCESS';
export const WORKBOARD_UPLOAD_FILES_ERROR = 'WORKBOARD_UPLOAD_FILES_ERROR';
export const SEND_TEXT_CHAT_START = 'SEND_TEXT_CHAT_START';
export const SEND_TEXT_CHAT_SUCCESS = 'SEND_TEXT_CHAT_SUCCESS';
export const SEND_TEXT_CHAT_ERROR = 'SEND_TEXT_CHAT_ERROR';
export const SET_TEXT_CHAT_SCROLL_POINT = 'SET_TEXT_CHAT_SCROLL_POINT';
export const WORKBOARD_DELETE_FILE_START = 'WORKBOARD_DELETE_FILE_START';
export const WORKBOARD_DELETE_FILE_SUCCESS = 'WORKBOARD_DELETE_FILE_SUCCESS';
export const WORKBOARD_DELETE_FILE_ERROR = 'WORKBOARD_DELETE_FILE_ERROR';
export const SUBSCRIBE_SHARING_FILES = 'SUBSCRIBE_SHARING_FILES';
export const SUBSCRIBE_TEXT_CHAT = 'SUBSCRIBE_TEXT_CHAT';
export const CLEAR_SHARING_FILES_NOTICE = 'CLEAR_SHARING_FILES_NOTICE';
export const CLEAR_TEXT_CHAT_NOTICE = 'CLEAR_TEXT_CHAT_NOTICE';
export const GET_WORKBOARD_FILE_URL_START = 'GET_WORKBOARD_FILE_URL_START';
export const GET_WORKBOARD_FILE_URL_SUCCESS = 'GET_WORKBOARD_FILE_URL_SUCCESS';
export const GET_WORKBOARD_FILE_URL_ERROR = 'GET_WORKBOARD_FILE_URL_ERROR';
export const GET_WORKBOARD_FILE_URL_CLEAR = 'GET_WORKBOARD_FILE_URL_CLEAR';
export const SET_CANVAS_SENDER = 'SET_CANVAS_SENDER';
export const SUBSCRIBE_CANVAS_MEMBERS = 'SUBSCRIBE_CANVAS_MEMBERS';
export const SEND_CANVAS_TMP_DATA = 'SEND_CANVAS_TMP_DATA';
export const GET_CANVAS_HISTORY_START = 'GET_CANVAS_HISTORY_START';
export const GET_CANVAS_HISTORY_SUCCESS = 'GET_CANVAS_HISTORY_SUCCESS';
export const GET_CANVAS_HISTORY_ERROR = 'GET_CANVAS_HISTORY_ERROR';
export const REMOVE_CANVAS_MEMBER = 'REMOVE_CANVAS_MEMBER';
export const CLEAR_WORKBOARD_DATA = 'CLEAR_WORKBOARD_DATA';

// --- COMMON ---//
export const COMMON_CLEAR = 'COMMON_CLEAR';
export const COMMON_ERROR = 'COMMON_ERROR';
export const COMMON_SUCCESS = 'COMMON_SUCCESS';

// --- ROUTE ---//
export const SET_REDIRECT_PATH = 'SET_REDIRECT_PATH';

// --- SPLASH ---//
export const SET_SPLASH_DISPLAYED = 'SET_SPLASH_DISPLAYED';
// --- HTML HEADER ---//
export const PUSH_HTML_HEADER_MESSAGES = 'PUSH_HTML_HEADER_MESSAGES';
export const REMOVE_HTML_HEADER_MESSAGES = 'REMOVE_HTML_HEADER_MESSAGES';
export const CLEAR_HTML_HEADER_MESSAGES = 'CLEAR_HTML_HEADER_MESSAGES';
