/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { Splash } from 'components/common/Splash';

export const SplashContainer: FC = () => {
  const displayed = useSelector<RootState, boolean>(state => state.splash.displayed);

  return <Splash displayed={displayed} />;
};
