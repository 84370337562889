import * as ActionType from 'actions/ActionTypeConstants';

export const updateUserViewsIsShadeActions = {
  start: (isShade: boolean) => ({
    type: ActionType.HOME_UPDATE_USER_VIEWS_IS_SHADE as typeof ActionType.HOME_UPDATE_USER_VIEWS_IS_SHADE,
    payload: { isShade },
  }),
};

export type HeaderBlurAction = ReturnType<typeof updateUserViewsIsShadeActions.start>;
