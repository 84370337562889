/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useMemo } from 'react';
import { jsx } from '@emotion/core';
import reactStringReplace from 'react-string-replace';

/**
 * チャットテキスト用のフォーマット生成
 * @param text チャットメッセージ
 * @returns チャットメッセージに、改行タグ、htmlのリンクタグを付加
 */
export const FormatChatTextTag: FC<{ text: string }> = ({ text }) => {
  const regExp = /(https?:\/\/\S+)/g;

  const texts = useMemo(
    () =>
      text.split('\n').map((item, index) => {
        return (
          <React.Fragment key={index.toString()}>
            {reactStringReplace(item, regExp, (match, i) => (
              <a href={match} key={match + i} target="_blank" rel="noopener noreferrer">
                {match}
              </a>
            ))}
            <br />
          </React.Fragment>
        );
      }),
    [text, regExp],
  );

  return <div>{texts}</div>;
};
