/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx, css } from '@emotion/core';
import { CallingUser } from 'reducers/call/call';
import { CallMenuContainer } from 'containers/call/CallMenu';
import { AddMemberModalContainer } from 'containers/call/modal/AddMemberModal';
import { DeviceSettingModalContainer } from 'containers/call/modal/DeviceSettingModal';
import { CallNotificationContainer } from 'containers/call/modal/CallNotification';
import { SendingWhisperInvitationModalContainer } from 'containers/call/modal/SendingWhisperInvitationModal';
import { ReceivingWhisperModalContainer } from 'containers/call/modal/ReceivingWhisperModal';
import { SharingFilesContainer } from 'containers/call/workboard/SharingFiles';
import { WorkboardUiState } from 'reducers/call/workboard';
import { TextChatContainer } from 'containers/call/workboard/TextChat';
import { COLORS } from 'styles/style';
import { MainArea } from './MainArea';
import { SideMemberList } from './SideMemberList';

const CALL_MENU_H = '80px';

const callViewBaseContainer = css`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

// メイン + メニューが表示されるエリア
const leftArea = css`
  flex: 1 1 0; /* サイドメンバーエリアとチャットエリアの横幅を差し引いた残りの幅すべて */
  min-width: 0; /* FlexItem として伸縮する親要素の幅を子要素がを超えた場合にスクロールバーを出すために必要 */
`;

// メインエリア (メンバー or 拡大されたメンバー or キャンバスが表示されるエリア)
const mainArea = css`
  background-color: ${COLORS.white};
  height: calc(100vh - ${CALL_MENU_H});
  width: 100%;
`;

// メニューが表示されるエリア
const menuArea = css`
  background-color: ${COLORS.black};
  height: ${CALL_MENU_H};
`;

// 拡大表示時 or キャンバス表示時にメンバーが縦に並ぶエリア
const sideMembersArea = css`
  flex: 0 0 300px;
`;

// チャットオープン時にチャットが表示されるエリア
const chatArea = css`
  flex: 0 0 300px;
`;

export interface CallViewProps {
  currentUserId: string;
  sentInvitations: any[];
  users: CallingUser[];
  maximizedUid: string | undefined;
  handleOnExpandBtnClicked: (uid: string) => void;
  handleOnTileBtnClicked: () => void;
  handleOnWhisperBtnClicked: (uid: string) => void;
  whisperTargetUid: string | undefined;
  workboardUiState: WorkboardUiState;
}

export const CallView: FC<CallViewProps> = ({
  currentUserId,
  sentInvitations,
  users = [],
  maximizedUid,
  handleOnExpandBtnClicked,
  handleOnTileBtnClicked,
  handleOnWhisperBtnClicked,
  whisperTargetUid,
  workboardUiState,
}) => {
  const modalAndNotifications = (
    <React.Fragment>
      <CallNotificationContainer />
      {workboardUiState.sharingFilesOpened && <SharingFilesContainer />}
      <AddMemberModalContainer />
      <DeviceSettingModalContainer />
      <SendingWhisperInvitationModalContainer />
      <ReceivingWhisperModalContainer />
    </React.Fragment>
  );

  const sideMembersDisplayed = maximizedUid !== undefined || workboardUiState.canvasOpened;

  return (
    <React.Fragment>
      {modalAndNotifications}
      <div css={callViewBaseContainer}>
        <div css={leftArea}>
          <div css={mainArea}>
            <MainArea
              currentUserId={currentUserId}
              sentInvitations={sentInvitations}
              users={users}
              maximizedUid={maximizedUid}
              handleOnExpandBtnClicked={handleOnExpandBtnClicked}
              handleOnTileBtnClicked={handleOnTileBtnClicked}
              handleOnWhisperBtnClicked={handleOnWhisperBtnClicked}
              whisperTargetUid={whisperTargetUid}
              workboardUiState={workboardUiState}
            />
          </div>
          <div css={menuArea}>
            <CallMenuContainer />
          </div>
        </div>
        {sideMembersDisplayed && (
          <div css={sideMembersArea}>
            <SideMemberList
              currentUserId={currentUserId}
              users={workboardUiState.canvasOpened ? users : users.filter(u => u.uid !== maximizedUid)}
              handleOnExpandBtnClicked={handleOnExpandBtnClicked}
              handleOnWhisperBtnClicked={handleOnWhisperBtnClicked}
              whisperTargetUid={whisperTargetUid}
              scaleButtonDisplayed={!workboardUiState.canvasOpened}
            />
          </div>
        )}
        {workboardUiState.textChatOpened && (
          <div css={chatArea}>
            <TextChatContainer />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
