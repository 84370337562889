/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWorkboardFileUrlActions,
  setWorkboardOpenedAction,
  workboardDeleteFileActions,
  workboardUploadFilesActions,
} from 'actions/call/workboard';
import { RootState } from 'reducers/mainReducer';
import { WorkboardFile } from 'reducers/call/workboard';
import { SharingFiles } from 'components/call/workboard/SharingFiles';
import { commonActions } from 'actions/common/common';
import { CommonMessage } from 'reducers/common/common';

const MAX_FILE_SIZE = 100e6;
const MAX_FILE_SIZE_MSG = '100MB';

export const SharingFilesContainer: FC = () => {
  const dispatch = useDispatch();
  const currentUserId = useSelector<RootState, string>(state => state.homeSubscriber.currentUserStatus.uid);
  const files = useSelector<RootState, WorkboardFile[]>(state => state.workboard.uiState.files);
  const loading = useSelector<RootState, boolean>(state => state.workboard.uiState.loading);
  const fileUrl = useSelector<RootState, string>(state => state.workboard.uiState.downloadUrl);
  const commonError = useSelector<RootState, CommonMessage | undefined>(state => state.common.errorMsg);

  useEffect(() => {
    if (fileUrl) {
      const a = document.createElement('a');
      a.href = decodeURI(fileUrl);
      a.target = '_blank';
      a.download = fileUrl;
      // Firefoxの場合、ユーザーのアクションなしでclick()を使用するとポップアップブロックされる
      // 一度許可すれば以降ダウンロードできるため、現時点では対処しない
      a.click();

      dispatch(getWorkboardFileUrlActions.clear());
    }
  }, [fileUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadFiles = (acceptedFiles: File[]) => {
    acceptedFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE) {
        dispatch(commonActions.error(`ファイルサイズが${MAX_FILE_SIZE_MSG}を超えるファイルはアップロードできません。`));
      } else {
        dispatch(workboardUploadFilesActions.start(file));
      }
    });
  };

  return (
    <SharingFiles
      currentUserId={currentUserId}
      uploadFiles={(acceptedFiles: File[]) => uploadFiles(acceptedFiles)}
      fileState={files}
      loading={loading}
      handleOnClose={() => dispatch(setWorkboardOpenedAction.sharingFiles(false))}
      handleOnDeleteFile={(fileState: WorkboardFile) => dispatch(workboardDeleteFileActions.start(fileState))}
      handleOnGetFileUrl={(fileState: WorkboardFile) => dispatch(getWorkboardFileUrlActions.start(fileState))}
      commonError={commonError}
    />
  );
};
