/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { BasicMessageBox } from 'components/common/parts/BasicMessageBox';
import { ContentsBoxContainerPage } from 'components/common/ContentsBoxContainerPage';
import { ContentsBox } from 'components/common/ContentsBox';
import { AuthPageHeader } from './AuthPageHeader';

export const MaintenanceCheckFailed: FC = () => {
  return (
    <div>
      <AuthPageHeader />
      <ContentsBoxContainerPage headerDisplayed={false}>
        <ContentsBox title="メンテナンス">
          <BasicMessageBox color="red">
            <p>メンテナンス状況のチェックに失敗しました</p>
            <p>インターネットに接続されているかご確認ください。</p>
          </BasicMessageBox>
        </ContentsBox>
      </ContentsBoxContainerPage>
    </div>
  );
};
