/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import OnDutyIcon from 'styles/image/icon_status_in.svg';
import LeaveIcon from 'styles/image/icon_status_out.svg';
import BeforeIcon from 'styles/image/icon_status_before.svg';
import { formatDate } from 'common/Util';
import { MemberViewPopup, SelectButton, AttendanceButton } from './MemberViewButtons';

interface AttendanceProps {
  updateAttendance?: () => void;
  startTime: firebase.firestore.Timestamp;
  onDuty: boolean;
  isOwn: boolean;
}
interface Props {
  onDuty: boolean;
  startDate: Date;
  updateAttendance?: () => void;
}

const Own: FC<Props> = ({ onDuty, startDate, updateAttendance = () => {} }) => {
  const today = new Date();
  today.setHours(0, 0, 0);

  return onDuty ? (
    <MemberViewPopup value={<SelectButton value="退勤する" onClick={updateAttendance} />}>
      <AttendanceButton iconPath={OnDutyIcon} isOwn onClick={updateAttendance}>
        {formatDate(startDate, 'HH:mm')}
      </AttendanceButton>
    </MemberViewPopup>
  ) : (
    <MemberViewPopup value={<SelectButton value="出勤する" onClick={updateAttendance} />}>
      <AttendanceButton
        iconPath={today < startDate ? LeaveIcon : BeforeIcon}
        isOwn
        onClick={updateAttendance}
        colored={!(today < startDate)}
      >
        {today < startDate ? '退勤済' : '出勤前'}
      </AttendanceButton>
    </MemberViewPopup>
  );
};

const Others: FC<Props> = ({ onDuty, startDate }) => {
  const today = new Date();
  today.setHours(0, 0, 0);

  return onDuty ? (
    <AttendanceButton iconPath={OnDutyIcon}>{formatDate(startDate, 'HH:mm')}</AttendanceButton>
  ) : (
    <AttendanceButton iconPath={today < startDate ? LeaveIcon : BeforeIcon} colored={!(today < startDate)}>
      {today < startDate ? '退勤済' : '出勤前'}
    </AttendanceButton>
  );
};

export const Attendance: FC<AttendanceProps> = ({ updateAttendance = () => {}, startTime, onDuty, isOwn }) => {
  return isOwn ? (
    <Own onDuty={onDuty} startDate={startTime.toDate()} updateAttendance={updateAttendance} />
  ) : (
    <Others onDuty={onDuty} startDate={startTime.toDate()} />
  );
};

export default Attendance;
