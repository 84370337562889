/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import { CallingUserStatus } from 'reducers/call/call';
import CloseIcon from 'styles/image/close_white.svg';
import { BasicButton } from 'components/common/parts/BasicButton';
import { RefusingUser } from './RefusingUser';

const baseCss = css`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  .icon-box {
    text-align: center;
    width: 100%;
  }

  .button-wrap {
    text-align: center;
    width: 100%;
  }

  .info-area {
    text-align: center;
    width: 100%;
  }

  .icon-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 30%;
    margin: 0 auto;
    text-align: center;
    width: 30%;

    img {
      object-fit: contain;
    }
  }
`;

const sideMemberCss = css`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  .icon-image {
    height: 0;
    width: 0;
  }

  .info-area {
    font-size: 11px;
    padding-left: 5px;
    width: 100%;
  }

  .button-wrap {
    font-size: 11px;
    padding: 25px 0 0 5px;
    text-align: center;
    width: 100%;

    button {
      border: none;
      outline: 0;
    }
  }
`;

interface StandbyUserProps {
  iconUrl: string;
  status: CallingUserStatus;
  onCancelClicked: () => void;
  isSideView?: boolean;
}

/**
 * 招待されている、もしくはルームに JOIN してカメラなどの準備しているユーザを表現するコンポーネント
 *
 * @param iconUrl - icon の URL
 * @param status - 招待の状態
 * @param onCancelClicked - キャンセル時のアクション
 * @param isSideView - サイド表示かどうか
 */
export const StandbyUser: FC<StandbyUserProps> = ({ iconUrl, status, onCancelClicked, isSideView = false }) => {
  const standbyUserCss = isSideView ? sideMemberCss : baseCss;

  if (status === CallingUserStatus.REFUSED) {
    return <RefusingUser />;
  }

  // 自動整形によるエラー回避
  // prettier-ignore
  const iconImageCss = css`
    background-image: url(${iconUrl});
`;

  let informationArea;

  if (status === CallingUserStatus.CONNECTING) {
    informationArea = <div>接続しています... </div>;
  } else if (status === CallingUserStatus.CALLING) {
    informationArea = <div>発信中... </div>;
  } else if (status === CallingUserStatus.ACCEPTED || status === CallingUserStatus.JOINING) {
    informationArea = <div>参加中です...</div>;
  } else if (status === CallingUserStatus.JOIN_FAILURE) {
    informationArea = <div>参加に失敗しました</div>;
  } else if (status === CallingUserStatus.CANCELED) {
    informationArea = <div>キャンセルしました</div>;
  } else if (status === CallingUserStatus.FAILURE) {
    informationArea = <div>招待に失敗しました</div>;
  }

  return (
    <div css={standbyUserCss}>
      {status === CallingUserStatus.CONNECTING || status === CallingUserStatus.CALLING ? (
        <div className="button-wrap">
          <BasicButton color="red" onClick={onCancelClicked}>
            <div css={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <img src={CloseIcon} css={{ height: 12, width: 12 }} alt="" />
              キャンセル
            </div>
          </BasicButton>
        </div>
      ) : (
        <div />
      )}

      <div className="icon-image" css={iconImageCss} />
      <div className="info-area">{informationArea}</div>
    </div>
  );
};
