import { Reducer } from 'redux';
import * as ActionType from 'actions/ActionTypeConstants';
import { authAction } from 'actions/auth/auth';

export interface AuthState {
  errorMessages: string[];
  loginFinished: boolean;
}

export const initialState: AuthState = {
  errorMessages: [],
  loginFinished: true,
};

export const authReducer: Reducer<AuthState, authAction> = (
  state: AuthState = initialState,
  action: authAction,
): AuthState => {
  switch (action.type) {
    case ActionType.SIGN_IN_GOOGLE:
      return {
        ...state,
        loginFinished: false,
      };
    case ActionType.SIGN_IN_SUCCESS: {
      return {
        ...state,
        loginFinished: true,
      };
    }
    case ActionType.SIGN_IN_ERROR: {
      return {
        ...state,
        loginFinished: true,
      };
    }
    case ActionType.SIGN_OUT:
      return initialState;
    default: {
      return state;
    }
  }
};
