/** @jsx jsx */
import { FC, RefObject } from 'react';
import { jsx, css } from '@emotion/core';
import IconTextBoxError from 'styles/image/icon_textBox_error.svg';
import { boldFont, COLORS, fontSizePlaceholder, rounded } from 'styles/style';

const messageCommonStyle = css`
  ${boldFont}
  margin: 0 0 4px 10px;
  padding-left: 20px;
`;

const normalMsg = css`
  ${messageCommonStyle}
  color: ${COLORS.blue};
`;

const errorMsg = css`
  ${messageCommonStyle}
  background: ${COLORS.white} url(${IconTextBoxError}) no-repeat padding-box left;
  color: ${COLORS.red};
`;

const textBoxCss = css`
  width: 100%;

  .label-wrap {
    display: flex;

    .label {
      ${boldFont}
      display: flex;
      margin-bottom: 4px;

      .required {
        color: ${COLORS.red};
      }
    }
  }

  input {
    ${rounded}
    background: ${COLORS.white} 0 0 no-repeat padding-box;
    border: 1px solid ${COLORS.darkGray};
    color: ${COLORS.darkGray};
    height: 36px;
    padding-left: 14px;
    width: 100%;

    ::placeholder {
      ${fontSizePlaceholder}
      color: ${COLORS.gray};
    }

    &:disabled {
      background: ${COLORS.gray} 0 0 no-repeat padding-box;
    }
  }

  .error {
    background: #fefafa;
    border: 1px solid ${COLORS.red};
  }
`;

type TextBoxProps = JSX.IntrinsicElements['input'];
type Props = TextBoxProps & {
  label?: string;
  hasError?: boolean;
  message?: string;
  inputRef?: RefObject<HTMLInputElement>;
};

/**
 * テキストボックス
 * @param textBoxProps - 任意。テキストボックスのオプション（onChange 等）
 * @param label - 任意。ラベル
 * @param hasError - 任意。エラーの場合 true
 * @param message - 任意。メッセージ。 hasError が true の場合はエラーメッセージとなる
 * @returns テキストボックスを表示
 * @example
 * ```
 *  const [textBoxText, setTextBoxText] = useState('');
 *  <BasicTextBox label="名前" onChange={e => setTextBoxText(e.target.value)} />
 * ```
 */
export const BasicTextBox: FC<Props> = Props => {
  const { label, hasError, message, inputRef, ...textBoxProps } = Props;

  return (
    <div css={textBoxCss}>
      <div className="label-wrap">
        {label && (
          <div className="label">
            {label}
            {textBoxProps?.required && <div className="required">*</div>}
          </div>
        )}
        {message && <div css={hasError ? errorMsg : normalMsg}>{message}</div>}
      </div>
      <input ref={inputRef} type="text" {...textBoxProps} className={(hasError && 'error') || ''} />
    </div>
  );
};
