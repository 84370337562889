/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { SendingWhisperInvitationModal } from 'components/call/modal/SendingWhisperInvitationModal';
import { RootState } from 'reducers/mainReducer';
import { closeSendingWhisperInvitationModal } from 'actions/call/call';
import { LocalInvitation } from 'sagas/call/classes/LocalInvitation';
import { InvitationStage } from 'sagas/call/classes/ConnectionData';

export const SendingWhisperInvitationModalContainer: FC = () => {
  const invitation = useSelector<RootState, LocalInvitation | undefined>(state => state.call.sentWhisperInvitation);
  const stage = useSelector<RootState, InvitationStage | undefined>(state =>
    state.call.sentWhisperInvitation?.getStage(),
  );
  const dispatch = useDispatch();

  const onCancel = () => {
    if (invitation) {
      invitation.cancel();
    }

    dispatch(closeSendingWhisperInvitationModal());
  };

  if (invitation && stage) {
    return <SendingWhisperInvitationModal stage={stage} onCancel={onCancel} />;
  }

  return <div />;
};
