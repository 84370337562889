/** @jsx jsx */
import { FC } from 'react';
import { jsx, css } from '@emotion/core';
import IconSearchBox from 'styles/image/icon_searchBox.svg';
import { COLORS, fontSizePlaceholder, rounded } from 'styles/style';

const searchBoxCss = css`
  input {
    ${rounded};
    background: ${COLORS.white} url(${IconSearchBox}) no-repeat right 11px top 12px;
    border: 1px solid ${COLORS.darkGray};
    height: 36px;
    padding-left: 14px;

    ::placeholder {
      ${fontSizePlaceholder};
      color: ${COLORS.gray};
    }
  }

  .small {
    width: 174px;
  }

  .medium {
    width: 189px;
  }

  .large {
    width: 204px;
  }
`;

type SearchBoxProps = JSX.IntrinsicElements['input'];
type Props = SearchBoxProps & {
  searchBoxProps?: JSX.IntrinsicElements['input'];
  boxSize?: 'small' | 'medium' | 'large';
};
/**
 * 検索ボックス
 * @param searchBoxProps - 任意。テキストボックスのオプション（onChange 等）
 * @param boxSize - 任意。small, medium, large から選択。デフォルトはsmall
 * @returns チェックボックスを表示
 * @example
 * ```
 *  const [searchBoxText, setSearchBoxText] = useState('');
 *  <BasicSearchBox
 *   onChange={e => setSearchBoxText(e.target.value)}
 *   placeholder="名前を検索"
 *   boxSize="small"
 *   />
 * ```
 */
export const BasicSearchBox: FC<Props> = Props => {
  const { boxSize, ...searchBoxProps } = Props;

  return (
    <div css={searchBoxCss}>
      <input type="text" {...searchBoxProps} className={boxSize || 'small'} />
    </div>
  );
};
