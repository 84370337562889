/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import { CallingUser, CallingUserStatus } from 'reducers/call/call';
import { Video } from 'components/call/Video';
import { StandbyUserContainer } from 'containers/call/StandbyUser';
import IconMicOff from 'styles/image/icon_video_mic_off_red.svg';
import { PersonalAreaButtons } from 'components/call/PersonalAreaButtons';
import { sideMenuZIndex } from 'styles/style';

const actionButtons = css`
  bottom: 5px;
  position: absolute;
  right: 10px;
  ${sideMenuZIndex};
`;

interface Props {
  currentUserId: string;
  users: CallingUser[];
  handleOnExpandBtnClicked: (uid: string) => void;
  handleOnWhisperBtnClicked: (uid: string) => void;
  whisperTargetUid: string | undefined;
  scaleButtonDisplayed: boolean;
}

const personalSpaceBaseCss = css`
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5) inset;
  box-sizing: border-box;
  position: relative;
`;

const personalHeaderBaseCss = css`
  display: flex;
  left: 0;
  position: absolute;
  top: 0;

  .name {
    box-sizing: border-box;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .audio-muted {
    background-color: #fff;
    background-image: url(${IconMicOff});
    background-position: center;
    background-repeat: no-repeat;
    border: solid 1px #f15340;
    border-radius: 50%;
    box-sizing: border-box;
  }
`;

const videoImageCss = css`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const sideMembersCss = css`
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 300px;
`;

const sidePersonalSpaceCss = css`
  ${personalSpaceBaseCss};
  height: 180px;
  width: 100%;
`;

const sidePersonalHeaderCss = css`
  ${personalHeaderBaseCss};
  width: 100%;

  .name {
    background-color: #000;
    display: inline-block;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    max-width: 80%;
    padding: 0 10px;
  }

  .audio-muted {
    background-size: 18px;
    height: 22px;
    margin: 5px 0 0 8px;
    width: 22px;
  }
`;

export const SideMemberList: FC<Props> = ({
  currentUserId,
  users = [],
  handleOnExpandBtnClicked,
  handleOnWhisperBtnClicked,
  whisperTargetUid,
  scaleButtonDisplayed,
}) => {
  return (
    <div css={sideMembersCss}>
      <ul>
        {users.map(user => (
          <li css={sidePersonalSpaceCss} key={user.uid}>
            <div css={videoImageCss}>
              {user.status === CallingUserStatus.JOINED ? (
                <Video
                  uid={user.uid}
                  iconUrl={user.iconUrl}
                  stream={user.stream}
                  videoType={user.streamStat?.videoType}
                  videoEnabled={user.streamStat?.isVideoEnabled}
                  nowSpeaking={user.nowSpeaking || false}
                  myself={user.uid === currentUserId}
                  whisperTargetUid={whisperTargetUid}
                />
              ) : (
                <StandbyUserContainer user={user} isSideView />
              )}
            </div>
            <div css={sidePersonalHeaderCss}>
              <div className="name">{user.name}</div>
              {(!user.stream || !user.streamStat?.isAudioEnabled) && <div className="audio-muted" />}
            </div>
            <div css={actionButtons}>
              <PersonalAreaButtons
                currentUserId={currentUserId}
                user={user}
                handleOnExpandBtnClicked={() => handleOnExpandBtnClicked(user.uid)}
                handleOnWhisperBtnClicked={() => handleOnWhisperBtnClicked(user.uid)}
                whisperTargetUid={whisperTargetUid}
                isExpandedUser={false}
                scaleButtonDisplayed={scaleButtonDisplayed}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
