/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { css, jsx } from '@emotion/core';
import MediaDeviceListContainer from 'containers/settings/MediaDeviceList';
import AudioDeviceListContainer from 'containers/settings/AudioDeviceList';
import { Spacer } from 'components/common/parts/Spacer';
import { rounded, COLORS } from 'styles/style';
import { CallModalBase } from './CallModalBase';

const formWrapper = css`
  ${rounded};
  border: solid 1px ${COLORS.gray};
  padding: 30px 20px;
`;

type Props = {
  onClose: () => {};
};
export const DeviceSettingModal = ({ onClose }: Props) => {
  return (
    <CallModalBase title="デバイス設定" handleOnClose={onClose}>
      <div css={formWrapper}>
        <MediaDeviceListContainer />
        <Spacer height="30px" />
        <AudioDeviceListContainer />
      </div>
    </CallModalBase>
  );
};
