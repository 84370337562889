/** @jsx jsx */
import { FC } from 'react';
import { jsx, css } from '@emotion/core';
import BackgroundImage1 from 'styles/image/background-1.png';
import BackgroundImage2 from 'styles/image/background-2.png';
import BackgroundImage3 from 'styles/image/background-3.png';
import BackgroundImage4 from 'styles/image/background-4.png';
import { backGroundZIndex, baseZIndex } from 'styles/style';

const animationCss = css`
  background-color: #f1f1f1;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  ${backGroundZIndex};

  .base {
    height: auto;
    opacity: 0.5;
    position: absolute;
    ${baseZIndex};
  }

  .bt-100 {
    bottom: -100px;
  }

  .bt-300 {
    bottom: -300px;
  }

  .bt-500 {
    bottom: -500px;
  }

  .bt-700 {
    bottom: -700px;
  }

  .bt-900 {
    bottom: -800px;
  }

  .lt-05 {
    left: 5%;
  }

  .lt-10 {
    left: 10%;
  }

  .lt-15 {
    left: 15%;
  }

  .lt-20 {
    left: 20%;
  }

  .lt-40 {
    left: 40%;
  }

  .lt-60 {
    left: 60%;
  }

  .lt-80 {
    left: 80%;
  }

  .lt-85 {
    left: 85%;
  }

  .lt-90 {
    left: 90%;
  }

  .lt-95 {
    left: 95%;
  }

  .w-40 {
    width: 40px;
  }

  .w-80 {
    width: 80px;
  }

  .w-120 {
    width: 120px;
  }

  .d-30 {
    animation: 30s ease-in infinite rotation;
  }

  .d-40 {
    animation: 40s ease-in infinite rotation;
  }

  .d-50 {
    animation: 50s ease-in infinite rotation;
  }

  .d-60 {
    animation: 60s ease-in infinite rotation;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0);
    }

    100% {
      bottom: 2500px;
      transform: rotate(360deg); /* 下から上にどれだけ移動させるか */
    }
  }
`;

export const Background: FC = () => {
  return (
    <div css={animationCss}>
      <img className="base bt-100 lt-05 w-80 d-50" src={BackgroundImage1} alt="" />
      <img className="base bt-500 lt-10 w-40 d-30" src={BackgroundImage4} alt="" />
      <img className="base bt-300 lt-15 w-80 d-60" src={BackgroundImage2} alt="" />
      <img className="base bt-900 lt-20 w-120 d-50" src={BackgroundImage3} alt="" />

      <img className="base bt-300 lt-80 w-40 d-40" src={BackgroundImage4} alt="" />
      <img className="base bt-500 lt-85 w-120 d-60" src={BackgroundImage1} alt="" />
      <img className="base bt-100 lt-90 w-40 d-40" src={BackgroundImage3} alt="" />
      <img className="base bt-300 lt-95 w-80 d-50" src={BackgroundImage2} alt="" />
    </div>
  );
};
