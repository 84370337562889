/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { WorkboardUiState } from 'reducers/call/workboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { ChatNotice } from 'components/call/workboard/TextChat/ChatNotice';
import { clearTextChatNotice, setWorkboardOpenedAction } from 'actions/call/workboard';

export const ChatNoticeContainer: FC = () => {
  const uiState = useSelector<RootState, WorkboardUiState>(state => state.workboard.uiState);
  const dispatch = useDispatch();

  if (uiState.chatAddNotice && !uiState.textChatOpened) {
    return (
      <ChatNotice
        uiState={uiState}
        clearMessage={() => dispatch(clearTextChatNotice())}
        openChat={() => dispatch(setWorkboardOpenedAction.textChat(true))}
      />
    );
  }

  return <div />;
};
