import { call, cancelled, fork, put, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import Peer from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import {
  CallAction,
  closeSendingWhisperInvitationModal,
  openSendingWhisperInvitationModal,
  startWhisperActions,
  storeCallError,
  updateLocalAudioEnabled,
} from 'actions/call/call';
import { ICON_URL, User } from 'components/home/Home';
import { RootState } from 'reducers/mainReducer';
import { LocalInvitation } from 'sagas/call/classes/LocalInvitation';
import { ConnectionMetadata } from 'sagas/call/tasks/watchSendingInvitationAction';
import { InvitationContent } from 'sagas/call/classes/ConnectionData';
import { analytics } from 'firebase/Instances';

const subscribeLocalInvitation = (localInvitation: LocalInvitation, peer: Peer) =>
  eventChannel(emitter => {
    const conn = localInvitation.getRawConnection();

    conn.on('LocalInvitationAccepted', async (remoteId: string) => {
      const deviceId = localStorage.getItem('audioDeviceId') || '';
      const whisperStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } });
      emitter(updateLocalAudioEnabled.start(false));
      const mediaConnection = peer.call(remoteId, whisperStream);
      localInvitation.close();
      emitter(closeSendingWhisperInvitationModal());
      emitter(startWhisperActions.start(mediaConnection, whisperStream));
    });

    conn.on('LocalInvitationRefused', () => {
      localInvitation.close();
    });

    conn.on('LocalInvitationRefusedByAlreadyWhispering', () => {
      localInvitation.close();
    });

    conn.on('LocalInvitationCanceled', () => {
      emitter(closeSendingWhisperInvitationModal());
    });

    conn.on('LocalInvitationClosedByAccident', () => {
      conn.close(true);
    });

    conn.on('LocalInvitationFailure', () => {
      conn.close(true);
    });

    return () => conn.close(true);
  });

function* watchLocalInvitationEvents(localInvitation: LocalInvitation, peer: Peer) {
  const channel = yield call(subscribeLocalInvitation, localInvitation, peer);
  try {
    while (true) {
      const action: CallAction = yield take(channel);
      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

/**
 * ひそひそ開始アクションを監視するタスク。
 *
 * @param peer
 */
export function* watchSendingWhisperInvitationAction(peer: Peer) {
  try {
    while (true) {
      const action = yield take(Action.SEND_WHISPER_INVITATION_START);
      const { targetId } = action.payload;

      const currentUser: User = yield select((state: RootState) => state.homeSubscriber.currentUserStatus);

      const metadata: ConnectionMetadata = { type: 'whisper-invitation' };
      const invitationContent: InvitationContent = {
        name: currentUser.name,
        iconUrl: ICON_URL,
        isVoiceOnly: true,
      };

      try {
        const conn = peer.connect(targetId, { metadata });
        const localInvitation = new LocalInvitation(conn);
        yield fork(watchLocalInvitationEvents, localInvitation, peer);
        conn.on('open', () => {
          localInvitation.invite(invitationContent);
        });
        yield put(openSendingWhisperInvitationModal(localInvitation));
      } catch (error) {
        analytics.logEvent('send_whisper_invitation_failure', { uid: peer.id, remoteId: targetId, error });
        yield put(storeCallError({ msgKey: `招待に失敗しました: ${error.message}` }));
      }
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey: '招待の送信でエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  }
}
