import { call, put, takeLatest } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import { signInActions, signInAnonymouslyActions } from 'actions/auth/signIn';
import * as api from 'services/firebase/api';
import firebase from 'firebase/Instances';
import { commonActions } from 'actions/common/common';
import { disconnectCallServerActions } from 'actions/call/call';

const switchMessage = (code: string) => {
  switch (code) {
    case 'auth/credential-already-in-use':
      return 'すでに別のサービスのアカウントでZaiTarkのアカウントが作成されています。';
    case 'auth/popup-blocked':
      return 'ポップアップがブロックされました。ポップアップを許可して再度お試しください。';
    // ポップアップのクローズはエラーとしない
    case 'auth/popup-closed-by-user':
      return '';
    case 'auth/invalid-action-code':
      return 'URLが無効です。お手数ですがはじめからやり直してください。';
    case 'auth/expired-action-code':
      return 'URLが無効です。お手数ですがはじめからやり直してください。';
    case 'auth/user-disabled':
      return '指定したユーザは管理者によって無効にされています。';
    case 'domain_error':
      return '許可されていないドメインです。';
    default:
      return `予期せぬエラーが発生しました。code: ${code}`;
  }
};

export function* runSignInGoogle() {
  try {
    yield put(commonActions.clear());
    const user: firebase.User = yield call(api.signInGoogle);
    yield put(signInActions.success(user));
  } catch (e) {
    const message = switchMessage(e.code || e.message);
    yield put(signInActions.error());
    yield put(commonActions.error(message));
  }
}

export function* watchSignInGoogle() {
  yield takeLatest(Action.SIGN_IN_GOOGLE, runSignInGoogle);
}

export function* runSignOut() {
  yield call(api.signOut);
  yield put(disconnectCallServerActions.start());
}

export function* watchSignOut() {
  yield takeLatest(Action.SIGN_OUT, runSignOut);
}

export function* runSignInAnonymously(action: ReturnType<typeof signInAnonymouslyActions.start>) {
  try {
    yield call(api.signInAnonymously, action.payload.userName);
  } catch (e) {
    yield put(signInAnonymouslyActions.error(e.message));
  }
  yield put(signInAnonymouslyActions.success());
}

export function* watchSignInAnonymously() {
  yield takeLatest(Action.SIGN_IN_ANONYMOUSLY_START, runSignInAnonymously);
}
