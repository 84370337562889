/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { BasicIconButton } from 'components/common/parts/BasicIconButton';
import { BasicMessageBox } from 'components/common/parts/BasicMessageBox';
import { Spacer } from 'components/common/parts/Spacer';
import { popUpAndToastZIndex } from 'styles/style';
import CloseIcon from 'styles/image/close.svg';

const notification = css`
  animation-duration: 0.3s;
  animation-name: fadeUp;
  height: fit-content;
  inset: 20px 0 0 0;
  margin: 0 auto;
  max-width: 70%;
  position: fixed;
  width: fit-content;
  ${popUpAndToastZIndex};

  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

interface Props {
  msgKeys: string[];
  onClose: () => void;
}

export const CallNotification: FC<Props> = ({ msgKeys, onClose }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [onClose]);

  return (
    <div css={notification}>
      <BasicMessageBox color="blue">
        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            {msgKeys.map(key => (
              <div key={key}>{key}</div>
            ))}
          </div>
          <Spacer width="10px" />
          <BasicIconButton iconPath={CloseIcon} pxSize={14} onClick={onClose} />
        </div>
      </BasicMessageBox>
    </div>
  );
};
