import { call, cancelled, put, select, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { RoomStream, SfuRoom } from 'skyway-js';
import {
  addRemoteStream,
  CallAction,
  receiveRemoteStream,
  receiveRemoteStreamStats,
  remotePeerJoined,
  removeRemoteUser,
  sendLocalStreamStat,
  setMaximizedUid,
  setRemoteStat,
  setRemoteUser,
  stopCallingActions,
  storeCallError,
  updateRemoteUserSpeaking,
} from 'actions/call/call';
import {
  RECEIVE_REMOTE_STREAM,
  RECEIVE_REMOTE_STREAM_STATS,
  REMOTE_PEER_JOINED,
  SEND_LOCAL_STREAM_STAT,
} from 'actions/ActionTypeConstants';
import { Stream } from 'sagas/call/classes/Stream';
import { RootState } from 'reducers/mainReducer';
import { RoomData, StreamStat } from 'sagas/call/classes/RoomData';
import { sendStreamStat } from 'sagas/call/tasks/watchJoiningRoomAction';
import { AudioVolumeIndicator } from 'sagas/call/classes/AudioVolumeIndicator';
import { analytics } from 'firebase/Instances';
import { getUserInfoInCallView } from 'services/firebase/api';
import { CallingUser } from 'reducers/call/call';
import { setCanvasSender } from 'actions/call/workboard';
import { DrawingDataSender } from '../classes/DrawingDataSender';

const subscribe = (uid: string, room: SfuRoom) =>
  eventChannel(emitter => {
    // 通話用 RTC Client のイベント購読
    room.on('open', async () => {
      const sender = new DrawingDataSender(room);
      emitter(sendLocalStreamStat());
      emitter(setCanvasSender(sender));
    });

    room.on('peerJoin', async (peerId: string) => {
      emitter(remotePeerJoined(peerId));
    });

    room.on('peerLeave', async (peerId: string) => {
      emitter(removeRemoteUser(peerId));

      if (room.members.length === 0) {
        // room.members には自分を除いたメンバーの uid が格納されている
        emitter(stopCallingActions.start());
      }
    });

    let indicator: AudioVolumeIndicator;

    room.on('stream', async (stream: RoomStream) => {
      emitter(receiveRemoteStream(stream.peerId, new Stream(stream.getAudioTracks()[0], stream.getVideoTracks()[0])));

      indicator = new AudioVolumeIndicator(stream);
      indicator.on('speaking', () => {
        emitter(updateRemoteUserSpeaking(stream.peerId, true));
      });
      indicator.on('stopped_speaking', () => {
        emitter(updateRemoteUserSpeaking(stream.peerId, false));
      });
    });

    room.on('data', async roomData => {
      const { data }: { data: RoomData } = roomData;
      switch (data.type) {
        case 'stat': {
          emitter(receiveRemoteStreamStats(roomData.src, data.payload));
          if (data.payload.videoType === 'screen') {
            emitter(setMaximizedUid(roomData.src));
          }
          break;
        }
        case 'canvas': {
          // components/call/workboard/Canvas.tsxで処理するため何もしない
          break;
        }
        default: {
          const { type } = data;
          emitter(storeCallError({ msgKey: `不明なデータを受信しました。type:${type}` }));
        }
      }
    });

    room.on('error', error => {
      analytics.logEvent('room_error', { uid, roomName: room.name, error });
      throw error;
    });

    return async () => {
      room.close();
      if (indicator) {
        await indicator.closeIndicator();
      }
    };
  });

function* setRemoteUserWithOtherInfoIfDoesNotExist(uid: string) {
  const remoteUser: CallingUser = yield select((state: RootState) => state.call.remoteUsers.find(r => r.uid === uid));
  if (!remoteUser) {
    const [targetName, targetIconUrl] = yield call(getUserInfoInCallView, uid);
    yield put(setRemoteUser(uid, targetName, targetIconUrl));
  }
}

function* sendLocalStreamStats(room: SfuRoom) {
  const stat: StreamStat = yield select((store: RootState) => store.call.localUser?.streamStat);
  sendStreamStat(room, stat);
}

function* handleRoomEvents(action: CallAction) {
  const room: SfuRoom = yield select((state: RootState) => state.call.room);

  switch (action.type) {
    case REMOTE_PEER_JOINED: {
      const { uid } = action.payload;
      yield call(setRemoteUserWithOtherInfoIfDoesNotExist, uid);
      yield call(sendLocalStreamStats, room);
      break;
    }
    case RECEIVE_REMOTE_STREAM: {
      const { uid, stream } = action.payload;
      yield call(setRemoteUserWithOtherInfoIfDoesNotExist, uid);
      yield put(addRemoteStream(uid, stream));
      break;
    }
    case RECEIVE_REMOTE_STREAM_STATS: {
      const { uid, stat } = action.payload;

      // 拡大画面共有していた人が画面共有を停止した場合、拡大表示を終了する
      const remoteUser = yield select((state: RootState) => state.call.remoteUsers.find(r => r.uid === uid));
      const prevVideoType = remoteUser?.streamStat?.videoType;
      if (prevVideoType === 'screen' && (stat.videoType === 'camera' || stat.videoType === 'dummy')) {
        const maximizedUid = yield select((state: RootState) => state.call.uiState.maximizedUid);
        if (maximizedUid === uid) {
          yield put(setMaximizedUid(undefined));
        }
      }

      yield call(setRemoteUserWithOtherInfoIfDoesNotExist, uid);

      yield put(setRemoteStat(uid, stat));

      break;
    }
    case SEND_LOCAL_STREAM_STAT: {
      yield call(sendLocalStreamStats, room);
      break;
    }
    default: {
      // nothing to do
    }
  }

  yield put(action);
}

/**
 * 通話ルームのイベントを監視する。
 *
 * @param currentUserId
 * @param room JOIN したルームのインスタンス
 */
export function* subscribeRoomEvents(currentUserId: string, room: SfuRoom) {
  const channel = yield call(subscribe, currentUserId, room);
  try {
    yield takeEvery(channel, handleRoomEvents);
  } catch (error) {
    yield put(
      storeCallError({
        msgKey:
          '通話用ルームとの接続でエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}
