/** @jsx jsx */
import { FC } from 'react';
import { css, jsx } from '@emotion/core';
import RefusedIcon from 'styles/image/call_refuse.png';
import { popFont } from 'styles/style';

const denyMsg = css`
  ${popFont};
  font-size: 20px;
`;

/**
 * 通話画面上で着信を拒否したユーザを表現するコンポーネント
 */
export const RefusingUser: FC = () => {
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <img src={RefusedIcon} css={{ height: '30%' }} alt="refuse" />
      <p css={denyMsg}>すみません、後ほどお願いします。</p>
    </div>
  );
};
