/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { Dimmer, Loader } from 'semantic-ui-react';

const Spinner: FC<{ text?: string }> = ({ text = '読み込み中...' }) => (
  <div>
    <Dimmer active inverted>
      <Loader inverted={false}>{text}</Loader>
    </Dimmer>
  </div>
);

export default Spinner;
