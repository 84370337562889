/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import Peer, { SfuRoom } from 'skyway-js';
import { v4 as uuidv4 } from 'uuid';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import {
  joinRoomActions,
  removeRemoteUser,
  setLocalUser,
  stopCallingActions,
  storeChildTaskOfCall,
  storeCallError,
  storeMessage,
  storeRoom,
} from 'actions/call/call';
import { RootState } from 'reducers/mainReducer';
import { Stream } from 'sagas/call/classes/Stream';
import { StreamStat } from 'sagas/call/classes/RoomData';
import { RemoteInvitation } from 'sagas/call/classes/RemoteInvitation';
import { AudioVolumeIndicator } from 'sagas/call/classes/AudioVolumeIndicator';
import { subscribeRoomEvents } from 'sagas/call/tasks/subscribeRoomEvents';
import { watchSendingInvitationAction } from 'sagas/call/tasks/watchSendingInvitationAction';
import { watchSharingScreenAction } from 'sagas/call/tasks/watchSharingScreenAction';
import {
  watchChangingAudioDeviceAction,
  watchChangingMediaDeviceAction,
} from 'sagas/call/tasks/watchChangingDeviceAction';
import {
  watchEnablingVideoTrackAction,
  watchUpdatingAudioTrackEnabledAction,
} from 'sagas/call/tasks/watchEnablingTrackAction';
import { watchDisablingVideoTrackAction } from 'sagas/call/tasks/watchDisablingTrackAction';
import { subscribeLocalVolume } from 'sagas/call/tasks/subscribeLocalVolume';
import { CallingUser } from 'reducers/call/call';
import { REMOVE_REMOTE_USER_AFTER_3_SEC } from 'actions/ActionTypeConstants';
import { watchSendingWhisperInvitationAction } from 'sagas/call/tasks/watchSendingWhisperInvitationAction';
import { analytics } from 'firebase/Instances';
import { watchStartingWhisperAction } from 'sagas/call/tasks/watchWhisperActions';
import { getUserInfoInCallView } from 'services/firebase/api';
import { watchFetchingCallableMembersAction } from 'sagas/call/tasks/watchCallableMembersAction';
import { clearWorkboardData, joinWorkboard } from 'actions/call/workboard';
import { watchRecoveringTalkingStatusAction } from 'sagas/call/tasks/watchRecoveringTalkingStatusAction';

/**
 * Room にいる全員に自身の Stream の情報を展開する。
 *
 * @param room
 * @param stat
 */
export const sendStreamStat = (room: SfuRoom, stat: StreamStat) => {
  room.send({ type: 'stat', payload: stat });
};

function* watchRemovingRemoteUser(action: ReturnType<typeof removeRemoteUser>) {
  try {
    yield delay(2000);
    const targetUser: CallingUser = yield select((state: RootState) =>
      state.call.remoteUsers.find(r => r.uid === action.payload.targetUid),
    );
    if (targetUser.stream) {
      // A, B で通話中に、C に対して二人同時に招待を送り、片方を受諾して参加してきた場合の考慮。
      // 自身の招待は拒否されたが、別メンバーからの招待で通話に参加してきているため、 removeRemoteUser アクションは発行しない
    } else {
      yield put(removeRemoteUser(action.payload.targetUid));
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey:
          '招待キャンセルまたは拒否ユーザの処理中にエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  }
}

function* runChildTasks(peer: Peer, room: SfuRoom, stream: Stream, indicator: AudioVolumeIndicator) {
  yield fork(subscribeRoomEvents, peer.id, room);
  yield fork(subscribeLocalVolume, indicator);
  yield fork(watchSendingInvitationAction, peer);
  yield fork(watchSendingWhisperInvitationAction, peer);
  yield fork(watchStartingWhisperAction);
  yield fork(watchSharingScreenAction, peer.id, room, stream);
  yield fork(watchChangingAudioDeviceAction, room, stream, indicator);
  yield fork(watchChangingMediaDeviceAction, room);
  yield fork(watchEnablingVideoTrackAction, room);
  yield fork(watchUpdatingAudioTrackEnabledAction, room);
  yield fork(watchDisablingVideoTrackAction, room);
  yield takeEvery(REMOVE_REMOTE_USER_AFTER_3_SEC, watchRemovingRemoteUser);
  yield fork(watchFetchingCallableMembersAction);
  yield fork(watchRecoveringTalkingStatusAction, room.name);
}

/**
 * 通話への参加アクションを監視するタスク。
 * 動くタイミングは 新規通話開始時 or 着信受諾時 or 既存通話参加時 。
 *
 * @param peer 通話用の RTC Client
 */
export function* watchJoiningRoomAction(peer: Peer) {
  while (true) {
    const action = yield take(Action.JOIN_ROOM_START);

    // 別のルームに入っている場合は一度抜ける。
    // 基本的に通話中の人には発信できないようにしているので、発信と同時に着信した場合などのエッジケースのための処理
    yield put(stopCallingActions.start());
    yield put(clearWorkboardData());

    const { invitedRoomId, isVoiceOnly, callbackAfterJoining } = action.payload;
    const warnings: string[] = [];

    // 複数着信が来ていた場合、承諾したもの以外は拒否しておく
    const receivedInvitations: RemoteInvitation[] = yield select(
      (state: RootState) => state.call.receivedInvitationList,
    );
    receivedInvitations
      .filter(i => i.getContent()?.roomId !== invitedRoomId)
      .forEach(i => {
        try {
          i.refuse();
        } catch {
          // 相手側でキャンセル済みなど、無効な招待を拒否した場合に例外が発生するが特に通知する必要性はないので無視する
        }
      });
    const acceptedInvitation = receivedInvitations.find(
      i => i.getContent()?.roomId === invitedRoomId,
    ) as RemoteInvitation; // 必ず存在する

    // オーディオ、ビデオの取得、パブリッシュ
    const streamStat: StreamStat = { videoType: 'unknown', isAudioEnabled: true, isVideoEnabled: true };
    const { audioTrack, audioDeviceError } = yield call(Stream.createAudioTrack);
    if (audioDeviceError) {
      if (acceptedInvitation) {
        acceptedInvitation.failedToJoin();
      }
      yield put(
        joinRoomActions.fail(
          '使用可能なデバイスが存在しないため通話に参加できませんでした。デバイス設定からデバイスの確認してください。',
        ),
      );
      continue; // eslint-disable-line no-continue
    }
    const stream = new Stream(audioTrack);
    streamStat.isAudioEnabled = true;
    const dummyVideoTrack = yield select((store: RootState) => store.call.dummyVideoTrack);
    if (isVoiceOnly) {
      stream.setOrReplaceVideoTrack(dummyVideoTrack);
      streamStat.videoType = 'dummy';
      streamStat.isVideoEnabled = false;
    } else {
      const { videoTrack, videoDeviceError } = yield call(Stream.createVideoTrack);
      if (videoDeviceError) {
        yield put(storeMessage('カメラの取得に失敗しました。デバイス設定で使用可能なカメラを選択してください。'));
        stream.setOrReplaceVideoTrack(dummyVideoTrack);
        streamStat.videoType = 'dummy';
        streamStat.isVideoEnabled = false;
      } else {
        stream.setOrReplaceVideoTrack(videoTrack);
        streamStat.videoType = 'camera';
        streamStat.isVideoEnabled = true;
      }
    }
    const [targetName, targetIconUrl] = yield call(getUserInfoInCallView, peer.id);
    yield put(setLocalUser(peer.id, targetName, targetIconUrl, stream, streamStat));

    // ルーム名生成
    let targetRoomId: string;
    if (invitedRoomId) {
      targetRoomId = invitedRoomId;
      yield put(joinWorkboard.start(targetRoomId, false));
    } else {
      // 新規作成の際は uuidv4 で roomId を生成する。
      // スペース ID は Firestore のドキュメントID 自動採番によるものだが、最大長の明記がないため念のため 10 桁に絞る
      targetRoomId = `${uuidv4()}`;
      yield put(joinWorkboard.start(targetRoomId, true));
    }

    // ルーム参加
    const room = peer.joinRoom<SfuRoom>(targetRoomId, { mode: 'sfu', stream: stream.getMediaStream() });
    analytics.logEvent('join_room_succeeded', { uid: peer.id, roomId: targetRoomId, streamStat });
    yield put(storeRoom(room));

    const indicator = new AudioVolumeIndicator(stream.getMediaStream());

    // 様々な子タスクを走らせる
    const childTask = yield fork(runChildTasks, peer, room, stream, indicator);
    yield put(storeChildTaskOfCall(childTask));

    // 新規通話開始時の招待など、Room 参加後に実行するコールバックがあれば実行する
    if (callbackAfterJoining) {
      callbackAfterJoining(targetRoomId);
    }

    // Firestore の状態を更新し、参加完了
    yield call(api.updateMyCallStatusOnTalking, targetRoomId);
    yield put(joinRoomActions.succeed(warnings));
  }
}
