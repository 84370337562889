import { takeLatest, call } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { updateLocationActions } from 'actions/home/updateLocation';

export function* runUpdateLocation(action: ReturnType<typeof updateLocationActions.start>) {
  const { location } = action.payload;

  try {
    const handler = api.updateLocationFactory();
    yield call(handler, location);
  } catch (error) {
    // nothing to do
  }
}

export function* watchUpdateLocation() {
  yield takeLatest(Action.HOME_UPDATE_LOCATION, runUpdateLocation);
}
