/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateStatusActions, UpdateStatusParams } from 'actions/home/updateStatus';
import { Status } from 'components/home/Status';
import { StatusCode } from 'components/common/Statuses';
import { RootState } from 'reducers/mainReducer';

interface StatusProps {
  isOwn: boolean;
  statusCode: StatusCode;
}

const UpdateStatusContainer: FC<StatusProps> = ({ isOwn, statusCode }) => {
  const dispatch = useDispatch();
  const currentStatus = useSelector<RootState, string>(state => state.homeSubscriber.currentUserStatus.status);

  const updateStatus = (status: string) => {
    if (currentStatus !== status) {
      let breakStartTimeValue = 0;
      if (status === 'break') {
        const now = new Date();
        breakStartTimeValue = now.getHours() * 100 + now.getMinutes();
      }
      const params: UpdateStatusParams = { status, breakStartTimeValue };
      if (status !== statusCode) {
        dispatch(updateStatusActions.start(params));
      }
    }
  };

  return <Status updateStatus={updateStatus} statusCode={statusCode} isOwn={isOwn} />;
};

export default UpdateStatusContainer;
