/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { PATH_HOME } from 'RouteManager';
import firebase, { db } from 'firebase/Instances';
import { RootState } from 'reducers/mainReducer';
import { subscribeMyUsers, subscribeNowLoginUsers } from 'actions/home/subscriber';
import { setSplashDisplayed } from 'actions/splash/splash';
import { User } from 'components/home/Home';
import Spinner from 'components/common/Spinner';

export const SubscribeOnlineUsers: FC<{ authUser: firebase.User }> = ({ authUser, children }) => {
  const dispatch = useDispatch();

  const [onlineUsers, loadingOnlineUsers, collectionError] = useCollectionData<User>(db.collection('users'), {
    idField: 'uid',
  });
  const splashDisplayed = useSelector<RootState, boolean>(state => state.splash.displayed);

  if (loadingOnlineUsers) {
    if (splashDisplayed) {
      return <div />;
    }

    return <Spinner text="ログインしています..." />;
  }

  if (onlineUsers) {
    const user = onlineUsers.find(onlineUser => authUser.uid === onlineUser.uid);
    if (user) dispatch(subscribeMyUsers.start(user));

    onlineUsers.forEach(onlineUser => {
      dispatch(subscribeNowLoginUsers.start(onlineUser));
    });

    dispatch(setSplashDisplayed(false));

    return (
      <div>
        <Redirect to={PATH_HOME} />
        {children}
      </div>
    );
  }

  if (collectionError) {
    // auth にログインできていないとこちらもエラーになるが、特に表示は必要ないので無視する
  }

  return <div />;
};
