/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { BasicSearchBox } from './parts/BasicSearchBox';

export interface MemberSearchProps {
  handleOnFocused?: () => void;
  handleOnChanged: (str: string) => void;
}
export const MemberSearch: FC<MemberSearchProps> = ({ handleOnFocused = () => {}, handleOnChanged }) => {
  return (
    <BasicSearchBox
      placeholder="名前を入力して検索..."
      onFocus={handleOnFocused}
      onChange={e => handleOnChanged(e.target.value)}
      boxSize="large"
    />
  );
};
