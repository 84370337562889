/** @jsx jsx */
import { FC, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers/mainReducer';
import { joinRoomActions, sendInvitationActions, setNoticeForGuest } from 'actions/call/call';
import { StatusCode } from 'components/common/Statuses';
import { CallingUser } from 'reducers/call/call';
import ImageView from 'components/home/ImageView';
import { ICON_URL, User } from 'components/home/Home';

export interface ImageViewIconUrl {
  uid: string;
  iconUrl: string;
}

interface ImageViewProps {
  uid: string;
  isOwn: boolean;
  demoUser: boolean;
  isGuest: boolean;
  myRoomId: string;
  roomId: string;
  status: StatusCode;
  breakStartTime: number;
  userIsShade: boolean;
}

const ImageViewContainer: FC<ImageViewProps> = ({
  uid,
  isOwn,
  demoUser,
  isGuest,
  myRoomId,
  roomId,
  status,
  breakStartTime,
  userIsShade,
}) => {
  const dispatch = useDispatch();
  const [snapshotUrl, setSnapshotUrl] = useState(ICON_URL);
  const CAPTURE_MAX_NUMBER = 30;

  // 実際のZaiTarkはひとりひとりが30秒に一度キャプチャを取得してアップロードし表示画像を入れ替えているが、ダミーなので固定のものを一気に差し替えしている
  useEffect(() => {
    if (!isGuest) {
      let capNumber = Number(uid.slice(-1)) + 1;
      if (!demoUser) capNumber = Math.floor(Math.random() * (CAPTURE_MAX_NUMBER - 1 + 1) + 1);
      setSnapshotUrl(`${process.env.PUBLIC_URL}/images/randomCaptures/${capNumber}.png`);
      const switchSnapshotUrl = setInterval(() => {
        if (capNumber === CAPTURE_MAX_NUMBER) {
          capNumber = 1;
        } else {
          capNumber += 1;
        }
        setSnapshotUrl(`${process.env.PUBLIC_URL}/images/randomCaptures/${capNumber}.png`);
      }, 30000);

      return () => clearInterval(switchSnapshotUrl);
    }

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const salesMembers = useSelector<RootState, User[]>(state => state.homeSubscriber.salesMembers).filter(
    member => !member.isTalking && member.status === 'presence',
  );
  const isGuestOwn = useSelector<RootState, boolean>(state => state.homeSubscriber.currentUserStatus.isGuest);

  const startConversation = (isVoiceOnly: boolean) => {
    const salesIds = salesMembers.map(member => member.uid);
    // 新規通話開始時はルームへ参加後に相手に招待を送る
    const callback = (createdRoomId: string) => {
      // 営業担当者のうち、通話中ではない人全員にコールする
      dispatch(sendInvitationActions.start(salesIds, isVoiceOnly, createdRoomId));
      dispatch(setNoticeForGuest(true));
    };
    dispatch(joinRoomActions.start(isVoiceOnly, undefined, callback));
  };

  const handleOnCallClick = (targetId: string) => {
    if (salesMembers.find(member => member.uid === targetId) && isGuestOwn) startConversation(true);
  };

  const handleOnVideoClick = (targetId: string) => {
    if (salesMembers.find(member => member.uid === targetId) && isGuestOwn) startConversation(false);
  };

  const localUser = useSelector<RootState, CallingUser | undefined>(state => state.call.localUser);
  const remoteUsers = useSelector<RootState, CallingUser[]>(state => state.call.remoteUsers);

  const callUser = isOwn ? localUser : remoteUsers.find(user => user.uid === uid);

  return (
    <ImageView
      uid={uid}
      isOwn={isOwn}
      isGuestOwn={isGuestOwn}
      demoUser={demoUser}
      isGuest={isGuest}
      myRoomId={myRoomId}
      roomId={roomId}
      status={status}
      breakStartTime={breakStartTime}
      snapshotUrl={snapshotUrl}
      isShade={userIsShade}
      handleOnCallClick={handleOnCallClick}
      handleOnVideoClick={handleOnVideoClick}
      callUserStatus={callUser?.status}
    />
  );
};

export default ImageViewContainer;
