/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import iconTop from 'styles/image/logo_top.png';
import { COLORS } from 'styles/style';

const pageTitleStyle = css`
  align-items: center;
  background-color: ${COLORS.blue};
  display: flex;
  height: 60px;
  padding: 0 30px;

  h1 {
    color: #fff;
    font-size: 24px;
    line-height: 1;
    margin: 0;
    padding-top: 12px;
  }
`;

export const iconTopCss = css`
  height: 36px;
  margin-right: 20px;
`;

export const AuthPageHeader: FC = () => {
  return (
    <div css={pageTitleStyle}>
      <img src={iconTop} alt="ZaiTark" css={iconTopCss} />
      <h1>Demo</h1>
    </div>
  );
};
