import * as ActionType from 'actions/ActionTypeConstants';

export const pushHtmlHeaderMessages = (messages: string[]) => ({
  type: ActionType.PUSH_HTML_HEADER_MESSAGES as typeof ActionType.PUSH_HTML_HEADER_MESSAGES,
  payload: { messages },
});

export const removeHtmlHeaderMessage = (removeTarget: string) => ({
  type: ActionType.REMOVE_HTML_HEADER_MESSAGES as typeof ActionType.REMOVE_HTML_HEADER_MESSAGES,
  payload: { removeTarget },
});

export const clearHtmlHeaderMessages = () => ({
  type: ActionType.CLEAR_HTML_HEADER_MESSAGES as typeof ActionType.CLEAR_HTML_HEADER_MESSAGES,
});

export type htmlHeaderAction =
  | ReturnType<typeof pushHtmlHeaderMessages>
  | ReturnType<typeof removeHtmlHeaderMessage>
  | ReturnType<typeof clearHtmlHeaderMessages>;
