/* eslint-disable no-console */
import { call, cancel, cancelled, fork, put, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { SfuRoom } from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import {
  shareScreenActions,
  stopShareScreenActions,
  storeCallError,
  storeMessage,
  updateLocalStream,
} from 'actions/call/call';
import { Stream } from 'sagas/call/classes/Stream';
import { StreamStat } from 'sagas/call/classes/RoomData';
import { sendStreamStat } from 'sagas/call/tasks/watchJoiningRoomAction';
import { RootState } from 'reducers/mainReducer';
import { convertCaptureErrorToMsg } from 'common/Util';
import { analytics } from '../../../firebase/Instances';

const subscribe = (track: MediaStreamTrack) =>
  eventChannel(emitter => {
    // eslint-disable-next-line no-param-reassign
    track.onended = () => emitter(stopShareScreenActions.start());

    return () => {};
  });

/**
 * ZaiTark のメニューからではなく、ブラウザから直接共有を停止された場合のイベントを監視するタスク。
 * ブラウザから停止された場合にもメニューから停止された時と同様の処理を行う。
 *
 * @param track 画面共有のビデオトラック
 */
function* subscribeTrackOnEndEvent(track: MediaStreamTrack) {
  const channel = yield call(subscribe, track);
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

/**
 * 通話中の画面共有開始、終了のアクションを監視するタスク。
 *
 * @param uid
 * @param room
 * @param stream
 */
export function* watchSharingScreenAction(uid: string, room: SfuRoom, stream: Stream) {
  try {
    while (true) {
      yield take(Action.SHARE_SCREEN_START);
      analytics.logEvent('start_sharing_screen', { uid, roomName: room.name });

      const {
        displayTrack,
        createDisplayTrackError,
      }: { displayTrack: MediaStreamTrack; createDisplayTrackError: Error } = yield call(Stream.createDisplayTrack);
      if (createDisplayTrackError) {
        const msg = convertCaptureErrorToMsg(createDisplayTrackError);
        if (msg) {
          analytics.logEvent('start_sharing_screen_error', {
            uid,
            roomName: room.name,
            error: createDisplayTrackError,
          });
          yield put(storeCallError({ msgKey: msg }));
        }
        continue; // eslint-disable-line no-continue
      }
      const task = yield fork(subscribeTrackOnEndEvent, displayTrack);
      stream.setOrReplaceVideoTrack(displayTrack);

      const statBeforeStartSharing: StreamStat = yield select((store: RootState) => store.call.localUser?.streamStat);
      const streamStat: StreamStat = {
        videoType: 'screen',
        isAudioEnabled: statBeforeStartSharing.isAudioEnabled,
        isVideoEnabled: true,
      };
      room.replaceStream(stream.getMediaStream());
      sendStreamStat(room, streamStat);

      yield put(updateLocalStream(stream, streamStat));
      yield put(shareScreenActions.success());

      // 共有終了の待ち受け、終了の際には元の Stream に戻す
      yield take(Action.STOP_SHARE_SCREEN_START);
      yield cancel(task);

      const statBeforeStopSharing: StreamStat = yield select((store: RootState) => store.call.localUser?.streamStat);
      const newStat: StreamStat = {
        videoType: statBeforeStartSharing.videoType,
        isAudioEnabled: statBeforeStopSharing.isAudioEnabled, // 音声だけは画面共有終了時の状態を利用
        isVideoEnabled: statBeforeStartSharing.isVideoEnabled,
      };

      if (statBeforeStartSharing.videoType === 'camera') {
        const { videoTrack, videoDeviceError } = yield call(Stream.createVideoTrack);
        if (videoDeviceError) {
          // 共有前はカメラを使用していたが、共有中に別アプリなどでカメラを掴んでしまい復帰に失敗したパターン
          yield put(storeMessage('カメラの取得に失敗しました。デバイス設定で使用可能なカメラを選択してください。'));
          const dummyVideoTrack = yield select((store: RootState) => store.call.dummyVideoTrack);
          stream.setOrReplaceVideoTrack(dummyVideoTrack);
          newStat.videoType = 'dummy';
          newStat.isVideoEnabled = false;
        } else {
          stream.setOrReplaceVideoTrack(videoTrack);
        }
      }

      room.replaceStream(stream.getMediaStream());
      sendStreamStat(room, newStat);
      displayTrack.stop();
      yield put(updateLocalStream(stream, newStat));
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey:
          '画面共有の切り替え時にエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  }
}
