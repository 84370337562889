import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getWorkboardFileUrlActions,
  workboardDeleteFileActions,
  workboardUploadFilesActions,
  sendTextChatActions,
  sendCanvasTmpData,
  getCanvasHistoryActions,
  joinWorkboard,
} from 'actions/call/workboard';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { RootState } from 'reducers/mainReducer';
import { commonActions } from 'actions/common/common';
import { setJoinWorkboardError } from 'actions/call/call';
import { CanvasTmpData } from 'reducers/call/workboard';

export function* runWorkboardUploadFiles(action: ReturnType<typeof workboardUploadFilesActions.start>) {
  const { file } = action.payload;
  try {
    yield put(commonActions.clear());
    const roomId: string = yield select((state: RootState) => state.workboard.roomId);
    yield call(api.uploadWorkboardFile, file, roomId);
    yield put(workboardUploadFilesActions.success());
  } catch (error) {
    yield put(workboardUploadFilesActions.error());
    yield put(commonActions.error(error.message));
  }
}

export function* watchWorkboardUploadFiles() {
  yield takeEvery(Action.WORKBOARD_UPLOAD_FILES_START, runWorkboardUploadFiles);
}

export function* runWorkboardDeleteFile(action: ReturnType<typeof workboardDeleteFileActions.start>) {
  try {
    yield put(commonActions.clear());
    const roomId: string = yield select((state: RootState) => state.workboard.roomId);
    const { fileState } = action.payload;
    const filePath = `workboards/${roomId}/${fileState.dirPath}/${fileState.name}`;
    const docPath = `workboards/${roomId}/files/${fileState.id}`;
    yield call(api.deleteStorageFile, roomId, filePath, docPath, 'call');
    yield put(workboardDeleteFileActions.success());
  } catch (error) {
    yield put(workboardDeleteFileActions.error());
    yield put(commonActions.error(error.message));
  }
}

export function* watchWorkboardDeleteFile() {
  yield takeLatest(Action.WORKBOARD_DELETE_FILE_START, runWorkboardDeleteFile);
}

export function* runSendTextChat(action: ReturnType<typeof sendTextChatActions.start>) {
  const { text } = action.payload;
  yield put(commonActions.clear());
  try {
    const roomId: string = yield select((state: RootState) => state.workboard.roomId);
    const userName: string = yield select((state: RootState) => state.homeSubscriber.currentUserStatus.name);
    yield call(api.sendTextChat, text, roomId, userName);
    yield put(sendTextChatActions.success());
  } catch (error) {
    yield put(sendTextChatActions.error());
  }
}

export function* watchSendTextChat() {
  yield takeLatest(Action.SEND_TEXT_CHAT_START, runSendTextChat);
}
export function* runGetWorkboardFileUrl(action: ReturnType<typeof getWorkboardFileUrlActions.start>) {
  try {
    yield put(commonActions.error(''));
    const roomId: string = yield select((state: RootState) => state.workboard.roomId);
    const { fileState } = action.payload;
    const filePath = `workboards/${roomId}/${fileState.dirPath}/${fileState.name}`;
    const url: string = yield call(api.getStorageFileUrl, roomId, filePath, 'call');
    yield put(getWorkboardFileUrlActions.success(url));
  } catch (error) {
    yield put(getWorkboardFileUrlActions.error());
    yield put(commonActions.error(error.message));
  }
}

export function* watchGetWorkboardFileUrl() {
  yield takeLatest(Action.GET_WORKBOARD_FILE_URL_START, runGetWorkboardFileUrl);
}

export function* runSendCanvasTmpData(action: ReturnType<typeof sendCanvasTmpData>) {
  const roomId: string = yield select((state: RootState) => state.workboard.roomId);
  yield call(api.sendTmpCanvasData, action.payload.tmpData, roomId);
}
export function* watchSendCanvasTmpData() {
  yield takeLatest(Action.SEND_CANVAS_TMP_DATA, runSendCanvasTmpData);
}

export function* runGetCanvasHistory() {
  const roomId: string = yield select((state: RootState) => state.workboard.roomId);

  try {
    yield put(commonActions.clear());
    const data: CanvasTmpData = yield call(api.getCanvasHistory, roomId);
    yield put(getCanvasHistoryActions.success(data));
  } catch (error) {
    yield put(commonActions.error(error.message));
    yield put(getCanvasHistoryActions.error());
  }
}
export function* watchGetCanvasHistory() {
  yield takeLatest(Action.GET_CANVAS_HISTORY_START, runGetCanvasHistory);
}

export function* runRemoveCanvasMember() {
  const roomId: string = yield select((state: RootState) => state.workboard.roomId);
  const uid: string = yield select((state: RootState) => state.homeSubscriber.currentUserStatus.uid);
  const members: string[] = yield select((state: RootState) => state.workboard.canvasTmpData.members);

  if (roomId && members) {
    const newMembers: string[] = members.filter((id: string) => id !== uid);
    yield call(api.removeCanvasMember, roomId, newMembers);
  }
}
export function* watchRemoveCanvasMember() {
  yield takeLatest(Action.REMOVE_CANVAS_MEMBER, runRemoveCanvasMember);
}

export function* runJoinWorkboard(action: ReturnType<typeof joinWorkboard.start>) {
  try {
    if (action.payload.creating) {
      yield call(api.createWorkboard, action.payload.roomId);
    } else {
      yield call(api.joinWorkboard, action.payload.roomId);
    }
    yield put(joinWorkboard.success());
  } catch (error) {
    yield put(commonActions.error(error.message));
    yield put(setJoinWorkboardError([{ msgKey: error.message }]));
  }
}
export function* watchJoinWorkBoard() {
  yield takeLatest(Action.JOIN_WORKBOARD_START, runJoinWorkboard);
}
