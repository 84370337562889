/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useState } from 'react';
import { jsx } from '@emotion/core';
import { CallableMember } from 'containers/call/modal/useCallableMembers';
import { AllTeamListItem } from 'components/call/modal/AddMemberModal/TeamListItem';
import { BasicMessageBox } from 'components/common/parts/BasicMessageBox';
import { CallModalLoader } from 'components/call/modal/CallModalBase';

interface Props {
  callableMembers: CallableMember[];
  loading: boolean;
  error: Error | undefined;
}

export const ModalBody: FC<Props> = ({ callableMembers, loading, error }) => {
  if (loading) {
    return <CallModalLoader label="読込中..." />;
  }

  if (error) {
    return (
      <BasicMessageBox color="red">
        メンバーの取得に失敗しました。
        <p>{error.message}</p>
      </BasicMessageBox>
    );
  }

  return <AllTeamListItem members={callableMembers} />;
};
