import { put, select, take } from 'redux-saga/effects';
import { SfuRoom } from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import { storeMessage, updateLocalStream } from 'actions/call/call';
import { RootState } from 'reducers/mainReducer';
import { StreamStat } from 'sagas/call/classes/RoomData';
import { Stream } from 'sagas/call/classes/Stream';
import { sendStreamStat } from 'sagas/call/tasks/watchJoiningRoomAction';

/**
 * ビデオを OFF にする際に dispatch されるアクションを監視するタスク。
 * 画面共有時にはビデオの ON/OFF は変更させないので、カメラの場合のみを考慮する。
 *
 * @param room
 */
export function* watchDisablingVideoTrackAction(room: SfuRoom) {
  while (true) {
    const action = yield take(Action.DISABLE_LOCAL_VIDEO_START);
    const { stream, stat }: { stream: Stream; stat: StreamStat } = action.payload;
    try {
      const dummyVideoTrack = yield select((store: RootState) => store.call.dummyVideoTrack);
      stream.setOrReplaceVideoTrack(dummyVideoTrack);
      const newStat: StreamStat = {
        videoType: 'dummy',
        isAudioEnabled: stat.isAudioEnabled,
        isVideoEnabled: false,
      };
      room.replaceStream(stream.getMediaStream());
      sendStreamStat(room, newStat);
      yield put(updateLocalStream(stream, newStat));
    } catch (error) {
      yield put(
        storeMessage(`カメラが使用できませんでした。一度通話を終了し、デバイス設定でカメラの確認をしてください。`),
      );
    }
  }
}
