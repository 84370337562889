import { call, takeLatest } from 'redux-saga/effects';
import * as Action from 'actions/ActionTypeConstants';
import * as api from 'services/firebase/api';
import { updateCommentActions } from 'actions/home/comment';

export function* runUpdateComment(action: ReturnType<typeof updateCommentActions.start>) {
  try {
    yield call(api.updateComment, action.payload.comment, action.payload.commentTime);
  } catch (error) {
    // nothing to do
  }
}

export function* watchUpdateComment() {
  yield takeLatest(Action.HOME_UPDATE_COMMENT, runUpdateComment);
}
