/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { Modal } from 'semantic-ui-react';

interface ErrorNotifyModalProps {
  errorMessages: string[];
}

const ErrorNotifyModal: FC<ErrorNotifyModalProps> = ({ errorMessages }) => {
  return (
    <Modal size="small" open closeOnEscape={false} closeOnDimmerClick={false} onClose={() => {}}>
      <Modal.Header>エラー</Modal.Header>
      <Modal.Content>
        {errorMessages.map(msg => {
          return <p key={msg}>{msg}</p>;
        })}
      </Modal.Content>
    </Modal>
  );
};

export default ErrorNotifyModal;
