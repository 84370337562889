/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { jsx } from '@emotion/core';
import { BasicPopup } from 'components/common/parts/BasicPopup';
import { locationIconOf } from 'components/common/Locations';
import { LocationCode, Locations } from 'actions/home/updateLocation';
import { MemberViewPopup, PersonalHeaderIconBox, SelectButton } from './MemberViewButtons';

export interface LocationProps {
  updateLocation?: (location: LocationCode) => void;
  locationCode: LocationCode;
  isOwn?: boolean;
}

const LocationsList = React.memo(
  ({
    updateLocation,
    currentLocation,
  }: {
    updateLocation: (code: LocationCode) => void;
    currentLocation: LocationCode;
  }) => {
    return (
      <>
        {Locations.map(l => (
          <SelectButton
            iconPath={locationIconOf(l.code)}
            value={l.name}
            key={l.code}
            onClick={() => updateLocation(l.code)}
            currentSelected={l.code === currentLocation}
          />
        ))}
      </>
    );
  },
);

export const Location: FC<LocationProps> = ({ updateLocation = () => {}, locationCode, isOwn = false }) => {
  const locationName = Locations.find(location => location.code === locationCode)?.name || '...';
  if (!isOwn) {
    return (
      <BasicPopup value={locationName} position="bottom">
        <PersonalHeaderIconBox iconPath={locationIconOf(locationCode)} />
      </BasicPopup>
    );
  }

  return (
    <MemberViewPopup value={<LocationsList updateLocation={updateLocation} currentLocation={locationCode} />}>
      <PersonalHeaderIconBox iconPath={locationIconOf(locationCode)} />
    </MemberViewPopup>
  );
};
