import LocationDefault from 'styles/image/icon_status_location_2.svg';
import LocationHome from 'styles/image/icon_location_home.svg';
import LocationOffice from 'styles/image/icon_location_office_1.svg';
import LocationOut from 'styles/image/icon_location_out.svg';
import { LocationCode } from 'actions/home/updateLocation';

export const locationIconOf = (iconCode: LocationCode) => {
  switch (iconCode) {
    case 'home':
      return LocationHome;
    case 'office':
      return LocationOffice;
    case 'goOut':
      return LocationOut;
    default:
      return LocationDefault;
  }
};
