/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, cancelled, fork, take, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { MediaConnection } from 'skyway-js';
import * as Action from 'actions/ActionTypeConstants';
import { CallAction, startWhisperActions, stopWhisperActions, storeCallError } from 'actions/call/call';
import { analytics } from '../../../firebase/Instances';

const subscribe = (conn: MediaConnection) =>
  eventChannel(emitter => {
    conn.on('stream', stream => {
      const audio = document.createElement('audio');
      audio.srcObject = stream;
      audio.play().then(r => r);
    });

    conn.on('close', () => {
      emitter(stopWhisperActions.start());
    });

    conn.on('error', error => {
      emitter(stopWhisperActions.start());
      emitter(storeCallError({ msgKey: 'ひそひそ通話用の接続でエラーが発生しました', detail: error.message }));
    });

    return () => {
      conn.close();
    };
  });

/**
 * ひそひそで利用しているコネクションのイベントを監視する。
 *
 * @param conn
 */
export function* subscribeWhisperConnectionEvents(conn: MediaConnection) {
  const channel = yield call(subscribe, conn);
  try {
    while (true) {
      const action: CallAction = yield take(channel);
      yield put(action);
    }
  } catch (error) {
    yield put(
      storeCallError({
        msgKey:
          'ひそひそ用コネクションの監視中にエラーが発生しました。お手数ですが一度ブラウザをリロードし、通話に再参加してください',
        detail: error.message,
      }),
    );
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export function* watchStartingWhisperAction() {
  let conn: MediaConnection | undefined;
  let localStream: MediaStream | undefined;
  try {
    while (true) {
      const action = yield take(Action.START_WHISPER_START);
      conn = action.payload.conn as MediaConnection;
      analytics.logEvent('whisper_start', { uid: conn.id, remoteId: conn?.remoteId });
      localStream = action.payload.localStream as MediaStream;
      yield fork(subscribeWhisperConnectionEvents, conn);
      yield put(startWhisperActions.succeed(conn.remoteId));

      yield take(Action.STOP_WHISPER_START);
      analytics.logEvent('whisper_end', { uid: conn.id, remoteId: conn?.remoteId });
      conn.close(true);
      localStream.getAudioTracks().forEach(t => t.stop());
    }
  } finally {
    if (yield cancelled()) {
      if (conn) {
        conn.close(true);
      }
      if (localStream) {
        localStream.getAudioTracks().forEach(t => t.stop());
      }
    }
  }
}
