import { DataConnection } from 'skyway-js';
import { ConnectionData, InvitationContent, InvitationStage } from 'sagas/call/classes/ConnectionData';
import { analytics } from 'firebase/Instances';

/**
 * DataConnection をラップし、送信側からみた招待を表すようにしたクラス
 */
export class LocalInvitation {
  private readonly conn: DataConnection;

  private stage: InvitationStage;

  private closedGracefully = false;

  private timerId: NodeJS.Timeout | undefined = undefined;

  constructor(conn: DataConnection) {
    this.conn = conn;
    this.stage = 'sending';

    conn.on('data', (data: ConnectionData) => {
      // conn.on('LocalInvitationReceivedByPeer', () => {
      switch (data.type) {
        case 'invitation': {
          switch (data.payload.stage) {
            case 'received': {
              conn.emit('LocalInvitationReceivedByPeer', conn.remoteId);
              this.stage = 'received';
              break;
            }
            case 'accepted': {
              conn.emit('LocalInvitationAccepted', conn.remoteId);
              this.clearTimer();
              this.stage = 'accepted';
              break;
            }
            case 'refused': {
              conn.emit('LocalInvitationRefused', conn.remoteId);
              this.clearTimer();
              this.stage = 'refused';
              break;
            }
            case 'joinFinished': {
              conn.emit('LocalInvitationJoinFinished', conn.remoteId);
              this.stage = 'joinFinished';
              break;
            }
            case 'joinFailure': {
              conn.emit('LocalInvitationJoinFailure', conn.remoteId);
              this.stage = 'joinFailure';
              break;
            }
            case 'refusedByWhispering': {
              conn.emit('LocalInvitationRefusedByAlreadyWhispering', conn.remoteId);
              this.clearTimer();
              this.stage = 'refusedByWhispering';
              break;
            }
            default: {
              this.clearTimer();
              conn.emit('LocalInvitationFailure', conn.remoteId);
            }
          }
          break;
        }
        default: {
          conn.emit('LocalInvitationFailure', `不明なデータを受信しました。type: ${data.type}`);
        }
      }
    });

    conn.on('close', () => {
      this.clearTimer();
      if (!this.closedGracefully) {
        conn.emit('LocalInvitationClosedByAccident', conn.remoteId);
      }
    });

    conn.on('error', error => {
      this.clearTimer();
      analytics.logEvent('data_connection_error', { uid: conn.id, remoteId: conn.remoteId, error });
      conn.emit('LocalInvitationFailure', conn.remoteId, error);
    });
  }

  getRawConnection(): DataConnection {
    return this.conn;
  }

  invite(invitationContent: InvitationContent, timeoutMs?: number): void {
    const data: ConnectionData = { type: 'invitation', payload: { stage: 'sending', content: invitationContent } };
    this.conn.send(data);
    if (timeoutMs) {
      this.timerId = setTimeout(() => {
        this.cancel();
      }, timeoutMs);
    }
  }

  cancel(): void {
    const data: ConnectionData = { type: 'invitation', payload: { stage: 'canceled' } };
    this.closedGracefully = true;
    this.clearTimer();
    this.conn.send(data);
    this.conn.emit('LocalInvitationCanceled', this.conn.remoteId);
  }

  close() {
    this.closedGracefully = true;
    this.conn.close(true);
  }

  clearTimer(): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  getStage(): InvitationStage {
    return this.stage;
  }
}
