/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { css, jsx } from '@emotion/core';
import CloseIcon from 'styles/image/close.svg';

const closeButton = css`
  background-color: transparent;
  border: none;
  outline: none;

  &:enabled {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

interface Props {
  handleOnCloseTextChat: () => void;
}

export const ChatHeader: FC<Props> = ({ handleOnCloseTextChat }) => {
  return (
    <div css={{ textAlign: 'right' }}>
      <button css={closeButton} type="button" onClick={handleOnCloseTextChat}>
        <img css={{ height: 20, width: 20 }} src={CloseIcon} alt="" />
      </button>
    </div>
  );
};
